import { environment } from '#environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var OfflineActivityTimelogApiService = /** @class */ (function () {
    //#region 'Angular Life Cycle'
    function OfflineActivityTimelogApiService(http) {
        this.http = http;
    }
    //#endregion 'Angular Life Cycle'
    //#region 'General'
    OfflineActivityTimelogApiService.prototype.getURL = function (method) {
        return environment.apiEndpoint + "/offlineActivityTimelog" + method;
    };
    //#endregion 'General'
    //#region 'Get'
    OfflineActivityTimelogApiService.prototype.getActivityCode = function () {
        return this.http.get(this.getURL("/getActivityCode"));
    };
    OfflineActivityTimelogApiService.prototype.getByOfflineActivityDateId = function (oaDateId) {
        return this.http.get(this.getURL("/getByOfflineActivityDateId"), {
            params: { oaDateId: oaDateId },
        });
    };
    //#endregion 'Get'
    //#region 'Post'
    OfflineActivityTimelogApiService.prototype.create = function (offlineActivityTimelog) {
        return this.http.post(this.getURL("/create"), offlineActivityTimelog);
    };
    OfflineActivityTimelogApiService.prototype.createBulk = function (offlineActivityTimelog) {
        return this.http.post(this.getURL("/createBulk"), offlineActivityTimelog);
    };
    //#endregion 'Post'
    //#region 'Put'
    OfflineActivityTimelogApiService.prototype.update = function (offlineActivityTimelog) {
        return this.http.put(this.getURL("/update"), offlineActivityTimelog);
    };
    OfflineActivityTimelogApiService.prototype.updateBulk = function (offlineActivityTimelog) {
        return this.http.put(this.getURL("/updateBulk"), offlineActivityTimelog);
    };
    //#endregion 'Put'
    //#region 'Delete'
    OfflineActivityTimelogApiService.prototype.delete = function (offlineActivityTimelog) {
        return this.http.put(this.getURL("/delete"), offlineActivityTimelog);
    };
    OfflineActivityTimelogApiService.ngInjectableDef = i0.defineInjectable({ factory: function OfflineActivityTimelogApiService_Factory() { return new OfflineActivityTimelogApiService(i0.inject(i1.HttpClient)); }, token: OfflineActivityTimelogApiService, providedIn: "root" });
    return OfflineActivityTimelogApiService;
}());
export { OfflineActivityTimelogApiService };
