/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./environment.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./environment.component";
import * as i4 from "../../../services/api/shared/wells-api.service";
import * as i5 from "@angular/router";
var styles_EnvironmentInfoComponent = [i0.styles];
var RenderType_EnvironmentInfoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EnvironmentInfoComponent, data: {} });
export { RenderType_EnvironmentInfoComponent as RenderType_EnvironmentInfoComponent };
export function View_EnvironmentInfoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["id", "envInfo"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(0, i2.JsonPipe, []), (_l()(), i1.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["id", "btn_return_settings"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.returnToSettings() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Return to Settings "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.jsonConfig)); _ck(_v, 2, 0, currVal_0); }); }
export function View_EnvironmentInfoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-environment-info", [], null, null, null, View_EnvironmentInfoComponent_0, RenderType_EnvironmentInfoComponent)), i1.ɵdid(1, 245760, null, 0, i3.EnvironmentInfoComponent, [i4.WellsApiService, i5.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EnvironmentInfoComponentNgFactory = i1.ɵccf("app-environment-info", i3.EnvironmentInfoComponent, View_EnvironmentInfoComponent_Host_0, {}, {}, []);
export { EnvironmentInfoComponentNgFactory as EnvironmentInfoComponentNgFactory };
