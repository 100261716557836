import { environment } from '#environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var WellsApiService = /** @class */ (function () {
    //#region 'Angular Life Cycle'
    function WellsApiService(http) {
        this.http = http;
    }
    //#endregion 'Angular Life Cycle'
    //#region 'General'
    WellsApiService.prototype.getURL = function (method) {
        return environment.apiEndpoint + "/Well" + method;
    };
    //#endregion 'General'
    //#region 'Get'
    WellsApiService.prototype.getWellData = function () {
        return this.http.get(this.getURL("/getAll"));
    };
    WellsApiService.prototype.getWellDataByID = function (wellID) {
        return this.http.get(this.getURL("/getByWellId"), { params: { wellId: wellID } });
    };
    WellsApiService.prototype.getApiConfigurationInfo = function () {
        return this.http.get(this.getURL("/getApiConfigurationInfo"));
    };
    WellsApiService.ngInjectableDef = i0.defineInjectable({ factory: function WellsApiService_Factory() { return new WellsApiService(i0.inject(i1.HttpClient)); }, token: WellsApiService, providedIn: "root" });
    return WellsApiService;
}());
export { WellsApiService };
