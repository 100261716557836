import { environment } from '#environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var TaskPlanApiService = /** @class */ (function () {
    //#region 'Angular Life Cycle'
    function TaskPlanApiService(http) {
        this.http = http;
    }
    //#endregion 'Angular Life Cycle'
    //#region 'General'
    TaskPlanApiService.prototype.getURL = function (method) {
        return environment.apiEndpoint + "/task-plan" + method;
    };
    //#endregion 'General'
    //#region 'TASK PLAN'
    //#region 'Get'
    TaskPlanApiService.prototype.getTaskPlans = function () {
        return this.http.get(this.getURL("/getTaskPlans"));
    };
    TaskPlanApiService.prototype.getTaskPlanById = function (id) {
        return this.http.get(this.getURL("/getTaskPlanById/" + id));
    };
    //#endregion 'Get'
    //#region 'Create'
    TaskPlanApiService.prototype.createTaskPlan = function (taskPlan) {
        return this.http.post(this.getURL("/createTaskPlan"), taskPlan);
    };
    //#endregion 'Create'
    //#region 'Update'
    TaskPlanApiService.prototype.updateTaskPlan = function (taskPlan) {
        return this.http.put(this.getURL("/updateTaskPlan"), taskPlan);
    };
    //#endregion 'Update'
    //#region 'Delete'
    TaskPlanApiService.prototype.deleteTaskPlan = function (id) {
        return this.http.put(this.getURL("/deleteTaskPlan/" + id), null);
    };
    //#endregion 'Delete'
    //#region 'Others'
    TaskPlanApiService.prototype.resetTaskPlan = function (id) {
        return this.http.put(this.getURL("/resetTaskPlan/" + id), null);
    };
    //#endregion 'Others'
    //#endregion 'TASK PLAN'
    //#region 'TASK PLAN SECTION'
    //#region 'Get'
    TaskPlanApiService.prototype.getTaskPlanSectionById = function (id) {
        return this.http.get(this.getURL("/getTaskPlanSectionById/" + id));
    };
    //#endregion 'Get'
    //#region 'Create'
    TaskPlanApiService.prototype.createTaskPlanSection = function (taskPlanSection) {
        return this.http.post(this.getURL("/createTaskPlanSection"), taskPlanSection);
    };
    //#endregion 'Create'
    //#region 'Update'
    TaskPlanApiService.prototype.updateTaskPlanSection = function (taskPlanSection) {
        return this.http.put(this.getURL("/updateTaskPlanSection"), taskPlanSection);
    };
    //#endregion 'Update'
    //#region 'Delete'
    TaskPlanApiService.prototype.deleteTaskPlanSection = function (id) {
        return this.http.put(this.getURL("/deleteTaskPlanSection/" + id), null);
    };
    //#endregion 'Delete'
    //#region 'Others'
    TaskPlanApiService.prototype.moveUpTaskPlanSection = function (id) {
        return this.http.put(this.getURL("/moveUpTaskPlanSection/" + id), null);
    };
    TaskPlanApiService.prototype.moveDownTaskPlanSection = function (id) {
        return this.http.put(this.getURL("/moveDownTaskPlanSection/" + id), null);
    };
    //#endregion 'Others'
    //#endregion 'TASK PLAN SECTION'
    //#region 'TASK PLAN SUB SECTION'
    //#region 'Get'
    TaskPlanApiService.prototype.getTaskPlanSubSections = function (id) {
        return this.http.get(this.getURL("/getTaskPlanSubSections/" + id));
    };
    TaskPlanApiService.prototype.getTaskPlanSubSectionById = function (id) {
        return this.http.get(this.getURL("/getTaskPlanSubSectionById/" + id));
    };
    //#endregion 'Get'
    //#region 'Create'
    TaskPlanApiService.prototype.createTaskPlanSubSection = function (taskPlanSubSection) {
        return this.http.post(this.getURL("/createTaskPlanSubSection"), taskPlanSubSection);
    };
    //#endregion 'Create'
    //#region 'Update'
    TaskPlanApiService.prototype.updateTaskPlanSubSection = function (taskPlanSubSection) {
        return this.http.put(this.getURL("/updateTaskPlanSubSection"), taskPlanSubSection);
    };
    //#endregion 'Update'
    //#region 'Delete'
    TaskPlanApiService.prototype.deleteTaskPlanSubSection = function (id) {
        return this.http.put(this.getURL("/deleteTaskPlanSubSection/" + id), null);
    };
    //#endregion 'Delete'
    //#region 'Others'
    TaskPlanApiService.prototype.moveUpTaskPlanSubSection = function (id) {
        return this.http.put(this.getURL("/moveUpTaskPlanSubSection/" + id), null);
    };
    TaskPlanApiService.prototype.moveDownTaskPlanSubSection = function (id) {
        return this.http.put(this.getURL("/moveDownTaskPlanSubSection/" + id), null);
    };
    //#endregion 'Others'
    //#endregion 'TASK PLAN SUB SECTION'
    //#region 'TASK PLAN ITEM'
    //#region 'Get'
    TaskPlanApiService.prototype.getTaskPlanItemById = function (id) {
        return this.http.get(this.getURL("/getTaskPlanItem/" + id));
    };
    //#endregion 'Get'
    //#region 'Create'
    TaskPlanApiService.prototype.createTaskPlanItem = function (taskPlanItem) {
        return this.http.post(this.getURL("/createTaskPlanItem"), taskPlanItem);
    };
    //#endregion 'Create'
    //#region 'Update'
    TaskPlanApiService.prototype.updateTaskPlanItem = function (taskPlanItem) {
        return this.http.put(this.getURL("/updateTaskPlanItem"), taskPlanItem);
    };
    //#endregion 'Update'
    //#region 'Delete'
    TaskPlanApiService.prototype.deleteTaskPlanItem = function (id) {
        return this.http.put(this.getURL("/deleteTaskPlanItem/" + id), null);
    };
    //#endregion 'Delete'
    //#region 'Others'
    TaskPlanApiService.prototype.moveUpTaskPlanItem = function (id) {
        return this.http.put(this.getURL("/moveUpTaskPlanItem/" + id), null);
    };
    TaskPlanApiService.prototype.moveDownTaskPlanItem = function (id) {
        return this.http.put(this.getURL("/moveDownTaskPlanItem/" + id), null);
    };
    //#endregion 'Others'
    //#endregion 'TASK PLAN ITEM'
    //#region 'GENERAL FUNCTIONS'
    TaskPlanApiService.prototype.isInvalidString = function (value) {
        return value === undefined || value == null || value.length === 0;
    };
    TaskPlanApiService.prototype.isInvalidInt = function (value) {
        return value === undefined || value == null || value === 0;
    };
    TaskPlanApiService.ngInjectableDef = i0.defineInjectable({ factory: function TaskPlanApiService_Factory() { return new TaskPlanApiService(i0.inject(i1.HttpClient)); }, token: TaskPlanApiService, providedIn: "root" });
    return TaskPlanApiService;
}());
export { TaskPlanApiService };
