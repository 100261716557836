var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { CasingComponent, CasingString, } from '#models/index';
import { CasingComponentApiService } from '#services-api/sections/casing-component-api.service';
import { CasingStringApiService } from '#services-api/sections/casing-string-api.service';
import { CentralizeApiService } from '#services-api/sections/centralize-api.service';
import { SectionsApiService } from '#services-api/sections/sections-api.service';
import { LoadingIndicatorService } from '#services-shared/loading-indicator.service';
import { TooltipService } from '#services-shared/tooltip.service';
import { OnDestroy } from '@angular/core';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
import * as i0 from "@angular/core";
import * as i1 from "../../api/sections/sections-api.service";
import * as i2 from "../../api/sections/centralize-api.service";
import * as i3 from "../../api/sections/casing-string-api.service";
import * as i4 from "../../api/sections/casing-component-api.service";
import * as i5 from "../loading-indicator.service";
import * as i6 from "../tooltip.service";
var SectionCasingService = /** @class */ (function () {
    //#endregion 'Objects'
    //#region 'Angular Life Cycle'
    function SectionCasingService(_section, _centralize, _casingString, _casingComponent, _loader, _tooltip) {
        this._section = _section;
        this._centralize = _centralize;
        this._casingString = _casingString;
        this._casingComponent = _casingComponent;
        this._loader = _loader;
        this._tooltip = _tooltip;
        //#region 'Variables'
        this.onRun = 0;
        this.onTotal = 0;
        this.onShelf = 0;
        this.onBadJoint = 0;
        this.onCentralized = 0;
        this.ratHole = 0;
        this.shoeDepth = 0;
        this.prevSysSeq = 0;
        this.unvalidateFromRun = false;
        this.casingStringIdApproved = false;
        //? Subscriptions
        this.SUBS$ = new Subscription();
        //#endregion 'Variables'
        //#region 'Objects'
        // Lists
        this.iconDescription = [];
        this.lstCasingODID = [];
        this.filteredTopThread = [];
        this.lstCasingComponent = [];
        this.lstCasingComponentOriginal = [];
        this.lstCasingDescription = [];
        this.filteredBottomThread = [];
        this.lstCasingComponentSubType = [];
        this.lstCasingComponents_Validated = [];
        this.lstCasingComponents_RunChecked = [];
        this.lstCasingComponents_NotValidated = [];
    }
    Object.defineProperty(SectionCasingService.prototype, "wellID", {
        get: function () {
            return this.wellId;
        },
        set: function (data) {
            this.wellId = data;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SectionCasingService.prototype, "wellBoreID", {
        get: function () {
            return this.wellBoreId;
        },
        set: function (data) {
            this.wellBoreId = data;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SectionCasingService.prototype, "HoleSize", {
        get: function () {
            return this.holeSize;
        },
        set: function (data) {
            this.holeSize = data;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SectionCasingService.prototype, "ActualTopDepth", {
        get: function () {
            return this.actualTopDepth;
        },
        set: function (data) {
            this.actualTopDepth = data;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SectionCasingService.prototype, "ActualBottomDepth", {
        get: function () {
            return this.actualBottomDepth;
        },
        set: function (data) {
            this.actualBottomDepth = data;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SectionCasingService.prototype, "StartDate", {
        get: function () {
            return this.startDate;
        },
        set: function (data) {
            this.startDate = data;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SectionCasingService.prototype, "EndDate", {
        get: function () {
            return this.endDate;
        },
        set: function (data) {
            this.endDate = data;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SectionCasingService.prototype, "SectionDescription", {
        get: function () {
            return this.sectionDescription;
        },
        set: function (data) {
            this.sectionDescription = data;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SectionCasingService.prototype, "casingStringID", {
        get: function () {
            return this.casingStringId;
        },
        set: function (data) {
            this.casingStringId = data;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SectionCasingService.prototype, "wellBoreSectionID", {
        get: function () {
            return this.wellBoreSectionId;
        },
        set: function (data) {
            this.wellBoreSectionId = data;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SectionCasingService.prototype, "casingDescription", {
        get: function () {
            return this.casingdescription;
        },
        set: function (data) {
            this.casingdescription = data;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SectionCasingService.prototype, "isdrilling", {
        get: function () {
            return this.isDrilling;
        },
        set: function (data) {
            this.isDrilling = data;
        },
        enumerable: true,
        configurable: true
    });
    SectionCasingService.prototype.ngOnDestroy = function () {
        this.SUBS$.unsubscribe();
        this._loader.hide();
    };
    //#endregion 'Angular Life Cycle'
    //#region 'Load'
    SectionCasingService.prototype.loadSection = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _b = (_a = this.SUBS$).add;
                        return [4 /*yield*/, this._section.getSectionBySectionID(this.wellBoreSectionId).subscribe(function (data) {
                                _this.holeSize = data.HoleSize;
                                _this.actualTopDepth = data.ActualTopDepth;
                                _this.actualBottomDepth = data.ActualBottomDepth;
                                _this.startDate = data.StartDate;
                                _this.endDate = data.EndDate;
                                _this.sectionDescription = data.SectionDescription;
                            })];
                    case 1:
                        _b.apply(_a, [_c.sent()]);
                        return [2 /*return*/];
                }
            });
        });
    };
    SectionCasingService.prototype.loadCasingDescription = function () {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var _a, _b, err_1;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        this.lstCasingDescription = [];
                        this._loader.show();
                        _b = (_a = this.SUBS$).add;
                        return [4 /*yield*/, this._casingString
                                .getCasingDescription()
                                .pipe(map(function (dt) {
                                dt.unshift({ Description: '---Select---', Help: 'Nothing to select' });
                                return dt;
                            }))
                                .finally(function () { return _this._loader.hide(); })
                                .subscribe(function (data) {
                                _this.lstCasingDescription = [];
                                _this.lstCasingDescription = data;
                                resolve(true);
                            }, function (error) {
                                console.error(error.message);
                                reject({
                                    key: 'casingError',
                                    severity: 'error',
                                    summary: 'We got a problem!',
                                    detail: 'There was an error loading casing description, please refresh the page or notify the administrator.',
                                    life: 6000,
                                });
                            })];
                    case 1:
                        _b.apply(_a, [_c.sent()]);
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _c.sent();
                        reject(Error(err_1));
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); });
    };
    SectionCasingService.prototype.loadCasingComponentMetaData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                        var _a, _b, err_2;
                        var _this = this;
                        return __generator(this, function (_c) {
                            switch (_c.label) {
                                case 0:
                                    _c.trys.push([0, 2, , 3]);
                                    this._loader.show();
                                    _b = (_a = this.SUBS$).add;
                                    return [4 /*yield*/, this._casingComponent
                                            .getCasingComponentMetaData()
                                            .finally(function () { return _this._loader.hide(); })
                                            .subscribe(function (data) {
                                            _this.lstCasingComponentColumns = data;
                                        }, function (error) {
                                            console.error(error.message);
                                            reject({
                                                key: 'casingError',
                                                severity: 'error',
                                                summary: 'We got a problem!',
                                                detail: 'There was an error loading casing description, please refresh the page or notify the administrator.',
                                                life: 6000,
                                            });
                                        })];
                                case 1:
                                    _b.apply(_a, [_c.sent()]);
                                    return [3 /*break*/, 3];
                                case 2:
                                    err_2 = _c.sent();
                                    reject(Error(err_2));
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    SectionCasingService.prototype.loadCasingItemDescription = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                        var _a, _b, err_3;
                        var _this = this;
                        return __generator(this, function (_c) {
                            switch (_c.label) {
                                case 0:
                                    _c.trys.push([0, 2, , 3]);
                                    this._loader.show();
                                    _b = (_a = this.SUBS$).add;
                                    return [4 /*yield*/, this._casingComponent.getCasingItemDescription().subscribe(function (data) {
                                            _this.lstCasingODID = [];
                                            _this.lstCasingODID = data;
                                            _this.filterData_Casing(_this.lstCasingODID);
                                            if (data[0]) {
                                                _this.selectedCasingODID = data[0];
                                            }
                                            _this._loader.hide();
                                        }, function (error) {
                                            console.error(error.message);
                                            _this._loader.hide();
                                            reject({
                                                key: 'casingError',
                                                severity: 'error',
                                                summary: 'We got a problem!',
                                                detail: 'There was an error loading casing item descriptions, please refresh the page or notify the administrator.',
                                                life: 6000,
                                            });
                                        })];
                                case 1:
                                    _b.apply(_a, [_c.sent()]);
                                    return [3 /*break*/, 3];
                                case 2:
                                    err_3 = _c.sent();
                                    reject(Error(err_3));
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    SectionCasingService.prototype.loadCasingComponentSubType = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                        var _a, _b, err_4;
                        var _this = this;
                        return __generator(this, function (_c) {
                            switch (_c.label) {
                                case 0:
                                    _c.trys.push([0, 2, , 3]);
                                    this._loader.show();
                                    _b = (_a = this.SUBS$).add;
                                    return [4 /*yield*/, this._casingComponent.getCasingItemSubType().subscribe(function (data) {
                                            _this.lstCasingComponentSubType = [];
                                            _this.lstCasingComponentSubType = data;
                                            _this._loader.hide();
                                        }, function (error) {
                                            console.error(error.message);
                                            _this._loader.hide();
                                            reject({
                                                key: 'casingError',
                                                severity: 'error',
                                                summary: 'We got a problem!',
                                                detail: 'There was an error loading casing description, please refresh the page or notify the administrator.',
                                                life: 6000,
                                            });
                                        })];
                                case 1:
                                    _b.apply(_a, [_c.sent()]);
                                    return [3 /*break*/, 3];
                                case 2:
                                    err_4 = _c.sent();
                                    reject(Error(err_4));
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    SectionCasingService.prototype.loadCasingComponentConnectionThread = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                        var _a, _b, err_5;
                        var _this = this;
                        return __generator(this, function (_c) {
                            switch (_c.label) {
                                case 0:
                                    _c.trys.push([0, 2, , 3]);
                                    this._loader.show();
                                    _b = (_a = this.SUBS$).add;
                                    return [4 /*yield*/, this._casingComponent.getCasingItemConnectionThread().subscribe(function (data) {
                                            _this.filteredTopThread = [];
                                            _this.filteredBottomThread = [];
                                            _this.filteredTopThread = data;
                                            _this.filteredBottomThread = data;
                                            _this.ddl_selectedTopThread = _this.filteredTopThread[0];
                                            _this.ddl_selectedBottomThread = _this.filteredBottomThread[0];
                                            _this._loader.hide();
                                        }, function (error) {
                                            console.error(error.message);
                                            _this._loader.hide();
                                            reject({
                                                key: 'casingError',
                                                severity: 'error',
                                                summary: 'We got a problem!',
                                                detail: 'There was an error loading casing description, please refresh the page or notify the administrator.',
                                                life: 6000,
                                            });
                                        })];
                                case 1:
                                    _b.apply(_a, [_c.sent()]);
                                    return [3 /*break*/, 3];
                                case 2:
                                    err_5 = _c.sent();
                                    reject(Error(err_5));
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    SectionCasingService.prototype.loadValidatedRecords = function (data, comeFrom) {
        var _this = this;
        return new Promise(function (resolve) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.resetLstCasingComponent();
                this.lstCasingComponent = this.filterByJointNumber(data);
                this.loadOriginalCasingComponent();
                this.addEdit();
                resolve({
                    key: comeFrom == 1 ? 'runningNotify' : 'casingNotify',
                    severity: 'success',
                    summary: 'Correct!',
                    detail: 'All Casings Validated Successfully',
                });
                return [2 /*return*/];
            });
        }); });
    };
    SectionCasingService.prototype.loadOriginalCasingComponent = function () {
        this.lstCasingComponentOriginal = [];
        this.lstCasingComponentOriginal = this._tooltip.deepClone(this.lstCasingComponent);
    };
    //#endregion 'Load'
    //#region 'General Methods'
    SectionCasingService.prototype.showNotification = function (ntf, type) {
        var _this = this;
        if (type === 1) {
            // Inventory
            this.notificationsInventory = [];
            this.notificationsInventory.push({
                severity: ntf.severity,
                summary: ntf.summary,
                detail: ntf.detail,
            });
        }
        else if (type === 2) {
            // Run Validated
            this.notificationsRunValidated = [];
            this.notificationsRunValidated.push({
                severity: ntf.severity,
                summary: ntf.summary,
                detail: ntf.detail,
            });
        }
        else if (type === 3) {
            // On Run
            this.notificationsOnRun = [];
            this.notificationsOnRun.push({
                severity: ntf.severity,
                summary: ntf.summary,
                detail: ntf.detail,
            });
        }
        else if (type === 4) {
            // Cement Volume
            this.notificationsCementVolume = [];
            this.notificationsCementVolume.push({
                severity: ntf.severity,
                summary: ntf.summary,
                detail: ntf.detail,
            });
        }
        setTimeout(function () {
            _this.notificationsInventory = [];
            _this.notificationsRunValidated = [];
            _this.notificationsOnRun = [];
            _this.notificationsCementVolume = [];
        }, 4000);
    };
    SectionCasingService.prototype.resetNotifications = function () {
        this.notificationsOnRun = [];
        this.notificationsInventory = [];
        this.notificationsRunValidated = [];
        this.notificationsCementVolume = [];
    };
    SectionCasingService.prototype.addEdit = function () {
        this.lstCasingComponent.map(function (casing) {
            casing['isEdit'] = false;
            return casing;
        });
    };
    SectionCasingService.prototype.getValidatedAndRunComponents = function () {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var RUN;
            return __generator(this, function (_a) {
                this.lstCasingComponents_Validated = [];
                this.lstCasingComponents_RunChecked = [];
                if (this.lstCasingComponent.length > 0) {
                    this.lstCasingComponents_Validated = this.lstCasingComponent.filter(function (obj) { return obj.IsValidated && !obj.Run; });
                    if (this.lstCasingComponents_Validated.length > 0) {
                        this.lstCasingComponents_Validated = this.filterByJointNumber(this.lstCasingComponents_Validated);
                    }
                    else {
                        this.lstCasingComponents_Validated = [];
                        this.resetDepthHole();
                        resolve({
                            severity: 'info',
                            summary: 'No Validated Components',
                            detail: 'Selected casing does not have any validated casing components.',
                        });
                    }
                    RUN = this.lstCasingComponent.filter(function (obj) { return obj.Run; }).length;
                    if (RUN > 0) {
                        this.loadRunList();
                    }
                    else {
                        this.calculateAmounts();
                    }
                }
                else {
                    this.lstCasingComponents_Validated = [];
                    this.lstCasingComponents_RunChecked = [];
                    this.resetAmounts();
                    resolve({
                        severity: 'info',
                        summary: 'No Casing Components',
                        detail: 'Selected casing does not have any validated or run casing components.',
                    });
                }
                return [2 /*return*/];
            });
        }); });
    };
    SectionCasingService.prototype.resetDepthHole = function () {
        this.shoeDepth = 0;
        this.ratHole = 0;
    };
    SectionCasingService.prototype.assignJointTopBottom = function (array, arrayType, casingStringRKB) {
        var ShoeDepthCalc = 0;
        if (array && array.length > 0) {
            if (arrayType === 'Original') {
                this.shoeDepth = array[0].JointBottom ? array[0].JointBottom : 0;
            }
            else {
                ShoeDepthCalc = array[0].JointBottom ? array[0].JointBottom : 0;
            }
            this.ratHole = Number(this.casingString.SetDepth) - this.shoeDepth;
        }
        else {
            this.shoeDepth = 0;
            ShoeDepthCalc = 0;
            this.ratHole = Number(this.casingString.SetDepth) - this.shoeDepth;
        }
        return ShoeDepthCalc;
    };
    SectionCasingService.prototype.computeBottom = function (array, rowIndex, refIndex, arrayType, casingStringRKB) {
        if (array[rowIndex].IsReferenceDepth) {
            return casingStringRKB;
        }
        if (refIndex < 0) {
            return 0;
        }
        if (rowIndex < refIndex) {
            return (Number(array[rowIndex].ThreadOff) +
                this.computeBottom(array, rowIndex + 1, refIndex, arrayType, casingStringRKB));
        }
        else {
            return Number(array[rowIndex - 1].JointTop);
        }
    };
    SectionCasingService.prototype.computeTop = function (array, rowIndex, refIndex) {
        if (refIndex < 0) {
            return 0;
        }
        return Number(array[rowIndex].JointBottom) - Number(array[rowIndex].ThreadOff);
    };
    SectionCasingService.prototype.resetLstCasingComponent = function () {
        this.lstCasingComponent = [];
        this.lstCasingComponentOriginal = [];
    };
    SectionCasingService.prototype.newCasingString = function () {
        var ncs = new CasingString();
        ncs.WellId = this.wellID;
        ncs.WellboreId = this.wellBoreID;
        ncs.CasingStringId = '';
        ncs.CasingDescription = this.selectedCasingDescription.Description;
        ncs.SetDepth = '0';
        ncs.RunDate = '';
        ncs.RKB = 0;
        ncs.Length = 0;
        ncs.Yield = 0;
        ncs.MixWater = 0;
        ncs.PrevCasingDescription = '';
        ncs.StickUp = 0;
        return ncs;
    };
    SectionCasingService.prototype.array_move = function (arr, old_index, new_index) {
        while (old_index < 0) {
            old_index += arr.length;
        }
        while (new_index < 0) {
            new_index += arr.length;
        }
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
    };
    SectionCasingService.prototype.getLastestJointNumber = function (data) {
        data = data && data.length > 0 ? data : this.lstCasingComponent;
        this.resetJoint();
        var DATA = [];
        if (this.isdrilling) {
            DATA = data.filter(function (obj) { return obj.JointNumber && obj.JointNumber.length > 0 && obj.JointNumber.startsWith('DP'); });
        }
        else {
            DATA = data.filter(function (obj) { return obj.JointNumber && obj.JointNumber.length > 0 && !obj.JointNumber.startsWith('DP'); });
        }
        DATA.sort(function (a, b) {
            return Number(a.JointNumber.replace('DP', '')) > Number(b.JointNumber.replace('DP', ''))
                ? 1
                : Number(b.JointNumber.replace('DP', '')) > Number(a.JointNumber.replace('DP', ''))
                    ? -1
                    : 0;
        });
        if (DATA.length > 0) {
            var Last_JointNumber = DATA[DATA.length - 1].JointNumber;
            if (Last_JointNumber) {
                var RegExSplit = Last_JointNumber.match(/[a-z]+|[^a-z]+/gi);
                if (RegExSplit && RegExSplit.length === 1 && !RegExSplit[0].match(/[a-z]/i)) {
                    this.CasingComponent_NewJointNumber = Number(RegExSplit[0]);
                }
                else {
                    if (!this.isdrilling) {
                        this.CasingComponent_NewJointLetter = RegExSplit[0];
                    }
                    this.CasingComponent_NewJointNumber = RegExSplit[1] ? Number(RegExSplit[1]) : 0;
                }
            }
        }
    };
    SectionCasingService.prototype.resetJoint = function () {
        this.CasingComponent_NewJointLetter = this.isdrilling ? 'DP' : undefined;
        this.CasingComponent_NewJointNumber = 0;
    };
    SectionCasingService.prototype.createNewCasingComponent = function () {
        var ncc = new CasingComponent();
        ncc.CasingComponentId.Value = '';
        ncc.JointNumber = this.getLastJoint(true, ncc);
        ncc.WellId.Value = this.wellID;
        ncc.CasingStringId.Value = this.casingString.CasingStringId;
        // Set these to the Last Used Configuration or Default
        ncc.OD.Value =
            this.lstCasingComponent.length > 0
                ? this.lstCasingComponent[this.lstCasingComponent.length - 1].OD.Value
                : this.filteredOD[0].OD;
        ncc.ID.Value =
            this.lstCasingComponent.length > 0
                ? this.lstCasingComponent[this.lstCasingComponent.length - 1].ID.Value
                : this.filteredOD[0].ID;
        ncc.Grade.Value =
            this.lstCasingComponent.length > 0
                ? this.lstCasingComponent[this.lstCasingComponent.length - 1].Grade.Value
                : this.filteredOD[0].Grade;
        ncc.Weight.Value =
            this.lstCasingComponent.length > 0
                ? this.lstCasingComponent[this.lstCasingComponent.length - 1].Weight.Value
                : this.filteredOD[0].Weight;
        ncc.TopThread.Value = this.ddl_selectedTopThread.Name;
        ncc.BottomThread.Value = this.ddl_selectedBottomThread.Name;
        ncc.Description.Value = this.isdrilling ? 'Drill Pipe' : 'Casing Joint';
        ncc.Length.Value = '0';
        ncc.SysSeq.Value = '';
        ncc.EquipmentType.Value = this.isdrilling ? 'Other' : 'Casing';
        ncc.IsExclude = this.isdrilling ? true : false;
        // These are not on the UI - They may have Errors
        ncc.CollapsePressure.Value = this.selectedCasingODID.CollapsePressure;
        ncc.BurstPressure.Value = this.selectedCasingODID.BurstPressure;
        // These are not on the UI - They may have Errors
        ncc.IsThreadLoss = false;
        ncc.ThreadLoss = '0';
        ncc.ThreadOff = ncc.Length.Value;
        return ncc;
    };
    SectionCasingService.prototype.getLastJoint = function (isNew, ncc) {
        this.CasingComponent_NewJointNumber++;
        var JTN = this.CasingComponent_NewJointLetter
            ? "" + this.CasingComponent_NewJointLetter + this.CasingComponent_NewJointNumber
            : "" + this.CasingComponent_NewJointNumber;
        if (ncc) {
            ncc.JointNumber = JTN;
        }
        if (isNew) {
            var duplicated = this.getDuplicate(ncc);
            while (duplicated.length > 0) {
                this.CasingComponent_NewJointNumber++;
                JTN = this.CasingComponent_NewJointLetter
                    ? "" + this.CasingComponent_NewJointLetter + this.CasingComponent_NewJointNumber
                    : "" + this.CasingComponent_NewJointNumber;
                ncc.JointNumber = JTN;
                duplicated = this.getDuplicate(ncc);
            }
            return JTN;
        }
        else {
            return JTN;
        }
    };
    SectionCasingService.prototype.checkforDuplicateJointNumbers = function (casingComponent, index) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var chkDuplicateJointNumber;
            return __generator(this, function (_a) {
                if (casingComponent && casingComponent.JointNumber && casingComponent.JointNumber === '') {
                    resolve(true);
                }
                else {
                    chkDuplicateJointNumber = this.getDuplicate(casingComponent);
                    if (chkDuplicateJointNumber.length > 0) {
                        if (index) {
                            this.lstCasingComponent[index].JointNumber = '';
                        }
                        reject({
                            key: 'inventoryError',
                            severity: 'warn',
                            summary: 'Something went wrong!',
                            detail: 'Joint number entered already exists, please try again.',
                            life: 6000,
                        });
                    }
                    else {
                        resolve(true);
                    }
                }
                return [2 /*return*/];
            });
        }); });
    };
    SectionCasingService.prototype.getDuplicate = function (casingComponent) {
        return this.lstCasingComponent.filter(function (obj) {
            return obj.JointNumber &&
                obj.JointNumber.length > 0 &&
                casingComponent.JointNumber &&
                casingComponent.JointNumber.length > 0 &&
                obj.JointNumber.trim() === casingComponent.JointNumber.trim() &&
                obj.CasingComponentId.Value !== casingComponent.CasingComponentId.Value;
        });
    };
    SectionCasingService.prototype.filterByJointNumber = function (casingComponent) {
        var ccWithJoint = casingComponent.filter(function (obj) { return obj.JointNumber && obj.JointNumber.length > 0; });
        var ccWithoutJoint = casingComponent.filter(function (obj) { return !obj.JointNumber || obj.JointNumber.length === 0; });
        if (ccWithJoint.length > 0) {
            ccWithJoint.sort(function (a, b) {
                return a.JointNumber.localeCompare(b.JointNumber, 'en', {
                    numeric: true,
                    sensitivity: 'accent',
                });
            });
            return ccWithoutJoint.concat(ccWithJoint);
        }
        else {
            return casingComponent;
        }
    };
    SectionCasingService.prototype.filterBySequence = function (casingComponent) {
        casingComponent.sort(function (a, b) {
            return Number(a.SysSeq.Value) > Number(b.SysSeq.Value) ? 1 : Number(b.SysSeq.Value) > Number(a.SysSeq.Value) ? -1 : 0;
        });
        return casingComponent;
    };
    SectionCasingService.prototype.reOrderRunCasings = function () {
        var seqValue = 1;
        this.lstCasingComponents_RunChecked.map(function (element) {
            element.SysSeq.Value = seqValue.toString();
            seqValue++;
            return element;
        });
    };
    SectionCasingService.prototype.reDoNumerationInArray = function (arr, startOn) {
        var seqValue = startOn ? startOn : 1;
        arr.map(function (element) {
            if (element.Run) {
                element.SysSeq.Value = seqValue.toString();
                seqValue++;
            }
            return element;
        });
        return arr;
    };
    SectionCasingService.prototype.resetLists = function () {
        this.lstCasingComponent = [];
        this.lstCasingComponentOriginal = [];
        this.lstCasingComponents_Validated = [];
        this.lstCasingComponents_RunChecked = [];
        this.lstCasingComponents_NotValidated = [];
    };
    SectionCasingService.prototype.sysSequence = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            try {
                if (_this.unvalidateFromRun) {
                    resolve(_this.reDoNumerationInArray(_this.lstCasingComponents_RunChecked, 1));
                }
                else {
                    resolve(_this.reDoNumerationInArray(_this.lstCasingComponents_Validated, 1));
                }
            }
            catch (err) {
                reject(Error(err));
            }
        });
    };
    SectionCasingService.prototype.resetALLData = function () {
        this.resetAmounts();
        this.ratHole = 0;
        this.shoeDepth = 0;
        this.prevSysSeq = 0;
        this.casingStringIdApproved = false;
        this.CasingComponent_NewJointNumber = undefined;
        this.CasingComponent_NewJointLetter = undefined;
        this.casingToUnvalidate = undefined;
        this.casingToUnvalidateIndex = undefined;
        // Lists
        this.lstCasingComponent = [];
        this.lstCasingComponentOriginal = [];
        this.lstCasingComponents_Validated = [];
        this.lstCasingComponents_RunChecked = [];
        this.lstCasingComponents_NotValidated = [];
        // Notifications
        this.notificationsOnRun = [];
        this.notificationsInventory = [];
        this.notificationsRunValidated = [];
        this.notificationsCementVolume = [];
        // Cement Volume
        this.prevCasingString = undefined;
        this.prevCasingStringID = undefined;
        this.prevShoeDepth = undefined;
        this.prevCasingID = undefined;
    };
    SectionCasingService.prototype.calculateAmounts = function () {
        this.resetAmounts();
        this.onShelf = this.lstCasingComponents_Validated.length;
        if (this.lstCasingComponents_RunChecked.length > 0) {
            this.onRun = this.lstCasingComponents_RunChecked.length;
            this.onBadJoint = this.lstCasingComponents_RunChecked.filter(function (obj) { return obj.IsBadJoint; }).length;
            this.onCentralized = this.lstCasingComponents_RunChecked.filter(function (obj) { return obj.IsCentralized === true; }).length;
        }
        this.onTotal = this.onRun + this.onShelf;
    };
    SectionCasingService.prototype.resetAmounts = function () {
        this.onRun = 0;
        this.onShelf = 0;
        this.onTotal = 0;
        this.onBadJoint = 0;
        this.onCentralized = 0;
    };
    //#endregion 'General Methods'
    //#region 'Excel & PDF'
    SectionCasingService.prototype.getCasingInventoryPDF = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                        var _a, _b;
                        var _this = this;
                        return __generator(this, function (_c) {
                            switch (_c.label) {
                                case 0:
                                    this._loader.show();
                                    _b = (_a = this.SUBS$).add;
                                    return [4 /*yield*/, this._casingComponent.getCasingInventoryPDF(this.casingStringID).subscribe(function (data) {
                                            var contentDisposition = data.headers.get('content-disposition');
                                            var filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
                                            var blobData = new Blob([data.body], { type: 'application/pdf' });
                                            //Download File
                                            _this._loader.hide();
                                            _this._tooltip.downloadBlob(blobData, filename);
                                        }, function (error) {
                                            console.error(error.message);
                                            _this._loader.hide();
                                            reject({
                                                key: 'inventoryError',
                                                severity: 'error',
                                                summary: 'We got a problem!',
                                                detail: 'There was an error retrieving inventory PDF, please refresh the page or notify the administrator.',
                                                life: 6000,
                                            });
                                        })];
                                case 1:
                                    _b.apply(_a, [_c.sent()]);
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    SectionCasingService.prototype.getCasingInventoryExcel = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                        var _a, _b;
                        var _this = this;
                        return __generator(this, function (_c) {
                            switch (_c.label) {
                                case 0:
                                    this._loader.show();
                                    _b = (_a = this.SUBS$).add;
                                    return [4 /*yield*/, this._casingComponent.getCasingInventoryExcel(this.casingStringID).subscribe(function (data) {
                                            var contentDisposition = data.headers.get('content-disposition');
                                            var filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
                                            var blobData = new Blob([data.body], { type: 'application/vnd.ms-excel' });
                                            //Download File
                                            _this._loader.hide();
                                            _this._tooltip.downloadBlob(blobData, filename);
                                        }, function (error) {
                                            console.error(error.message);
                                            _this._loader.hide();
                                            reject({
                                                key: 'inventoryError',
                                                severity: 'error',
                                                summary: 'We got a problem!',
                                                detail: 'There was an error retrieving inventory Excel, please refresh the page or notify the administrator.',
                                                life: 6000,
                                            });
                                        })];
                                case 1:
                                    _b.apply(_a, [_c.sent()]);
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    SectionCasingService.prototype.getCasingRunningTallyPDF_AllCasings = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                        var _a, _b;
                        var _this = this;
                        return __generator(this, function (_c) {
                            switch (_c.label) {
                                case 0:
                                    this._loader.show();
                                    _b = (_a = this.SUBS$).add;
                                    return [4 /*yield*/, this._casingComponent.getCasingRunningTallyPDF_AllCasings(this.casingStringID).subscribe(function (data) {
                                            var contentDisposition = data.headers.get('content-disposition');
                                            var filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
                                            var blobData = new Blob([data.body], { type: 'application/pdf' });
                                            //Download File
                                            _this._loader.hide();
                                            _this._tooltip.downloadBlob(blobData, filename);
                                        }, function (error) {
                                            console.error(error.message);
                                            _this._loader.hide();
                                            reject({
                                                key: 'runningError',
                                                severity: 'error',
                                                summary: 'We got a problem!',
                                                detail: 'There was an error retrieving PDF, please refresh the page or notify the administrator.',
                                                life: 6000,
                                            });
                                        })];
                                case 1:
                                    _b.apply(_a, [_c.sent()]);
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    SectionCasingService.prototype.getCasingRunningTallyPDF_RunOnly = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                        var _a, _b;
                        var _this = this;
                        return __generator(this, function (_c) {
                            switch (_c.label) {
                                case 0:
                                    this._loader.show();
                                    _b = (_a = this.SUBS$).add;
                                    return [4 /*yield*/, this._casingComponent.getCasingRunningTallyPDF_RunOnly(this.casingStringID).subscribe(function (data) {
                                            var contentDisposition = data.headers.get('content-disposition');
                                            var filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
                                            var blobData = new Blob([data.body], { type: 'application/pdf' });
                                            //Download File
                                            _this._loader.hide();
                                            _this._tooltip.downloadBlob(blobData, filename);
                                        }, function (error) {
                                            console.error(error.message);
                                            _this._loader.hide();
                                            reject({
                                                key: 'runningError',
                                                severity: 'error',
                                                summary: 'We got a problem!',
                                                detail: 'There was an error retrieving Run Only PDF, please refresh the page or notify the administrator.',
                                                life: 6000,
                                            });
                                        })];
                                case 1:
                                    _b.apply(_a, [_c.sent()]);
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    SectionCasingService.prototype.getCasingRunningTallyExcel_AllCasings = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                        var _a, _b;
                        var _this = this;
                        return __generator(this, function (_c) {
                            switch (_c.label) {
                                case 0:
                                    this._loader.show();
                                    _b = (_a = this.SUBS$).add;
                                    return [4 /*yield*/, this._casingComponent.getCasingRunningTallyExcel_AllCasings(this.casingStringID).subscribe(function (data) {
                                            var contentDisposition = data.headers.get('content-disposition');
                                            var filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
                                            var blobData = new Blob([data.body], { type: 'application/vnd.ms-excel' });
                                            //Download File
                                            _this._loader.hide();
                                            _this._tooltip.downloadBlob(blobData, filename);
                                        }, function (error) {
                                            console.error(error.message);
                                            _this._loader.hide();
                                            reject({
                                                key: 'runningError',
                                                severity: 'error',
                                                summary: 'We got a problem!',
                                                detail: 'There was an error retrieving All Casings Excel, please refresh the page or notify the administrator.',
                                                life: 6000,
                                            });
                                        })];
                                case 1:
                                    _b.apply(_a, [_c.sent()]);
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    SectionCasingService.prototype.getCasingRunningTallyExcel_RunOnly = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                        var _a, _b;
                        var _this = this;
                        return __generator(this, function (_c) {
                            switch (_c.label) {
                                case 0:
                                    this._loader.show();
                                    _b = (_a = this.SUBS$).add;
                                    return [4 /*yield*/, this._casingComponent.getCasingRunningTallyExcel_RunOnly(this.casingStringID).subscribe(function (data) {
                                            var contentDisposition = data.headers.get('content-disposition');
                                            var filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
                                            var blobData = new Blob([data.body], { type: 'application/vnd.ms-excel' });
                                            //Download File
                                            _this._loader.hide();
                                            _this._tooltip.downloadBlob(blobData, filename);
                                        }, function (error) {
                                            console.error(error.message);
                                            _this._loader.hide();
                                            reject({
                                                key: 'runningError',
                                                severity: 'error',
                                                summary: 'We got a problem!',
                                                detail: 'There was an error retrieving Run Only Casings Excel, please refresh the page or notify the administrator.',
                                                life: 6000,
                                            });
                                        })];
                                case 1:
                                    _b.apply(_a, [_c.sent()]);
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    //#endregion 'Excel & PDF'
    //#region 'Filters'
    SectionCasingService.prototype.filterData_Casing = function (data) {
        // Client may eventually ask to 'remember' the most used Configuration
        var key = Object.create(null);
        this.filteredID = [];
        this.filteredID = data.filter(function (obj) { return (key[obj.ID] ? false : (key[obj.ID] = true)); });
        this.filteredID = this.filteredID.filter(function (val) { return val.ID !== undefined && val.ID !== '' && val.ID !== null; });
        this.filteredID.sort(function (a, b) { return (Number(a.ID) > Number(b.ID) ? 1 : Number(b.ID) > Number(a.ID) ? -1 : 0); });
        this.filteredOD = [];
        this.filteredOD = data.filter(function (obj) { return (key[obj.OD] ? false : (key[obj.OD] = true)); });
        this.filteredOD = this.filteredOD.filter(function (val) { return val.OD !== undefined && val.OD !== '' && val.OD !== null; });
        this.filteredOD.sort(function (a, b) { return (Number(a.OD) > Number(b.OD) ? 1 : Number(b.OD) > Number(a.OD) ? -1 : 0); });
        this.filteredGrade = [];
        this.filteredGrade = data.filter(function (obj) { return (key[obj.Grade] ? false : (key[obj.Grade] = true)); });
        this.filteredGrade = this.filteredGrade.filter(function (val) { return val.Grade !== undefined && val.Grade !== '' && val.Grade !== null; });
        this.filteredGrade.sort(function (a, b) { return (a.Grade > b.Grade ? 1 : b.Grade > a.Grade ? -1 : 0); });
        this.filteredWeight = [];
        this.filteredWeight = data.filter(function (obj) { return (key[obj.Weight] ? false : (key[obj.Weight] = true)); });
        this.filteredWeight = this.filteredWeight.filter(function (val) { return val.Weight !== undefined && val.Weight !== '' && val.Weight !== null; });
        this.filteredWeight.sort(function (a, b) {
            return Number(a.Weight) > Number(b.Weight) ? 1 : Number(b.Weight) > Number(a.Weight) ? -1 : 0;
        });
    };
    //#endregion 'Filters'
    //#region 'CRUD'
    SectionCasingService.prototype.createCasingString = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                        var newCasingString, _a, _b;
                        var _this = this;
                        return __generator(this, function (_c) {
                            switch (_c.label) {
                                case 0:
                                    this.casingString = null;
                                    newCasingString = this.newCasingString();
                                    _b = (_a = this.SUBS$).add;
                                    return [4 /*yield*/, this._casingString
                                            .create(newCasingString)
                                            .finally(function () { return _this._loader.hide(); })
                                            .subscribe(function (data) {
                                            _this.casingString = data;
                                            _this.casingStringID = data.CasingStringId;
                                            _this.resetLstCasingComponent();
                                            _this.casingStringIdApproved = false;
                                            resolve({
                                                key: 'casingNotify',
                                                severity: 'success',
                                                summary: 'Correct!',
                                                detail: 'The casing was created successfully!.',
                                            });
                                        }, function (error) {
                                            console.error(error.message);
                                            reject({
                                                key: 'casingError',
                                                severity: 'error',
                                                summary: 'We got a problem!',
                                                detail: 'There was an error creating a casing string, please refresh the page or notify the administrator.',
                                                life: 6000,
                                            });
                                        })];
                                case 1:
                                    _b.apply(_a, [_c.sent()]);
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    // TODO: Special case cause we get the type
    SectionCasingService.prototype.updateCasingString = function (notificationType, updateType) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                        var _a, _b;
                        var _this = this;
                        return __generator(this, function (_c) {
                            switch (_c.label) {
                                case 0:
                                    if (!this.casingString) {
                                        return [2 /*return*/];
                                    }
                                    this._loader.show();
                                    _b = (_a = this.SUBS$).add;
                                    return [4 /*yield*/, this._casingString
                                            .update(this.casingString)
                                            .finally(function () { return _this._loader.hide(); })
                                            .subscribe(function (data) {
                                            _this.casingString = data;
                                            if (updateType !== 'Cement_Volume') {
                                                var RUN = _this.lstCasingComponent.filter(function (obj) { return obj.Run; }).length;
                                                if (RUN > 0) {
                                                    _this.loadRunList();
                                                }
                                            }
                                            return true;
                                        }, function (error) {
                                            console.error(error.message);
                                            reject({
                                                key: 'runningError',
                                                severity: 'error',
                                                summary: 'We got a problem!',
                                                detail: 'There was an error saving casing string information, please refresh the page or notify the administrator.',
                                                life: 6000,
                                            });
                                        })];
                                case 1:
                                    _b.apply(_a, [_c.sent()]);
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    SectionCasingService.prototype.casingComponentUpdate = function (casingComponent, loadRun) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                        var _a, _b;
                        var _this = this;
                        return __generator(this, function (_c) {
                            switch (_c.label) {
                                case 0:
                                    if (!casingComponent) {
                                        return [2 /*return*/];
                                    }
                                    this._loader.show();
                                    _b = (_a = this.SUBS$).add;
                                    return [4 /*yield*/, this._casingComponent
                                            .update(casingComponent)
                                            .finally(function () {
                                            if (!loadRun) {
                                                _this._loader.hide();
                                            }
                                        })
                                            .subscribe(function () {
                                            if (loadRun) {
                                                _this.loadRunList();
                                                _this._loader.hide();
                                            }
                                        }, function (error) {
                                            console.error(error.message);
                                            _this._loader.hide();
                                            reject({
                                                key: 'runningError',
                                                severity: 'error',
                                                summary: 'We got a problem!',
                                                detail: 'There was an error updating casing component information, please refresh the page or notify the administrator.',
                                                life: 6000,
                                            });
                                        })];
                                case 1:
                                    _b.apply(_a, [_c.sent()]);
                                    return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    SectionCasingService.prototype.loadRunList = function () {
        var _this = this;
        this._loader.show();
        this.SUBS$.add(this._casingComponent
            .getRunningByCasingStringId(this.casingStringId)
            .pipe(map(function (cc) {
            cc.map(function (dto) {
                dto.isReorder = false;
                return dto;
            });
            return cc;
        }))
            .subscribe(function (data) {
            _this.lstCasingComponents_RunChecked = _this._tooltip.deepClone(data);
            if (_this.lstCasingComponents_RunChecked.length > 0) {
                _this.lstCasingComponents_RunChecked = _this.filterBySequence(_this.lstCasingComponents_RunChecked);
                _this.assignJointTopBottom(_this.lstCasingComponents_RunChecked, 'Original', _this.casingString.RKB);
            }
            else {
                _this.lstCasingComponents_RunChecked = [];
                _this.resetDepthHole();
            }
            _this.calculateAmounts();
            _this._loader.hide();
        }));
    };
    SectionCasingService.prototype.updateCasingComponentBulk = function (arrayCasingComponent, infoField, comeFrom) {
        var _this = this;
        this._loader.show();
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var _a, _b;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _b = (_a = this.SUBS$).add;
                        return [4 /*yield*/, this._casingComponent.updateBulk(arrayCasingComponent).subscribe(function (data) {
                                if (infoField === 'ValidateAll') {
                                    var notify_1;
                                    _this.loadValidatedRecords(data, comeFrom).then(function (correct) {
                                        _this._loader.hide();
                                        _this.getValidatedAndRunComponents();
                                        notify_1 = correct;
                                        resolve(notify_1);
                                    });
                                }
                                else {
                                    _this.resetLstCasingComponent();
                                    _this.lstCasingComponent = _this.filterByJointNumber(data);
                                    _this.loadOriginalCasingComponent();
                                    _this.addEdit();
                                    // Not showing an error Message for UpdateRunSequenceNumbers
                                    if (infoField === 'UpdateThreadOff') {
                                        _this._loader.hide();
                                        // Casing Tally
                                        resolve({
                                            key: 'casingNotify',
                                            severity: 'success',
                                            summary: 'Correct!',
                                            detail: "Information updated correctly!",
                                        });
                                    }
                                    else if (infoField === 'ToggleSequence') {
                                        _this.getValidatedAndRunComponents();
                                        // Running
                                        _this._loader.hide();
                                        resolve({
                                            key: 'runningNotify',
                                            severity: 'success',
                                            summary: 'Correct!',
                                            detail: "Information updated correctly!",
                                        });
                                    }
                                    else if (infoField === 'MultipleEdit') {
                                        _this._loader.hide();
                                        // Casing Tally
                                        resolve({
                                            key: 'casingNotify',
                                            severity: 'success',
                                            summary: 'Correct!',
                                            detail: "Information updated correctly!",
                                        });
                                    }
                                    else if (infoField === 'Centralized') {
                                        _this._loader.hide();
                                        // Running
                                        resolve({
                                            key: 'runningNotify',
                                            severity: 'success',
                                            summary: 'Correct!',
                                            detail: "Information updated correctly!",
                                        });
                                    }
                                    else if (infoField === 'LoadRun') {
                                        _this.loadRunList();
                                        _this._loader.hide();
                                        // Running
                                        resolve({
                                            key: 'runningNotify',
                                            severity: 'success',
                                            summary: 'Correct!',
                                            detail: "Information updated correctly!",
                                        });
                                    }
                                    else {
                                        _this._loader.hide();
                                        // General
                                        resolve({
                                            key: 'casingNotify',
                                            severity: 'success',
                                            summary: 'Correct!',
                                            detail: "Information updated correctly!",
                                        });
                                    }
                                }
                            }, function (error) {
                                console.error(error.message);
                                _this._loader.hide();
                                reject({
                                    key: comeFrom == 1 ? 'runningError' : 'casingError',
                                    severity: 'error',
                                    summary: 'We got a problem!',
                                    detail: 'There was an error updating casing components, please refresh the page or notify the administrator.',
                                    life: 6000,
                                });
                            })];
                    case 1:
                        _b.apply(_a, [_c.sent()]);
                        return [2 /*return*/];
                }
            });
        }); });
    };
    SectionCasingService.prototype.runCasing = function (casingComponent, addToRunChecked, setRun) {
        if (setRun) {
            casingComponent.Run = true;
        }
        if (this.lstCasingComponents_RunChecked.length > 0) {
            this.prevSysSeq =
                this.lstCasingComponents_RunChecked.length > 0 ? this.lstCasingComponents_RunChecked.length + 1 : 0;
            casingComponent.SysSeq.Value = this.getNextSysSeq();
        }
        else {
            casingComponent.SysSeq.Value = '1';
        }
        if (addToRunChecked) {
            this.lstCasingComponents_RunChecked.push(casingComponent);
        }
        return casingComponent;
    };
    SectionCasingService.prototype.getNextSysSeq = function (startFrom) {
        if (startFrom) {
            this.prevSysSeq = startFrom;
        }
        else {
            this.prevSysSeq = this.prevSysSeq + 1;
        }
        return this.prevSysSeq.toString();
    };
    SectionCasingService.prototype.unvalidateCasing = function () {
        if (!this.casingToUnvalidate) {
            return undefined;
        }
        return this._casingComponent.unvalidateCasing(this.casingToUnvalidate);
    };
    SectionCasingService.prototype.createCentralizeSetup = function (dto) {
        if (dto.length === 1) {
            return this._centralize.create(dto[0]);
        }
        else {
            return this._centralize.createBulk(dto);
        }
    };
    SectionCasingService.prototype.updateCentralizeSetup = function (dto) {
        if (dto.length === 1) {
            return this._centralize.update(dto[0]);
        }
        else {
            return this._centralize.updateBulk(dto);
        }
    };
    SectionCasingService.prototype.deleteCentralizeSetup = function (dto) {
        if (dto.length === 1) {
            return this._centralize.delete(dto[0]);
        }
        else {
            return this._centralize.deleteBulk(dto);
        }
    };
    SectionCasingService.prototype.setUnvalidate = function () {
        this.casingToUnvalidate.Run = false;
        this.casingToUnvalidate.SysSeq.Value = '';
        this.casingToUnvalidate.IsValidated = false;
        this.casingToUnvalidate.IsReferenceDepth = false;
        if (this.unvalidateFromRun) {
            this.lstCasingComponents_RunChecked[this.casingToUnvalidateIndex] = this.casingToUnvalidate;
        }
        else {
            this.lstCasingComponents_Validated[this.casingToUnvalidateIndex] = this.casingToUnvalidate;
        }
    };
    SectionCasingService.prototype.getIconDescription = function () {
        var _this = this;
        this.SUBS$.add(this._casingComponent.getIconDescription().subscribe(function (data) {
            _this.iconDescription = data.slice();
        }));
    };
    //#endregion 'CRUD'
    //#region 'Cement Volume'
    SectionCasingService.prototype.loadCementVolUniqueOD = function (array) {
        var UniqueODValues = [];
        if (array.length > 0) {
            var tempVals = array.filter(function (value, index, self) { return self.map(function (x) { return x.OD.Value; }).indexOf(value.OD.Value) === index; });
            tempVals.forEach(function (obj) { return UniqueODValues.push(obj.OD.Value); });
            UniqueODValues = UniqueODValues.filter(function (obj) { return obj !== ''; });
        }
        return UniqueODValues;
    };
    SectionCasingService.prototype.loadCementVolUniqueID = function (array) {
        var UniqueIDValues = [];
        if (array.length > 0) {
            var tempVals = array.filter(function (value, index, self) { return self.map(function (x) { return x.ID.Value; }).indexOf(value.ID.Value) === index; });
            tempVals.forEach(function (obj) { return UniqueIDValues.push(obj.ID.Value); });
            UniqueIDValues = UniqueIDValues.filter(function (obj) { return obj !== ''; });
        }
        return UniqueIDValues;
    };
    SectionCasingService.prototype.calculateMixedNumbers = function (mixedNumber) {
        var calcMix = mixedNumber.split(' ');
        var mixedNum = calcMix[1].split('/');
        return Number(calcMix[0]) + Number(mixedNum[0]) / Number(mixedNum[1]);
    };
    SectionCasingService.prototype.loadPreviousCasingData = function (IsUpdateCasingString, casingDesc) {
        var _this = this;
        this.resetNotifications();
        this._loader.show();
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.SUBS$.add(this._casingString.getWellboreIdCasingDescription(this.wellBoreID, casingDesc).subscribe(function (data) {
                    if (data && data.length > 0) {
                        _this.prevCasingString = data[0];
                        _this.prevCasingStringID = data[0].CasingStringId;
                        if (IsUpdateCasingString) {
                            _this.casingString.PrevCasingDescription = casingDesc;
                            _this.updateCasingString(4, 'Cement_Volume');
                        }
                        _this.loadPreviousCasingComponentData().then(function () {
                            resolve();
                        }, function (err) {
                            reject(err);
                        });
                    }
                    else {
                        reject('EmptyCasingString');
                    }
                    _this._loader.hide();
                }, function (error) {
                    console.error(error.message);
                    _this._loader.hide();
                    reject('ErrorPreviousCasing');
                }));
                return [2 /*return*/];
            });
        }); });
    };
    SectionCasingService.prototype.loadPreviousCasingComponentData = function () {
        var _this = this;
        this.resetNotifications();
        this._loader.show();
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var lstPrevCasingComponents;
            var _this = this;
            return __generator(this, function (_a) {
                lstPrevCasingComponents = [];
                this.SUBS$.add(this._casingComponent.getCasingStringId(this.prevCasingStringID).subscribe(function (data) {
                    if (data && data.length > 0) {
                        lstPrevCasingComponents = data;
                        _this.prevCasingID = _this.loadCementVolUniqueID(lstPrevCasingComponents)[0];
                        var lstPrevCasingComponents_RunChecked = lstPrevCasingComponents.filter(function (obj) { return obj.IsValidated && obj.Run; });
                        if (lstPrevCasingComponents_RunChecked.length > 0) {
                            lstPrevCasingComponents_RunChecked = _this.filterBySequence(lstPrevCasingComponents_RunChecked);
                            _this.prevShoeDepth = _this.assignJointTopBottom(lstPrevCasingComponents_RunChecked, 'CementVolume', _this.prevCasingString.RKB);
                            resolve();
                        }
                        else {
                            _this.prevCasingID = undefined;
                            _this.prevShoeDepth = undefined;
                            reject('EmptyShoeDepth');
                        }
                    }
                    else {
                        _this.prevCasingID = undefined;
                        _this.prevShoeDepth = undefined;
                        reject('EmptyCasingID');
                    }
                    _this._loader.hide();
                }, function (error) {
                    console.error(error.message);
                    _this.prevCasingID = undefined;
                    _this.prevShoeDepth = undefined;
                    _this._loader.hide();
                    reject('ErrorPreviousCasingInfo');
                }));
                return [2 /*return*/];
            });
        }); });
    };
    SectionCasingService.prototype.reloadCasingsAfterApproval = function (data) {
        var _this = this;
        return new Promise(function (resolve) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.resetLstCasingComponent();
                this.lstCasingComponent = this.filterByJointNumber(data).slice();
                this.loadOriginalCasingComponent();
                this.addEdit();
                this.casingStringIdApproved = true;
                this.getValidatedAndRunComponents();
                this._loader.hide();
                resolve({
                    key: 'casingNotify',
                    severity: 'success',
                    summary: 'Correct!',
                    detail: 'Selected casing has been successfully approved.',
                });
                return [2 /*return*/];
            });
        }); });
    };
    SectionCasingService.prototype.getValidatedAmount = function () {
        return this.lstCasingComponent.filter(function (dto) { return dto.IsValidated === false; }).length;
    };
    SectionCasingService.ngInjectableDef = i0.defineInjectable({ factory: function SectionCasingService_Factory() { return new SectionCasingService(i0.inject(i1.SectionsApiService), i0.inject(i2.CentralizeApiService), i0.inject(i3.CasingStringApiService), i0.inject(i4.CasingComponentApiService), i0.inject(i5.LoadingIndicatorService), i0.inject(i6.TooltipService)); }, token: SectionCasingService, providedIn: "root" });
    return SectionCasingService;
}());
export { SectionCasingService };
