import * as moment from 'moment';
import * as i0 from "@angular/core";
var TooltipService = /** @class */ (function () {
    //#endregion 'Variables'
    function TooltipService() {
        //#region 'Variables'
        this.enableNavbar = true;
    }
    TooltipService.prototype.GetDuration = function (startDate, endDate, startTime, endTime, language, culture) {
        moment.locale(culture && culture.length > 0 ? culture : 'en');
        var timespan = null;
        var day = null;
        var days = null;
        var dateIni = startDate + "T" + startTime + ":00Z";
        var dateFin = endDate + "T" + endTime + ":00Z";
        if (language === 'es') {
            day = 'día';
            days = 'dias';
        }
        else if (language === 'en') {
            day = 'day';
            days = 'days';
        }
        // Convert dates to Moment
        var DTEINI = moment(dateIni);
        var DTEFIN = moment(dateFin);
        // Get dates difference
        var duration = moment.duration(DTEFIN.diff(DTEINI));
        var _days = DTEFIN.diff(DTEINI, 'days');
        var _hrs = duration.get('hours');
        var _mins = duration.get('minutes');
        if (_days > 0 && _hrs === 0 && _mins === 0) {
            if (_days === 1) {
                timespan = _days + " " + day;
            }
            else {
                timespan = _days + " " + days;
            }
        }
        else if (_days > 0 && _hrs > 0 && _mins === 0) {
            if (_days === 1) {
                timespan = _days + " " + day + ", " + _hrs + " hrs";
            }
            else {
                timespan = _days + " " + days + ", " + _hrs + " hrs";
            }
        }
        else if (_days > 0 && _hrs > 0 && _mins > 0) {
            if (_days === 1) {
                timespan = _days + " " + day + ", " + _hrs + " hrs, " + _mins + " mins";
            }
            else {
                timespan = _days + " " + days + ", " + _hrs + " hrs, " + _mins + " mins";
            }
        }
        else if (_days === 0 && _hrs > 0 && _mins > 0) {
            timespan = _hrs + " hrs, " + _mins + " mins";
        }
        else if (_days === 0 && _hrs > 0 && _mins === 0) {
            if (_hrs === 1) {
                timespan = _hrs + " hr";
            }
            else {
                timespan = _hrs + " hrs";
            }
        }
        else if (_days === 0 && _hrs === 0 && _mins > 0) {
            if (_mins === 1) {
                timespan = _mins + " min";
            }
            else {
                timespan = _mins + " mins";
            }
        }
        return timespan;
    };
    TooltipService.prototype.GetDaysOfDates = function (startDate, endDate, culture) {
        moment.locale(culture && culture.length > 0 ? culture : 'en');
        var timespan = 0;
        // Convert dates to Moment
        var DTEINI = moment(startDate);
        var DTEFIN = moment(endDate);
        var x = moment(DTEINI.format('YYYY-MM-DD'));
        var y = moment(DTEFIN.format('YYYY-MM-DD'));
        // Get dates difference
        var duration = y.diff(x, 'days');
        // Add 1 to count the start day
        var _days = duration + 1;
        timespan = _days;
        return timespan;
    };
    TooltipService.prototype.GetDatesDesciption = function (startDate, endDate, culture) {
        moment.locale(culture && culture.length > 0 ? culture : 'en');
        var date = '';
        // Convert dates to Moment
        var DTEINI = moment(startDate);
        var DTEFIN = moment(endDate);
        var startDay = DTEINI.format('D');
        var endDay = DTEFIN.format('D');
        if (startDay === '1') {
            startDay = startDay + "st";
        }
        else if (startDay === '2') {
            startDay = startDay + "nd";
        }
        else if (startDay === '3') {
            startDay = startDay + "rd";
        }
        else {
            startDay = startDay + "th";
        }
        if (endDay === '1') {
            endDay = endDay + "st";
        }
        else if (endDay === '2') {
            endDay = endDay + "nd";
        }
        else if (endDay === '3') {
            endDay = endDay + "rd";
        }
        else {
            endDay = endDay + "th";
        }
        var startMonth = DTEINI.format('MMMM');
        var endtMonth = DTEFIN.format('MMMM');
        var startYear = DTEINI.format('YYYY');
        var endYear = DTEFIN.format('YYYY');
        if (startMonth === endtMonth && startYear === endYear) {
            date = this.FirstLetterCapital(startMonth.toLowerCase()) + " " + startDay + " to " + endDay + ", " + startYear;
        }
        else if (startMonth !== endtMonth && startYear === endYear) {
            date = this.FirstLetterCapital(startMonth.toLowerCase()) + " " + startDay + " to " + this.FirstLetterCapital(endtMonth.toLowerCase()) + " " + endDay + ", " + startYear;
        }
        else if (startYear !== endYear) {
            date = this.FirstLetterCapital(startMonth.toLowerCase()) + " " + startDay + ", " + startYear + " to " + this.FirstLetterCapital(endtMonth.toLowerCase()) + " " + endDay + ", " + endYear;
        }
        return date;
    };
    TooltipService.prototype.GetDateFormatted = function (date, culture) {
        moment.locale(culture && culture.length > 0 ? culture : 'en');
        var nowDay = moment().format('DD');
        var fecha = moment(date);
        var day = fecha.format('DD');
        var time = fecha.format('hh:mm A');
        if (nowDay === day) {
            return "Today - " + time;
        }
        else if (Number(nowDay) - 1 === Number(day)) {
            return "Yesterday - " + time;
        }
        else {
            var formatedDate = moment(date).format('D MMM YYYY');
            return formatedDate + " - " + time;
        }
    };
    TooltipService.prototype.GetDateHourFormatted = function (date, culture) {
        moment.locale(culture && culture.length > 0 ? culture : 'en');
        var fmtDate;
        var x = moment(date).format('D MMM YYYY hh:mm A');
        fmtDate = this.FirstLetterCapital(x);
        fmtDate = this.RemoveLastPoint(fmtDate);
        return fmtDate;
    };
    TooltipService.prototype.currencyFormat = function (amount, currency) {
        var x = amount.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
        if (currency) {
            return "$" + x + " " + currency;
        }
        else {
            return "$" + x;
        }
    };
    TooltipService.prototype.FormatDateShow = function (fecha, culture) {
        moment.locale(culture && culture.length > 0 ? culture : 'en');
        var date;
        if (fecha && fecha.length === 0) {
            date = moment().format('D MMM YYYY');
        }
        else {
            date = moment(fecha).format('D MMM YYYY');
        }
        date = this.FirstLetterCapital(date);
        date = this.RemoveLastPoint(date);
        return date;
    };
    TooltipService.prototype.getFileExtension = function (filename) {
        return /[.]/.exec(filename) ? /[^.]+$/.exec(filename)[0] : undefined;
    };
    TooltipService.prototype.isToday = function (reference, momentDate) {
        return momentDate.isSame(reference, 'd');
    };
    TooltipService.prototype.isYesterday = function (reference, momentDate) {
        return momentDate.isSame(reference, 'd');
    };
    TooltipService.prototype.momentFormat = function (date) {
        var fmtDate;
        var x = moment(date).format('D MMM YYYY');
        fmtDate = this.FirstLetterCapital(x);
        fmtDate = this.RemoveLastPoint(fmtDate);
        return fmtDate;
    };
    TooltipService.prototype.momentFormatNormal = function (date) {
        return moment(date).format('D/MM/YYYY');
    };
    TooltipService.prototype.momentGetCurrentMonth = function (date) {
        return moment(date, 'YYYY/MM/DD').format('M');
    };
    TooltipService.prototype.momentGetCurrentYear = function (date) {
        return moment(date, 'YYYY/MM/DD').format('YYYY');
    };
    TooltipService.prototype.currencyFormatUSD = function (number, digits) {
        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: digits,
        });
        return formatter.format(number);
    };
    TooltipService.prototype.ReplaceRareWordsHTML = function (text) {
        var newText = text;
        if (newText) {
            newText = newText.replace(/&#32;/g, ' ');
            newText = newText.replace(/&#33;/g, '!');
            newText = newText.replace(/&#34;/g, '"');
            newText = newText.replace(/&quot;/g, '"');
            newText = newText.replace(/&#35;/g, '#');
            newText = newText.replace(/&#36;/g, '$');
            newText = newText.replace(/&#37;/g, '%');
            newText = newText.replace(/&#38;/g, '&');
            newText = newText.replace(/&amp;/g, '&');
            newText = newText.replace(/&#40;/g, '(');
            newText = newText.replace(/&#41;/g, ')');
            newText = newText.replace(/&#42;/g, '*');
            newText = newText.replace(/&#43;/g, '+');
            newText = newText.replace(/&#44;/g, ',');
            newText = newText.replace(/&#45;/g, '-');
            newText = newText.replace(/&#46;/g, '.');
            newText = newText.replace(/&#47;/g, '/');
            newText = newText.replace(/&#48;/g, '0');
            newText = newText.replace(/&#49;/g, '1');
            newText = newText.replace(/&#50;/g, '2');
            newText = newText.replace(/&#51;/g, '3');
            newText = newText.replace(/&#52;/g, '4');
            newText = newText.replace(/&#53;/g, '5');
            newText = newText.replace(/&#54;/g, '6');
            newText = newText.replace(/&#55;/g, '7');
            newText = newText.replace(/&#56;/g, '8');
            newText = newText.replace(/&#57;/g, '9');
            newText = newText.replace(/&#58;/g, ':');
            newText = newText.replace(/&#59;/g, ';');
            newText = newText.replace(/&#60;/g, '<');
            newText = newText.replace(/&lt;/g, '<');
            newText = newText.replace(/&#61;/g, '=');
            newText = newText.replace(/&#62;/g, '>');
            newText = newText.replace(/&gt;/g, '>');
            newText = newText.replace(/&#63;/g, '?');
            newText = newText.replace(/&#64;/g, '@');
            newText = newText.replace(/&#91;/g, '[');
            newText = newText.replace(/&#93;/g, ']');
            newText = newText.replace(/&#94;/g, '^');
            newText = newText.replace(/&#95;/g, '_');
            newText = newText.replace(/&#123;/g, '{');
            newText = newText.replace(/&#124;/g, '|');
            newText = newText.replace(/&#125;/g, '}');
            newText = newText.replace(/&#126;/g, '~');
            newText = newText.replace(/&#160;/g, ' ');
        }
        return newText;
    };
    TooltipService.prototype.getAllMonths = function () {
        return moment.months();
    };
    TooltipService.prototype.FirstLetterCapital = function (texto) {
        if (texto && texto.length > 0) {
            return texto.replace(/^([a-z\u00E0-\u00FC])|\s+([a-z\u00E0-\u00FC])/g, function ($1) {
                return $1.toUpperCase();
            });
        }
        else {
            return null;
        }
    };
    TooltipService.prototype.RemoveLastPoint = function (text) {
        return text.replace('.', '');
    };
    TooltipService.prototype.UUID = function () {
        return 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (Math.random() * 16) | 0, v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    };
    TooltipService.prototype.getFirstLetterWord = function (word) {
        var x = this.FirstLetterCapital(word);
        var y = x.concat(' ').replace(/([a-zA-Z]{0,} )/g, function (match) {
            return match.trim()[0];
        });
        return y.substring(0, 3);
    };
    TooltipService.prototype.zeroPad = function (num, places) {
        var zero = places - num.toString().length + 1;
        return Array(+(zero > 0 && zero)).join('0') + num;
    };
    TooltipService.prototype.momentFormatHour = function (date) {
        var fmtDate;
        fmtDate = this.FirstLetterCapital(moment(date).format('DD MMM YYYY hh:mm a'));
        fmtDate = this.RemoveLastPoint(fmtDate);
        return fmtDate;
    };
    TooltipService.prototype.isOdd = function (num) {
        return num % 2;
    };
    TooltipService.prototype.FormatDateOpcional = function (format, date, culture) {
        moment.locale(culture && culture.length > 0 ? culture : 'en');
        var dateReturn;
        if (date) {
            dateReturn = moment(date).format(format);
        }
        else {
            dateReturn = moment().format(format);
        }
        dateReturn = this.FirstLetterCapital(dateReturn);
        dateReturn = this.RemoveLastPoint(dateReturn);
        return dateReturn;
    };
    TooltipService.prototype.GetEnumName = function (_enum, find) {
        return Object.keys(_enum).find(function (k) { return _enum[k] === find; });
    };
    TooltipService.prototype.formatDateWithHourDate = function (date, culture) {
        moment.locale(culture && culture.length > 0 ? culture : 'en');
        var dateReturn;
        dateReturn = moment(date).format('DD MMM YYYY h:mm a');
        dateReturn = this.FirstLetterCapital(dateReturn);
        dateReturn = this.RemoveLastPoint(dateReturn);
        return dateReturn;
    };
    TooltipService.prototype.FormatDate = function (fecha, format, culture) {
        moment.locale(culture && culture.length > 0 ? culture : 'en');
        var date;
        if (fecha && fecha.length === 0) {
            date = moment().format(format);
        }
        else {
            date = moment(fecha).format(format);
        }
        date = this.FirstLetterCapital(date);
        date = this.RemoveLastPoint(date);
        return date;
    };
    TooltipService.prototype.DateLetterFormatPrint = function (fecha, culture) {
        moment.locale(culture && culture.length > 0 ? culture : 'en');
        var date;
        var P1;
        var P2;
        var P3;
        if (fecha && fecha.length === 0) {
            P1 = moment().format('D');
            P2 = moment().format('MMMM');
            P3 = moment().format('YYYY');
        }
        else {
            P1 = moment().format('D');
            P2 = moment().format('MMMM');
            P3 = moment().format('YYYY');
        }
        date = this.FirstLetterCapital(P2) + " " + P1 + " of " + P3;
        date = this.RemoveLastPoint(date);
        return date;
    };
    TooltipService.prototype.ReplaceLineBreak = function (text) {
        var newText = text;
        if (text) {
            newText = text.replace(/\r?\n/g, '<br>');
        }
        return newText;
    };
    TooltipService.prototype.compare = function (a, b) {
        var dateA = new Date(a).getDay();
        var dateB = new Date(b).getDay();
        return dateA - dateB;
    };
    TooltipService.prototype.momentMonth = function (date) {
        return this.FirstLetterCapital(moment(date).format('MMMM'));
    };
    TooltipService.prototype.momentCreateDate = function (date, time) {
        var fmtDate;
        var x = moment(date).format('D MMM YYYY');
        var times = time.split(':');
        var correctTime;
        if (Number(times[0]) > 12) {
            var hour = Number(times[0]) - 12;
            correctTime = hour + ':' + times[1] + ' PM';
        }
        else {
            correctTime = time + ' AM';
        }
        fmtDate = this.FirstLetterCapital(x + " " + correctTime);
        fmtDate = this.RemoveLastPoint(fmtDate);
        return fmtDate;
    };
    TooltipService.prototype.momentFormatTime = function (date) {
        return moment(date).format('hh:mm A');
    };
    TooltipService.prototype.getNameMonth = function (date, cultureLanguage) {
        var objDate = new Date(date), locale = cultureLanguage, month = objDate.toLocaleString(locale, { month: 'short' });
        return month.split('.')[0];
    };
    TooltipService.prototype.text_truncate = function (text, length, ending) {
        if (length == null) {
            length = 45;
        }
        if (ending == null) {
            ending = '...';
        }
        if (text.length > length) {
            return text.substring(0, length - ending.length) + ending;
        }
        else {
            return text;
        }
    };
    TooltipService.prototype.IsEndDateValid = function (start, end, culture) {
        // Expected format   "YYYY-MM-DDTHH:mm:ssZ"
        moment.locale(culture && culture.length > 0 ? culture : 'en');
        return moment(start).isAfter(end);
    };
    TooltipService.prototype.RoundDate = function (hoursToAdd, date, daysToAdd, culture) {
        moment.locale(culture && culture.length > 0 ? culture : 'en');
        var D1 = date.split('T');
        var TimeD1 = D1[1];
        var TimesD1 = TimeD1.split(':');
        var newDate = moment(date);
        if (daysToAdd) {
            newDate = newDate.add(daysToAdd, 'd');
        }
        var originalDate = newDate.subtract(Number(TimesD1[1]), 'minutes');
        var start = originalDate.format('YYYY-MM-DDTHH:mm:ssZ');
        var lastDate = originalDate.add(hoursToAdd, 'h');
        var end = lastDate.format('YYYY-MM-DDTHH:mm:ssZ');
        var dates = {
            dateStart: start,
            endDate: end,
        };
        return dates;
    };
    TooltipService.prototype.createCustomDate = function (date, time) {
        // date format YYYY-MM-DD
        return date + "T" + time + ":00Z";
    };
    TooltipService.prototype.getDatesduration = function (fechaIni, timeIni, fechaFin, timefin) {
        // Convert dates to Moment
        var DTEINI = moment(fechaIni + "T" + timeIni + ":00Z");
        var DTEFIN = moment(fechaFin + "T" + timefin + ":00Z");
        // Get difference between dates
        var duration = moment.duration(DTEFIN.diff(DTEINI));
        var _year = duration.get('years');
        var _yearlbl = '';
        var _month = duration.get('months');
        var _monthlbl = '';
        var _days = duration.get('days');
        var _dayslbl = '';
        var _hrs = duration.get('hours');
        var _hrslbl = '';
        var _mins = duration.get('minutes');
        var _minslbl = '';
        if (_year === 1) {
            _yearlbl = '1 year';
        }
        else {
            _yearlbl = _year + " years";
        }
        if (_month === 1) {
            _monthlbl = '1 month';
        }
        else {
            _monthlbl = _month + " months";
        }
        if (_days === 1) {
            _dayslbl = '1 day';
        }
        else {
            _dayslbl = _days + " days";
        }
        if (_hrs === 1) {
            _hrslbl = '1 hr';
        }
        else {
            _hrslbl = _hrs + " hrs";
        }
        if (_mins === 1) {
            _minslbl = '1 min';
        }
        else {
            _minslbl = _mins + " mins";
        }
        if (_year > 0 && _month > 0 && _days > 0 && _hrs > 0 && _mins > 0) {
            return _yearlbl + ", " + _monthlbl + ", " + _dayslbl + ", " + _hrslbl + ", " + _minslbl;
        }
        else if (_year > 0 && _month > 0 && _days > 0 && _hrs > 0 && _mins === 0) {
            return _yearlbl + ", " + _monthlbl + ", " + _dayslbl + ", " + _hrslbl;
        }
        else if (_year > 0 && _month > 0 && _days > 0 && _hrs === 0 && _mins === 0) {
            return _yearlbl + ", " + _monthlbl + ", " + _dayslbl;
        }
        else if (_year > 0 && _month > 0 && _days === 0 && _hrs === 0 && _mins === 0) {
            return _yearlbl + ", " + _monthlbl;
        }
        else if (_year > 0 && _month === 0 && _days === 0 && _hrs === 0 && _mins === 0) {
            return "" + _yearlbl;
        }
        else if (_year === 0 && _month > 0 && _days > 0 && _hrs > 0 && _mins > 0) {
            return _monthlbl + ", " + _dayslbl + ", " + _hrslbl + ", " + _minslbl;
        }
        else if (_year === 0 && _month > 0 && _days > 0 && _hrs > 0 && _mins === 0) {
            return _monthlbl + ", " + _dayslbl + ", " + _hrslbl;
        }
        else if (_year === 0 && _month > 0 && _days > 0 && _hrs === 0 && _mins === 0) {
            return _monthlbl + ", " + _dayslbl;
        }
        else if (_year === 0 && _month > 0 && _days === 0 && _hrs === 0 && _mins === 0) {
            return "" + _monthlbl;
        }
        else if (_year === 0 && _month === 0 && _days > 0 && _hrs > 0 && _mins > 0) {
            return _dayslbl + ", " + _hrslbl + ", " + _minslbl;
        }
        else if (_year === 0 && _month === 0 && _days > 0 && _hrs > 0 && _mins === 0) {
            return _dayslbl + ", " + _hrslbl;
        }
        else if (_year === 0 && _month === 0 && _days > 0 && _hrs === 0 && _mins === 0) {
            return "" + _dayslbl;
        }
        else if (_year === 0 && _month === 0 && _days === 0 && _hrs > 0 && _mins > 0) {
            return _hrslbl + ", " + _minslbl;
        }
        else if (_year === 0 && _month === 0 && _days === 0 && _hrs > 0 && _mins === 0) {
            return "" + _hrslbl;
        }
        else if (_year === 0 && _month === 0 && _days === 0 && _hrs === 0 && _mins > 0) {
            return "" + _minslbl;
        }
        else {
            return "0";
        }
    };
    TooltipService.prototype.addItemLocalStorage = function (key, value) {
        localStorage.setItem(key, value);
    };
    TooltipService.prototype.removeItemLocalStorage = function (key) {
        localStorage.removeItem(key);
    };
    TooltipService.prototype.getItemLocalStorage = function (key) {
        return localStorage.getItem(key);
    };
    TooltipService.prototype.deepClone = function (jsonDATA) {
        return JSON.parse(JSON.stringify(jsonDATA));
    };
    TooltipService.prototype.convertToBase64 = function (arrayBuffer) {
        var response;
        try {
            response = btoa(new Uint8Array(arrayBuffer).reduce(function (data, byte) {
                return data + String.fromCharCode(byte);
            }, ''));
        }
        catch (error) {
            response = undefined;
        }
        return response;
    };
    TooltipService.prototype.returnFiles = function (data) {
        var formData = new FormData();
        Array.from(data).map(function (file, index) {
            return formData.append('file' + index, file, file.name);
        });
        return formData;
    };
    TooltipService.prototype.downloadBlob = function (blobData, name) {
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blobData);
        link.download = name;
        link.click();
    };
    TooltipService.prototype.getFileSize = function (size) {
        return size * 0.00000095367432;
    };
    TooltipService.ngInjectableDef = i0.defineInjectable({ factory: function TooltipService_Factory() { return new TooltipService(); }, token: TooltipService, providedIn: "root" });
    return TooltipService;
}());
export { TooltipService };
