/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./task-plan.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./task-plan.component";
import * as i4 from "../../../services/api/shared/task-plan-api.service";
import * as i5 from "../../../services/shared/loading-indicator.service";
import * as i6 from "@angular/router";
var styles_TaskPlanComponent = [i0.styles];
var RenderType_TaskPlanComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TaskPlanComponent, data: {} });
export { RenderType_TaskPlanComponent as RenderType_TaskPlanComponent };
function View_TaskPlanComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 8, "td", [["style", "text-align: end"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "a", [["style", "cursor: pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onRedirectButtonClick("edit", _v.context.$implicit.ID) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Edit"])), (_l()(), i1.ɵted(-1, null, [" | "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "a", [["style", "cursor: pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onRedirectButtonClick("manage", _v.context.$implicit.ID) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Manage"])), (_l()(), i1.ɵted(-1, null, [" | "])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "a", [["style", "cursor: pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onRedirectButtonClick("delete", _v.context.$implicit.ID) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Delete"]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.Code; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.Description; _ck(_v, 4, 0, currVal_1); }); }
export function View_TaskPlanComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "box-shadow module"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Task Plan Template"])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "col-sm-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "a", [["style", "cursor: pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onRedirectButtonClick("create", null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Create New"])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "col-sm-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 9, "table", [["class", "table table-hover"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 8, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 5, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Code"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Description"])), (_l()(), i1.ɵeld(15, 0, null, null, 0, "th", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaskPlanComponent_1)), i1.ɵdid(17, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.taskPlans; _ck(_v, 17, 0, currVal_0); }, null); }
export function View_TaskPlanComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-task-plan", [], null, null, null, View_TaskPlanComponent_0, RenderType_TaskPlanComponent)), i1.ɵdid(1, 245760, null, 0, i3.TaskPlanComponent, [i4.TaskPlanApiService, i5.LoadingIndicatorService, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TaskPlanComponentNgFactory = i1.ɵccf("app-task-plan", i3.TaskPlanComponent, View_TaskPlanComponent_Host_0, {}, {}, []);
export { TaskPlanComponentNgFactory as TaskPlanComponentNgFactory };
