import { environment } from '#environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PlanApiService = /** @class */ (function () {
    //#region 'Angular Life Cycle'
    function PlanApiService(http) {
        this.http = http;
    }
    //#endregion 'Angular Life Cycle'
    //#region 'General'
    PlanApiService.prototype.getURL = function (method) {
        return environment.apiEndpoint + "/Plan" + method;
    };
    //#endregion 'General'
    //#region 'Get'
    PlanApiService.prototype.getCVXPlannedDuration = function () {
        return this.http.get(environment.apiEndpoint + "/CVXPlanActivities/getCVXPlannedDuration");
    };
    PlanApiService.prototype.getAllPlansByJobId = function (jobId) {
        return this.http.get(this.getURL("/getAllPlansByJobId"), {
            params: { idRecParent: jobId },
        });
    };
    //#endregion 'Get'
    //#region 'Post'
    PlanApiService.prototype.createPlan = function (planData) {
        return this.http.post(this.getURL("/create"), planData);
    };
    PlanApiService.prototype.createBulkPlan = function (planData) {
        return this.http.post(this.getURL("/createBulk"), planData);
    };
    PlanApiService.prototype.createPlanActivity = function (planData) {
        return this.http.post(environment.apiEndpoint + "/PlanActivity/create", planData);
    };
    PlanApiService.prototype.createBulkPlanActivity = function (planData) {
        return this.http.post(environment.apiEndpoint + "/PlanActivity/createBulk", planData);
    };
    //#endregion 'Post'
    //#region 'Update'
    PlanApiService.prototype.updatePlan = function (planData) {
        return this.http.put(this.getURL("/update"), planData);
    };
    PlanApiService.prototype.updateBulkPlan = function (planData) {
        return this.http.put(this.getURL("/updateBulk"), planData);
    };
    PlanApiService.ngInjectableDef = i0.defineInjectable({ factory: function PlanApiService_Factory() { return new PlanApiService(i0.inject(i1.HttpClient)); }, token: PlanApiService, providedIn: "root" });
    return PlanApiService;
}());
export { PlanApiService };
