var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { DailyOperationService } from '#services-shared/index';
import { OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';
var DailyOperationComponent = /** @class */ (function () {
    //#endregion 'Variables'
    //#region 'Angular Life Cycle'
    function DailyOperationComponent(_sectionDailyOperation, _message) {
        this._sectionDailyOperation = _sectionDailyOperation;
        this._message = _message;
        //#region 'Variables'
        this.setToastType = '';
        this.currentRigTime = 0;
        this.isDailyUpdate = false;
        this.areDatesInvalids = false;
        this.showEndDateError = false;
        this.showRigTimeError = false;
        this.showCreateNewDaily = false;
        this.showSmartProcedures = true;
        this.showStartDateError = false;
        this.showErrorCreateNewDaily = false;
        this.validateDates = false;
        //? Subscriptions
        this.SUBS$ = new Subscription();
    }
    DailyOperationComponent.prototype.ngOnInit = function () {
        this._sectionDailyOperation.loadActivityCodes();
        this._sectionDailyOperation.loadDOPs();
        this.loadFormDaily();
    };
    DailyOperationComponent.prototype.ngOnDestroy = function () {
        this.SUBS$.unsubscribe();
    };
    //#endregion 'Angular Life Cycle'
    //#region 'Load'
    DailyOperationComponent.prototype.loadFormDaily = function () {
        this.formDailyOperation = new FormGroup({
            startDate: new FormControl('', Validators.required),
            endDate: new FormControl('', Validators.required),
            rigTime: new FormControl('', Validators.required),
            rptTimeStatus: new FormControl('', Validators.required),
            summary: new FormControl('', Validators.required),
            projectedOps: new FormControl('', Validators.required),
        });
        this._onStartDateFormChanges();
        this._onEndDateFormChanges();
    };
    DailyOperationComponent.prototype._onStartDateFormChanges = function () {
        var _this = this;
        this.SUBS$.add(this.formDailyOperation.get('startDate').valueChanges.subscribe(function () {
            if (_this.validateDates) {
                _this.areDatesValid();
            }
        }));
    };
    DailyOperationComponent.prototype._onEndDateFormChanges = function () {
        var _this = this;
        this.SUBS$.add(this.formDailyOperation.get('endDate').valueChanges.subscribe(function () {
            if (_this.validateDates) {
                _this.areDatesValid();
            }
        }));
    };
    //#endregion 'Load'
    //#region 'Modals'
    DailyOperationComponent.prototype.openDailyModal = function (isEdit) {
        if (!isEdit) {
            this.resetFormDailyOperation();
        }
        this.isDailyUpdate = isEdit;
        this.showErrorCreateNewDaily = false;
        this.showCreateNewDaily = true;
    };
    DailyOperationComponent.prototype.closeDailyModal = function () {
        this.showCreateNewDaily = false;
    };
    //#endregion 'Modals'
    //#region 'Validation'
    DailyOperationComponent.prototype.isDailyOperationValid = function () {
        if (!this.formDailyOperation.valid) {
            this.showErrorCreateNewDaily = true;
            return false;
        }
        if (this.areDatesInvalids) {
            return false;
        }
        if (this.showStartDateError) {
            return false;
        }
        if (this.showEndDateError) {
            return false;
        }
        if (this.showRigTimeError) {
            return false;
        }
        this.showRigTimeError = false;
        this.areDatesInvalids = false;
        this.showErrorCreateNewDaily = false;
        return true;
    };
    DailyOperationComponent.prototype.validateRigTime = function () {
        var NEWRIGTIME = Number(this.formDailyOperation.controls['rigTime'].value);
        var AVAILABLETIME = this.getAvailableTime(this.formDailyOperation.controls['startDate'].value, this.formDailyOperation.controls['endDate'].value);
        if (NEWRIGTIME > 24 || NEWRIGTIME > AVAILABLETIME) {
            this.showRigTimeError = true;
            return false;
        }
        else {
            this.showRigTimeError = false;
            return true;
        }
    };
    //#endregion 'Validation'
    //#region 'CRUD'
    DailyOperationComponent.prototype.createDailyOperation = function () {
        var _this = this;
        if (this.isDailyOperationValid()) {
            this._sectionDailyOperation.formDOP = this.formDailyOperation;
            this._sectionDailyOperation.createNewDOP().then(function (done) {
                _this.showNotification(done);
            }, function (error) {
                _this.showNotification(error);
            });
            this.closeDailyModal();
        }
    };
    DailyOperationComponent.prototype.updateDailyOperation = function () {
        var _this = this;
        this.validateRigTime();
        if (this.isDailyOperationValid() && this.validateRigTime()) {
            this._sectionDailyOperation.formDOP = this.formDailyOperation;
            this._sectionDailyOperation.updateDOP().then(function (done) {
                _this.showNotification(done);
            }, function (error) {
                _this.showNotification(error);
            });
            this.closeDailyModal();
        }
    };
    DailyOperationComponent.prototype.deleteDOP = function (dto) {
        this._sectionDailyOperation.DOPDelteObject = dto;
        this.showMessage({
            key: 'c',
            sticky: true,
            severity: 'warn',
            summary: 'Delete Daily Component',
            detail: 'Are you sure you want to delete the selected Daily Component?',
            clear: true,
            toastType: 'DeleteDaily',
        });
    };
    //#endregion 'CRUD'
    //#region 'Toast'
    DailyOperationComponent.prototype.showMessage = function (msg) {
        if (msg.clear) {
            this.hideToast();
        }
        if (msg.toastType) {
            this.setToastType = msg.toastType;
        }
        this._message.clear();
        this._message.add({
            key: msg.key,
            sticky: msg.sticky,
            severity: msg.severity,
            summary: msg.summary,
            detail: msg.detail,
        });
    };
    DailyOperationComponent.prototype.onReject = function () {
        this.hideToast();
    };
    DailyOperationComponent.prototype.hideToast = function () {
        this._message.clear('c');
    };
    DailyOperationComponent.prototype.onConfirm = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.hideToast();
                if (this.setToastType === 'DeleteDaily') {
                    this._sectionDailyOperation.deleteDOP().then(function (done) {
                        _this.showNotification(done);
                    }, function (error) {
                        _this.showNotification(error);
                    });
                }
                else if (this.setToastType === 'DeleteTimeLog') {
                    this._sectionDailyOperation.deleteTML().then(function (done) {
                        _this.showNotification(done);
                    }, function (error) {
                        _this.showNotification(error);
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    //#endregion 'Toast'
    //#region 'General Methods'
    DailyOperationComponent.prototype.editDailyOperation = function (index) {
        if (index) {
            this.selectDOP(index);
        }
        else {
            this.loadDailyToEdit();
        }
        this.openDailyModal(true);
    };
    DailyOperationComponent.prototype.resetFormDailyOperation = function () {
        this.formDailyOperation.reset();
        this.formDailyOperation.controls['startDate'].setValue(moment().format('MM/DD/YYYY 00:00'));
        this.formDailyOperation.controls['endDate'].setValue(moment().add(1, 'days').format('MM/DD/YYYY 00:00'));
    };
    DailyOperationComponent.prototype.selectDOP = function (index) {
        this._sectionDailyOperation.selectDOP(index, true);
        this.loadDailyToEdit();
    };
    DailyOperationComponent.prototype.loadDailyToEdit = function () {
        this.validateDates = false;
        this.formDailyOperation.controls['startDate'].setValue(this._sectionDailyOperation.DOPObject.ReportStartDate.Value);
        this.formDailyOperation.controls['endDate'].setValue(this._sectionDailyOperation.DOPObject.ReportEndDate.Value);
        this.formDailyOperation.controls['summary'].setValue(this._sectionDailyOperation.DOPObject.OperationsSummary.Value);
        this.formDailyOperation.controls['rptTimeStatus'].setValue(this._sectionDailyOperation.DOPObject.StatusReportingTime.Value);
        this.formDailyOperation.controls['projectedOps'].setValue(this._sectionDailyOperation.DOPObject.ProjectedOperations.Value);
        this.formDailyOperation.controls['rigTime'].setValue(this._sectionDailyOperation.DOPObject.RigTime.Value);
        this.validateDates = true;
    };
    DailyOperationComponent.prototype.areDatesValid = function () {
        var start = this.formDailyOperation.controls['startDate'].value;
        var end = this.formDailyOperation.controls['endDate'].value;
        if (start) {
            this.showStartDateError = false;
            this.showStartDateError = this._sectionDailyOperation.checkDatesExist(start);
        }
        if (end) {
            this.showEndDateError = false;
            this.showEndDateError = this._sectionDailyOperation.checkDatesExist(end);
        }
        if (start && end) {
            this.areDatesInvalids = moment(start).isAfter(end);
            this.calculateRigTime(start, end);
        }
        else {
            this.areDatesInvalids = false;
        }
    };
    DailyOperationComponent.prototype.calculateRigTime = function (start, end) {
        this.showRigTimeError = false;
        var TIME = this.getAvailableTime(start, end);
        this.formDailyOperation.controls['rigTime'].setValue(TIME);
        if (TIME > 24) {
            this.showRigTimeError = true;
        }
    };
    DailyOperationComponent.prototype.getAvailableTime = function (start, end) {
        var DURATION = moment.duration(moment(end).diff(moment(start)));
        var HOURS = DURATION.asHours();
        return HOURS;
    };
    //#endregion 'General Methods'
    //#region 'Notification'
    DailyOperationComponent.prototype.showNotification = function (msg) {
        this._message.add({
            key: msg && msg.key && msg.key.length > 0 ? msg.key : 'DOPNotify',
            sticky: msg && msg.key && msg.key != 'DOPNotify' ? true : false,
            closable: msg && msg.key && msg.key != 'DOPNotify' ? true : false,
            severity: msg.severity,
            summary: msg.summary,
            detail: msg.detail,
            life: msg.life,
        });
    };
    return DailyOperationComponent;
}());
export { DailyOperationComponent };
