import { environment } from '#environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SectionsApiService = /** @class */ (function () {
    //#region 'Angular Life Cycle'
    function SectionsApiService(http) {
        this.http = http;
    }
    //#endregion 'Angular Life Cycle'
    //#region 'General'
    SectionsApiService.prototype.getURL = function (method) {
        return environment.apiEndpoint + "/wellBoreSection" + method;
    };
    //#endregion 'General'
    //#region 'Get'
    SectionsApiService.prototype.getWellBoreByID = function (id) {
        return this.http.get(environment.apiEndpoint + "/wellbore/getByWellId", { params: { wellId: id } });
    };
    SectionsApiService.prototype.getSectionsByWellBoreID = function (id) {
        return this.http.get(this.getURL("/getByWellboreId"), {
            params: { wellboreId: id },
        });
    };
    SectionsApiService.prototype.getSectionBySectionID = function (id) {
        return this.http.get(this.getURL("/getByWellboreSectionId"), {
            params: { wellboreSectionId: id },
        });
    };
    SectionsApiService.ngInjectableDef = i0.defineInjectable({ factory: function SectionsApiService_Factory() { return new SectionsApiService(i0.inject(i1.HttpClient)); }, token: SectionsApiService, providedIn: "root" });
    return SectionsApiService;
}());
export { SectionsApiService };
