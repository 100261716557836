var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Field, OfflineActivityTimelog } from '#models/index';
import { AttachmentApiService, NotificationsApiService } from '#services-api/index';
import { DailyOperationService, LoadingIndicatorService, TooltipService } from '#services-shared/index';
import { OfflineActivityService } from '#services-shared/jobs/offline-activity.service';
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
var TimeLogOAComponent = /** @class */ (function () {
    //#endregion 'Variables'
    //#region 'Angular Life Cycle'
    function TimeLogOAComponent(_sectionDailyOperation, _offlineActivity, _AttachmentAPI, _NotificationsAPI, _loader, _tooltip, _message) {
        this._sectionDailyOperation = _sectionDailyOperation;
        this._offlineActivity = _offlineActivity;
        this._AttachmentAPI = _AttachmentAPI;
        this._NotificationsAPI = _NotificationsAPI;
        this._loader = _loader;
        this._tooltip = _tooltip;
        this._message = _message;
        this.message = new EventEmitter();
        this.showAttachments = false;
        this.showErrorAttachments = false;
        this.attachments = [];
        this.files = [];
        this.filesToUpload = [];
        //? Email Notification
        this.emailToAdd = '';
        this.showEmailNotification = false;
        //? Subscriptions
        this.SUBS$ = new Subscription();
    }
    TimeLogOAComponent.prototype.ngOnInit = function () {
        this.loadFormEntry();
    };
    TimeLogOAComponent.prototype.ngOnDestroy = function () {
        this.SUBS$.unsubscribe();
    };
    //#endregion 'Angular Life Cycle'
    //#region 'Load'
    TimeLogOAComponent.prototype.loadFormEntry = function () {
        this.formOAT = new FormGroup({
            duration: new FormControl(1, Validators.required),
            activityCode: new FormControl(''),
            activityDescription: new FormControl(''),
            inactive: new FormControl(false),
            depthStart: new FormControl(0),
            depthEnd: new FormControl(0),
            comments: new FormControl('', Validators.required),
            sysSeq: new FormControl(''),
        });
    };
    //#endregion 'Load'
    //#region 'Modals'
    TimeLogOAComponent.prototype.openEntryModal = function (isEdit) {
        var _this = this;
        this._offlineActivity.isDOPFull().then(function (res) {
            return res;
        }, function (error) {
            _this.showNotification(error);
            return true;
        });
        if (!isEdit) {
            this.activityToEdit = new Field();
        }
        this.isTimeUpdate = isEdit;
        this.showErrorcreateTML = false;
        this.showCreateNewEntry = true;
    };
    TimeLogOAComponent.prototype.closeEntryModal = function (resetTime) {
        this.formOAT.reset();
        this.showErrorDuration = false;
        this.showCreateNewEntry = false;
        this.showErrorcreateTML = false;
        if (resetTime)
            this._offlineActivity.getAvailableTime(false);
    };
    //#endregion 'Modals'
    //#region 'SideBar'
    TimeLogOAComponent.prototype.openActivityCodes = function () {
        this.displayActivityConfigurations = true;
    };
    TimeLogOAComponent.prototype.closeActivityCodes = function () {
        this.displayActivityConfigurations = false;
    };
    TimeLogOAComponent.prototype.activityCodeSelect = function (e) {
        this.editActivityCode(e.data);
        this.closeActivityCodes();
    };
    //#endregion 'SideBar'
    //#region 'Validation'
    TimeLogOAComponent.prototype.isTimeLogValid = function (isEdit) {
        if (!this.formOAT.valid) {
            this.showErrorcreateTML = true;
            return false;
        }
        else {
            this.showErrorcreateTML = false;
        }
        var DURATION = this.formOAT.controls['duration'].value;
        if (Number(DURATION) === 0) {
            this.showErrorcreateTML = true;
            return false;
        }
        else {
            this.showErrorcreateTML = false;
        }
        if (!this.validateDurationAvailable(this.formOAT.controls['duration'].value, isEdit)) {
            return true;
        }
        else {
            return false;
        }
    };
    TimeLogOAComponent.prototype.validateDurationAvailable = function (duration, isEdit) {
        this.showErrorDuration = this._offlineActivity.validateAvailableDuration(duration, isEdit);
        return this.showErrorDuration;
    };
    //#endregion 'Validation'
    //#region 'CRUD'
    TimeLogOAComponent.prototype.setOAT = function (isEdit) {
        var dto = new OfflineActivityTimelog();
        if (!isEdit) {
            dto.WellId.Value = this._offlineActivity.wellId;
            dto.OADateId.Value = this._offlineActivity.OADList[this._offlineActivity.selectedOADId].IdRec.Value;
        }
        else {
            dto = this._offlineActivity.OATList[this._offlineActivity.selectedOATId];
        }
        dto.Duration.Value = this.formOAT.controls['duration'].value;
        dto.Activity.Value = this.formOAT.controls['activityCode'].value;
        dto.InactiveTime.Value = this.formOAT.controls['inactive'].value;
        dto.StartDepth.Value = this.formOAT.controls['depthStart'].value;
        dto.EndDepth.Value = this.formOAT.controls['depthEnd'].value;
        dto.Comment.Value = this.formOAT.controls['comments'].value;
        dto.SysSeq.Value = this.formOAT.controls['sysSeq'].value;
        return dto;
    };
    TimeLogOAComponent.prototype.createTML = function () {
        var _this = this;
        if (this.isTimeLogValid(false)) {
            this._offlineActivity.createOAT(this.setOAT(false)).then(function (done) {
                _this.showNotification(done);
            }, function (error) {
                _this.showNotification(error);
            });
            this.closeEntryModal();
        }
    };
    TimeLogOAComponent.prototype.updateTML = function () {
        var _this = this;
        if (this.isTimeLogValid(true)) {
            this._offlineActivity.updateOAT(this.setOAT(true)).then(function (done) {
                _this.showNotification(done);
            }, function (error) {
                _this.showNotification(error);
            });
            this.closeEntryModal();
        }
    };
    TimeLogOAComponent.prototype.deleteTML = function (dto) {
        this._offlineActivity.OATDeleteObject = this._tooltip.deepClone(dto);
        this.message.next({
            key: 'c',
            sticky: true,
            severity: 'warn',
            summary: 'Delete Offline Activity',
            detail: 'Are you sure you want to delete the selected Offline Activity?',
            clear: true,
            toastType: 'DeleteTimeLogOA',
        });
    };
    //#endregion 'CRUD'
    //#region 'General Methods'
    TimeLogOAComponent.prototype.editTMLOA = function () {
        this.isTimeUpdate = true;
        this.formOAT.controls['duration'].setValue(this._offlineActivity.OATObject.Duration.Value);
        this.formOAT.controls['depthStart'].setValue(this._offlineActivity.OATObject.StartDepth.Value);
        this.formOAT.controls['depthEnd'].setValue(this._offlineActivity.OATObject.EndDepth.Value);
        this.formOAT.controls['inactive'].setValue(this._offlineActivity.getInactive(this._offlineActivity.OATObject.InactiveTime.Value));
        this.formOAT.controls['activityCode'].setValue(this._offlineActivity.OATObject.Activity.Value);
        this.formOAT.controls['activityDescription'].setValue(this._offlineActivity.OATObject.Activity.Help);
        this.formOAT.controls['comments'].setValue(this._offlineActivity.OATObject.Comment.Value);
        this.formOAT.controls['sysSeq'].setValue(this._offlineActivity.OATObject.SysSeq.Value);
        this.activityToEdit = this._offlineActivity.OATObject.Activity;
        this.openEntryModal(true);
    };
    TimeLogOAComponent.prototype.editTimeLogRecord = function (dto, index) {
        this._offlineActivity.selectedOATId = index;
        this._offlineActivity.OATObject = this._tooltip.deepClone(dto);
        this.editTMLOA();
        this.showCreateNewEntry = true;
    };
    TimeLogOAComponent.prototype.editActivityCode = function (dto) {
        this.formOAT.controls['activityCode'].setValue(dto.Activity);
        this.formOAT.controls['activityDescription'].setValue(dto.Help);
        this.activityToEdit = new Field();
        this.activityToEdit.Value = dto.Activity;
        this.activityToEdit.Help = dto.Help;
    };
    //#endregion 'General Methods'
    //#region 'Re-Order'
    TimeLogOAComponent.prototype.timelogUp = function (index) {
        if (index === 0) {
            return;
        }
        this.changeSysSequence(index, index - 1, true);
    };
    TimeLogOAComponent.prototype.timelogDown = function (index) {
        if (index === this._offlineActivity.OATList.length - 1) {
            return;
        }
        this.changeSysSequence(index, index + 1, false);
    };
    TimeLogOAComponent.prototype.changeSysSequence = function (firstIndex, secondIndex, isUp) {
        var _this = this;
        var TML1 = this._offlineActivity.OATList[firstIndex];
        var TML2 = this._offlineActivity.OATList[secondIndex];
        TML1.SysSeq.Value = isUp ? firstIndex.toString() : (secondIndex + 1).toString();
        TML2.SysSeq.Value = isUp ? (firstIndex + 1).toString() : secondIndex.toString();
        var DATATOSAVE = [TML1, TML2];
        this._offlineActivity.updateOATBulk(DATATOSAVE).then(function (done) {
            _this.showNotification(done);
        }, function (error) {
            _this.showNotification(error);
        });
    };
    //#endregion 'Re-Order'
    //#region 'Attachments'
    TimeLogOAComponent.prototype.openAttachments = function () {
        this.files = [];
        this.showAttachments = true;
    };
    TimeLogOAComponent.prototype.closeAttachments = function () {
        this.showAttachments = false;
        this.showErrorDuplicatedFiles = false;
    };
    TimeLogOAComponent.prototype.removeFile = function (index) {
        var NAME = this.files[index].nameToShow;
        var INDEX = this.filesToUpload.findIndex(function (x) { return x.name === NAME; });
        this.filesToUpload.splice(INDEX, 1);
        this.files.splice(index, 1);
    };
    TimeLogOAComponent.prototype.isUploadDisabled = function () {
        if (!this.files || this.files.length === 0)
            return true;
    };
    TimeLogOAComponent.prototype.importFileSuccess = function (ev) {
        var _this = this;
        this.filesToUpload = this.filesToUpload.concat(ev.files);
        ev.files.forEach(function (fl) {
            _this.showErrorDuplicatedFiles = false;
            var reader = new FileReader();
            reader.readAsArrayBuffer(fl);
            reader.onload = function (e) {
                var SIZE = _this._tooltip.getFileSize(e.loaded);
                var NAME = fl.name.replace(/\s/g, '_');
                var ISINFILES = _this.files.filter(function (obj) { return obj.nameToShow === NAME; });
                var ISONLINE = _this._offlineActivity.spFilesList.filter(function (obj) { return obj.nameToShow === NAME; });
                if (ISINFILES && ISINFILES.length === 0 && ISONLINE && ISONLINE.length === 0) {
                    var arrayBuffer = e.target['result'];
                    var bytes = new Uint8Array(arrayBuffer);
                    _this.files.push({
                        data: bytes,
                        src: !fl.type.includes('pdf') && SIZE <= 2
                            ? "data:" + fl.type + ";base64," + _this._tooltip.convertToBase64(arrayBuffer)
                            : '',
                        name: _this.getName(NAME),
                        nameToShow: NAME,
                        icon: '',
                        type: fl.type,
                        mb: SIZE,
                        size: e.loaded,
                    });
                }
                else {
                    _this.showErrorDuplicatedFiles = true;
                }
            };
        });
        this.setFileImportNotify = true;
    };
    TimeLogOAComponent.prototype.uploadFiles = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.attachments = [];
                        this._offlineActivity.spFilesList = [];
                        return [4 /*yield*/, this.getAttachmentObj(this.files).then(function () {
                                _this.closeAttachments();
                                _this.uploadFilesToAzure();
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TimeLogOAComponent.prototype.getAttachmentObj = function (file) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            try {
                file.forEach(function (fl) {
                    var dto = {
                        FileName: fl.name,
                        FileNameDisplay: fl.nameToShow,
                    };
                    _this.attachments.push(dto);
                    _this._offlineActivity.spFilesList.push({
                        _url: '',
                        name: fl.name,
                        nameToShow: fl.nameToShow,
                        imgType: fl.type,
                        size: fl.size.toString(),
                    });
                });
                resolve(true);
            }
            catch (err) {
                reject(Error(err));
            }
        });
    };
    TimeLogOAComponent.prototype.uploadFilesToAzure = function () {
        var _this = this;
        this._loader.show();
        var FDATA = this._tooltip.returnFiles(this.filesToUpload);
        this._offlineActivity.uploadFilesToAzure(FDATA).then(function () {
            _this._offlineActivity.uploadUrlsToWellView().then(function (doneW) {
                _this._loader.hide();
                _this.showNotification(doneW);
            }, function (error) {
                _this._loader.hide();
                _this.showNotification(error);
            });
        }, function (error) {
            _this._loader.hide();
            _this.showNotification(error);
        });
    };
    TimeLogOAComponent.prototype.showFile = function (index) {
        this._offlineActivity.downloadFile(index);
    };
    TimeLogOAComponent.prototype.getName = function (fileName) {
        return this._offlineActivity.OADObject.IdRec.Value + "_" + fileName;
    };
    TimeLogOAComponent.prototype.typeOfFile = function (file, index) {
        var NAME = file.name.toUpperCase();
        if ((NAME.includes('.PNG') || NAME.includes('.JPG') || NAME.includes('.JPEG')) && file.mb <= 2) {
            return true;
        }
        else {
            if (NAME.includes('.PDF')) {
                this.files[index].icon = './../../../../../assets/images/pdf_icon.png';
            }
            else {
                this.files[index].icon = './../../../../../assets/images/png_icon.png';
            }
            return false;
        }
    };
    //#endregion 'Attachments'
    //#region 'Notifications'
    TimeLogOAComponent.prototype.sendNotification = function () {
        var _this = this;
        this._loader.show();
        this.closeEmailNotification();
        this.SUBS$.add(this._NotificationsAPI
            .sendNotificationEmail(this._offlineActivity.generateEmailData())
            .finally(function () {
            _this._loader.hide();
        })
            .subscribe(function () {
            _this.showNotification({
                severity: 'success',
                summary: 'Email Sent.',
                detail: 'An email notification has been sent.',
            });
        }));
    };
    TimeLogOAComponent.prototype.openEmailNotification = function () {
        this.showEmailNotification = true;
    };
    TimeLogOAComponent.prototype.closeEmailNotification = function () {
        this.showEmailNotification = false;
    };
    TimeLogOAComponent.prototype.deleteEmail = function (index) {
        this._offlineActivity.deleteEmail(index);
    };
    TimeLogOAComponent.prototype.addEmail = function () {
        this._offlineActivity.addEmail(this.emailToAdd);
        this.emailToAdd = '';
    };
    //#endregion 'Notifications'
    //#region 'View Files'
    TimeLogOAComponent.prototype.deleteFile = function (dto, index) {
        var _this = this;
        var idx = this._offlineActivity.OAAList.findIndex(function (x) { return x.IdRec.Value === dto.idRec; });
        this._offlineActivity.spFilesList.splice(index, 1);
        this._offlineActivity.deleteOAA(idx).then(function (dto) {
            _this._offlineActivity.deleteFromAzure(dto).then(function (doneW) {
                _this.showNotification(doneW);
            }, function (error) {
                _this.showNotification(error);
            });
        }, function (error) {
            _this.showNotification(error);
        });
    };
    //#endregion 'View Files'
    //#region 'Notifications'
    TimeLogOAComponent.prototype.showNotification = function (msg) {
        this._message.add({
            key: msg && msg.key && msg.key.length > 0 ? msg.key : 'TMLOANotify',
            sticky: msg && msg.key && msg.key != 'TMLOANotify' ? true : false,
            closable: msg && msg.key && msg.key != 'TMLOANotify' ? true : false,
            severity: msg.severity,
            summary: msg.summary,
            detail: msg.detail,
            life: msg.life,
        });
    };
    return TimeLogOAComponent;
}());
export { TimeLogOAComponent };
