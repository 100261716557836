var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { CVXPlanData, Field, PlanActions, PlanActivityEdit, PlanFilters, } from '#models/index';
import { PlanApiService } from '#services-api/index';
import { DailyOperationService, LoadingIndicatorService, TooltipService } from '#services-shared/index';
import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
var PlanActivitiesComponent = /** @class */ (function () {
    //#endregion 'Variables'
    //#region 'Angular Life Cycle'
    function PlanActivitiesComponent(_AMRA, _message, _toolTip, activatedRoute, _loader, _sectionDailyOperation, _tooltip) {
        this._AMRA = _AMRA;
        this._message = _message;
        this._toolTip = _toolTip;
        this.activatedRoute = activatedRoute;
        this._loader = _loader;
        this._sectionDailyOperation = _sectionDailyOperation;
        this._tooltip = _tooltip;
        //#region 'Variables'
        this.editIdxC = 0;
        this.editIdxP = 0;
        this.allSelected = false;
        this.alreadyDrilled = false;
        this.isThreeStringDesign = false;
        this.activityToEdit = new PlanActivityEdit();
        this.dataCVX = [];
        this.selectFilterItems = [];
        this.actionCodes = new Set();
        this.onlyAvailablePhases = [];
        this.currentPlans = [];
        this.plansToUpdate = [];
        this.selectedCVX = [];
        this.selectedActions = [];
        this.dataCVXOriginal = [];
        this.existingPlans = [];
        this.notExistingPlans = [];
        //? Subscriptions
        this.SUBS$ = new Subscription();
        this._sectionDailyOperation.wellId = this.activatedRoute.snapshot.paramMap.get('wellID');
        this._sectionDailyOperation.jobID = this.activatedRoute.snapshot.paramMap.get('jobID');
        this._sectionDailyOperation.idRecWellBore = this.activatedRoute.snapshot.paramMap.get('wellboreID');
    }
    PlanActivitiesComponent.prototype.ngOnDestroy = function () {
        this.SUBS$.unsubscribe();
        this._loader.hide();
    };
    PlanActivitiesComponent.prototype.ngOnInit = function () {
        this.loadAMRAInfo();
        this.loadACurrentPlans();
    };
    //#endregion 'Angular Life Cycle'
    //#region 'Load'
    PlanActivitiesComponent.prototype.loadAMRAInfo = function () {
        var _this = this;
        this._loader.show();
        var CVX$ = this._AMRA
            .getCVXPlannedDuration()
            .pipe(map(function (dt) {
            dt.map(function (plan) {
                plan.HaveActionsSelected = false;
                plan.Actions.map(function (act) {
                    act.Selected = act.StepDependency === 'Required' ? true : false;
                    act.StepTitle = act.StepTitle.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
                    act.SequenceNo = _this._toolTip.zeroPad(Number(act.SequenceNo), 4);
                    if (!plan.HaveActionsSelected && act.Selected) {
                        plan.HaveActionsSelected = true;
                    }
                    return act;
                });
                plan.HeaderName.SequenceNo = _this._toolTip.zeroPad(Number(plan.HeaderName.SequenceNo), 4);
            });
            return dt;
        }))
            .subscribe(function (dto) {
            dto.sort(function (a, b) {
                return a.HeaderName.SequenceNo > b.HeaderName.SequenceNo
                    ? 1
                    : b.HeaderName.SequenceNo > a.HeaderName.SequenceNo
                        ? -1
                        : 0;
            });
            _this.loadPhasesWithSequence(_this._tooltip.deepClone(dto));
            _this.dataCVX = _this._tooltip.deepClone(dto);
            _this.dataCVXOriginal = _this._tooltip.deepClone(dto);
            _this._loader.hide();
        }, function () {
            _this._loader.hide();
            _this.showNotification({
                key: 'planActivitiesError',
                severity: 'error',
                summary: 'We got a problem!',
                detail: 'There was a problem while loading Plans & Plan Activities, please refresh the page or notify the administrator.',
                life: 6000,
            });
        });
        this.selectFilterItems.push('All', 'Required', 'None');
        this.SUBS$.add(CVX$);
    };
    PlanActivitiesComponent.prototype.loadPhasesWithSequence = function (dto) {
        var _this = this;
        var counter = 1;
        var phases = new Set();
        var actionsToOrder = [];
        this.onlyAvailablePhases = [];
        dto.forEach(function (header) {
            actionsToOrder.push.apply(actionsToOrder, header.Actions);
        });
        actionsToOrder.sort(function (a, b) { return (a.SequenceNo > b.SequenceNo ? 1 : b.SequenceNo > a.SequenceNo ? -1 : 0); });
        actionsToOrder.forEach(function (act) {
            phases.add(JSON.stringify({ WellPhase: act.WellPhase, Phase1: act.Phase1, Phase2: act.Phase2 }));
        });
        phases.forEach(function (el) {
            var phase = JSON.parse(el);
            phase.SysSeq = counter;
            _this.onlyAvailablePhases.push(phase);
            counter++;
        });
    };
    PlanActivitiesComponent.prototype.loadACurrentPlans = function () {
        var _this = this;
        this._loader.show();
        var PH$ = this._AMRA.getAllPlansByJobId(this._sectionDailyOperation.jobID).subscribe(function (dto) {
            _this.currentPlans = dto.slice();
            _this._loader.hide();
        }, function () {
            _this._loader.hide();
            _this.showNotification({
                key: 'planActivitiesError',
                severity: 'error',
                summary: 'We got a problem!',
                detail: 'There was a problem while inserting Plans & Plan Activities, please refresh the page or notify the administrator.',
                life: 6000,
            });
        });
        this.SUBS$.add(PH$);
    };
    //#endregion 'Load'
    //#region 'Filters'
    PlanActivitiesComponent.prototype.filterSurface = function () {
        this.filterBy(PlanFilters.ALREADY_DRILLED, this.alreadyDrilled);
    };
    PlanActivitiesComponent.prototype.filterDesign = function () {
        this.filterBy(PlanFilters.THREE_STRING_DESIGN, this.isThreeStringDesign);
    };
    PlanActivitiesComponent.prototype.getActionsByPhase = function (plan) {
        var actions = this.dataCVXOriginal.filter(function (dt) { return dt.HeaderName.StepTitle === plan.HeaderName.StepTitle; })[0].Actions;
        actions = this.validateFilters(actions);
        return actions;
    };
    PlanActivitiesComponent.prototype.filterBy = function (plan, condition) {
        var _this = this;
        this.dataCVX.forEach(function (dt) {
            var el = dt.HeaderName.StepTitle.toUpperCase();
            if (plan === PlanFilters.ALREADY_DRILLED) {
                if (el.includes('SURFACE HOLE') || el.includes('SURFACE')) {
                    dt.Actions = condition ? [] : _this.getActionsByPhase(dt);
                }
            }
            else if (plan === PlanFilters.THREE_STRING_DESIGN) {
                if (el.includes('INTERMEDIATE #2 HOLE') || el.includes('INTERMEDIATE #2')) {
                    dt.Actions = condition ? [] : _this.getActionsByPhase(dt);
                }
            }
        });
    };
    PlanActivitiesComponent.prototype.selectItems = function (event) {
        var _this = this;
        this.currentSelect = event.target['value'];
        this.dataCVX.forEach(function (dt) {
            _this.validateFilters(dt.Actions);
        });
    };
    PlanActivitiesComponent.prototype.validateFilters = function (actions) {
        var _actions = [];
        if (this.currentSelect === 'All') {
            _actions = this.selectAll(true, actions);
        }
        else if (this.currentSelect === 'Required') {
            _actions = this.selectRequiredOnly(actions);
        }
        else {
            _actions = this.selectAll(false, actions);
        }
        return _actions;
    };
    PlanActivitiesComponent.prototype.selectAll = function (val, actions) {
        actions.map(function (el) {
            el.Selected = val;
            return;
        });
        return actions;
    };
    PlanActivitiesComponent.prototype.selectRequiredOnly = function (actions) {
        actions.map(function (el) {
            if (el.StepDependency === 'Required') {
                el.Selected = true;
            }
            else {
                el.Selected = false;
            }
            return;
        });
        return actions;
    };
    PlanActivitiesComponent.prototype.selectItem = function (idxP, idxC, isSelected) {
        this.dataCVX[idxP].Actions[idxC].Selected = isSelected;
    };
    //#endregion 'Filters'
    //#region 'General Methods'
    PlanActivitiesComponent.prototype.getDataReadyToInsert = function () {
        var _this = this;
        this.selectedCVX = [];
        this.dataCVX.forEach(function (cvx) {
            var _a;
            if (cvx.HaveActionsSelected) {
                var actionsSelected = cvx.Actions.filter(function (obj) { return obj.Selected; });
                (_a = _this.selectedActions).push.apply(_a, actionsSelected);
            }
        });
        this.createCVXObject();
    };
    PlanActivitiesComponent.prototype.createCVXObject = function () {
        var _this = this;
        this.actionCodes.clear();
        this.setActionCodes();
        this.actionCodes.forEach(function (ac) {
            _this.createCVXPlanDataToInsert(ac);
        });
    };
    PlanActivitiesComponent.prototype.setActionCodes = function () {
        var _this = this;
        this.selectedActions.forEach(function (act) {
            if (act.WellPhase &&
                act.WellPhase.length > 0 &&
                act.Phase1 &&
                act.Phase1.length > 0 &&
                act.Phase2 &&
                act.Phase2.length > 0 &&
                act.Selected) {
                _this.actionCodes.add(JSON.stringify({
                    WellPhase: act.WellPhase,
                    Phase1: act.Phase1,
                    Phase2: act.Phase2,
                }));
            }
        });
    };
    PlanActivitiesComponent.prototype.createCVXPlanDataToInsert = function (actionCode) {
        var _this = this;
        var dataAC = JSON.parse(actionCode);
        var headerExist = this.validatePlanExist(dataAC);
        var header = new PlanActions();
        var IdRec = new Field();
        if (headerExist.length > 0) {
            IdRec = headerExist[0].IdRec;
            header.Exist = true;
            header.IdRec = IdRec.Value;
        }
        header.WellPhase = dataAC.WellPhase;
        header.Phase1 = dataAC.Phase1;
        header.Phase2 = dataAC.Phase2;
        header.WellId = this._sectionDailyOperation.wellId;
        header.JobId = this._sectionDailyOperation.jobID;
        header.Wellbore = this._sectionDailyOperation.idRecWellBore;
        var cvx = new CVXPlanData();
        cvx.HeaderName = header;
        var actions = this.selectedActions.filter(function (obj) { return obj.WellPhase === dataAC.WellPhase && obj.Phase1 === dataAC.Phase1 && obj.Phase2 === dataAC.Phase2; });
        actions.map(function (act) {
            act.WellId = _this._sectionDailyOperation.wellId;
            act.Wellbore = _this._sectionDailyOperation.idRecWellBore;
            if (header.Exist) {
                act.JobId = IdRec.Value;
            }
            return;
        });
        cvx.Actions = actions.slice();
        this.selectedCVX.push(cvx);
    };
    //#endregion 'General Methods'
    //#region 'Toast'
    PlanActivitiesComponent.prototype.hideToast = function () {
        this._message.clear('c');
    };
    PlanActivitiesComponent.prototype.onConfirm = function () {
        if (this.validateIfSelected()) {
            this.hideToast();
            this.saveData();
        }
        else {
            this.hideToast();
            this.showNotification({
                key: 'planActivitiesError',
                severity: 'warn',
                summary: 'Missiing Plan',
                detail: 'Plase select at least one Plan Activity',
                life: 6000,
            });
        }
    };
    PlanActivitiesComponent.prototype.onReject = function () {
        this.hideToast();
    };
    PlanActivitiesComponent.prototype.showMessage = function () {
        this._message.add({
            key: 'c',
            sticky: true,
            severity: 'info',
            summary: 'Save into WellView',
            detail: 'You are about to save the info to WellView, would you like to continue?.',
        });
    };
    //#endregion 'Toast'
    //#region 'Validations'
    PlanActivitiesComponent.prototype.validateIfSelected = function () {
        var count = 0;
        this.dataCVX.forEach(function (cvx) {
            var contAct = 0;
            cvx.Actions.forEach(function (ac) {
                count = ac.Selected ? count + 1 : count;
                contAct = ac.Selected ? contAct + 1 : contAct;
            });
            if (contAct > 0) {
                cvx.HaveActionsSelected = true;
            }
            else {
                cvx.HaveActionsSelected = false;
            }
        });
        return count > 0 ? true : false;
    };
    PlanActivitiesComponent.prototype.validatePlanExist = function (header) {
        return this.currentPlans.filter(function (obj) {
            return obj.WellPhase.Value === header.WellPhase &&
                obj.Phase1.Value === header.Phase1 &&
                obj.Phase2.Value === header.Phase2;
        });
    };
    PlanActivitiesComponent.prototype.validateCorrectSeq = function () {
        var _this = this;
        this.plansToUpdate = [];
        this.currentPlans.forEach(function (cp) {
            var OAP = _this.onlyAvailablePhases.filter(function (obj) {
                return obj.WellPhase === cp.WellPhase.Value && obj.Phase1 === cp.Phase1.Value && obj.Phase2 === cp.Phase2.Value;
            });
            if (OAP && OAP.length > 0) {
                var updt = _this._tooltip.deepClone(cp);
                updt.SysSeq.Value = OAP[0].SysSeq.toString();
                _this.plansToUpdate.push(updt);
            }
        });
        this.plansToUpdate.sort(function (a, b) {
            return Number(a.SysSeq.Value) > Number(b.SysSeq.Value) ? 1 : Number(b.SysSeq.Value) > Number(a.SysSeq.Value) ? -1 : 0;
        });
        var counter = 1;
        this.plansToUpdate.map(function (plan) {
            plan.SysSeq.Value = counter.toString();
            counter++;
            return plan;
        });
        if (this.plansToUpdate && this.plansToUpdate.length > 0) {
            this.updatePlansSequence();
        }
    };
    //#endregion 'Validations'
    //#region 'CRUD'
    PlanActivitiesComponent.prototype.saveData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.existingPlans = [];
                        this.selectedActions = [];
                        this.notExistingPlans = [];
                        this.getDataReadyToInsert();
                        this.notExistingPlans = this.selectedCVX.filter(function (cvx) { return !cvx.HeaderName.Exist; });
                        this.existingPlans = this.selectedCVX.filter(function (cvx) { return cvx.HeaderName.Exist; });
                        if (!(this.existingPlans.length > 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.insertPlanActivities(true).then(function () {
                                _this.showNotification({
                                    severity: 'success',
                                    summary: 'Correct!',
                                    detail: 'Plan Activities inserted correctly',
                                });
                            }, function (reject) {
                                console.error(reject);
                                _this.showNotification({
                                    key: 'planActivitiesError',
                                    severity: 'error',
                                    summary: 'We got a problem!',
                                    detail: 'There was a problem while inserting Plan Activities, please refresh the page or notify the administrator.',
                                    life: 6000,
                                });
                            })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        if (!(this.notExistingPlans.length > 0)) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.insertPlans().then(function (resolve) { return __awaiter(_this, void 0, void 0, function () {
                                var _this = this;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            resolve.forEach(function (cvx) {
                                                var HEAD = _this.notExistingPlans.filter(function (obj) {
                                                    return obj.HeaderName.WellPhase === cvx.WellPhase.Value &&
                                                        obj.HeaderName.Phase1 === cvx.Phase1.Value &&
                                                        obj.HeaderName.Phase2 === cvx.Phase2.Value;
                                                });
                                                HEAD[0].HeaderName.IdRec = cvx.IdRec.Value;
                                                HEAD[0].Actions.map(function (cvxAct) {
                                                    cvxAct.JobId = cvx.IdRec.Value;
                                                    return;
                                                });
                                            });
                                            return [4 /*yield*/, this.insertPlanActivities(false).then(function () {
                                                    _this.validateCorrectSeq();
                                                    _this.showNotification({
                                                        severity: 'success',
                                                        summary: 'Correct!',
                                                        detail: 'Plans & Plan Activities Inserted Correctly',
                                                    });
                                                }, function (error) {
                                                    console.error(error);
                                                    _this.showNotification({
                                                        key: 'planActivitiesError',
                                                        severity: 'error',
                                                        summary: 'We got a problem!',
                                                        detail: 'There was a problem while inserting Plans & Plan Activities, please refresh the page or notify the administrator.',
                                                        life: 6000,
                                                    });
                                                })];
                                        case 1:
                                            _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); }, function (reject) {
                                console.error(reject);
                                _this.showNotification({
                                    key: 'planActivitiesError',
                                    severity: 'error',
                                    summary: 'We got a problem!',
                                    detail: 'There was a problem while inserting Plans & Plan Activities, please refresh the page or notify the administrator.',
                                    life: 6000,
                                });
                            })];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    PlanActivitiesComponent.prototype.insertPlans = function () {
        var _this = this;
        var plansToInsert = [];
        return new Promise(function (resolve, reject) {
            try {
                _this._loader.show();
                _this.notExistingPlans.forEach(function (head) {
                    plansToInsert.push(head.HeaderName);
                });
                var CP$ = _this._AMRA.createBulkPlan(plansToInsert).subscribe(function (resp) {
                    _this.currentPlans = _this.currentPlans.concat(resp);
                    _this._loader.hide();
                    resolve(resp);
                }, function (error) {
                    _this._loader.hide();
                    reject(error);
                });
                _this.SUBS$.add(CP$);
            }
            catch (err) {
                _this._loader.hide();
                _this.showNotification({
                    key: 'planActivitiesError',
                    severity: 'error',
                    summary: 'We got a problem!',
                    detail: 'There was a problem while inserting Plans, please refresh the page or notify the administrator.',
                    life: 6000,
                });
                reject(Error(err));
            }
        });
    };
    PlanActivitiesComponent.prototype.insertPlanActivities = function (fromExist) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            try {
                var actionsToInsert_1 = [];
                if (fromExist) {
                    _this.existingPlans.forEach(function (act) {
                        actionsToInsert_1.push.apply(actionsToInsert_1, act.Actions);
                    });
                }
                else {
                    _this.notExistingPlans.forEach(function (act) {
                        actionsToInsert_1.push.apply(actionsToInsert_1, act.Actions);
                    });
                }
                _this._loader.show();
                var CP$ = _this._AMRA.createBulkPlanActivity(actionsToInsert_1).subscribe(function (resp) {
                    _this._loader.hide();
                    resolve(resp);
                }, function (error) {
                    _this._loader.hide();
                    reject(error);
                });
                _this.SUBS$.add(CP$);
            }
            catch (err) {
                _this._loader.hide();
                _this.showNotification({
                    key: 'planActivitiesError',
                    severity: 'error',
                    summary: 'We got a problem!',
                    detail: 'There was a problem while inserting Plan Activities, please refresh the page or notify the administrator.',
                    life: 6000,
                });
                reject(Error(err));
            }
        });
    };
    PlanActivitiesComponent.prototype.updatePlansSequence = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            try {
                _this._loader.show();
                var CP$ = _this._AMRA.updateBulkPlan(_this.plansToUpdate).subscribe(function (resp) {
                    _this._loader.hide();
                    resolve(resp);
                }, function (error) {
                    _this._loader.hide();
                    reject(error);
                });
                _this.SUBS$.add(CP$);
                resolve(true);
            }
            catch (err) {
                reject(Error(err));
            }
        });
    };
    //#endregion 'CRUD'
    //#region 'Notification'
    PlanActivitiesComponent.prototype.showNotification = function (msg) {
        this._message.add({
            key: msg && msg.key && msg.key.length > 0 ? msg.key : 'planActivitiesNotify',
            sticky: msg && msg.key && msg.key != 'planActivitiesNotify' ? true : false,
            closable: msg && msg.key && msg.key != 'planActivitiesNotify' ? true : false,
            severity: msg.severity,
            summary: msg.summary,
            detail: msg.detail,
            life: msg.life,
        });
    };
    return PlanActivitiesComponent;
}());
export { PlanActivitiesComponent };
