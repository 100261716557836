var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { SectionsApiService } from '#services-api/index';
import { LoadingIndicatorService, SectionDataService } from '#services-shared/index';
import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';
var SectionsComponent = /** @class */ (function () {
    //#endregion 'Variables'
    //#region 'Angular Life Cycle''
    function SectionsComponent(_section, _sectionData, _loader, router, activatedRoute, _message) {
        this._section = _section;
        this._sectionData = _sectionData;
        this._loader = _loader;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this._message = _message;
        //? Subscriptions
        this.SUBS$ = new Subscription();
    }
    SectionsComponent.prototype.ngOnInit = function () {
        this.wellId = this.activatedRoute.snapshot.paramMap.get('wellID');
        this.validatedCasings = this._sectionData.getvalidatedCasings();
        this.loadWellbore(this.wellId);
    };
    SectionsComponent.prototype.ngOnDestroy = function () {
        this.SUBS$.unsubscribe();
        this._loader.hide();
    };
    //#endregion 'Angular Life Cycle''
    //#region 'Load'
    SectionsComponent.prototype.loadWellbore = function (wellId) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this._loader.show();
                        _b = (_a = this.SUBS$).add;
                        return [4 /*yield*/, this._section
                                .getWellBoreByID(wellId)
                                .finally(function () { return _this._loader.hide(); })
                                .subscribe(function (data) {
                                _this.wellBoreData = data;
                                if (data[0]) {
                                    _this.selectedWellBore = data[0];
                                    _this.loadSections(_this.selectedWellBore);
                                }
                            }, function () {
                                _this.sectionData = [];
                                _this.showNotification({
                                    key: 'SecError',
                                    severity: 'error',
                                    summary: 'We got a problem!',
                                    detail: 'There was an error loading well information, please refresh the page or notify the administrator.',
                                    life: 6000,
                                });
                            })];
                    case 1:
                        _b.apply(_a, [_c.sent()]);
                        return [2 /*return*/];
                }
            });
        });
    };
    SectionsComponent.prototype.loadSections = function (wellBore) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this._loader.show();
                        _b = (_a = this.SUBS$).add;
                        return [4 /*yield*/, this._section
                                .getSectionsByWellBoreID(wellBore.WellboreId)
                                .finally(function () { return _this._loader.hide(); })
                                .subscribe(function (data) {
                                _this.sectionData = data;
                                _this.sectionData.sort(function (a, b) {
                                    if (a.StartDate === '' && b.StartDate !== '') {
                                        return 1;
                                    }
                                    else if (a.StartDate !== '' && b.StartDate === '') {
                                        return -1;
                                    }
                                    else {
                                        if (new Date(a.StartDate) > new Date(b.StartDate)) {
                                            return -1;
                                        }
                                        else if (new Date(a.StartDate) < new Date(b.StartDate)) {
                                            return 1;
                                        }
                                        else {
                                            return 0;
                                        }
                                    }
                                });
                            }, function () {
                                _this.sectionData = [];
                                _this.showNotification({
                                    key: 'SecError',
                                    severity: 'error',
                                    summary: 'We got a problem!',
                                    detail: 'There was an error loading well information, please refresh the page or notify the administrator.',
                                    life: 6000,
                                });
                            })];
                    case 1:
                        _b.apply(_a, [_c.sent()]);
                        return [2 /*return*/];
                }
            });
        });
    };
    //#endregion 'Load'
    //#region 'General Methods'
    SectionsComponent.prototype.wellBoreIDChange = function () {
        this.loadSections(this.selectedWellBore);
    };
    SectionsComponent.prototype.casingTally = function (section) {
        this.router.navigate([
            'section-casings',
            {
                wellId: this.wellId,
                wellboreId: this.selectedWellBore.WellboreId,
                wellboreSectionId: section.WellboreSectionId,
            },
        ]);
    };
    //#endregion 'General Methods'
    //#region 'Notification'
    SectionsComponent.prototype.showNotification = function (msg) {
        this._message.add({
            key: msg && msg.key && msg.key.length > 0 ? msg.key : 'SecNotify',
            sticky: msg && msg.key && msg.key != 'SecNotify' ? true : false,
            closable: msg && msg.key && msg.key != 'SecNotify' ? true : false,
            severity: msg.severity,
            summary: msg.summary,
            detail: msg.detail,
            life: msg.life,
        });
    };
    return SectionsComponent;
}());
export { SectionsComponent };
