/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./not-found.component";
var styles_NotFoundComponent = [];
var RenderType_NotFoundComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NotFoundComponent, data: {} });
export { RenderType_NotFoundComponent as RenderType_NotFoundComponent };
export function View_NotFoundComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Not found"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Please correct page address or contact support if you believe this url is correct"]))], null, null); }
export function View_NotFoundComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-not-found", [], null, null, null, View_NotFoundComponent_0, RenderType_NotFoundComponent)), i0.ɵdid(1, 114688, null, 0, i1.NotFoundComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotFoundComponentNgFactory = i0.ɵccf("app-not-found", i1.NotFoundComponent, View_NotFoundComponent_Host_0, {}, {}, []);
export { NotFoundComponentNgFactory as NotFoundComponentNgFactory };
