import { environment } from '#environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CentralizeApiService = /** @class */ (function () {
    //#region 'Angular Life Cycle'
    function CentralizeApiService(http) {
        this.http = http;
    }
    //#endregion 'Angular Life Cycle'
    //#region 'General'
    CentralizeApiService.prototype.getURL = function (method) {
        return environment.apiEndpoint + "/centralizersetup" + method;
    };
    //#endregion 'General'
    //#region 'Get'
    CentralizeApiService.prototype.getByCasingStringId = function (casingStringId) {
        return this.http.get(this.getURL("/getByCasingStringId"), {
            params: { casingStringId: casingStringId },
        });
    };
    //#endregion 'Get'
    //#region 'Post'
    CentralizeApiService.prototype.create = function (dto) {
        return this.http.post(this.getURL("/create"), dto);
    };
    CentralizeApiService.prototype.createBulk = function (dto) {
        return this.http.post(this.getURL("/createBulk"), dto);
    };
    //#endregion 'Post'
    //#region 'Put'
    CentralizeApiService.prototype.update = function (dto) {
        return this.http.put(this.getURL("/update"), dto);
    };
    CentralizeApiService.prototype.updateBulk = function (dto) {
        return this.http.put(this.getURL("/updateBulk"), dto);
    };
    //#endregion 'Put'
    //#region 'Delete'
    CentralizeApiService.prototype.delete = function (dto) {
        return this.http.put(this.getURL("/delete"), dto);
    };
    CentralizeApiService.prototype.deleteBulk = function (dto) {
        return this.http.put(this.getURL("/deleteBulk"), dto);
    };
    CentralizeApiService.ngInjectableDef = i0.defineInjectable({ factory: function CentralizeApiService_Factory() { return new CentralizeApiService(i0.inject(i1.HttpClient)); }, token: CentralizeApiService, providedIn: "root" });
    return CentralizeApiService;
}());
export { CentralizeApiService };
