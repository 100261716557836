import { environment } from '#environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var FeedbackApiService = /** @class */ (function () {
    //#region 'Angular Life Cycle'
    function FeedbackApiService(http) {
        this.http = http;
    }
    //#endregion 'Angular Life Cycle'
    //#region 'Create'
    FeedbackApiService.prototype.postFeedback = function (feedback) {
        return this.http.post(environment.apiEndpoint + "/feedback", feedback);
    };
    FeedbackApiService.ngInjectableDef = i0.defineInjectable({ factory: function FeedbackApiService_Factory() { return new FeedbackApiService(i0.inject(i1.HttpClient)); }, token: FeedbackApiService, providedIn: "root" });
    return FeedbackApiService;
}());
export { FeedbackApiService };
