var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { SectionCasingService } from '#services-shared/index';
import { EventEmitter, OnInit } from '@angular/core';
var CementVolumeComponent = /** @class */ (function () {
    //#endregion 'Variables'
    //#region 'Angular Life Cycle'
    function CementVolumeComponent(_sectionCasing) {
        this._sectionCasing = _sectionCasing;
        //#region 'Input, Output, ViewChild'
        this.message = new EventEmitter();
        //#endregion 'Input, Output, ViewChild'
        //#region 'Variables'
        // UI Input Variables
        this.defaultDescription = { Description: '---Select---', Help: '' };
    }
    CementVolumeComponent.prototype.ngOnInit = function () { };
    //#endregion 'Angular Life Cycle'
    //#region 'Load'
    CementVolumeComponent.prototype.reCalculate = function () {
        // Follow this Order - Calculations Depend on Each other
        this.computePipeCapacity();
        this.computePipeDisplacement();
        this.computeInternalCasingVolume();
        this.computeTotalAnnulusVolume();
        this.computeTotalFluidVolume();
        this.computeShoeTrackVolume();
        this.computeHalfShoeTrackVolume();
        this.computeTargetVolume();
        this.computeTargetVolume30();
        this.computeSacks();
        this.computeMixWater();
        this.computeFreshWaterDisp();
        this.computeMaxDisp();
    };
    //#endregion 'Load'
    //#region 'Compute Methods'
    CementVolumeComponent.prototype.computePipeCapacity = function () {
        this.pipeCapacity = Math.pow(this.calculatedODCmntVol, 2) / 1029.4;
    };
    CementVolumeComponent.prototype.computePipeDisplacement = function () {
        this.pipeDisplacement =
            ((Math.pow(this.calculatedODCmntVol, 2) - Math.pow(Number(this.selectedIDCmntVol), 2)) / 1029.4) *
                this._sectionCasing.shoeDepth;
    };
    CementVolumeComponent.prototype.computeInternalCasingVolume = function () {
        this.internalCasingVolume = (Math.pow(Number(this.selectedIDCmntVol), 2) / 1029.4) * this._sectionCasing.shoeDepth;
    };
    CementVolumeComponent.prototype.computeTotalAnnulusVolume = function () {
        if (this.prevCasingDescription === this.defaultDescription) {
            this.totalAnnulusVolume =
                ((Math.pow(Number(this.selectedHoleSize), 2) - Math.pow(this.calculatedODCmntVol, 2)) / 1029.4) *
                    this._sectionCasing.shoeDepth;
        }
        else {
            this.totalAnnulusVolume =
                ((Math.pow(Number(this._sectionCasing.prevCasingID), 2) - Math.pow(this.calculatedODCmntVol, 2)) / 1029.4) *
                    (this._sectionCasing.prevShoeDepth - 19.3) +
                    ((Math.pow(Number(this.selectedHoleSize), 2) - Math.pow(this.calculatedODCmntVol, 2)) / 1029.4) *
                        (this._sectionCasing.shoeDepth - this._sectionCasing.prevShoeDepth);
        }
    };
    CementVolumeComponent.prototype.computeTotalFluidVolume = function () {
        this.totalFluidVolume = this.totalAnnulusVolume + this.internalCasingVolume;
    };
    CementVolumeComponent.prototype.computeTargetVolume = function () {
        this.targetVolume = this.totalAnnulusVolume + this.shoeTrackVolume;
    };
    CementVolumeComponent.prototype.computeTargetVolume30 = function () {
        this.targetVolumeOH =
            ((Math.pow(Number(this._sectionCasing.prevCasingID), 2) - Math.pow(this.calculatedODCmntVol, 2)) / 1029.4) *
                (this._sectionCasing.prevShoeDepth - 32.4) +
                ((Math.pow(Number(this.selectedHoleSize), 2) - Math.pow(this.calculatedODCmntVol, 2)) / 1029.4) *
                    (this._sectionCasing.shoeDepth - this._sectionCasing.prevShoeDepth) *
                    1.3 +
                this.shoeTrackVolume;
    };
    CementVolumeComponent.prototype.computeSacks = function () {
        this.sacksReqd = this.targetVolumeOH / 0.1781 / this._sectionCasing.casingString.Yield;
    };
    CementVolumeComponent.prototype.computeMixWater = function () {
        this.mixWaterCalc = (this.sacksReqd * this._sectionCasing.casingString.MixWater) / 42.2;
    };
    CementVolumeComponent.prototype.computeShoeTrackVolume = function () {
        this.shoeTrackVolume = this._sectionCasing.casingString.Length * this.pipeCapacity;
    };
    CementVolumeComponent.prototype.computeHalfShoeTrackVolume = function () {
        this.halfShoeTrackVolume = this.shoeTrackVolume * 0.5;
    };
    CementVolumeComponent.prototype.computeFreshWaterDisp = function () {
        this.freshWaterDisplacement =
            (this._sectionCasing.shoeDepth -
                this._sectionCasing.casingString.Length +
                this._sectionCasing.casingString.StickUp) *
                this.pipeCapacity;
    };
    CementVolumeComponent.prototype.computeMaxDisp = function () {
        this.maxDisplacement = this.freshWaterDisplacement + this.halfShoeTrackVolume;
    };
    CementVolumeComponent.prototype.prevCasingDescriptionChange = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this._sectionCasing.resetNotifications();
                if (this.prevCasingDescription.Description === this._sectionCasing.selectedCasingDescription.Description) {
                    this._sectionCasing.prevCasingID = '';
                    this._sectionCasing.prevShoeDepth = 0;
                    this._sectionCasing.showNotification({
                        severity: 'warn',
                        summary: 'Something went wrong!',
                        detail: 'Please Select Different Previous Casing Than Current.',
                    }, 4);
                }
                else {
                    if (this.prevCasingDescription === this.defaultDescription) {
                        this._sectionCasing.casingString.PrevCasingDescription = null;
                        this._sectionCasing.prevCasingID = undefined;
                        this._sectionCasing.prevShoeDepth = undefined;
                        this._sectionCasing.updateCasingString(4, 'Cement_Volume');
                        this.reCalculate();
                    }
                    else {
                        this._sectionCasing.loadPreviousCasingData(true, this.prevCasingDescription.Description).then(function () {
                            _this.message.next({
                                key: 'casingNotify',
                                sticky: false,
                                closable: false,
                                severity: 'success',
                                summary: 'Casing String Updated',
                                detail: 'Previous Casing Description Successfully Updated.',
                            });
                            _this.reCalculate();
                        }, function (err) {
                            switch (err) {
                                case 'EmptyCasingString': {
                                    _this._sectionCasing.showNotification({
                                        severity: 'info',
                                        summary: 'No Casing String',
                                        detail: 'No Associated Casings Available.',
                                    }, 4);
                                    break;
                                }
                                case 'ErrorPreviousCasing': {
                                    _this._sectionCasing.showNotification({
                                        severity: 'error',
                                        summary: 'We got a problem!',
                                        detail: 'Error Retrieving Previous Casing for Cement Volume Calculation.',
                                    }, 4);
                                    break;
                                }
                                case 'EmptyShoeDepth': {
                                    _this._sectionCasing.showNotification({
                                        severity: 'info',
                                        summary: 'No Casing Shoe Depth Info',
                                        detail: 'No Associated Casing Shoe Depth Info.',
                                    }, 4);
                                    break;
                                }
                                case 'EmptyCasingID': {
                                    _this._sectionCasing.showNotification({
                                        severity: 'info',
                                        summary: 'No Casing ID Info',
                                        detail: 'No Associated Casings ID Info.',
                                    }, 4);
                                    break;
                                }
                                case 'ErrorPreviousCasingInfo': {
                                    _this._sectionCasing.showNotification({
                                        severity: 'error',
                                        summary: 'We got a problem!',
                                        detail: 'Error Retrieving Previous Casing Info.',
                                    }, 4);
                                    break;
                                }
                                default: {
                                    _this._sectionCasing.showNotification({
                                        severity: 'error',
                                        summary: 'We got a problem!',
                                        detail: 'Error Loading Previous Casing Information, please refresh the page.',
                                    }, 4);
                                    break;
                                }
                            }
                        });
                    }
                }
                return [2 /*return*/];
            });
        });
    };
    //#endregion 'Compute Methods'
    //#region 'CRUD'
    CementVolumeComponent.prototype.updateODCmntVal = function () {
        if (this.selectedODCmntVol.includes('/')) {
            this.calculatedODCmntVol = this._sectionCasing.calculateMixedNumbers(this.selectedODCmntVol);
        }
        else {
            this.calculatedODCmntVol = Number(this.selectedODCmntVol);
        }
    };
    CementVolumeComponent.prototype.updateCasingStringInfo = function (updatedField, notificationType) {
        var updateSuccess = this._sectionCasing.updateCasingString(notificationType, 'Cement_Volume');
        if (updateSuccess) {
            this.message.next({
                key: 'casingNotify',
                sticky: false,
                closable: false,
                severity: 'success',
                summary: 'Casing String Updated',
                detail: updatedField + " was Successfully Updated.",
            });
        }
    };
    return CementVolumeComponent;
}());
export { CementVolumeComponent };
