/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./generic-error.component";
var styles_GenericErrorComponent = [];
var RenderType_GenericErrorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GenericErrorComponent, data: {} });
export { RenderType_GenericErrorComponent as RenderType_GenericErrorComponent };
export function View_GenericErrorComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "box-shadow module"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Internal Error"])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["There was an error processing your request. Please try again later or contact helpdesk."]))], null, null); }
export function View_GenericErrorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-generic-error", [], null, null, null, View_GenericErrorComponent_0, RenderType_GenericErrorComponent)), i0.ɵdid(1, 114688, null, 0, i1.GenericErrorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GenericErrorComponentNgFactory = i0.ɵccf("app-generic-error", i1.GenericErrorComponent, View_GenericErrorComponent_Host_0, {}, {}, []);
export { GenericErrorComponentNgFactory as GenericErrorComponentNgFactory };
