<!-- Messages -->
<!-- <div class="row">
  <p-messages id="tml_msg_oa" [(value)]="_offlineActivity.notifyTMLOA" [closable]="false"></p-messages>
</div> -->
<!-- Messages -->

<!-- Timelog Content -->
<div style="max-height: 700px">
  <!-- Timelog Toolbar -->
  <div class="row mb8">
    <div class="row">
      <div class="col-md-3">
        <div class="divCenter40">
          <span class="tmle">Offline Activity Timelog</span>
        </div>
      </div>
      <div class="col-md-2"></div>
      <div class="col-md-7">
        <div class="tmlt divCenter40 ml12">
          <span class="subTitle ml15"> Current Offline Activity Hours</span>&nbsp;&nbsp;&nbsp;<span class="rigTime"
            >{{ this._offlineActivity.TMLTime | number: '1.2' }} hr</span
          >
        </div>
        <div class="tmlt divCenter40">
          <span class="subTitle ml10">Remaining Offline Activity Hours</span>&nbsp;&nbsp;&nbsp;<span class="rigTime"
            >{{ _offlineActivity.currentTimelogAvailable | number: '1.2' }} hr</span
          >
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-1">
      <button
        id="btn_new_entry_oa"
        type="button"
        class="btn btn-success normalText centerContent"
        [disabled]="!_offlineActivity.OADHaveData()"
        (click)="openEntryModal(false)"
      >
        <i class="pi pi-plus"></i>
        <span class="ml6">New Entry</span>
      </button>
    </div>
    <div class="col-md-5"></div>
    <div class="col-md-2">
      <button
        id="btn_new_entry_oa"
        type="button"
        class="btn btn-primary normalText centerContent fr"
        [disabled]="!_offlineActivity.OADHaveData()"
        (click)="openEmailNotification()"
      >
        <i class="pi pi-check-circle"></i>
        <span class="ml6">Send Notification</span>
      </button>
    </div>
    <div class="col-md-2">
      <button
        id="btn_add_att_oa"
        type="button"
        class="btn btn-primary normalText centerContent fr"
        [disabled]="!_offlineActivity.SPFHaveData()"
        (click)="_offlineActivity.openViewFiles()"
      >
        <i class="pi pi-search"></i>
        <span class="ml6">View Attachments ({{ _offlineActivity.spFilesList.length }}) </span>
      </button>
    </div>
    <div class="col-md-2">
      <button
        id="btn_add_att_oa"
        type="button"
        class="btn btn-success normalText centerContent fr"
        [disabled]="!_offlineActivity.OADHaveData()"
        (click)="openAttachments()"
      >
        <i class="pi pi-file"></i>
        <span class="ml6">Add Attachment</span>
      </button>
    </div>
  </div>
  <!-- Timelog Toolbar -->

  <!-- Timelog Table -->
  <div class="row paddingLR15">
    <div class="text-left">
      <table class="table table-striped noborder noMarginBottom">
        <!-- Table Headers -->
        <thead class="text-primary">
          <tr>
            <th class="w4">Order</th>
            <th class="w6">Duration</th>
            <th class="w12">Activity Code</th>
            <th class="w6">Inactive Time</th>
            <th class="w8">Start Depth</th>
            <th class="w8">End Depth</th>
            <th class="w50">Comment</th>
            <th class="w6"></th>
          </tr>
        </thead>
        <!-- Table Headers -->
      </table>
    </div>
    <div class="text-left table-responsive" style="height: 470px; max-height: 470px">
      <table class="table table-striped noborder">
        <!-- Table Body -->
        <tbody id="timelogRecord">
          <tr
            *ngFor="let tl of _offlineActivity.OATList; let idx = index"
            [ngClass]="{
              overflowComment: tl.Comment.Value.startsWith('OVERFLOW')
            }"
          >
            <td class="w4">
              <div>
                <i
                  id="icn_up_0{{ idx }}"
                  [ngClass]="{ disable: idx === 0 }"
                  class="pi pi-caret-up reorder"
                  (click)="timelogUp(idx)"
                ></i>
                <i
                  id="icn_down_0{{ idx }}"
                  [ngClass]="{ disable: idx === _offlineActivity.OATList.length - 1 }"
                  class="pi pi-caret-down reorder"
                  (click)="timelogDown(idx)"
                ></i>
              </div>
            </td>
            <td class="w6">
              <div class="fl">
                <span class="divCenter2">{{ tl.Duration.Value }}</span>
              </div>
              <div class="fl ml8">
                <span class="divCenter2">hr</span>
              </div>
            </td>
            <td class="w12">
              <div>
                <span class="divCenter2">{{ tl.Activity.Value }}</span>
              </div>
            </td>
            <td class="w6">
              <div class="fl">
                <span class="divCenter2"> {{ tl.InactiveTime.Value }}</span>
              </div>
            </td>
            <td class="w8">
              <div class="fl">
                <span class="divCenter2">{{ tl.StartDepth.Value | number: '1.2' }}</span>
              </div>
            </td>
            <td class="w8">
              <div class="fl">
                <span class="divCenter2">{{ tl.EndDepth.Value | number: '1.2' }}</span>
              </div>
            </td>
            <td class="w50">
              <div>
                <span>{{ tl.Comment.Value }}</span>
              </div>
            </td>
            <td class="w6">
              <div id="div_edit_tl_0{{ idx }}" class="fl" (click)="editTimeLogRecord(tl, idx)">
                <i class="pi pi-pencil divCenter2 edtIcon"></i>
              </div>
              <div id="div_delete_tl_0{{ idx }}" class="fl ml8" (click)="deleteTML(tl)">
                <i class="pi pi-trash divCenter2 dltIcon"></i>
              </div>
            </td>
          </tr>
        </tbody>
        <!-- Table Body -->
      </table>
      <div *ngIf="!_offlineActivity.OATHaveData()" class="centerNoData">
        <span>Sorry, the selected Offline Activity Date has no entries to display at this moment.</span>
      </div>
    </div>
  </div>
  <!-- Timelog Table -->
</div>
<!-- Timelog Content -->

<!-- Create New Entry / Edit Entry -->
<p-dialog
  [(visible)]="showCreateNewEntry"
  [width]="720"
  [height]="'auto'"
  [baseZIndex]="5000"
  [modal]="true"
  [responsive]="true"
  [showHeader]="false"
  [contentStyle]="{ overflow: 'auto' }"
>
  <form [formGroup]="formOAT">
    <br />
    <div class="row">
      <div class="col-md-12">
        <span class="titleCreateNew">Offline Timelog Entry</span>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-3">
        <div class="row">
          <div>
            <span class="rigTime"><span class="mark-asterisk">*</span> Duration</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-10 noPaddingLeft">
            <div>
              <input
                id="txt_dlg_duration_oa"
                type="number"
                onkeyup="if(this.value < 0){this.value = this.value * -1}"
                min="1"
                class="form-control"
                formControlName="duration"
                onfocus="this.select();"
              />
            </div>
          </div>
          <div class="col-md-2 noPadding">
            <span class="divCenter2">hr</span>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="row">
          <div><span class="rigTime">Depth Start</span></div>
        </div>
        <div class="row">
          <div class="col-md-10 noPaddingLeft">
            <div>
              <input
                id="txt_dlg_depth_start_oa"
                type="number"
                onkeyup="if(this.value < 0){this.value = this.value * -1}"
                min="0"
                class="form-control"
                formControlName="depthStart"
                onfocus="this.select();"
              />
            </div>
          </div>
          <div class="col-md-2 noPadding">
            <span class="divCenter2">ft</span>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="row">
          <div><span class="rigTime">Depth End</span></div>
        </div>
        <div class="row">
          <div class="col-md-10 noPaddingLeft">
            <div>
              <input
                id="txt_dlg_depth_end_oa"
                type="number"
                onkeyup="if(this.value < 0){this.value = this.value * -1}"
                min="0"
                class="form-control"
                formControlName="depthEnd"
                onfocus="this.select();"
              />
            </div>
          </div>
          <div class="col-md-2 noPadding">
            <span class="divCenter2">ft</span>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="row">
          <div><span class="rigTime">Inactive Time</span></div>
        </div>
        <div class="row">
          <div class="col-md-12 noPaddingLeft">
            <div>
              <p-checkbox id="ccb_dlg_inactive_oa" name="multiEdit" binary="true" formControlName="inactive">
              </p-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-3">
        <div class="row"><span class="rigTime">Activity Code</span></div>
        <div class="row">
          <div id="div_dlg_open_activity_oa" class="fl" (click)="openActivityCodes()">
            <i class="pi pi-pencil divCenter2 edtIcon"></i>
          </div>
          <div *ngIf="activityToEdit" class="fl">
            <span class="divCenter2">{{ activityToEdit.Value }}</span>
          </div>
        </div>
      </div>
      <div class="col-md-9">
        <div class="row"><span class="rigTime">Activity Description</span></div>
        <div class="row">
          <div *ngIf="activityToEdit && activityToEdit.Value" class="col-md-12 noPaddingLeft">
            <span>{{ activityToEdit.Help }}</span>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <span class="rigTime"><span class="mark-asterisk">*</span> Comments</span>
        </div>
        <div class="row">
          <div class="col-md-12 noPaddingLeft">
            <textarea id="txt_dlg_comments_oa" maxlength="1800" formControlName="comments"></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <span class="errorSpan">NOTE: All the fields marked with (*) are required.</span>
      </div>
    </div>
    <br *ngIf="showErrorcreateTML || showErrorDuration" />
    <div *ngIf="showErrorcreateTML" class="row">
      <div class="col-md-12">
        <span class="errorSpan"
          >- Please fill all the fields marked with (*) and validate the Duration is greater than 0.</span
        >
      </div>
    </div>
    <div *ngIf="showErrorDuration" class="row">
      <div class="col-md-12">
        <span class="errorSpan"
          >- Current duration exceeds timelog available <b>({{ _offlineActivity.currentTimelogAvailable }} hr)</b> time,
          please check.</span
        >
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-12">
        <div class="fr mr14">
          <button
            id="btn_dlg_create_oa"
            *ngIf="!isTimeUpdate"
            type="button"
            class="btn btn-primary normalText"
            (click)="createTML()"
          >
            Create
          </button>
          <button
            id="btn_dlg_update_oa"
            *ngIf="isTimeUpdate"
            type="button"
            class="btn btn-primary normalText"
            (click)="updateTML()"
          >
            Update
          </button>
          <button id="btn_dlg_cancel_oa" type="button" class="btn btn-danger ml8" (click)="closeEntryModal(true)">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </form>
</p-dialog>
<!-- Create New Entry / Edit Entry -->

<!-- Activities SideBar -->
<p-sidebar
  [(visible)]="displayActivityConfigurations"
  [showCloseIcon]="true"
  [dismissible]="true"
  styleClass="ui-sidebar-lg"
  position="right"
  [style]="{ overflow: 'auto' }"
  [baseZIndex]="10000"
>
  <br />
  <p-table
    #dt
    id="btn_sdb_select_oa"
    [value]="_sectionDailyOperation.activityCodes"
    selectionMode="single"
    (onRowSelect)="activityCodeSelect($event)"
    class="normalText"
  >
    <ng-template pTemplate="caption">
      <span class="normalText"> Select Activity Code </span>
    </ng-template>
    <ng-template pTemplate="header">
      <tr class="normalText">
        <th class="w30">Activity</th>
        <th class="w70">Description</th>
      </tr>
      <tr class="normalText">
        <th class="w30">
          <i class="pi pi-search"></i>
          <input
            id="txt_sdb_filter_activity_oa"
            class="autoWidth normalText"
            pInputText
            type="text"
            placeholder="Filter"
            (input)="dt.filter($event.target.value, 'Activity', 'contains')"
          />
        </th>
        <th class="w70">
          <i class="pi pi-search"></i>
          <input
            id="txt_sdb_filter_help_oa"
            class="autoWidth normalText"
            pInputText
            type="text"
            placeholder="Filter"
            (input)="dt.filter($event.target.value, 'Help', 'contains')"
          />
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
      <tr [pSelectableRow]="item" class="normalText">
        <td class="w30">{{ item.Activity }}</td>
        <td class="w70">{{ item.Help }}</td>
      </tr>
    </ng-template>
  </p-table>
</p-sidebar>
<!-- Activities SideBar -->

<!-- Attachments -->
<p-dialog
  [(visible)]="showAttachments"
  [width]="700"
  [height]="'auto'"
  [baseZIndex]="5000"
  [modal]="true"
  [responsive]="true"
  [showHeader]="false"
  [contentStyle]="{ overflow: 'auto' }"
>
  <div>
    <br />
    <div class="row">
      <div class="col-md-12">
        <span class="titleCreateNew">Upload Files (Image and PDF)</span>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-12">
        <app-attachments (importFileSuccess)="importFileSuccess($event)"></app-attachments>
        <span class="note">Note: Some files cannot be previewed because of file size</span>
      </div>
    </div>
    <br *ngIf="showErrorDuplicatedFiles" />
    <div *ngIf="showErrorDuplicatedFiles" class="row">
      <div class="col-md-12">
        <span class="errorSpan">Some Images or PDF were not loaded because they already exist in the Attachments.</span>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-12">
        <span class="tmle">Files to be Uploaded</span>
        <div class="upload-list mt12">
          <div *ngFor="let fl of files; let idx = index" class="mb10">
            <div>
              <img *ngIf="typeOfFile(fl, idx)" [src]="fl.src" class="ml20" height="100" [alt]="fl.nameToShow" />
              <img *ngIf="!typeOfFile(fl, idx)" [src]="fl.icon" class="ml20" height="50" [alt]="fl.nameToShow" />
            </div>
            <div id="div_delete_file_0{{ idx }}" class="fl ml20" (click)="removeFile(idx)">
              <i class="pi pi-trash divCenter2 dltIcon"></i>
            </div>
            <div class="divCenter2">
              <span class="fileName">{{ fl.nameToShow }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-12">
        <div class="fr mr14">
          <button
            id="btn_dlg_upload_files"
            type="button"
            class="btn btn-primary normalText"
            (click)="uploadFiles()"
            [disabled]="isUploadDisabled()"
          >
            Upload
          </button>
          <button id="btn_dlg_cancel_files" type="button" class="btn btn-danger ml8" (click)="closeAttachments()">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</p-dialog>
<!-- Attachments -->

<!-- View Files -->
<p-dialog
  [(visible)]="_offlineActivity.showViewFiles"
  [width]="'auto'"
  [height]="'auto'"
  [baseZIndex]="5000"
  [modal]="true"
  [responsive]="true"
  [showHeader]="false"
  [contentStyle]="{ overflow: 'auto' }"
>
  <div>
    <br />
    <div class="row">
      <div class="col-md-12">
        <span class="titleCreateNew">Files Attached (Image and PDF)</span>
        <p class="note">Note: Large files will be downloaded to your device</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="files-uploaded mt12">
          <div
            *ngFor="let spf of _offlineActivity.spFilesList; let idx = index"
            id="div_delete_at_0{{ idx }}"
            class="urls divCenter2"
          >
            <i class="pi pi-trash divCenter2 dltIcon" (click)="deleteFile(spf, idx)"> </i>
            <span (click)="showFile(idx)" class="ml8 span-url">{{ spf.nameToShow }}</span>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-12">
        <div class="fr mr14">
          <button
            id="btn_dlg_cancel_files"
            type="button"
            class="btn btn-danger ml8"
            (click)="_offlineActivity.closeViewFiles()"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</p-dialog>
<!-- View Files -->

<!-- Add Emails -->
<p-dialog
  [(visible)]="showEmailNotification"
  [width]="500"
  [height]="'auto'"
  [baseZIndex]="5000"
  [modal]="true"
  [responsive]="true"
  [showHeader]="false"
  [contentStyle]="{ overflow: 'auto' }"
>
  <div>
    <br />
    <div class="row">
      <div class="col-md-12">
        <span class="titleCreateNew">Add emails to send the notification</span>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div>
            <span class="rigTime"><span class="mark-asterisk">*</span> Email:</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-10 noPaddingLeft">
            <div style="float: left; width: 83%">
              <input
                id="txt_email_oa"
                type="text"
                class="form-control"
                onfocus="this.select();"
                [(ngModel)]="emailToAdd"
              />
            </div>
            <div class="fl">
              <button id="btn_dlg_cancel_files" type="button" class="btn btn-primary ml8" (click)="addEmail()">
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-12">
        <div
          class="files-uploaded mt12"
          *ngIf="this._offlineActivity.emailData && this._offlineActivity.emailData.Emails.length > 0"
        >
          <div
            *ngFor="let spf of _offlineActivity.emailData.Emails; let idx = index"
            id="div_delete_at_0{{ idx }}"
            class="urls divCenter2"
          >
            <i class="pi pi-trash divCenter2 dltIcon" (click)="deleteEmail(idx)"> </i>
            <span class="ml8">{{ spf }}</span>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-12">
        <div class="fr mr14">
          <button
            id="btn_dlg_upload_files"
            type="button"
            class="btn btn-primary normalText"
            (click)="sendNotification()"
            [disabled]="this._offlineActivity.emailData && this._offlineActivity.emailData.Emails.length === 0"
          >
            Send Notification
          </button>
          <button id="btn_dlg_cancel_files" type="button" class="btn btn-danger ml8" (click)="closeEmailNotification()">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</p-dialog>
<!-- Add Emails -->

<!-- Toast -->
<p-toast [style]="{ marginTop: '40px !important' }" key="TMLOANotify" [baseZIndex]="6000"></p-toast>
<p-toast
  [style]="{ marginTop: '40px !important' }"
  key="TMLOAError"
  [baseZIndex]="6000"
  position="top-center"
></p-toast>
<!-- Toast -->
