var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { DailyOperation, Timelog } from '#models/index';
import { DailyOperationApiService } from '#services-api/jobs/daily-operation-api.service';
import { JobsApiService } from '#services-api/jobs/jobs-api.service';
import { TimelogApiService } from '#services-api/jobs/timelog-api.service';
import { LoadingIndicatorService } from '#services-shared/loading-indicator.service';
import { TooltipService } from '#services-shared/tooltip.service';
import { OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs/Subscription';
import * as i0 from "@angular/core";
import * as i1 from "../../api/jobs/jobs-api.service";
import * as i2 from "../../api/jobs/timelog-api.service";
import * as i3 from "../../api/jobs/daily-operation-api.service";
import * as i4 from "../loading-indicator.service";
import * as i5 from "../tooltip.service";
var DailyOperationService = /** @class */ (function () {
    //#endregion 'Variables'
    //#region 'Angular Life Cycle'
    function DailyOperationService(_jobs, _timelog, _dailyOperation, _loader, _tooltip) {
        this._jobs = _jobs;
        this._timelog = _timelog;
        this._dailyOperation = _dailyOperation;
        this._loader = _loader;
        this._tooltip = _tooltip;
        this.selectedDOP = 0;
        this.TMLSysSeq = 0;
        //? Notifications
        this.notificationsTMLDOP = [];
        this.notificationsDOP = [];
        this.DOPs = [];
        this.TMLs = [];
        this.activityCodes = [];
        //? Split Timelog Variables
        this.dataToValidateDates = [];
        this.durationP1 = 0;
        this.durationP2 = 0;
        this.isNormalUpdate = true;
        //? Subscriptions
        this.SUBS$ = new Subscription();
    }
    DailyOperationService.prototype.ngOnDestroy = function () {
        this.SUBS$.unsubscribe();
        this._loader.hide();
    };
    //#endregion 'Angular Life Cycle'
    //#region 'CRUD - Job'
    DailyOperationService.prototype.getJobByID = function () {
        var _this = this;
        this.SUBS$.add(this._jobs.getJobByID(this.jobID).subscribe(function (data) {
            _this.selectedJob = data;
        }, function (e) {
            console.error(e.message);
        }));
    };
    //#endregion 'CRUD - Job'
    //#region 'CRUD - Daily Operations'
    DailyOperationService.prototype.loadDOPs = function () {
        var _this = this;
        // console.log('******************** Enter loadDOPs ********************');
        this._loader.show();
        this.SUBS$.add(this._dailyOperation
            .getByJobId(this.jobID)
            .finally(function () { return _this._loader.hide(); })
            .subscribe(function (dataDOP) {
            if (dataDOP.length > 0) {
                dataDOP = _this.sortDataByStartDate(dataDOP);
                _this.DOPs = _this._tooltip.deepClone(dataDOP);
                // console.log('DOPs: ', this._tooltip.deepClone(dataDOP));
                // console.log('CALL -> selectDOP(this.selectedDOP)', this.selectedDOP);
                _this.selectDOP(_this.selectedDOP, true);
            }
        }));
    };
    DailyOperationService.prototype.createNewDOP = function () {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this._loader.show();
                if (this.formDOP) {
                    this.fetchFormToDOPNew(true);
                }
                this.SUBS$.add(this._dailyOperation
                    .create(this.DOPNew)
                    .finally(function () {
                    _this._loader.hide();
                    _this.loadDOPs();
                })
                    .subscribe(function () {
                    resolve({
                        key: 'DOPNotify',
                        severity: 'success',
                        summary: 'Correct!',
                        detail: 'Daily Operation Created Successfully',
                    });
                }, function (e) {
                    console.error(e.message);
                    reject({
                        key: 'DOPError',
                        severity: 'error',
                        summary: 'We got a problem!',
                        detail: 'We had a problem while creating, please refresh the page and try again.',
                        life: 6000,
                    });
                }));
                return [2 /*return*/];
            });
        }); });
    };
    DailyOperationService.prototype.updateDOP = function () {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this._loader.show();
                this.fetchFormToDOPNew(false);
                this.SUBS$.add(this._dailyOperation
                    .update(this.DOPNew)
                    .finally(function () {
                    _this._loader.hide();
                    _this.loadDOPs();
                })
                    .subscribe(function () {
                    resolve({
                        key: 'DOPNotify',
                        severity: 'success',
                        summary: 'Correct!',
                        detail: 'Daily Operation Updated Successfully',
                    });
                }, function (e) {
                    console.error(e.message);
                    reject({
                        key: 'DOPError',
                        severity: 'error',
                        summary: 'We got a problem!',
                        detail: 'We had a problem while updating, please refresh the page and try again.',
                        life: 6000,
                    });
                }));
                return [2 /*return*/];
            });
        }); });
    };
    DailyOperationService.prototype.deleteDOP = function () {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this._loader.show();
                this.SUBS$.add(this._dailyOperation
                    .delete(this.DOPDelteObject)
                    .finally(function () {
                    _this._loader.hide();
                    _this.loadDOPs();
                })
                    .subscribe(function () {
                    _this.selectedDOP = 0;
                    resolve({
                        key: 'DOPNotify',
                        severity: 'success',
                        summary: 'Correct!',
                        detail: 'Daily Operation Deleted Successfully',
                    });
                }, function (e) {
                    console.error(e.message);
                    reject({
                        key: 'DOPError',
                        severity: 'error',
                        summary: 'We got a problem!',
                        detail: 'We had a problem while deleting, please refresh the page and try again.',
                        life: 6000,
                    });
                }));
                return [2 /*return*/];
            });
        }); });
    };
    //#endregion 'CRUD - Daily Operations'
    //#region 'CRUD - Timelog'
    DailyOperationService.prototype.loadTMLs = function () {
        var _this = this;
        // console.log('******************** Enter loadTMLs ********************');
        this._loader.show();
        this.SUBS$.add(this._timelog
            .getByDailyOperationId(this.DOP_ID)
            .finally(function () { return _this._loader.hide(); })
            .subscribe(function (data) {
            _this.setTMLData(data);
        }));
    };
    DailyOperationService.prototype.loadActivityCodes = function () {
        var _this = this;
        this._loader.show();
        this.SUBS$.add(this._timelog
            .getActivityCode()
            .finally(function () { return _this._loader.hide(); })
            .subscribe(function (data) {
            _this.activityCodes = _this._tooltip.deepClone(data);
        }));
    };
    DailyOperationService.prototype.createTML = function (tml) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var CREATE$;
            var _this = this;
            return __generator(this, function (_a) {
                if (tml) {
                    this.TMLNew = tml;
                }
                else {
                    this._loader.show();
                    if (this.formTML) {
                        this.fetchFormToTMLNew(true);
                    }
                }
                CREATE$ = this._timelog
                    .create(this.TMLNew)
                    .finally(function () {
                    _this._loader.hide();
                    _this.loadTMLs();
                })
                    .subscribe(function () {
                    resolve({
                        key: 'TMLNotify',
                        severity: 'success',
                        summary: 'Correct!',
                        detail: 'Timelog Created Successfully',
                    });
                }, function (e) {
                    console.error(e.message);
                    reject({
                        key: 'TMLError',
                        severity: 'error',
                        summary: 'We got a problem!',
                        detail: 'We had a problem while creating, please refresh the page and try again.',
                        life: 6000,
                    });
                });
                this.SUBS$.add(CREATE$);
                return [2 /*return*/];
            });
        }); });
    };
    DailyOperationService.prototype.createTMLBulk = function (tml) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var CREATEB$;
            var _this = this;
            return __generator(this, function (_a) {
                this._loader.show();
                CREATEB$ = this._timelog
                    .createBulk(tml)
                    .finally(function () {
                    _this._loader.hide();
                    _this.loadTMLs();
                })
                    .subscribe(function () {
                    resolve({
                        key: 'TMLNotify',
                        severity: 'success',
                        summary: 'Correct!',
                        detail: 'Timelogs Created Successfully',
                    });
                }, function (e) {
                    console.error(e.message);
                    reject({
                        key: 'TMLError',
                        severity: 'error',
                        summary: 'We got a problem!',
                        detail: 'We had a problem while creating, please refresh the page and try again.',
                        life: 6000,
                    });
                });
                this.SUBS$.add(CREATEB$);
                return [2 /*return*/];
            });
        }); });
    };
    DailyOperationService.prototype.updateTML = function () {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this._loader.show();
                this.fetchFormToTMLNew(false);
                this.SUBS$.add(this.callUpdate(this.TMLNew)
                    .finally(function () {
                    _this._loader.hide();
                    _this.loadTMLs();
                })
                    .subscribe(function () {
                    resolve({
                        key: 'TMLNotify',
                        severity: 'success',
                        summary: 'Correct!',
                        detail: 'Timelog Updated Successfully',
                    });
                }, function (e) {
                    console.error(e.message);
                    reject({
                        key: 'TMLError',
                        severity: 'error',
                        summary: 'We got a problem!',
                        detail: 'We had a problem while updating, please refresh the page and try again.',
                        life: 6000,
                    });
                }));
                return [2 /*return*/];
            });
        }); });
    };
    DailyOperationService.prototype.updateTMLBulk = function (data) {
        this.SUBS$.add(this._timelog.updateBulk(data).subscribe(function (resp) {
            // console.log(resp);
        }));
    };
    DailyOperationService.prototype.deleteTML = function () {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this._loader.show();
                this.SUBS$.add(this._timelog
                    .delete(this.TMLDelteObject)
                    .finally(function () {
                    _this._loader.hide();
                    _this.loadTMLs();
                })
                    .subscribe(function () {
                    resolve({
                        key: 'DOPNotify',
                        severity: 'success',
                        summary: 'Correct!',
                        detail: 'Timelog Deleted Successfull',
                    });
                }, function (e) {
                    console.error(e.message);
                    reject({
                        key: 'DOPError',
                        severity: 'error',
                        summary: 'We got a problem!',
                        detail: 'We had a problem while deleting, please refresh the page and try again.',
                        life: 6000,
                    });
                }));
                return [2 /*return*/];
            });
        }); });
    };
    DailyOperationService.prototype.callUpdate = function (data) {
        return this._timelog.update(data);
    };
    //#endregion 'CRUD - Timelog'
    //#region 'Daily General Methods'
    DailyOperationService.prototype.fetchFormToDOPNew = function (isNew) {
        // console.log('******************** Enter fetchFormToDOPNew ********************');
        this.DOPNew = new DailyOperation();
        if (isNew) {
            this.DOPNew.WellId.Value = this.wellId;
            this.DOPNew.JobId.Value = this.jobID;
        }
        else {
            this.DOPNew.WellId = this.DOPObject.WellId;
            this.DOPNew.JobId = this.DOPObject.JobId;
            this.DOPNew.DailyOperationId = this.DOPObject.DailyOperationId;
        }
        this.DOPNew.ReportStartDate.Value = this.formDOP.controls['startDate'].value;
        this.DOPNew.ReportEndDate.Value = this.formDOP.controls['endDate'].value;
        this.DOPNew.OperationsSummary.Value = this.formDOP.controls['summary'].value;
        this.DOPNew.StatusReportingTime.Value = this.formDOP.controls['rptTimeStatus'].value;
        this.DOPNew.ProjectedOperations.Value = this.formDOP.controls['projectedOps'].value;
        this.DOPNew.RigTime.Value = this.formDOP.controls['rigTime'].value;
        this.DOPNew.RigTime.UOM = 'hr';
    };
    DailyOperationService.prototype.selectDOP = function (index, loadTMLs) {
        // console.log('******************** Enter selectDOP ********************');
        this.selectedDOP = index;
        // console.log('this.selectedDOP = index -> ', this.selectedDOP);
        // console.log('CALL -> setDataToValidateDates(true)');
        this.setDataToValidateDates(true);
        var current = this.DOPs.findIndex(function (obj) { return obj.selected === true; });
        if (current !== -1) {
            this.DOPs[current].selected = false;
        }
        if (this.DOPs && this.DOPs[this.selectedDOP]) {
            // console.log('****** Validation this.DOPs && this.DOPs[this.selectedDOP] === true ******');
            this.DOPs[this.selectedDOP].selected = true;
            this.DOPObject = this.DOPs[this.selectedDOP];
            this.DOP_ID = this.DOPObject.DailyOperationId.Value;
            this.dopDateSplit = this.DOPObject.ReportEndDate.Value;
        }
        else {
            // console.log('****** Validation this.DOPs && this.DOPs[this.selectedDOP] === false ******');
            // console.log('CALL -> DOPDateSplitAddDAY()');
            this.DOPDateSplitAddDAY();
        }
        if (loadTMLs) {
            // console.log('CALL -> loadTMLs()');
            this.loadTMLs();
        }
        // this.resetNotifications();
    };
    DailyOperationService.prototype.selectDOPForSplit = function () {
        // console.log('******************** Enter selectDOPForSplit ********************');
        // console.log('selectedDOP => ', this.selectedDOP);
        if (this.DOPs && this.DOPs[this.selectedDOP]) {
            // console.log('****** Validation this.DOPs && this.DOPs[this.selectedDOP] === true ******');
            var lastDOPEndDate = '';
            var currentDOPStartDate = '';
            // const SDOP = this.selectedDOP > 0 ? this.selectedDOP + 1 : this.selectedDOP;
            var HAVE_TIME_PREV = "" + moment(this.DOPs[this.selectedDOP + 1].ReportEndDate.Value).get('hour') + moment(this.DOPs[this.selectedDOP + 1].ReportEndDate.Value).get('minute');
            if (Number(HAVE_TIME_PREV) > 0) {
                // console.log('****** Validation Number(HAVE_TIME_PREV) > 0 === true ****** ', HAVE_TIME_PREV);
                lastDOPEndDate = moment(this.DOPs[this.selectedDOP + 1].ReportEndDate.Value).format('MM/DD/YYYY hh:mm:ss');
            }
            else {
                // console.log('****** Validation Number(HAVE_TIME_PREV) > 0 === false ****** ', HAVE_TIME_PREV);
                lastDOPEndDate = moment(this.DOPs[this.selectedDOP + 1].ReportEndDate.Value).format('MM/DD/YYYY 00:00:00');
            }
            var HAVE_TIME_NEXT = "" + moment(this.DOPs[this.selectedDOP].ReportStartDate.Value).get('hour') + moment(this.DOPs[this.selectedDOP].ReportStartDate.Value).get('minute');
            if (Number(HAVE_TIME_NEXT) > 0) {
                // console.log('****** Validation Number(HAVE_TIME_NEXT) > 0 === true ****** ', HAVE_TIME_NEXT);
                currentDOPStartDate = moment(this.DOPs[this.selectedDOP].ReportStartDate.Value).format('MM/DD/YYYY hh:mm:ss');
            }
            else {
                // console.log('****** Validation Number(HAVE_TIME_NEXT) > 0 === false ****** ', HAVE_TIME_NEXT);
                currentDOPStartDate = moment(this.DOPs[this.selectedDOP].ReportStartDate.Value).format('MM/DD/YYYY 00:00:00');
            }
            // console.log('lastDOPEndDate => ', lastDOPEndDate);
            // console.log('currentDOPStartDate => ', currentDOPStartDate);
            if (moment(lastDOPEndDate).isSame(currentDOPStartDate)) {
                // console.log('****** Validation moment(lastDOPEndDate).isSame(currentDOPStartDate) === true ******');
                this.DOP_ID = this.DOPs[this.selectedDOP].DailyOperationId.Value;
                // console.log('DOP_ID: ', this.DOP_ID);
                // console.log('GOTO -> setDataToValidateDates(true)');
                this.setDataToValidateDates(true);
            }
            else {
                // console.log('****** Validation moment(lastDOPEndDate).isSame(currentDOPStartDate) === false ******');
                // console.log('GOTO -> setDataToValidateDates(false)');
                this.setDataToValidateDates(false);
            }
        }
        else {
            // console.log('****** Validation this.DOPs && this.DOPs[this.selectedDOP] === false ******');
            // console.log('CALL -> setDataToValidateDates(false)');
            this.setDataToValidateDates(false);
        }
        // console.log('CALL -> DOPDateSplitAddDAY()');
        this.DOPDateSplitAddDAY();
    };
    DailyOperationService.prototype.setDataToValidateDates = function (splice) {
        // console.log('******************** Enter setDataToValidateDates ********************');
        if (splice) {
            // console.log('****** Validation |if (splice)| === true ****** ', splice);
            this.dataToValidateDates = this._tooltip.deepClone(this.DOPs);
            var tempIndex = this.dataToValidateDates.indexOf(this.dataToValidateDates[this.selectedDOP]);
            this.dataToValidateDates.splice(tempIndex, 1);
        }
        else {
            // console.log('****** Validation |if (splice)| === false ****** ', splice);
            this.durationP1 = 0;
            this.DOP_ID = null;
            this.dataToValidateDates = this._tooltip.deepClone(this.DOPs);
            // console.log('durationP1 => ', this.durationP1, 'durationP2 => ', this.durationP2);
        }
    };
    DailyOperationService.prototype.checkDatesExist = function (_date) {
        // TODO: Validate if we can remove the add 1 minute
        var DATE = moment(_date).add(1, 'minutes');
        // console.log('******************** Enter checkDatesExist ********************');
        // console.log('DATE => ', moment(DATE).format('YYYY-MM-DD HH:mm:ss'));
        for (var _i = 0, _a = this.dataToValidateDates; _i < _a.length; _i++) {
            var element = _a[_i];
            var dateExist = moment(DATE).isBetween(element.ReportStartDate.Value, element.ReportEndDate.Value);
            if (dateExist) {
                // console.log('ReportStartDate => ', element.ReportStartDate.Value);
                // console.log('ReportEndDate => ', element.ReportEndDate.Value);
                // console.log('isBetween => ', dateExist);
                return dateExist;
            }
        }
        // console.log('isBetween => ', false);
        return false;
    };
    DailyOperationService.prototype.DOPDateSplitAddDAY = function () {
        // console.log('******************** Enter DOPDateSplitAddDAY ********************');
        // console.log('OLD dopDateSplit => ', this.dopDateSplit);
        // console.log('NEW dopDateSplit => ', moment(this.dopDateSplit).add(1, 'days').format('MM/DD/YYYY 00:00'));
        this.dopDateSplit = moment(this.dopDateSplit).add(1, 'days').format('MM/DD/YYYY 00:00');
    };
    DailyOperationService.prototype.DOPDateSplitRemoveDAY = function () {
        // console.log('******************** Enter DOPDateSplitRemoveDAY ********************');
        // console.log('OLD dopDateSplit => ', this.dopDateSplit);
        // console.log('NEW dopDateSplit => ', moment(this.dopDateSplit).subtract(1, 'days').format('MM/DD/YYYY 00:00'));
        this.dopDateSplit = moment(this.dopDateSplit).subtract(1, 'days').format('MM/DD/YYYY 00:00');
    };
    //#endregion 'Daily General Methods'
    //#region 'Timelog Methods'
    DailyOperationService.prototype.fetchFormToTMLNew = function (isNew) {
        // console.log('******************** Enter fetchFormToTMLNew ********************');
        this.TMLNew = new Timelog();
        var INACTIVE = this.getInactive(this.formTML.controls['inactive'].value);
        if (!isNew) {
            // console.log('****** Validation |if (!isNew)| === true ****** ');
            this.TMLNew.TimeLogId = this.TMLObject.TimeLogId;
            this.TMLNew.SysSeq = this.TMLObject.SysSeq;
            if (this.formTML.controls['sysSeq'].value.lenght === 0) {
                // console.log('****** Validation if (this.formTML.controls["sysSeq"].value.lenght === 0) === true ****** ');
                this.TMLNew.SysSeq.Value = this.TMLSysSeq.toString();
            }
        }
        else {
            // console.log('****** Validation |if (!isNew)| === false ****** ');
            this.TMLNew.SysSeq.Value = this.TMLSysSeq.toString();
        }
        this.TMLNew.WellId.Value = this.wellId;
        this.TMLNew.DailyOperationId.Value = this.DOP_ID;
        this.TMLNew.Duration.Value = this.formTML.controls['duration'].value;
        this.TMLNew.DepthStart.Value = this.formTML.controls['depthStart'].value;
        this.TMLNew.DepthEnd.Value = this.formTML.controls['depthEnd'].value;
        this.TMLNew.Inactive.Value = INACTIVE;
        this.TMLNew.Activity.Value = this.formTML.controls['activityCode'].value;
        this.TMLNew.Activity.Help = this.formTML.controls['activityDescription'].value;
        this.TMLNew.Comment.Value = this.formTML.controls['comments'].value;
    };
    DailyOperationService.prototype.calculateTML = function () {
        var _this = this;
        // console.log('******************** Enter calculateTML ********************');
        this.TMLTime = 0;
        this.TMLs.map(function (el) {
            _this.TMLTime = _this.TMLTime + Number(el.Duration.Value);
            el.DepthEnd.Value = el.DepthEnd.Value.replace(/,/g, '');
            el.DepthStart.Value = el.DepthStart.Value.replace(/,/g, '');
            return el;
        });
    };
    DailyOperationService.prototype.reOrderTMLRecord = function (old_index, new_index) {
        // console.log('******************** Enter reOrderTMLRecord ********************');
        while (old_index < 0) {
            old_index += this.TMLs.length;
        }
        while (new_index < 0) {
            new_index += this.TMLs.length;
        }
        if (new_index >= this.TMLs.length) {
            var k = new_index - this.TMLs.length + 1;
            while (k--) {
                this.TMLs.push(undefined);
            }
        }
        this.TMLs.splice(new_index, 0, this.TMLs.splice(old_index, 1)[0]);
    };
    DailyOperationService.prototype.getInactive = function (data) {
        if (typeof data === 'string') {
            if (data === '' || data === 'N') {
                return false;
            }
            else {
                return true;
            }
        }
        if (typeof data === 'boolean') {
            if (data) {
                return 'Y';
            }
            else {
                return 'N';
            }
        }
        return null;
    };
    DailyOperationService.prototype.setTMLData = function (data) {
        // console.log('******************** Enter setTMLData ********************');
        data = data.filter(function (dto) { return dto !== null; });
        var NOSYS = this._tooltip.deepClone(data.filter(function (dto) { return dto.SysSeq.Value.length === 0; }));
        var WITHSYS = this._tooltip.deepClone(data.filter(function (dto) { return dto.SysSeq.Value.length > 0; }));
        this.TMLSysSeq = WITHSYS.length;
        WITHSYS = WITHSYS.sort(function (a, b) {
            return Number(a.SysSeq.Value) > Number(b.SysSeq.Value) ? 1 : Number(b.SysSeq.Value) > Number(a.SysSeq.Value) ? -1 : 0;
        });
        data = this._tooltip.deepClone(NOSYS.concat(WITHSYS));
        this.TMLs = this._tooltip.deepClone(data);
        this.TMLObject = this.TMLs[0];
        // console.log('CALL -> calculateTML()');
        this.calculateTML();
    };
    //#endregion 'Timelog Methods'
    //#region 'Notification'
    DailyOperationService.prototype.showNotification = function (ntf, type) {
        var _this = this;
        this.resetNotifications();
        if (type === 1) {
            this.notificationsTMLDOP = [];
            this.notificationsTMLDOP.push({
                severity: ntf.severity,
                summary: ntf.summary,
                detail: ntf.detail,
            });
        }
        else if (type === 2) {
            this.notificationsDOP = [];
            this.notificationsDOP.push({
                severity: ntf.severity,
                summary: ntf.summary,
                detail: ntf.detail,
            });
        }
        setTimeout(function () {
            _this.notificationsTMLDOP = [];
            _this.notificationsDOP = [];
        }, 4000);
    };
    DailyOperationService.prototype.resetNotifications = function () {
        this.notificationsTMLDOP = [];
        this.notificationsDOP = [];
    };
    //#endregion 'Notification'
    //#region 'General Methods'
    DailyOperationService.prototype.isDOPFull = function () {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (this.TMLTime >= 24) {
                    reject({
                        key: 'TMLError',
                        severity: 'warn',
                        summary: 'Timelog Full',
                        detail: 'Timelog time is full now (24 hrs only), please verify.',
                        life: 6000,
                    });
                }
                else {
                    resolve(false);
                }
                return [2 /*return*/];
            });
        }); });
    };
    DailyOperationService.prototype.sortDataByStartDate = function (dataDOP) {
        // console.log('******************** Enter sortDataByStartDate ********************');
        dataDOP.sort(function (a, b) {
            if (a.ReportStartDate.Value === '' && b.ReportStartDate.Value !== '') {
                return 1;
            }
            else if (a.ReportStartDate.Value !== '' && b.ReportStartDate.Value === '') {
                return -1;
            }
            else {
                if (new Date(a.ReportStartDate.Value) > new Date(b.ReportStartDate.Value)) {
                    return -1;
                }
                else if (new Date(a.ReportStartDate.Value) < new Date(b.ReportStartDate.Value)) {
                    return 1;
                }
                else {
                    return 0;
                }
            }
        });
        return dataDOP;
    };
    //#endregion 'General Methods'
    //#region 'Split Timelog'
    DailyOperationService.prototype.loadDOPsSplit = function () {
        // console.log('******************** Enter loadDOPsSplit ********************');
        return this._dailyOperation.getByJobId(this.jobID);
    };
    DailyOperationService.prototype.loadDOPDto = function (start, end) {
        // console.log('******************** Enter loadDOPDto ********************');
        this.DOPNew = new DailyOperation();
        this.DOPNew.WellId.Value = this.wellId;
        this.DOPNew.JobId.Value = this.jobID;
        this.DOPNew.ReportStartDate.Value = start;
        this.DOPNew.ReportEndDate.Value = end;
        this.DOPNew.OperationsSummary.Value = 'Operations Summary generated by split *- OVERFLOW -*';
        this.DOPNew.StatusReportingTime.Value = 'Status at Reporting Time generated by split *- OVERFLOW -*';
        this.DOPNew.ProjectedOperations.Value = 'Projected Operations generated by split *- OVERFLOW -*';
        var duration = moment.duration(moment(end).diff(moment(start)));
        var hours = Math.round(duration.asHours()).toString();
        this.DOPObject.RigTime.Value = hours;
        this.DOPNew.RigTime.Value = hours;
        this.DOPNew.RigTime.UOM = 'hr';
    };
    DailyOperationService.prototype.loadTMLsSplit = function () {
        // console.log('******************** Enter loadTMLsSplit ********************');
        this._loader.show();
        return this._timelog.getByDailyOperationId(this.DOP_ID);
    };
    DailyOperationService.prototype.loadTMLDto = function (useDurationP1) {
        // console.log('******************** Enter loadTMLDto ********************');
        this.TMLNew = new Timelog();
        this.TMLNew.WellId.Value = this.wellId;
        this.TMLNew.DailyOperationId.Value = this.DOP_ID;
        this.TMLNew.SysSeq.Value = this.TMLSysSeq.toString();
        this.TMLNew.Duration.Value = useDurationP1 ? this.durationP1.toString() : this.durationP2.toString();
        this.TMLNew.DepthStart.Value = this.formTML.controls['depthStart'].value;
        this.TMLNew.DepthEnd.Value = this.formTML.controls['depthEnd'].value;
        this.TMLNew.Inactive.Value = this.formTML.controls['inactive'].value;
        this.TMLNew.Activity.Value = this.formTML.controls['activityCode'].value;
        this.TMLNew.Comment.Value = "OVERFLOW - " + this.formTML.controls['comments'].value;
        this.TMLNew.Comment.UOM = "OVERFLOW";
    };
    DailyOperationService.prototype.createDOPSplit = function () {
        // console.log('******************** Enter createDOPSplit ********************');
        // console.log('DOPNew: ', this._tooltip.deepClone(this.DOPNew));
        return this._dailyOperation.create(this.DOPNew);
    };
    DailyOperationService.prototype.createNewDOPAfterTML = function (startDate, endDate) {
        var _this = this;
        // console.log('******************** Enter createNewDOPAfterTML ********************');
        //? Now that we created our timelog on the current DOP
        //? we have to create the new DOP
        this.formTML.controls['duration'].setValue(this.durationP2);
        // console.log('CALL -> loadDOPDto()');
        this.loadDOPDto(startDate, endDate);
        // console.log('GOTO -> createDOPSplit()');
        this.SUBS$.add(this.createDOPSplit().subscribe(function (data) {
            //? Now in this new DOP we create our new timelog
            _this.DOP_ID = data.DailyOperationId.Value;
            // console.log('****** createDOPSplit Completed - In Subscriber ****** ');
            // console.log('DOP_ID => ', this.DOP_ID);
            // console.log('GOTO -> loadDOPsSplit()');
            _this.SUBS$.add(_this.loadDOPsSplit().subscribe(function (dataDOP) {
                // console.log('****** loadDOPsSplit Completed - In Subscriber ****** ');
                if (dataDOP.length > 0) {
                    // console.log('****** Validation if (dataDOP.length > 0) === true ****** ');
                    dataDOP = _this.sortDataByStartDate(dataDOP);
                    _this.DOPs = _this._tooltip.deepClone(dataDOP);
                    // console.log('DOPs :', this._tooltip.deepClone(dataDOP));
                    // console.log('CALL -> validateDurationBeforeCreate()');
                    _this.validateDurationBeforeCreate();
                }
            }));
        }));
    };
    DailyOperationService.prototype.createNewTML = function (useDurationP1) {
        var _this = this;
        // console.log('******************** Enter createNewTML ********************');
        // console.log('useDurationP1 => ', useDurationP1);
        this.formTML.controls['duration'].setValue(useDurationP1 ? this.durationP1 : this.durationP2);
        this.loadTMLDto(useDurationP1);
        // console.log('GOTO -> createTMLSplit()');
        this.SUBS$.add(this.createTMLSplit(useDurationP1).subscribe(function () {
            // console.log('****** createTMLSplit Completed - In Subscriber ****** ');
            _this.TMLSysSeq = 0;
            _this._loader.hide();
            if (useDurationP1) {
                // console.log('****** Validation if (useDurationP1) === true ****** ');
                _this.durationP1 = _this.durationP2;
                _this.formTML.controls['duration'].setValue(_this.durationP2);
                // console.log('CALL -> revalidationOfTML()');
                _this.revalidationOfTML();
            }
            else {
                // console.log('****** Validation if (useDurationP1) === false ****** ');
                // console.log('CALL -> loadDOPs()');
                _this.loadDOPs();
            }
        }));
    };
    DailyOperationService.prototype.createTMLSplit = function (fetch) {
        // console.log('******************** Enter createTMLSplit ********************');
        if (fetch) {
            // console.log('****** Validation if (fetch) === true ****** ');
            this.fetchFormToTMLNew(true);
            this.TMLNew.Duration.Value = this.durationP1.toString();
            this.TMLNew.Comment.Value = "OVERFLOW - " + this.TMLNew.Comment.Value;
            this.TMLNew.Comment.UOM = "OVERFLOW";
        }
        // console.log('this.TMLNew.Duration => ', this.TMLNew.Duration.Value);
        // console.log('this.TMLNew.DailyOperationId => ', this.TMLNew.DailyOperationId.Value);
        return this._timelog.create(this.TMLNew);
    };
    DailyOperationService.prototype.createTMLOnCurrentDOP = function () {
        // console.log('******************** Enter createTMLOnCurrentDOP ********************');
        var _this = this;
        // First we add the first split TML to the current DOP
        // console.log('GOTO -> createTMLSplit()');
        this.SUBS$.add(this.createTMLSplit(true).subscribe(function () {
            // Now that we created the TML in the current DOP
            // we need to validate that we can insert the next TML in the next DOP
            // console.log('****** createTMLSplit Completed - In Subscriber ****** ');
            _this.TMLSysSeq = 0;
            _this.formTML.controls['duration'].setValue(_this.durationP2);
            // console.log('CALL -> revalidationOfTML()');
            _this.revalidationOfTML();
        }));
    };
    DailyOperationService.prototype.isTMLTimeFull = function (isEdit) {
        this.isNormalUpdate = undefined;
        var CURRENT_DURATION = this.TMLObject ? Number(this.TMLObject.Duration.Value) : 0;
        var DURATION = Number(this.formTML.controls['duration'].value);
        var TOTALRIG = DURATION + this.TMLTime;
        var RIG_TIME = Number(this.DOPObject.RigTime.Value);
        // console.log('******************** Enter isTMLTimeFull ********************');
        // console.log('DURATION => ', DURATION);
        // console.log('TMLTime => ', this.TMLTime);
        // console.log('TOTALRIG => ', TOTALRIG);
        // console.log('RIG_TIME => ', RIG_TIME);
        // console.log('CURRENT_DURATION => ', CURRENT_DURATION);
        if (isEdit) {
            // console.log('****** Validation if (isEdit) === true ****** ');
            //? Validate if the Duration has been changed so we call the update, if not we can just update
            if (DURATION === CURRENT_DURATION) {
                // console.log('****** Validation if (DURATION === CURRENT_DURATION) === true ****** ');
                this.isNormalUpdate = true;
                return false;
            }
            else {
                // console.log('****** Validation if (DURATION === CURRENT_DURATION) === false ****** ');
                //? We need to validate first that the new time with the timelog doesn't exceed the
                //? time available, if not, we call a normal update, but if we exceed we need to update
                //? the new duration and make a split
                var NEW_DURATION = DURATION - CURRENT_DURATION + this.TMLTime;
                if (NEW_DURATION <= RIG_TIME) {
                    // console.log('****** Validation if (NEW_DURATION <= RIG_TIME) === true ****** ');
                    this.isNormalUpdate = true;
                    return false;
                }
                else {
                    // console.log('****** Validation if (NEW_DURATION <= RIG_TIME) === false ****** ');
                    var NEW_TMLT = this.TMLTime - CURRENT_DURATION;
                    this.durationP1 = RIG_TIME - NEW_TMLT;
                    this.durationP2 = DURATION - this.durationP1;
                    this.isNormalUpdate = false;
                    // console.log('durationP1 => ', this.durationP1, 'durationP2 => ', this.durationP2);
                    return false;
                }
            }
        }
        if (TOTALRIG > RIG_TIME) {
            // console.log('****** Validation if (TOTALRIG > RIG_TIME) === true ****** ');
            this.durationP1 = RIG_TIME - this.TMLTime;
            this.durationP2 = TOTALRIG - RIG_TIME;
            // console.log('durationP1 => ', this.durationP1, 'durationP2 => ', this.durationP2);
            return true;
        }
        else {
            // console.log('****** Validation if (TOTALRIG > RIG_TIME) === false ****** ');
            return false;
        }
    };
    DailyOperationService.prototype.checkNextDate = function () {
        var _this = this;
        // console.log('******************** Enter checkNextDate ********************');
        // this.resetNotifications();
        var NEWDOPSTARTDATE = '';
        var HAVE_TIME = "" + moment(this.dopDateSplit).get('hour') + moment(this.dopDateSplit).get('minute');
        if (Number(HAVE_TIME) > 0) {
            // console.log('****** Validation if (Number(HAVE_TIME) > 0) === true ****** ');
            NEWDOPSTARTDATE = moment(this.dopDateSplit).format('MM/DD/YYYY hh:mm:ss');
        }
        else {
            // console.log('****** Validation if (Number(HAVE_TIME) > 0) === false ****** ');
            NEWDOPSTARTDATE = moment(this.dopDateSplit).format('MM/DD/YYYY 00:00:00');
        }
        var NEWDOPENDDATE = moment(this.dopDateSplit).add(1, 'days').format('MM/DD/YYYY 00:00');
        // console.log('End Date => ', NEWDOPENDDATE);
        // console.log('Start Date to validate => ', NEWDOPSTARTDATE);
        if (this.checkDatesExist(NEWDOPSTARTDATE)) {
            // console.log('****** Validation if (this.checkDatesExist(NEWDOPSTARTDATE)) === true ****** ');
            //? Dates are not valid, so we add this new Timelog to the next DOP if exist
            //? But we need to validate that there is space for this new time log entry
            //? If not, we need to do all this again
            if (this.durationP1 === 0) {
                // console.log('****** Validation if (this.durationP1 === 0) === true ****** ');
                // this.selectedDOP = this.selectedDOP > 0 ? this.selectedDOP - 1 : this.selectedDOP;
                // console.log('this.selectedDOP -> ', this.selectedDOP);
                this.DOP_ID = this.DOPs[this.selectedDOP].DailyOperationId.Value;
                // console.log('this.DOPs[this.selectedDOP].DailyOperationId.Value => ', this.DOP_ID);
                this.durationP1 = this.durationP2;
                // console.log('durationP1 => ', this.durationP1, 'durationP2 => ', this.durationP2);
                // console.log('GOTO -> createTMLSplit()');
                this.SUBS$.add(this.createTMLSplit(true).subscribe(function () {
                    // console.log('****** createTMLSplit Completed - In Subscriber ****** ');
                    _this.TMLSysSeq = 0;
                    _this.loadDOPs();
                }));
            }
            else {
                // console.log('****** Validation if (this.durationP1 === 0) === false ****** ');
                // console.log('CALL -> createTMLOnCurrentDOP()');
                this.createTMLOnCurrentDOP();
            }
        }
        else {
            // console.log('****** Validation if (this.checkDatesExist(NEWDOPSTARTDATE)) === false ****** ');
            //? Dates are valid, so we create a new DOP
            //? But first we update the current one so we add the split part
            if (this.durationP1 === 0) {
                // console.log('CALL -> createNewDOPAfterTML()');
                this.createNewDOPAfterTML(NEWDOPSTARTDATE, NEWDOPENDDATE);
            }
            else {
                // console.log('GOTO -> createTMLSplit()');
                this.SUBS$.add(this.createTMLSplit(true).subscribe(function () {
                    // console.log('****** createTMLSplit Completed - In Subscriber ****** ');
                    _this.TMLSysSeq = 0;
                    // console.log('CALL -> createNewDOPAfterTML()');
                    _this.createNewDOPAfterTML(NEWDOPSTARTDATE, NEWDOPENDDATE);
                }));
            }
        }
    };
    DailyOperationService.prototype.validateDurationBeforeCreate = function () {
        var RIG_TIME = Number(this.DOPObject.RigTime.Value);
        // console.log('******************** Enter validateDurationBeforeCreate ********************');
        // console.log('durationP2 => ', this.durationP2);
        // console.log('RIG_TIME => ', RIG_TIME);
        if (this.durationP2 > RIG_TIME) {
            // console.log('****** Validation if (this.durationP2 > RIG_TIME) === true ****** ');
            this.durationP1 = RIG_TIME;
            this.durationP2 = this.durationP2 - RIG_TIME;
            // console.log('durationP1 => ', this.durationP1, 'durationP2 => ', this.durationP2);
            // console.log('CALL -> createNewTML(true)');
            this.createNewTML(true);
        }
        else {
            // console.log('****** Validation if (this.durationP2 > RIG_TIME) === false ****** ');
            // console.log('CALL -> createNewTML(false)');
            this.createNewTML(false);
        }
    };
    DailyOperationService.prototype.revalidationOfTML = function () {
        var _this = this;
        // console.log('******************** Enter revalidationOfTML ********************');
        // we have to assign the other part of the duration to make it the current one
        // so we can calculate again if there is space in the next DOP to insert
        this.selectedDOP = this.selectedDOP > 0 ? this.selectedDOP - 1 : this.selectedDOP;
        // console.log('this.selectedDOP -> ', this.selectedDOP);
        // console.log('CALL -> selectDOPForSplit()');
        this.selectDOPForSplit();
        if (this.DOP_ID) {
            // console.log('****** Validation if (this.DOP_ID) === true ****** ');
            // console.log('GOTO -> loadTMLsSplit()');
            this.SUBS$.add(this.loadTMLsSplit()
                .finally(function () { return _this._loader.hide(); })
                .subscribe(function (data) {
                // console.log('****** loadTMLsSplit Completed - In Subscriber ****** ');
                // console.log('CALL -> setTMLData()');
                _this.setTMLData(data);
                // If next DOP is full, we need to do again all the process of validation
                // to see if there is another DOP so we can insert, if not create it
                // console.log('CALL -> isTMLTimeFull()');
                var ISVALID = _this.isTMLTimeFull(false);
                if (ISVALID === true) {
                    // console.log('****** Validation if (ISVALID === true) === true ****** ');
                    // console.log('CALL -> checkNextDate()');
                    // Do again all the process
                    _this.checkNextDate();
                }
                else if (ISVALID === false) {
                    // console.log('****** Validation if (ISVALID === true) === false ****** ');
                    // The next DOP is not full so we can insert the TML
                    // console.log('CALL -> validateDurationBeforeCreate()');
                    _this.validateDurationBeforeCreate();
                }
            }));
        }
        else {
            // console.log('****** Validation if (this.DOP_ID) === false ****** ');
            // console.log('CALL -> DOPDateSplitRemoveDAY()');
            this.DOPDateSplitRemoveDAY();
            // console.log('CALL -> checkNextDate()');
            this.checkNextDate();
        }
    };
    DailyOperationService.prototype.updateTMLThenSplit = function () {
        var _this = this;
        // console.log('******************** Enter updateTMLThenSplit ********************');
        this._loader.show();
        this.fetchFormToTMLNew(false);
        this.TMLNew.Duration.Value = this.durationP1.toString();
        this.TMLNew.Comment.Value = "OVERFLOW - " + this.TMLNew.Comment.Value;
        this.TMLNew.Comment.UOM = "OVERFLOW";
        // console.log('GOTO -> callUpdate()');
        this.SUBS$.add(this.callUpdate(this.TMLNew)
            .finally(function () {
            _this._loader.hide();
        })
            .subscribe(function () {
            // console.log('****** callUpdate Completed - In Subscriber ****** ');
            // console.log('GOTO -> loadTMLsSplit()');
            _this.SUBS$.add(_this.loadTMLsSplit().subscribe(function () {
                // console.log('****** loadTMLsSplit Completed - In Subscriber ****** ');
                _this.durationP1 = 0;
                _this.DOPObject.RigTime.Value = '24';
                // console.log('CALL -> checkNextDate()');
                _this.checkNextDate();
            }));
        }));
    };
    DailyOperationService.ngInjectableDef = i0.defineInjectable({ factory: function DailyOperationService_Factory() { return new DailyOperationService(i0.inject(i1.JobsApiService), i0.inject(i2.TimelogApiService), i0.inject(i3.DailyOperationApiService), i0.inject(i4.LoadingIndicatorService), i0.inject(i5.TooltipService)); }, token: DailyOperationService, providedIn: "root" });
    return DailyOperationService;
}());
export { DailyOperationService };
