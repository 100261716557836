var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { CasingComponent, MultipleCasingAdd, MultipleCasingImport, } from '#models/index';
import { CasingComponentApiService } from '#services-api/index';
import { LoadingIndicatorService, SectionCasingService, SectionDataService } from '#services-shared/index';
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';
var InventoryComponent = /** @class */ (function () {
    //#endregion 'Report Format'
    //#region 'Angular Life Cycle'
    function InventoryComponent(_loader, _casingComponent, _dataSection, _sectionCasing, _message) {
        this._loader = _loader;
        this._casingComponent = _casingComponent;
        this._dataSection = _dataSection;
        this._sectionCasing = _sectionCasing;
        this._message = _message;
        this.deletedCasingComponentArray = [];
        this.message = new EventEmitter();
        // @Output() public validateCasing: EventEmitter<any> = new EventEmitter<any>();
        this.threadOffValues = new EventEmitter();
        this.updateMultipleEdit = new EventEmitter();
        this.updtCasingComponent = new EventEmitter();
        this.displayMultipleEdit = false;
        this.casingsToMultiEdit = [];
        this.sidebar_CasingODID = [];
        this.isMultiEdit = false;
        this.resetOldValues = true;
        this.canIsDrillingBeDisabled = true;
        this.itemsToSelect = [];
        this.selectedItemsDesc = [];
        //? Add new casings
        this.multipleAddIndex = 0;
        this.isMultipleAdd = false;
        this.showMultipleAdd = false;
        this.showMultipleAddError = false;
        this.multipleAddData = [];
        //? Import Chevron Excel
        this.isMultipleImport = false;
        this.multipleImportIndex = 0;
        this.multipleImportData = [];
        //? Subscriptions
        this.SUBS$ = new Subscription();
        this.casingCreationType = '';
        this.displayImportExcelDialogButtons = false;
        this.tableRows = [];
        this.tableColumns = [];
        this.casingComponentArrayImport = [];
        //#endregion 'File Import Variables'
        //#region 'Report Format'
        this.selectedReportFormat = [];
    }
    InventoryComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._sectionCasing.iconDescription.forEach(function (element) {
            _this.itemsToSelect.push({ label: element, value: element });
        });
    };
    InventoryComponent.prototype.ngOnDestroy = function () {
        this.SUBS$.unsubscribe();
        this._loader.hide();
    };
    //#endregion 'Angular Life Cycle'
    //#region 'Import'
    InventoryComponent.prototype.importFileSuccess = function (ev) {
        this.importFileType = ev.type;
        this.isMultipleImport = true;
        this.setFileImportNotify = true;
        this.canIsDrillingBeDisabled = true;
        this.displayImportExcelDialogButtons = true;
        if (this.multipleImportData.length === 0) {
            this.multipleImportData.push(new MultipleCasingImport());
        }
        var IMPORTDATA = this._dataSection.getImportFileData();
        if (IMPORTDATA.length > 0) {
            if (ev.type === 1) {
                this.loadNormalExcel(IMPORTDATA);
            }
            else {
                this.loadPipeTracerExcel(IMPORTDATA);
            }
        }
    };
    InventoryComponent.prototype.loadNormalExcel = function (data) {
        var colData = 0;
        for (colData = 0; colData < data[0].length; colData++) {
            this.tableColumns.push({ field: colData, header: data[0][colData] });
        }
        var idx = 1;
        for (idx = 1; idx < data.length; idx++) {
            if (data[idx].length !== 0) {
                var ROW = Object.assign({}, data[idx]);
                if (ROW.hasOwnProperty(0)) {
                    this.validateImport([], [], ROW);
                }
                else {
                    if (this.setFileImportNotify) {
                        this.tableRows.push(ROW);
                    }
                }
            }
        }
    };
    InventoryComponent.prototype.loadPipeTracerExcel = function (data) {
        var excludedColumns = [
            'Run ?',
            'Ref #',
            'Icon',
            'Centralized ?',
            '# Centralizers',
            'Cent Note',
            'Ext Jwrly',
            'Ref ID',
            'Cum. Len',
            'Top [ftO TH]',
            'Btm Depth [MD] [ftO TH]',
            'Vol Disp Cum [bbl]',
            'Cum Wt [1000bl]',
            'URC',
            'Surplus',
        ];
        var colData = 0;
        var _loop_1 = function () {
            var header = data[8][colData];
            var exist = excludedColumns.filter(function (x) { return x === header; });
            if (exist.length === 0) {
                this_1.tableColumns.push({ field: colData, header: header });
            }
        };
        var this_1 = this;
        for (colData = 0; colData < data[8].length; colData++) {
            _loop_1();
        }
        var idx = 9;
        for (idx; idx < data.length; idx++) {
            if (data[idx].length !== 0) {
                var ROW = Object.assign({}, data[idx]);
                if (ROW.hasOwnProperty(1)) {
                    this.validateImport([], [], ROW);
                }
                else {
                    if (this.setFileImportNotify) {
                        this.tableRows.push(ROW);
                    }
                }
            }
        }
        this.tableRows = this.tableRows.sort(function (a, b) { return (a['1'] > b['1'] ? 1 : b['1'] > a['1'] ? -1 : 0); });
    };
    InventoryComponent.prototype.validateImport = function (casingDuplicated, drillPipeDuplicated, row) {
        if (casingDuplicated.length > 0 && drillPipeDuplicated.length > 0) {
            this.setFileImportNotify = false;
            this.tableRows.push(row);
        }
        else if (casingDuplicated.length > 0) {
            if (drillPipeDuplicated.length > 0) {
                this.setFileImportNotify = false;
                this.tableRows.push(row);
            }
            else {
                this.canIsDrillingBeDisabled = false;
                this._sectionCasing.isdrilling = true;
                if (this.setFileImportNotify) {
                    this.tableRows.push(row);
                }
            }
        }
        else if (drillPipeDuplicated.length > 0) {
            if (casingDuplicated.length > 0) {
                this.setFileImportNotify = false;
                this.tableRows.push(row);
            }
            else {
                this.canIsDrillingBeDisabled = false;
                this._sectionCasing.isdrilling = false;
                if (this.setFileImportNotify) {
                    this.tableRows.push(row);
                }
            }
        }
        else {
            if (this.setFileImportNotify) {
                this.tableRows.push(row);
            }
        }
    };
    InventoryComponent.prototype.clearImportFile = function () {
        this.tableRows = [];
        this.tableColumns = [];
        this.multipleImportData = [];
        this.isMultipleImport = false;
        this.setFileImportNotify = undefined;
        this.canIsDrillingBeDisabled = true;
        this._sectionCasing.isdrilling = false;
        this.displayImportExcelDialogButtons = false;
        this._dataSection.setImportFileData([]);
        this._sectionCasing.resetNotifications();
    };
    InventoryComponent.prototype.createCasingsFromImport = function () {
        if (this.importFileType === 1) {
            this.initCasingImportChevronExcel();
        }
        else {
            this.initCasingImportPipeTracer();
        }
        this.casingCreationType = 'Import';
        this.displayImportExcelDialog = false;
        this._sectionCasing.isdrilling = false;
        this.createCasingComponentBulk(this.casingComponentArrayImport);
    };
    InventoryComponent.prototype.initCasingImportChevronExcel = function () {
        var isFirstTime = true;
        var _loop_2 = function (i) {
            var CASING_NEW = undefined;
            var JOINT_NUMBER = this_2.tableRows[i]['0'];
            var DTO = undefined;
            DTO = this_2.multipleImportData.filter(function (obj) { return Number(JOINT_NUMBER) >= obj.JointStart && Number(JOINT_NUMBER) <= obj.JointEnd; })[0];
            if (DTO) {
                this_2._sectionCasing.isdrilling = DTO.IsDrillPipe;
                if (isFirstTime) {
                    this_2._sectionCasing.getLastestJointNumber();
                }
                else {
                    this_2._sectionCasing.getLastestJointNumber(this_2.casingComponentArrayImport);
                }
                CASING_NEW = this_2._sectionCasing.createNewCasingComponent();
                CASING_NEW.Length.Value = this_2.tableRows[i]['1'];
                if (DTO.ItemDesc && DTO.ItemDesc.length > 0) {
                    CASING_NEW.Description.Value = DTO.ItemDesc;
                }
                if (DTO.ItemType && DTO.ItemType.length > 0) {
                    CASING_NEW.EquipmentType.Value = DTO.ItemType;
                }
                if (DTO.ODNominal && DTO.ODNominal.length > 0) {
                    CASING_NEW.OD.Value = DTO.ODNominal;
                }
                if (DTO.ID && DTO.ID.length > 0) {
                    CASING_NEW.ID.Value = DTO.ID;
                }
                if (DTO.WeightLength && DTO.WeightLength.length > 0) {
                    CASING_NEW.Weight.Value = DTO.WeightLength;
                }
                if (DTO.Grade && DTO.Grade.length > 0) {
                    CASING_NEW.Grade.Value = DTO.Grade;
                }
                if (DTO.TopThread && DTO.TopThread.length > 0) {
                    CASING_NEW.TopThread.Value = DTO.TopThread;
                }
                if (DTO.BottomThread && DTO.BottomThread.length > 0) {
                    CASING_NEW.BottomThread.Value = DTO.BottomThread;
                }
            }
            else {
                this_2._sectionCasing.isdrilling = false;
                if (isFirstTime) {
                    this_2._sectionCasing.getLastestJointNumber();
                }
                else {
                    this_2._sectionCasing.getLastestJointNumber(this_2.casingComponentArrayImport);
                }
                CASING_NEW = this_2._sectionCasing.createNewCasingComponent();
                CASING_NEW.Length.Value = this_2.tableRows[i]['1'];
            }
            isFirstTime = false;
            this_2.casingComponentArrayImport.push(CASING_NEW);
        };
        var this_2 = this;
        for (var i = 0; i < this.tableRows.length; i++) {
            _loop_2(i);
        }
    };
    InventoryComponent.prototype.initCasingImportPipeTracer = function () {
        var isFirstTime = true;
        var _loop_3 = function (i) {
            var CASING_NEW = undefined;
            var JOINT_NUMBER = this_3.tableRows[i]['1'];
            var DTO = undefined;
            DTO = this_3.multipleImportData.filter(function (obj) { return Number(JOINT_NUMBER) >= obj.JointStart && Number(JOINT_NUMBER) <= obj.JointEnd; })[0];
            if (DTO) {
                this_3._sectionCasing.isdrilling = DTO.IsDrillPipe;
                if (isFirstTime) {
                    this_3._sectionCasing.getLastestJointNumber();
                }
                else {
                    this_3._sectionCasing.getLastestJointNumber(this_3.casingComponentArrayImport);
                }
                CASING_NEW = this_3._sectionCasing.createNewCasingComponent();
            }
            else {
                this_3._sectionCasing.isdrilling = false;
                if (isFirstTime) {
                    this_3._sectionCasing.getLastestJointNumber();
                }
                else {
                    this_3._sectionCasing.getLastestJointNumber(this_3.casingComponentArrayImport);
                }
                CASING_NEW = this_3._sectionCasing.createNewCasingComponent();
            }
            CASING_NEW.Length.Value = this_3.tableRows[i]['3'];
            if (DTO && DTO.ItemDesc && DTO.ItemDesc.length > 0) {
                CASING_NEW.Description.Value = DTO.ItemDesc;
            }
            else {
                var DESC = this_3.tableRows[i]['4'];
                CASING_NEW.Description.Value =
                    DESC && DESC.length > 0 && this_3.validateImportDescription(DESC) ? DESC : 'Casing Joint';
            }
            if (DTO && DTO.ItemType && DTO.ItemType.length > 0) {
                CASING_NEW.EquipmentType.Value = DTO.ItemType;
            }
            else {
                var TYPE = this_3.tableRows[i]['11'];
                CASING_NEW.EquipmentType.Value = TYPE && TYPE.length > 0 && this_3.validateImportType(TYPE) ? TYPE : 'Casing';
            }
            if (DTO && DTO.ODNominal && DTO.ODNominal.length > 0) {
                CASING_NEW.OD.Value = DTO.ODNominal;
            }
            else {
                var OD = this_3.tableRows[i]['5'];
                CASING_NEW.OD.Value = OD && OD.length > 0 ? OD : '';
            }
            if (DTO && DTO.ID && DTO.ID.length > 0) {
                CASING_NEW.ID.Value = DTO.ID;
            }
            else {
                var ID = this_3.tableRows[i]['8'];
                CASING_NEW.ID.Value = ID && ID.length > 0 ? ID : '';
            }
            if (DTO && DTO.WeightLength && DTO.WeightLength.length > 0) {
                CASING_NEW.Weight.Value = DTO.WeightLength;
            }
            else {
                var WT = this_3.tableRows[i]['6'];
                CASING_NEW.Weight.Value = WT && WT.length > 0 ? WT : '';
            }
            if (DTO && DTO.Grade && DTO.Grade.length > 0) {
                CASING_NEW.Grade.Value = DTO.Grade;
            }
            else {
                var GRADE = this_3.tableRows[i]['7'];
                CASING_NEW.Grade.Value = GRADE && GRADE.length > 0 ? GRADE : '';
            }
            if (DTO && DTO.TopThread && DTO.TopThread.length > 0) {
                CASING_NEW.TopThread.Value = DTO.TopThread;
            }
            else {
                var TT = this_3.tableRows[i]['9'];
                CASING_NEW.TopThread.Value = TT && TT.length > 0 && this_3.validateImportTopThread(TT) ? TT : '8RD';
            }
            if (DTO && DTO.BottomThread && DTO.BottomThread.length > 0) {
                CASING_NEW.BottomThread.Value = DTO.BottomThread;
            }
            isFirstTime = false;
            this_3.casingComponentArrayImport.push(CASING_NEW);
        };
        var this_3 = this;
        for (var i = 0; i < this.tableRows.length; i++) {
            _loop_3(i);
        }
    };
    InventoryComponent.prototype.validateAllCasingInspect = function () {
        this._sectionCasing.resetNotifications();
        this.message.next({
            key: 'c',
            sticky: true,
            severity: 'warn',
            summary: 'Casing Validation',
            detail: 'Are you sure you want to validate the selected casing string arrangements and proceed with casing running tally?',
            clear: true,
            toastType: 'ValidateAll',
        });
    };
    InventoryComponent.prototype.setMultipleImportIsDrilling = function (index) {
        if (this.multipleImportData[index].IsDrillPipe) {
            this.multipleImportData[index].ItemDesc = 'Drill Pipe';
            this.multipleImportData[index].ItemType = 'Other';
        }
    };
    InventoryComponent.prototype.filterODNominalMultipleImport = function (dto, index) {
        this.multipleImportIndex = index;
        this.displayCasingComponentConfigurations = true;
        this.filterODNominalSideBar(dto.ODNominal);
    };
    InventoryComponent.prototype.addNewMultipleImportRecord = function () {
        var DATA = new MultipleCasingImport();
        DATA.JointStart = this.multipleImportData[this.multipleImportData.length - 1].JointEnd + 1;
        if (DATA.JointStart) {
            this.multipleImportData.push(DATA);
        }
    };
    InventoryComponent.prototype.deleteMultipleImportRecord = function (index) {
        this.multipleImportData.splice(index, 1);
    };
    InventoryComponent.prototype.setValueJTEnd = function (index) {
        var JTS = this.multipleImportData[index].JointStart;
        var JTE = this.multipleImportData[index].JointEnd || 1;
        if (JTE < JTS) {
            this.multipleImportData[index].JointEnd = JTS + 1;
        }
    };
    //#endregion 'Import'
    //#region 'Validations'
    InventoryComponent.prototype.isThreadOffValid = function (to) {
        return to && to !== NaN ? true : false;
    };
    InventoryComponent.prototype.validateImportDescription = function (text) {
        var DESC = this._sectionCasing.lstCasingDescription.filter(function (obj) { return obj.Description === text; });
        return DESC && DESC.length > 0 ? true : false;
    };
    InventoryComponent.prototype.validateImportType = function (text) {
        var TYPE = this._sectionCasing.lstCasingComponentSubType.filter(function (obj) { return obj === text; });
        return TYPE && TYPE.length > 0 ? true : false;
    };
    InventoryComponent.prototype.validateImportTopThread = function (text) {
        var TT = this._sectionCasing.filteredTopThread.filter(function (obj) { return obj.Name === text; });
        return TT && TT.length > 0 ? true : false;
    };
    InventoryComponent.prototype.isDrill = function (index) {
        var DESC = this._sectionCasing.lstCasingComponent[index].Description.Value;
        if (DESC === 'Drill Pipe' || DESC === 'Heavyweight Drill Pipe') {
            return true;
        }
        else {
            return false;
        }
    };
    InventoryComponent.prototype.validateImportJTEnd = function (index) {
        var JTStart = this.multipleImportData[index].JointStart;
        var JTEnd = this.multipleImportData[index].JointEnd;
        if (JTEnd <= JTStart) {
            this.multipleImportData[index].JointEnd = JTStart + 1;
        }
    };
    //#endregion 'Validations'
    //#region 'General Methods'
    InventoryComponent.prototype.computeThreadOffValues = function (casingComponent, index, info, type) {
        var original = 0;
        var update = false;
        if (type == 1) {
            //? Thread On
            original = Number(this._sectionCasing.lstCasingComponentOriginal[index].Length.Value);
            update = original == Number(casingComponent.Length.Value) ? false : true;
        }
        else if (type == 2) {
            //? Thread Loss
            original = Number(this._sectionCasing.lstCasingComponentOriginal[index].ThreadLoss);
            update = original == Number(casingComponent.ThreadLoss) ? false : true;
        }
        else if (type == 3) {
            update = true;
        }
        if (update) {
            this.threadOffValues.next({
                casingComponent: casingComponent,
                index: index,
                info: info,
            });
        }
    };
    InventoryComponent.prototype.selectDDLchangeHandler = function (casingComponent, index) {
        casingComponent = this.isMultiEdit ? this.tempComponentMultipleEdit : casingComponent;
        this.displayCasingComponentConfigurations = true;
        this.tmpSelectedIndex = index;
        this.tmpSelectedCasingComponent = casingComponent;
        this.filterODNominalSideBar(this.isMultiEdit ? this.ME_ODNominal : casingComponent.OD.Value);
        this.resetFilters();
    };
    InventoryComponent.prototype.filterODNominalSideBar = function (value) {
        this.sidebar_CasingODID = this._sectionCasing.lstCasingODID.filter(function (i) { return i.OD === value; });
    };
    InventoryComponent.prototype.resetALLData = function () {
        this.showMultiEdit = false;
        this.displayMultipleEdit = false;
        this.displayAddCasingOverlayPanel = false;
        this.displayCasingComponentConfigurations = false;
        this.multipleAddData = [];
        this.multipleImportData = [];
        this.casingsToMultiEdit = [];
        this.ME_ODNominal = undefined;
        this.ME_ID = undefined;
        this.ME_WeighLength = undefined;
        this.ME_Grade = undefined;
        this.ME_ThreadOn = undefined;
        this.ME_ChkThreadLoss = false;
        this.ME_ThreadLoss = undefined;
        this.ME_TopThread = undefined;
        this.ME_BottomThread = undefined;
        this.OLD_ODNominal = undefined;
        this.OLD_ID = undefined;
        this.OLD_WeightLength = undefined;
        this.OLD_Grade = undefined;
        this.OLD_Index = undefined;
        this.resetOldValues = true;
        this.isMultiEdit = false;
        this.isMultipleAdd = false;
        this.isMultipleImport = false;
        this.editAll = false;
        this.tempComponentMultipleEdit = undefined;
    };
    InventoryComponent.prototype.selectNextThreadOn = function (idx) {
        var TXT = document.getElementById("txt_Length" + idx);
        if (TXT) {
            TXT.focus();
        }
    };
    InventoryComponent.prototype.isDrillingDescription = function (desc, index, comeFrom) {
        //? comeFrom (1 = Import, 2 = Add)
        if (comeFrom === 1) {
            if (desc === 'Drill Pipe' || desc === 'Heavyweight Drill Pipe') {
                this.multipleImportData[index].IsDrillPipe = true;
                this.multipleImportData[index].ItemType = 'Other';
            }
            else {
                this.multipleImportData[index].IsDrillPipe = false;
                this.multipleImportData[index].ItemType = '';
            }
        }
        if (comeFrom === 2) {
            if (desc === 'Drill Pipe' || desc === 'Heavyweight Drill Pipe') {
                this.multipleAddData[index].IsDrillPipe = true;
                this.multipleAddData[index].ItemType = 'Other';
            }
            else {
                this.multipleAddData[index].IsDrillPipe = false;
                this.multipleAddData[index].ItemType = '';
            }
        }
    };
    InventoryComponent.prototype.resetFilters = function () {
        this.F_ODNominal = '';
        this.F_ID = '';
        this.F_WeightLength = '';
        this.F_Grade = '';
        this.dt.reset();
    };
    InventoryComponent.prototype.updateDescription = function (cc, index) {
        if (cc.Description.Value === 'Drill Pipe' || cc.Description.Value === 'Heavyweight Drill Pipe') {
            this._sectionCasing.isdrilling = true;
            this._sectionCasing.getLastestJointNumber();
            cc.IsExclude = true;
            cc.JointNumber = this._sectionCasing.getLastJoint(false);
            cc.RefID.Value = cc.JointNumber;
            cc.EquipmentType.Value = 'Other';
            this._sectionCasing.isdrilling = false;
            this.updateCasingInspect(cc, index, '');
        }
        else {
            this._sectionCasing.isdrilling = false;
            this.updateCasingInspect(cc, index, '');
        }
    };
    //#endregion 'General Methods'
    //#region 'Report PDF & Excel'
    InventoryComponent.prototype.generateReport = function (OPGenerateReports) {
        var _this = this;
        if (this.selectedReportFormat.includes('PDF')) {
            this._sectionCasing.getCasingInventoryPDF().then(function () { }, function (error) {
                _this.showNotification(error);
            });
        }
        if (this.selectedReportFormat.includes('Excel')) {
            this._sectionCasing.getCasingInventoryExcel().then(function () { }, function (error) {
                _this.showNotification(error);
            });
        }
        this.selectedReportFormat = [];
        OPGenerateReports.hide();
    };
    //#endregion 'Report PDF & Excel'
    //#region 'Multiple Edit'
    InventoryComponent.prototype.validateSelectedAll = function () {
        var notValidated = this._sectionCasing.lstCasingComponent.filter(function (obj) { return !obj.IsValidated; });
        if (notValidated.length !== this.casingsToMultiEdit.length) {
            this.editAll = false;
        }
        else {
            this.editAll = true;
        }
    };
    InventoryComponent.prototype.resetMultipleEdit = function () {
        this.selectedItemsDesc = [];
        this.casingsToMultiEdit = [];
        this.deletedCasingComponentArray = [];
        this.tempComponentMultipleEdit = null;
        this.isMultiEdit = false;
        this.showMultiEdit = false;
        this.editAll = false;
        this.displayMultipleEdit = false;
        this.ME_ODNominal = undefined;
        this.ME_ID = undefined;
        this.ME_WeighLength = undefined;
        this.ME_Grade = undefined;
        this.ME_ThreadOn = undefined;
        this.ME_ChkThreadLoss = undefined;
        this.ME_ThreadLoss = undefined;
        this.ME_TopThread = undefined;
        this.ME_BottomThread = undefined;
    };
    InventoryComponent.prototype.selectAll = function () {
        var _this = this;
        if (this.editAll) {
            var index_1 = 0;
            this._sectionCasing.lstCasingComponent.map(function (element) {
                if (!element.IsValidated) {
                    element.isEdit = true;
                    _this.allowMultiEdit(element, index_1, true);
                }
                index_1++;
                return element;
            });
        }
        else {
            this._sectionCasing.lstCasingComponent.map(function (element) {
                element.isEdit = false;
                return element;
            });
            this.resetMultipleEdit();
        }
    };
    InventoryComponent.prototype.allowMultiEdit = function (cc, index, doDeleteCalc) {
        this.checkCasingstoEdit(cc, index);
        var casingComponent = this._sectionCasing.lstCasingComponent.filter(function (obj) { return obj.isEdit === true; });
        this.tempComponentMultipleEdit = casingComponent.length > 0 ? casingComponent[0] : null;
        this.showMultiEdit = casingComponent.length > 0 ? true : false;
        if (doDeleteCalc) {
            this.deleteCasingInspect(cc);
        }
    };
    InventoryComponent.prototype.checkCasingstoEdit = function (casingComponent, index) {
        var newEditComponent = { casingComponent: casingComponent, index: index };
        var filteredEdit = this.casingsToMultiEdit.filter(function (obj) { return obj.index === newEditComponent.index; });
        if (this.casingsToMultiEdit.length > 0) {
            if (filteredEdit.length > 0) {
                this.casingsToMultiEdit = this.casingsToMultiEdit.filter(function (obj) { return obj.index !== newEditComponent.index; });
            }
            else {
                this.casingsToMultiEdit.push(newEditComponent);
            }
        }
        else {
            this.casingsToMultiEdit.push({ casingComponent: casingComponent, index: index });
        }
        this.validateSelectedAll();
    };
    InventoryComponent.prototype.showMultiEditCasings = function () {
        this.displayMultipleEdit = true;
        this.isMultiEdit = true;
        this.ME_ChkThreadLoss = undefined;
        this.ME_BottomThread = undefined;
        this.ME_Grade = undefined;
        this.ME_ID = undefined;
        this.ME_ODNominal = undefined;
        this.ME_ThreadLoss = undefined;
        this.ME_ThreadOn = undefined;
        this.ME_TopThread = undefined;
        this.ME_WeighLength = undefined;
    };
    InventoryComponent.prototype.applyMultiEdit = function (arrayCasingComponent) {
        this.updateMultipleEdit.next({ arrayEdited: arrayCasingComponent });
    };
    InventoryComponent.prototype.selectItems = function () {
        if (this.selectedItemsDesc.length > 0) {
            this.resetEditOnList();
            if (this.selectedItemsDesc.length === 1) {
                this.loopEdit(this.selectedItemsDesc[0].toUpperCase());
            }
            else {
                for (var idx = 0; idx < this.selectedItemsDesc.length; idx++) {
                    var element = this.selectedItemsDesc[idx].toUpperCase();
                    this.loopEdit(element);
                }
            }
            this.deletedCasingComponentArray = [];
            var TO_DELETE = this._sectionCasing.lstCasingComponent.filter(function (obj) { return obj.isEdit === true; });
            this.deletedCasingComponentArray = TO_DELETE.slice();
        }
        else {
            this.resetEditOnList();
        }
    };
    InventoryComponent.prototype.resetEditOnList = function () {
        this._sectionCasing.lstCasingComponent.map(function (element) {
            element.isEdit = false;
            return element;
        });
        this.showMultiEdit = false;
        this.casingsToMultiEdit = [];
        this.validateSelectedAll();
    };
    InventoryComponent.prototype.loopEdit = function (item) {
        for (var idx = 0; idx < this._sectionCasing.lstCasingComponent.length; idx++) {
            var element = this._sectionCasing.lstCasingComponent[idx];
            if (element.Description.Value.toUpperCase() === item) {
                element.isEdit = !element.isEdit;
                this.allowMultiEdit(element, idx, false);
            }
        }
    };
    //#endregion 'Multiple Edit'
    //#region 'Multiple Add'
    InventoryComponent.prototype.openMultipleEdit = function () {
        if (this.multipleAddData.length === 0) {
            this.multipleAddData.push(new MultipleCasingAdd());
        }
        this.isMultipleAdd = true;
        this.showMultipleAdd = true;
    };
    InventoryComponent.prototype.hideMultipleAdd = function () {
        this.isMultipleAdd = false;
        this.showMultipleAdd = false;
        this.showMultipleAddError = false;
    };
    InventoryComponent.prototype.addNewMultipleAddRecord = function () {
        this.multipleAddData.push(new MultipleCasingAdd());
    };
    InventoryComponent.prototype.deleteMultipleAddRecord = function (index) {
        this.multipleAddData.splice(index, 1);
    };
    InventoryComponent.prototype.addMultiple = function () {
        var _this = this;
        if (!this.verifyAddMultiple()) {
            var isFirstTime_1 = true;
            this.hideMultipleAdd();
            var casingComponentarrayBulk_1 = [];
            this.multipleAddData.forEach(function (dto) {
                _this._sectionCasing.isdrilling = dto.IsDrillPipe;
                if (isFirstTime_1) {
                    _this._sectionCasing.getLastestJointNumber();
                }
                else {
                    _this._sectionCasing.getLastestJointNumber(casingComponentarrayBulk_1);
                }
                var i = 0;
                for (i = 0; i < dto.Quantity; i++) {
                    var NEWCASING = _this._sectionCasing.createNewCasingComponent();
                    NEWCASING.Description.Value = dto.ItemDesc;
                    NEWCASING.EquipmentType.Value = dto.ItemType;
                    NEWCASING.OD.Value = dto.ODNominal && dto.ODNominal.length > 0 ? dto.ODNominal : NEWCASING.OD.Value;
                    NEWCASING.ID.Value = dto.ID && dto.ID.length > 0 ? dto.ID : NEWCASING.ID.Value;
                    NEWCASING.Weight.Value =
                        dto.WeightLength && dto.WeightLength.length > 0 ? dto.WeightLength : NEWCASING.Weight.Value;
                    NEWCASING.Grade.Value = dto.Grade && dto.Grade.length > 0 ? dto.Grade : NEWCASING.Grade.Value;
                    NEWCASING.TopThread.Value =
                        dto.TopThread && dto.TopThread.length > 0 ? dto.TopThread : NEWCASING.TopThread.Value;
                    NEWCASING.BottomThread.Value =
                        dto.BottomThread && dto.BottomThread.length > 0 ? dto.BottomThread : NEWCASING.BottomThread.Value;
                    casingComponentarrayBulk_1.push(NEWCASING);
                }
                isFirstTime_1 = false;
            });
            this.displayAddCasingOverlayPanel = false;
            this.casingCreationType = 'AddBulkCasings';
            this.createCasingComponentBulk(casingComponentarrayBulk_1);
            this.editAll = false;
            this._sectionCasing.isdrilling = false;
            this.selectAll();
        }
    };
    InventoryComponent.prototype.filterODNominalMultipleAdd = function (dto, index) {
        this.multipleAddIndex = index;
        this.displayCasingComponentConfigurations = true;
        this.filterODNominalSideBar(dto.ODNominal);
    };
    InventoryComponent.prototype.verifyAddMultiple = function () {
        var error = false;
        this.multipleAddData.forEach(function (dto) {
            if (!dto.Quantity || dto.Quantity.toString().length === 0) {
                error = true;
            }
            if (!dto.ItemDesc || (dto.ItemDesc && dto.ItemDesc.length === 0)) {
                error = true;
            }
            if (!dto.ItemType || (dto.ItemType && dto.ItemType.length === 0)) {
                error = true;
            }
        });
        this.showMultipleAddError = error;
        return error;
    };
    //#endregion 'Multiple Add'
    //#region 'Sidebar'
    InventoryComponent.prototype.casingComponentSelect = function (event) {
        var _this = this;
        this.resetOldValues = false;
        this._sectionCasing.selectedCasingODID = event.data;
        if (!this.isMultiEdit && !this.isMultipleAdd && !this.isMultipleImport) {
            if (!!this._sectionCasing.selectedCasingODID.OD) {
                this._sectionCasing.lstCasingComponent[this.tmpSelectedIndex].OD.Value = this._sectionCasing.filteredOD.find(function (x) { return x.OD === _this._sectionCasing.selectedCasingODID.OD; }).OD;
            }
            else {
                this._sectionCasing.lstCasingComponent[this.tmpSelectedIndex].OD.Value = '';
            }
            if (!!this._sectionCasing.selectedCasingODID.ID) {
                this._sectionCasing.lstCasingComponent[this.tmpSelectedIndex].ID.Value = this._sectionCasing.filteredID.find(function (x) { return x.ID === _this._sectionCasing.selectedCasingODID.ID; }).ID;
            }
            else {
                this._sectionCasing.lstCasingComponent[this.tmpSelectedIndex].ID.Value = '';
            }
            if (!!this._sectionCasing.selectedCasingODID.Grade) {
                this._sectionCasing.lstCasingComponent[this.tmpSelectedIndex].Grade.Value = this._sectionCasing.filteredGrade.find(function (x) { return x.Grade === _this._sectionCasing.selectedCasingODID.Grade; }).Grade;
            }
            else {
                this._sectionCasing.lstCasingComponent[this.tmpSelectedIndex].Grade.Value = '';
            }
            if (!!this._sectionCasing.selectedCasingODID.Weight) {
                this._sectionCasing.lstCasingComponent[this.tmpSelectedIndex].Weight.Value = this._sectionCasing.filteredWeight.find(function (x) { return x.Weight === _this._sectionCasing.selectedCasingODID.Weight; }).Weight;
            }
            else {
                this._sectionCasing.lstCasingComponent[this.tmpSelectedIndex].Weight.Value = '';
            }
            this.updateCasingInspect(this.tmpSelectedCasingComponent, this.tmpSelectedIndex, '');
        }
        else if (this.isMultiEdit) {
            this.ME_ODNominal = event.data.OD;
            this.ME_ID = event.data.ID;
            this.ME_WeighLength = event.data.Weight;
            this.ME_Grade = event.data.Grade;
        }
        else if (this.isMultipleAdd) {
            this.multipleAddData[this.multipleAddIndex].ODNominal = event.data.OD;
            this.multipleAddData[this.multipleAddIndex].ID = event.data.ID;
            this.multipleAddData[this.multipleAddIndex].WeightLength = event.data.Weight;
            this.multipleAddData[this.multipleAddIndex].Grade = event.data.Grade;
        }
        else if (this.isMultipleImport) {
            this.multipleImportData[this.multipleImportIndex].ODNominal = event.data.OD;
            this.multipleImportData[this.multipleImportIndex].ID = event.data.ID;
            this.multipleImportData[this.multipleImportIndex].WeightLength = event.data.Weight;
            this.multipleImportData[this.multipleImportIndex].Grade = event.data.Grade;
        }
        else {
            this.resetOldValues = true;
        }
        this.displayCasingComponentConfigurations = false;
    };
    InventoryComponent.prototype.getCurrentValues = function (casing, index, type) {
        if (type === 1) {
            this.OLD_ODNominal = casing.OD.Value;
            this.OLD_Index = index;
        }
        else if (type === 2) {
            this.OLD_ODNominal = this.ME_ODNominal;
        }
        else if (type === 3) {
            this.OLD_ODNominal = casing.ODNominal;
        }
    };
    InventoryComponent.prototype.resetToOldValues = function () {
        if (this.resetOldValues && this.OLD_Index >= 0 && !this.isMultiEdit && !this.isMultipleAdd) {
            this._sectionCasing.lstCasingComponent[this.OLD_Index].OD.Value = this.OLD_ODNominal;
        }
        else if (this.resetOldValues && this.isMultiEdit) {
            this.ME_ODNominal = this.OLD_ODNominal;
        }
        else if (this.resetOldValues && this.isMultipleAdd) {
            this.multipleAddData[this.multipleAddIndex].ODNominal = this.OLD_ODNominal;
        }
        else if (this.resetOldValues && this.isMultipleImport) {
            this.multipleImportData[this.multipleImportIndex].ODNominal = this.OLD_ODNominal;
        }
        this.resetOldValues = true;
    };
    //#endregion 'Sidebar'
    //#region 'CRUD'
    InventoryComponent.prototype.createCasingComponentBulk = function (casingComponentArrayBulk) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this._loader.show();
                        _b = (_a = this.SUBS$).add;
                        return [4 /*yield*/, this._casingComponent.createBulk(casingComponentArrayBulk).subscribe(function (data) {
                                _this.multipleImportData = [];
                                _this.isMultipleImport = false;
                                _this._sectionCasing.lstCasingComponent = _this._sectionCasing.lstCasingComponent.concat(data);
                                _this._sectionCasing.loadOriginalCasingComponent();
                                _this._loader.hide();
                                _this.showNotification({
                                    severity: 'success',
                                    summary: 'Correct!',
                                    detail: 'Casings Created Successfully.',
                                });
                                if (_this.casingCreationType === 'Import') {
                                    _this.displayImportExcelDialogButtons = false;
                                    _this.tableColumns = [];
                                    _this.tableRows = [];
                                    _this.casingComponentArrayImport = [];
                                    _this._dataSection.setImportFileData([]);
                                    _this.casingCreationType = '';
                                }
                            }, function () {
                                _this._loader.hide();
                                _this.showNotification({
                                    key: 'inventoryError',
                                    severity: 'error',
                                    summary: 'We got a problem!',
                                    detail: 'Please refresh the page and re-upload file or notify the administrator.',
                                    life: 6000,
                                });
                                if (_this.casingCreationType === 'Import') {
                                    _this.displayImportExcelDialogButtons = false;
                                    _this.tableColumns = [];
                                    _this.tableRows = [];
                                    _this.casingComponentArrayImport = [];
                                    _this._dataSection.setImportFileData([]);
                                    _this.casingCreationType = '';
                                }
                            })];
                    case 1:
                        _b.apply(_a, [_c.sent()]);
                        return [2 /*return*/];
                }
            });
        });
    };
    InventoryComponent.prototype.updateCasingInspect = function (casingComponent, index, infoField) {
        var _this = this;
        this._sectionCasing.checkforDuplicateJointNumbers(casingComponent, index).then(function (valid) {
            if (valid) {
                _this._sectionCasing.resetNotifications();
                _this.updtCasingComponent.next({
                    casingComponent: casingComponent,
                    index: index,
                    infoField: infoField,
                });
            }
        }, function (error) {
            _this.showNotification(error);
        });
    };
    InventoryComponent.prototype.updateMultipleValues = function () {
        var _this = this;
        var arrEdits = [];
        this.casingsToMultiEdit.map(function (element) {
            if (_this.ME_ODNominal && _this.ME_ODNominal.length > 0) {
                element.casingComponent.OD.Value = _this.ME_ODNominal;
            }
            if (_this.ME_ID && _this.ME_ID.length > 0) {
                element.casingComponent.ID.Value = _this.ME_ID;
            }
            if (_this.ME_WeighLength && _this.ME_WeighLength.length > 0) {
                element.casingComponent.Weight.Value = _this.ME_WeighLength;
            }
            if (_this.ME_Grade && _this.ME_Grade.length > 0) {
                element.casingComponent.Grade.Value = _this.ME_Grade;
            }
            if (_this.ME_ThreadLoss && _this.ME_ThreadLoss.toString().length > 0) {
                element.casingComponent.ThreadLoss = _this.ME_ThreadLoss;
            }
            if (_this.ME_ThreadOn && _this.ME_ThreadOn.toString().length > 0) {
                element.casingComponent.Length.Value = _this.ME_ThreadOn;
            }
            if (_this.ME_TopThread && _this.ME_TopThread.length > 0) {
                element.casingComponent.TopThread.Value = _this.ME_TopThread;
            }
            if (_this.ME_BottomThread && _this.ME_BottomThread.length > 0) {
                element.casingComponent.BottomThread.Value = _this.ME_BottomThread;
            }
            element.casingComponent.IsThreadLoss = _this.ME_ChkThreadLoss;
            if (_this.ME_ODNominal && _this.ME_ODNominal.length > 0) {
                _this._sectionCasing.lstCasingComponent[element.index].OD.Value = _this.ME_ODNominal;
            }
            if (_this.ME_ID && _this.ME_ID.length > 0) {
                _this._sectionCasing.lstCasingComponent[element.index].ID.Value = _this.ME_ID;
            }
            if (_this.ME_WeighLength && _this.ME_WeighLength.length > 0) {
                _this._sectionCasing.lstCasingComponent[element.index].Weight.Value = _this.ME_WeighLength;
            }
            if (_this.ME_Grade && _this.ME_Grade.length > 0) {
                _this._sectionCasing.lstCasingComponent[element.index].Grade.Value = _this.ME_Grade;
            }
            if (_this.ME_TopThread && _this.ME_TopThread.length > 0) {
                _this._sectionCasing.lstCasingComponent[element.index].TopThread.Value = _this.ME_TopThread;
            }
            if (_this.ME_BottomThread && _this.ME_BottomThread.length > 0) {
                _this._sectionCasing.lstCasingComponent[element.index].BottomThread.Value = _this.ME_BottomThread;
            }
            arrEdits.push(element.casingComponent);
        });
        this.applyMultiEdit(arrEdits);
        this.resetMultipleEdit();
    };
    InventoryComponent.prototype.removeCasingInspect = function () {
        if (this.deletedCasingComponentArray.length > 0) {
            this.message.next({
                key: 'c',
                sticky: true,
                severity: 'warn',
                summary: 'Delete Casing',
                detail: 'Are you sure you want to delete selected casing?',
                clear: true,
                toastType: 'Delete',
            });
        }
        else {
            this.showNotification({
                key: 'inventoryError',
                severity: 'error',
                summary: 'We got a problem!',
                detail: 'Please select casing components to delete and try again.',
                life: 6000,
            });
        }
    };
    InventoryComponent.prototype.deleteCasingInspect = function (casingComponent) {
        if (this.deletedCasingComponentArray.includes(casingComponent)) {
            this.deletedCasingComponentArray = this.deletedCasingComponentArray.filter(function (obj) { return obj !== casingComponent; });
        }
        else {
            this.deletedCasingComponentArray.push(casingComponent);
        }
    };
    //#endregion 'CRUD'
    //#region 'Notification'
    InventoryComponent.prototype.showNotification = function (msg) {
        this._message.add({
            key: msg && msg.key && msg.key.length > 0 ? msg.key : 'inventoryNotify',
            sticky: msg && msg.key && msg.key != 'inventoryNotify' ? true : false,
            closable: msg && msg.key && msg.key != 'inventoryNotify' ? true : false,
            severity: msg.severity,
            summary: msg.summary,
            detail: msg.detail,
            life: msg.life,
        });
    };
    return InventoryComponent;
}());
export { InventoryComponent };
