/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../node_modules/primeng/components/fileupload/fileupload.ngfactory";
import * as i2 from "primeng/components/dom/domhandler";
import * as i3 from "primeng/components/fileupload/fileupload";
import * as i4 from "@angular/platform-browser";
import * as i5 from "./attachments.component";
var styles_AttachmentsComponent = [];
var RenderType_AttachmentsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AttachmentsComponent, data: {} });
export { RenderType_AttachmentsComponent as RenderType_AttachmentsComponent };
export function View_AttachmentsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "fl"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "p-fileUpload", [["accept", ".jpg,.jpeg,.png,.pdf"], ["chooseLabel", "Select Files"], ["customUpload", "true"], ["maxFileSize", "214748364"], ["mode", "basic"], ["multiple", "multiple"], ["name", "attachments"]], null, [[null, "onClear"], [null, "uploadHandler"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClear" === en)) {
        var pd_0 = ((_co.notify = []) !== false);
        ad = (pd_0 && ad);
    } if (("uploadHandler" === en)) {
        var pd_1 = (_co.onUploadHandler(i0.ɵnov(_v, 4)) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_FileUpload_0, i1.RenderType_FileUpload)), i0.ɵprd(512, null, i2.DomHandler, i2.DomHandler, []), i0.ɵdid(4, 5488640, [["imgUploads", 4]], 1, i3.FileUpload, [i0.ElementRef, i2.DomHandler, i4.DomSanitizer, i0.NgZone], { name: [0, "name"], multiple: [1, "multiple"], accept: [2, "accept"], auto: [3, "auto"], maxFileSize: [4, "maxFileSize"], chooseLabel: [5, "chooseLabel"], mode: [6, "mode"], customUpload: [7, "customUpload"] }, { onClear: "onClear", uploadHandler: "uploadHandler" }), i0.ɵqud(603979776, 1, { templates: 1 })], function (_ck, _v) { var currVal_0 = "attachments"; var currVal_1 = "multiple"; var currVal_2 = ".jpg,.jpeg,.png,.pdf"; var currVal_3 = true; var currVal_4 = "214748364"; var currVal_5 = "Select Files"; var currVal_6 = "basic"; var currVal_7 = "true"; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_AttachmentsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-attachments", [], null, null, null, View_AttachmentsComponent_0, RenderType_AttachmentsComponent)), i0.ɵdid(1, 114688, null, 0, i5.AttachmentsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AttachmentsComponentNgFactory = i0.ɵccf("app-attachments", i5.AttachmentsComponent, View_AttachmentsComponent_Host_0, {}, { importFileSuccess: "importFileSuccess" }, []);
export { AttachmentsComponentNgFactory as AttachmentsComponentNgFactory };
