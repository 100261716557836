/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loading-indicator.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./loading-indicator.component";
import * as i4 from "../../services/shared/loading-indicator.service";
var styles_LoadingIndicatorComponent = [i0.styles];
var RenderType_LoadingIndicatorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoadingIndicatorComponent, data: {} });
export { RenderType_LoadingIndicatorComponent as RenderType_LoadingIndicatorComponent };
function View_LoadingIndicatorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "overlay"], ["style", "z-index: 99999"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["id", "loader-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "loaderCenter"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["id", "loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "loadText"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Drilling Information, please wait."]))], null, null); }
export function View_LoadingIndicatorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoadingIndicatorComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loadingIndicatorSvc.isVisible(); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LoadingIndicatorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loading-indicator", [], null, null, null, View_LoadingIndicatorComponent_0, RenderType_LoadingIndicatorComponent)), i1.ɵdid(1, 114688, null, 0, i3.LoadingIndicatorComponent, [i4.LoadingIndicatorService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoadingIndicatorComponentNgFactory = i1.ɵccf("app-loading-indicator", i3.LoadingIndicatorComponent, View_LoadingIndicatorComponent_Host_0, {}, {}, []);
export { LoadingIndicatorComponentNgFactory as LoadingIndicatorComponentNgFactory };
