import * as i0 from "@angular/core";
var SectionDataService = /** @class */ (function () {
    //#endregion 'Variables'
    //#region 'Angular Life Cycle'
    function SectionDataService() {
        this.validatedCasings = [];
    }
    //#endregion 'Angular Life Cycle'
    //#region 'General Methods'
    SectionDataService.prototype.setvalidatedInspect = function (validate) {
        this.validatedInspect = validate;
        this.setvalidatedCasings('inspect');
    };
    SectionDataService.prototype.getvalidatedInspect = function () {
        return this.validatedInspect;
    };
    SectionDataService.prototype.setvalidatedCasings = function (value) {
        this.validatedCasings.push(value);
    };
    SectionDataService.prototype.getvalidatedCasings = function () {
        if (this.validatedCasings.indexOf('inspect') !== -1) {
            this.setvalidatedInspect(true);
        }
        return this.validatedCasings;
    };
    SectionDataService.prototype.setImportFileData = function (value) {
        this.importedFileData = value;
    };
    SectionDataService.prototype.getImportFileData = function () {
        return this.importedFileData;
    };
    SectionDataService.ngInjectableDef = i0.defineInjectable({ factory: function SectionDataService_Factory() { return new SectionDataService(); }, token: SectionDataService, providedIn: "root" });
    return SectionDataService;
}());
export { SectionDataService };
