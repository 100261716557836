import { environment } from '#environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var NotificationsApiService = /** @class */ (function () {
    //#region 'Angular Life Cycle'
    function NotificationsApiService(http) {
        this.http = http;
    }
    //#endregion 'Angular Life Cycle'
    //#region 'General'
    NotificationsApiService.prototype.getURL = function (method) {
        return environment.apiEndpoint + "/email" + method;
    };
    //#endregion 'General'
    //#region 'Create'
    NotificationsApiService.prototype.sendNotificationEmail = function (email) {
        return this.http.post(this.getURL("/sendNotificationEmail"), email);
    };
    NotificationsApiService.ngInjectableDef = i0.defineInjectable({ factory: function NotificationsApiService_Factory() { return new NotificationsApiService(i0.inject(i1.HttpClient)); }, token: NotificationsApiService, providedIn: "root" });
    return NotificationsApiService;
}());
export { NotificationsApiService };
