var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { CementVolumeComponent } from '#pages/sections/casing-tally/cement-volume/cement-volume.component';
import { InventoryComponent } from '#pages/sections/casing-tally/inventory/inventory.component';
import { RunningComponent } from '#pages/sections/casing-tally/running/running.component';
import { CasingComponentApiService, CasingStringApiService } from '#services-api/index';
import { LoadingIndicatorService, SectionCasingService, TooltipService } from '#services-shared/index';
import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';
var CasingTallyComponent = /** @class */ (function () {
    //#endregion 'Variables'
    //#region 'Angular Life Cycle'
    function CasingTallyComponent(_casingString, _casingComponent, _loader, _message, activatedRoute, _sectionCasing, _tooltip) {
        this._casingString = _casingString;
        this._casingComponent = _casingComponent;
        this._loader = _loader;
        this._message = _message;
        this.activatedRoute = activatedRoute;
        this._sectionCasing = _sectionCasing;
        this._tooltip = _tooltip;
        this.setToastType = '';
        this.disableCasingComponents = true;
        this.Verify_CasingComponents_NotValidated = false;
        this.toggleArraySet = [];
        this.selectedDesc = '';
        this.runDateTimeNotSelected = false;
        //? Subscriptions
        this.SUBS$ = new Subscription();
    }
    CasingTallyComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._sectionCasing.wellID = this.activatedRoute.snapshot.paramMap.get('wellId');
        this._sectionCasing.wellBoreID = this.activatedRoute.snapshot.paramMap.get('wellboreId');
        this._sectionCasing.wellBoreSectionID = this.activatedRoute.snapshot.paramMap.get('wellboreSectionId');
        this._sectionCasing.loadCasingDescription().then(function () {
            var CURR_DESC_LS = _this._tooltip.getItemLocalStorage('description');
            if (CURR_DESC_LS && CURR_DESC_LS.length > 0) {
                var CURR_DESC = CURR_DESC_LS.split('|');
                _this.selectedDesc = CURR_DESC && CURR_DESC.length > 0 ? CURR_DESC[0] : undefined;
                if (_this.selectedDesc &&
                    _this._sectionCasing.wellID === CURR_DESC[1] &&
                    _this._sectionCasing.wellBoreSectionID === CURR_DESC[2]) {
                    _this.casingDescriptionChange(_this.selectedDesc);
                }
                else {
                    _this.selectedDesc = '---Select---';
                    _this._tooltip.addItemLocalStorage('description', "---Select---|" + _this._sectionCasing.wellID + "|" + _this._sectionCasing.wellBoreSectionID);
                    _this.casingDescriptionChange('---Select---');
                }
            }
            else {
                _this.selectedDesc = '---Select---';
                _this._tooltip.addItemLocalStorage('description', "---Select---|" + _this._sectionCasing.wellID + "|" + _this._sectionCasing.wellBoreSectionID);
                _this.casingDescriptionChange('---Select---');
            }
        }, function (error) {
            _this.showNotification(error);
        });
        this._sectionCasing.loadCasingItemDescription().then(function () { }, function (error) {
            _this.showNotification(error);
        });
        this._sectionCasing.loadCasingComponentSubType().then(function () { }, function (error) {
            _this.showNotification(error);
        });
        this._sectionCasing.loadCasingComponentConnectionThread().then(function () { }, function (error) {
            _this.showNotification(error);
        });
        this._sectionCasing.loadCasingComponentMetaData().then(function () { }, function (error) {
            _this.showNotification(error);
        });
        this._sectionCasing.getIconDescription();
        this._sectionCasing.loadSection();
    };
    CasingTallyComponent.prototype.ngOnDestroy = function () {
        this.SUBS$.unsubscribe();
        this._loader.hide();
    };
    //#endregion 'Angular Life Cycle'
    //#region 'Load'
    CasingTallyComponent.prototype.loadCasingString = function (wellBoreId, casingDescription) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this._loader.show();
                        _b = (_a = this.SUBS$).add;
                        return [4 /*yield*/, this._casingString.getWellboreIdCasingDescription(wellBoreId, casingDescription).subscribe(function (data) {
                                if (data && data.length > 0) {
                                    _this._sectionCasing.casingString = data[0];
                                    _this._sectionCasing.casingStringID = data[0].CasingStringId;
                                    _this.loadCasingComponents(_this._sectionCasing.casingString.CasingStringId);
                                    // Load Previous Casing String for Cement Volume
                                    if (_this._sectionCasing.casingString.PrevCasingDescription) {
                                        _this._sectionCasing.loadPreviousCasingData(false, _this._sectionCasing.casingString.PrevCasingDescription);
                                    }
                                    _this._loader.hide();
                                }
                                else {
                                    _this._loader.hide();
                                    _this.showMessage({
                                        key: 'c',
                                        sticky: true,
                                        severity: 'info',
                                        summary: 'Create Casing',
                                        detail: 'Casing string not found, would you like to create it?.',
                                        clear: true,
                                        toastType: 'Create',
                                    });
                                }
                            }, function (error) {
                                console.error(error.message);
                                _this._loader.hide();
                                _this.showNotification({
                                    key: 'casingError',
                                    severity: 'error',
                                    summary: 'We got a problem!',
                                    detail: 'There was an error loading casing strings, please refresh the page or notify the administrator.',
                                    life: 6000,
                                });
                            })];
                    case 1:
                        _b.apply(_a, [_c.sent()]);
                        return [2 /*return*/];
                }
            });
        });
    };
    CasingTallyComponent.prototype.loadCasingComponents = function (casingStringId) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this._sectionCasing.resetLstCasingComponent();
                        this._loader.show();
                        _b = (_a = this.SUBS$).add;
                        //TODO: Add IsEdit in a map on the call.
                        return [4 /*yield*/, this._casingComponent.getCasingStringId(casingStringId).subscribe(function (data) {
                                if (data.length > 0) {
                                    _this._sectionCasing.casingStringIdApproved =
                                        data.filter(function (obj) { return obj.IsApproved === true; }).length > 0 ? true : false;
                                    _this._sectionCasing.lstCasingComponent = _this._sectionCasing.filterByJointNumber(data);
                                    _this._sectionCasing.loadOriginalCasingComponent();
                                    _this._sectionCasing.addEdit();
                                    _this._loader.hide();
                                }
                                else {
                                    _this._loader.hide();
                                    _this._sectionCasing.casingStringIdApproved = false;
                                    _this.showNotification({
                                        severity: 'info',
                                        summary: 'No Casing Components',
                                        detail: 'Selected casing does not have any associated casing components.',
                                    });
                                }
                                _this._sectionCasing.getValidatedAndRunComponents();
                            }, function (error) {
                                console.error(error.message);
                                _this._sectionCasing.getValidatedAndRunComponents();
                                _this._loader.hide();
                                _this.showNotification({
                                    key: 'casingError',
                                    severity: 'error',
                                    summary: 'We got a problem!',
                                    detail: 'There was an error loading casing component, please refresh the page or notify the administrator.',
                                    life: 6000,
                                });
                            })];
                    case 1:
                        _b.apply(_a, [
                            //TODO: Add IsEdit in a map on the call.
                            _c.sent()]);
                        return [2 /*return*/];
                }
            });
        });
    };
    CasingTallyComponent.prototype.loadCementVolumeCalculation = function () {
        return __awaiter(this, void 0, void 0, function () {
            var getODValues, getIDValues;
            return __generator(this, function (_a) {
                getODValues = this._sectionCasing.loadCementVolUniqueOD(this._sectionCasing.lstCasingComponent);
                this.cementVolume.casingstringODValues = getODValues;
                this.cementVolume.selectedODCmntVol = getODValues[0];
                if (this.cementVolume.selectedODCmntVol.indexOf('/') !== -1) {
                    this.cementVolume.calculatedODCmntVol = this._sectionCasing.calculateMixedNumbers(this.cementVolume.selectedODCmntVol);
                }
                else {
                    this.cementVolume.calculatedODCmntVol = Number(this.cementVolume.selectedODCmntVol);
                }
                getIDValues = this._sectionCasing.loadCementVolUniqueID(this._sectionCasing.lstCasingComponent);
                this.cementVolume.casingstringIDValues = getIDValues;
                this.cementVolume.selectedIDCmntVol = getIDValues[0];
                // Hole Size
                if (this._sectionCasing.HoleSize.indexOf('/') !== -1) {
                    this.cementVolume.selectedHoleSize = this._sectionCasing
                        .calculateMixedNumbers(this._sectionCasing.HoleSize)
                        .toString();
                }
                else {
                    this.cementVolume.selectedHoleSize = this._sectionCasing.HoleSize;
                }
                return [2 /*return*/];
            });
        });
    };
    //#endregion 'Load'
    //#region 'Tabs'
    CasingTallyComponent.prototype.inspectTabClick = function () {
        this.tabName = 'inspect';
    };
    CasingTallyComponent.prototype.casingRunningTabClick = function () {
        this.tabName = 'casingrunning';
    };
    CasingTallyComponent.prototype.cementVolumeTabClick = function () {
        var _this = this;
        this.tabName = 'cementvolume';
        if (this._sectionCasing.lstCasingComponent.length > 0) {
            this.cementVolume.cementVolumeCalculation = true;
            this.loadCementVolumeCalculation();
            if (!this._sectionCasing.casingString.PrevCasingDescription) {
                this.cementVolume.prevCasingDescription = this.cementVolume.defaultDescription;
                // Need to Set to undefined for UI/Calculation purposes
                this._sectionCasing.prevCasingID = undefined;
                this._sectionCasing.prevShoeDepth = undefined;
            }
            else {
                this.cementVolume.prevCasingDescription = this._sectionCasing.lstCasingDescription.filter(function (obj) { return obj.Description === _this._sectionCasing.casingString.PrevCasingDescription; })[0];
            }
            this.cementVolume.reCalculate();
        }
        else {
            this.cementVolume.cementVolumeCalculation = false;
        }
    };
    //#endregion 'Tabs'
    //#region 'Action Message'
    CasingTallyComponent.prototype.showMessage = function (msg) {
        if (msg.clear) {
            this.hideToast();
        }
        if (msg.toastType) {
            this.setToastType = msg.toastType;
        }
        this._message.add({
            key: msg.key,
            sticky: msg.sticky,
            severity: msg.severity,
            summary: msg.summary,
            detail: msg.detail,
        });
    };
    CasingTallyComponent.prototype.onReject = function () {
        this.hideToast();
        if (this.setToastType === 'Create') {
            this.disableCC();
        }
    };
    CasingTallyComponent.prototype.onConfirm = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, _c, _d, _e, _f, filterNotValidatedComponents, index, _g, _h;
            var _this = this;
            return __generator(this, function (_j) {
                switch (_j.label) {
                    case 0:
                        if (this.setToastType !== 'Finalize') {
                            this.hideToast();
                        }
                        if (!(this.setToastType === 'Run')) return [3 /*break*/, 2];
                        this._loader.show();
                        _b = (_a = this.SUBS$).add;
                        return [4 /*yield*/, this._casingComponent.approveBulk(this._sectionCasing.lstCasingComponent, this.runDateTime).subscribe(function (data) {
                                _this.runDateTime = undefined;
                                _this._sectionCasing.reloadCasingsAfterApproval(data).then(function (notify) {
                                    _this._loader.hide();
                                    _this.showNotification(notify);
                                });
                            }, function (error) {
                                console.error(error.message);
                                _this.runDateTime = undefined;
                                _this._loader.hide();
                                _this.showNotification({
                                    key: 'casingError',
                                    severity: 'error',
                                    summary: 'We got a problem!',
                                    detail: 'There was an error approving run casing, please refresh the page or notify the administrator.',
                                    life: 6000,
                                });
                            })];
                    case 1:
                        _b.apply(_a, [_j.sent()]);
                        return [3 /*break*/, 12];
                    case 2:
                        if (!(this.setToastType === 'Delete')) return [3 /*break*/, 4];
                        this._loader.show();
                        _d = (_c = this.SUBS$).add;
                        return [4 /*yield*/, this._casingComponent
                                .deleteBulk(this.casingInventory.deletedCasingComponentArray)
                                .finally(function () { return _this.casingInventory.resetMultipleEdit(); })
                                .subscribe(function () {
                                _this.casingInventory.deletedCasingComponentArray = [];
                                _this.loadCasingComponents(_this._sectionCasing.casingString.CasingStringId);
                                _this.Verify_CasingComponents_NotValidated = false;
                                _this._loader.hide();
                                _this.showNotification({
                                    severity: 'success',
                                    summary: 'Correct!',
                                    detail: 'Selected casings were deleted successfully!',
                                });
                            }, function (error) {
                                console.error(error.message);
                                _this._loader.hide();
                                _this.showNotification({
                                    key: 'casingError',
                                    severity: 'error',
                                    summary: 'We got a problem!',
                                    detail: 'There was an error deleting casing component, please refresh the page or notify the administrator.',
                                    life: 6000,
                                });
                            })];
                    case 3:
                        _d.apply(_c, [_j.sent()]);
                        return [3 /*break*/, 12];
                    case 4:
                        if (!(this.setToastType === 'DeleteRunning')) return [3 /*break*/, 6];
                        this._loader.show();
                        _f = (_e = this.SUBS$).add;
                        return [4 /*yield*/, this._casingComponent
                                .delete(this.casingRunning.casingComponentId)
                                .finally(function () { return _this.casingInventory.resetMultipleEdit(); })
                                .subscribe(function () {
                                _this.casingInventory.deletedCasingComponentArray = [];
                                _this.loadCasingComponents(_this._sectionCasing.casingString.CasingStringId);
                                _this.Verify_CasingComponents_NotValidated = false;
                                _this._loader.hide();
                                _this.showNotification({
                                    severity: 'success',
                                    summary: 'Correct!',
                                    detail: 'Selected running casings were deleted successfully!.',
                                });
                            }, function (error) {
                                console.error(error.message);
                                _this._loader.hide();
                                _this.showNotification({
                                    key: 'casingError',
                                    severity: 'error',
                                    summary: 'We got a problem!',
                                    detail: 'There was an error deleting casing components, please refresh the page or notify the administrator.',
                                    life: 6000,
                                });
                            })];
                    case 5:
                        _f.apply(_e, [_j.sent()]);
                        return [3 /*break*/, 12];
                    case 6:
                        if (!(this.setToastType === 'ValidateAll')) return [3 /*break*/, 7];
                        filterNotValidatedComponents = this._sectionCasing.lstCasingComponent.filter(function (obj) { return !obj.IsValidated && obj.isEdit; });
                        this._sectionCasing.prevSysSeq =
                            this._sectionCasing.lstCasingComponents_RunChecked.length > 0
                                ? this._sectionCasing.lstCasingComponents_RunChecked.length
                                : 0;
                        filterNotValidatedComponents.map(function (element) {
                            element.IsValidated = true;
                            element.SysSeq.Value = _this._sectionCasing.getNextSysSeq();
                            element.Run = true;
                            if (element.Description.Value.toUpperCase() === 'DRILL PIPE' ||
                                element.Description.Value.toUpperCase() === 'HEAVYWEIGHT DRILL PIPE') {
                                element.IsExclude = true;
                            }
                            return element;
                        });
                        if (filterNotValidatedComponents && filterNotValidatedComponents.length === 1) {
                            index = this._sectionCasing.lstCasingComponent.indexOf(filterNotValidatedComponents[0]);
                            this.updateCasingComponent(filterNotValidatedComponents[0], index, 'Validate');
                        }
                        else {
                            this._sectionCasing.updateCasingComponentBulk(filterNotValidatedComponents, 'ValidateAll', 2).then(function (done) {
                                _this.casingInventory.resetMultipleEdit();
                                _this._sectionCasing.loadRunList();
                                _this.showNotification(done);
                            }, function (err) {
                                _this.casingInventory.resetMultipleEdit();
                                _this.showNotification(err);
                            });
                        }
                        return [3 /*break*/, 12];
                    case 7:
                        if (!(this.setToastType === 'Unvalidate')) return [3 /*break*/, 8];
                        this._loader.show();
                        this._sectionCasing.setUnvalidate();
                        this._sectionCasing.sysSequence().then(function (result) {
                            _this.SUBS$.add(_this._casingComponent
                                .updateBulk(result)
                                .finally(function () { return _this._loader.hide(); })
                                .subscribe(function () {
                                _this.loadCasingComponents(_this._sectionCasing.casingString.CasingStringId);
                                _this.inspectTabClick();
                                _this._sectionCasing.casingToUnvalidate = undefined;
                            }, function (error) {
                                _this._sectionCasing.casingToUnvalidate = undefined;
                                console.error(error.message);
                                _this.showNotification({
                                    key: 'casingError',
                                    severity: 'error',
                                    summary: 'We got a problem!',
                                    detail: 'There was an error unvalidating the casing component, please refresh the page or notify the administrator.',
                                    life: 6000,
                                });
                            }));
                        }, function (err) {
                            console.log('err => ', err);
                        });
                        return [3 /*break*/, 12];
                    case 8:
                        if (!(this.setToastType === 'Create')) return [3 /*break*/, 9];
                        this._sectionCasing.resetLists();
                        this._sectionCasing.getValidatedAndRunComponents().then(function (msg) {
                            _this.showNotification(msg);
                        });
                        this._sectionCasing.createCasingString().then(function (complete) {
                            _this.showNotification(complete);
                        }, function (error) {
                            _this.showNotification(error);
                        });
                        return [3 /*break*/, 12];
                    case 9:
                        if (!(this.setToastType === 'ClearAll')) return [3 /*break*/, 11];
                        this._loader.show();
                        _h = (_g = this.SUBS$).add;
                        return [4 /*yield*/, this._casingComponent.deleteBulk(this._sectionCasing.lstCasingComponent).subscribe(function () {
                                _this.resetALLData();
                                _this.casingRunning.resetALLData();
                                _this.casingInventory.resetALLData();
                                _this._sectionCasing.resetALLData();
                                _this.inspectTabClick();
                                _this._loader.hide();
                                _this.showNotification({
                                    severity: 'success',
                                    summary: 'Correct!',
                                    detail: 'Selected casings were deleted successfully!.',
                                });
                            }, function (error) {
                                console.error(error.message);
                                _this._loader.hide();
                                _this.showNotification({
                                    key: 'casingError',
                                    severity: 'error',
                                    summary: 'We got a problem!',
                                    detail: 'There was an error deleting casings component, please refresh the page or notify the administrator.',
                                    life: 6000,
                                });
                            })];
                    case 10:
                        _h.apply(_g, [_j.sent()]);
                        return [3 /*break*/, 12];
                    case 11:
                        if (this.setToastType === 'Finalize') {
                            if (this.runDateTime && this.runDateTime.length > 0) {
                                this.runDateTimeNotSelected = false;
                                this.hideToast();
                                this.showMessage({
                                    key: 'c',
                                    sticky: true,
                                    severity: 'warn',
                                    summary: 'Finalize & Write to WellView',
                                    detail: "This action will not allow for any future modification of the casing tally withing the tool.\n          You will need to perform any future edits in WellView.",
                                    clear: true,
                                    toastType: 'Run',
                                });
                            }
                            else {
                                this.runDateTimeNotSelected = true;
                            }
                        }
                        _j.label = 12;
                    case 12: return [2 /*return*/];
                }
            });
        });
    };
    CasingTallyComponent.prototype.isRunToast = function () {
        return this.setToastType === 'Run' || this.setToastType === 'ClearAll';
    };
    //#endregion 'Action Message'
    //#region 'General Methods'
    CasingTallyComponent.prototype.threadOffValues = function (toff) {
        this.computeThreadOffValues(toff.casingComponent, toff.index, toff.info, toff.updateTopBot);
    };
    CasingTallyComponent.prototype.hideToast = function () {
        this._message.clear('c');
    };
    CasingTallyComponent.prototype.updtToggleArrayInfo = function (dt) {
        this.toggleArraySet = dt;
    };
    CasingTallyComponent.prototype.disableCC = function () {
        this.disableCasingComponents = true;
    };
    CasingTallyComponent.prototype.refreshVirtualScrollCasing = function () {
        var TEMPCSNG = this._tooltip.deepClone(this._sectionCasing.lstCasingComponent);
        this._sectionCasing.lstCasingComponent = [];
        this._sectionCasing.lstCasingComponent = this._tooltip.deepClone(TEMPCSNG);
        this._sectionCasing.loadOriginalCasingComponent();
    };
    CasingTallyComponent.prototype.casingDescriptionChange = function (event) {
        this._tooltip.addItemLocalStorage('description', event.trim() + "|" + this._sectionCasing.wellID + "|" + this._sectionCasing.wellBoreSectionID);
        this._sectionCasing.resetNotifications();
        this._sectionCasing.casingStringIdApproved = true;
        this._sectionCasing.lstCasingComponent = [];
        this._sectionCasing.lstCasingComponentOriginal = [];
        this._sectionCasing.lstCasingComponents_Validated = [];
        this._sectionCasing.lstCasingComponents_RunChecked = [];
        this._sectionCasing.lstCasingComponents_NotValidated = [];
        if (this.casingRunning && this.casingRunning.isToggleEnabled) {
            this.casingRunning.cancelCutPaste();
        }
        if (event.trim() === '---Select---') {
            this.disableCC();
        }
        else {
            // This value is for Validate All Casing in Casing Inventory
            this.Verify_CasingComponents_NotValidated = false;
            this.disableCasingComponents = false;
            this._sectionCasing.selectedCasingDescription = this._sectionCasing.lstCasingDescription.find(function (x) { return x.Description === event.trim(); });
            this._sectionCasing.casingDescription = this._sectionCasing.selectedCasingDescription.Description;
            this._sectionCasing.resetDepthHole();
            this.loadCasingString(this._sectionCasing.wellBoreID, this._sectionCasing.selectedCasingDescription.Description);
            this.inspectTabClick();
            if (this.casingInventory) {
                this.casingInventory.resetMultipleEdit();
            }
        }
    };
    CasingTallyComponent.prototype.computeThreadOffValues = function (casingComponent, index, info, updateTopBot) {
        var _this = this;
        if (casingComponent) {
            if (casingComponent.IsThreadLoss) {
                casingComponent.ThreadOff = (Number(casingComponent.Length.Value.toString().replace(',', '')) -
                    Number(casingComponent.ThreadLoss) / 12).toString();
            }
            else {
                casingComponent.ThreadOff = casingComponent.Length.Value.toString().replace(',', '');
            }
            // This method is for casing running section
            this.updateCasingComponent(casingComponent, index, info, updateTopBot);
        }
        else {
            if (this._sectionCasing.lstCasingComponents_NotValidated.length > 0) {
                this._sectionCasing.lstCasingComponents_NotValidated.forEach(function (element) {
                    if (element.IsThreadLoss) {
                        element.ThreadOff = (Number(element.Length.Value.toString().replace(',', '')) -
                            Number(element.ThreadLoss) / 12).toString();
                    }
                    else {
                        element.ThreadOff = element.Length.Value;
                    }
                    element.ThreadLoss = '0';
                });
                // This methos is for  casing inventory
                this._sectionCasing
                    .updateCasingComponentBulk(this._sectionCasing.lstCasingComponents_NotValidated, 'UpdateThreadOff', 2)
                    .then(function () {
                    _this.showNotification({
                        severity: 'success',
                        summary: 'Correct!',
                        detail: 'Component thread off values successfully updated.',
                    });
                }, function (error) { return _this.showNotification(error); });
            }
        }
    };
    CasingTallyComponent.prototype.resetALLData = function () {
        this.toggleArraySet = [];
        this.setToastType = undefined;
        this.tmpSelectedIndex = undefined;
        this.selectedODCmntVol = undefined;
        this.selectedIDCmntVol = undefined;
        this.prevCasingDescription = undefined;
        this.tmpSelectedCasingComponent = undefined;
        this.Verify_CasingComponents_NotValidated = true;
    };
    //#endregion 'General Methods'
    //#region 'Validations'
    CasingTallyComponent.prototype.validateCasing = function (vld) {
        this.tmpSelectedCasingComponent = vld.casingComponent;
        this.tmpSelectedIndex = vld.index;
        this.setToastType = vld.toastType;
        this.showMessage(vld);
    };
    //#endregion 'Validations'
    //#region 'CRUD'
    CasingTallyComponent.prototype.updateMultipleEdit = function (data) {
        var _this = this;
        this._sectionCasing.updateCasingComponentBulk(data.arrayEdited, 'MultipleEdit', 2).then(function () {
            _this.casingInventory.resetMultipleEdit();
            _this.showNotification({
                severity: 'success',
                summary: 'Correct!',
                detail: 'Selected casing successfully updated.',
            });
        }, function (err) {
            _this.casingInventory.resetMultipleEdit();
            _this.showNotification(err);
        });
    };
    CasingTallyComponent.prototype.updtCasingComponent = function (dt) {
        this._loader.show();
        this.updateCasingComponent(dt.casingComponent, dt.index, dt.infoField);
    };
    CasingTallyComponent.prototype.updateCasingComponent = function (casingComponent, index, infoField, updateTopBot) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _b = (_a = this.SUBS$).add;
                        return [4 /*yield*/, this._casingComponent
                                .update(casingComponent)
                                .finally(function () { return _this.casingInventory.resetMultipleEdit(); })
                                .subscribe(function (data) {
                                if (infoField === 'Validate') {
                                    // After the edit we do a Refresh for the virtual scroll
                                    _this.refreshVirtualScrollCasing();
                                    _this._sectionCasing.getValidatedAndRunComponents();
                                    _this._loader.hide();
                                    _this.showNotification({
                                        key: '',
                                        severity: 'success',
                                        summary: 'Correct!',
                                        detail: 'Casing Validated Successfully',
                                    });
                                }
                                else {
                                    if (data.IsThreadLoss) {
                                        data.ThreadOff = data.ThreadOff = (Number(data.Length.Value.toString().replace(',', '')) -
                                            Number(data.ThreadLoss) / 12).toString();
                                    }
                                    _this._sectionCasing.lstCasingComponent[index] = data;
                                    _this._sectionCasing.lstCasingComponent[index]['isEdit'] = casingComponent['isEdit'];
                                    // After the edit we do a Refresh for the virtual scroll
                                    _this.refreshVirtualScrollCasing();
                                    if (updateTopBot) {
                                        _this._sectionCasing.assignJointTopBottom(_this._sectionCasing.lstCasingComponents_RunChecked, 'Original', _this._sectionCasing.casingString.RKB);
                                    }
                                    _this._sectionCasing.calculateAmounts();
                                    _this._loader.hide();
                                    _this.showNotification({
                                        severity: 'success',
                                        summary: 'Correct!',
                                        detail: "Component " + data.Description.Value + " was Successfully Updated.",
                                    });
                                    setTimeout(function () {
                                        if (infoField === 'threadOn') {
                                            _this.casingInventory.selectNextThreadOn(index + 1);
                                        }
                                    }, 200);
                                }
                            }, function (error) {
                                console.error(error.message);
                                _this.casingInventory.resetMultipleEdit();
                                _this._loader.hide();
                                _this.showNotification({
                                    key: 'casingError',
                                    severity: 'error',
                                    summary: 'We got a problem!',
                                    detail: 'There was an error updating well information, please refresh the page or notify the administrator.',
                                    life: 6000,
                                });
                            })];
                    case 1:
                        _b.apply(_a, [_c.sent()]);
                        return [2 /*return*/];
                }
            });
        });
    };
    //#endregion 'CRUD'
    //#region 'Notification'
    CasingTallyComponent.prototype.showNotification = function (msg) {
        this._message.add({
            key: msg && msg.key && msg.key.length > 0 ? msg.key : 'casingNotify',
            sticky: msg && msg.key && msg.key != 'casingNotify' ? true : false,
            closable: msg && msg.key && msg.key != 'casingNotify' ? true : false,
            severity: msg.severity,
            summary: msg.summary,
            detail: msg.detail,
            life: msg.life,
        });
    };
    return CasingTallyComponent;
}());
export { CasingTallyComponent };
