import { environment } from '#environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var OfflineActivityApiService = /** @class */ (function () {
    //#region 'Angular Life Cycle'
    function OfflineActivityApiService(http) {
        this.http = http;
    }
    //#endregion 'Angular Life Cycle'
    //#region 'General'
    OfflineActivityApiService.prototype.getURL = function (method) {
        return environment.apiEndpoint + "/offlineActivity" + method;
    };
    //#endregion 'General'
    //#region 'Get'
    OfflineActivityApiService.prototype.getByJobId = function (jobId) {
        return this.http.get(this.getURL("/getByJobId"), {
            params: { jobId: jobId },
        });
    };
    //#endregion 'Get'
    //#region 'Post'
    OfflineActivityApiService.prototype.create = function (offlineActivity) {
        return this.http.post(this.getURL("/create"), offlineActivity);
    };
    //#endregion 'Post'
    //#region 'Put'
    OfflineActivityApiService.prototype.update = function (offlineActivity) {
        return this.http.put(this.getURL("/update"), offlineActivity);
    };
    //#endregion 'Put'
    //#region 'Delete'
    OfflineActivityApiService.prototype.delete = function (offlineActivity) {
        return this.http.put(this.getURL("/delete"), offlineActivity);
    };
    OfflineActivityApiService.ngInjectableDef = i0.defineInjectable({ factory: function OfflineActivityApiService_Factory() { return new OfflineActivityApiService(i0.inject(i1.HttpClient)); }, token: OfflineActivityApiService, providedIn: "root" });
    return OfflineActivityApiService;
}());
export { OfflineActivityApiService };
