import { environment } from '#environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UserApiService = /** @class */ (function () {
    //#region 'Angular Life Cycle'
    function UserApiService(http) {
        this.http = http;
    }
    //#endregion 'Angular Life Cycle'
    //#region 'Get'
    UserApiService.prototype.getURL = function (method) {
        return environment.apiEndpoint + "/user" + method;
    };
    UserApiService.prototype.getCurrentUser = function () {
        return this.http.get(this.getURL("/current"));
    };
    UserApiService.prototype.getParticipants = function (query) {
        return this.http.get(this.getURL("/getParticipants/" + query));
    };
    UserApiService.prototype.getUsersByOrg = function (orgId) {
        return this.http.get(this.getURL("/getByOrganization/" + orgId));
    };
    UserApiService.prototype.acceptDisclaimer = function (userId) {
        return this.http.put(this.getURL("/acceptDisclaimer/" + userId), null);
    };
    UserApiService.prototype.getAll = function () {
        return this.http.get(this.getURL("/getAll"));
    };
    UserApiService.prototype.getProfiles = function () {
        return this.http.get(this.getURL("/getProfiles"));
    };
    UserApiService.prototype.getUnits = function () {
        return this.http.get(this.getURL("/getUnits"));
    };
    //#endregion 'Get'
    //#region 'Update'
    UserApiService.prototype.update = function (user) {
        return this.http.put(this.getURL("/update"), user);
    };
    //#endregion 'Update'
    //#region 'Delete'
    UserApiService.prototype.removeUser = function (id) {
        return this.http.put(this.getURL("/removeUser/" + id), null);
    };
    UserApiService.ngInjectableDef = i0.defineInjectable({ factory: function UserApiService_Factory() { return new UserApiService(i0.inject(i1.HttpClient)); }, token: UserApiService, providedIn: "root" });
    return UserApiService;
}());
export { UserApiService };
