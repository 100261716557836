export const environment = {
  production: true,
  adalConfig: {
    tenant: 'chevron.onmicrosoft.com',
    clientId: 'a57bda9c-34ed-4e38-8192-ad78c7929a48',
    postLogoutRedirectUri: 'http://localhost:44300/logout',
    endpoints: {
      'https://chevron.onmicrosoft.com/e915774d-b77c-4155-b0f3-dfd7a9413885':
        'https://chevron.onmicrosoft.com/e915774d-b77c-4155-b0f3-dfd7a9413885',
    },
  },
  appCode: 'webApp',
  environmentName: 'Cloud Test',
  apiEndpoint: 'https://dsrworkspace-cloud-api-test.azure.chevron.com/api',
  feedbackEndpoint: 'https://templates-feedback-test-cvx.azurewebsites.net/api',
};
