var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { CasingComponent, Centralizer } from '#models/index';
import { CentralizeApiService } from '#services-api/index';
import { SectionCasingService } from '#services-shared/index';
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';
var RunningComponent = /** @class */ (function () {
    //#endregion 'Variables'
    //#region 'Angular Life Cycle'
    function RunningComponent(_sectionCasing, _centralize, _message) {
        this._sectionCasing = _sectionCasing;
        this._centralize = _centralize;
        this._message = _message;
        this.message = new EventEmitter();
        this.shoeDepth = new EventEmitter();
        this.threadOffValues = new EventEmitter();
        this.updtToggleArrayInfo = new EventEmitter();
        this.updtCasingComponent = new EventEmitter();
        this.centralizedNotes = '';
        this.centralizeData = [];
        this.readyToPaste = false;
        this.pasteArray = [];
        this.toggleArrayCasingComponentSet = [];
        this.toggleMessages = '';
        // Report Format
        this.selectedReportFormat = [];
        this.runningType = 'Un-Run';
        this.disableRunUnRunButton = true;
        this.casingsToRun = [];
        this.casingsToUnRun = [];
        //? Subscriptions
        this.SUBS$ = new Subscription();
    }
    RunningComponent.prototype.ngOnInit = function () { };
    RunningComponent.prototype.ngOnDestroy = function () {
        this.SUBS$.unsubscribe();
    };
    //#endregion 'Angular Life Cycle'
    //#region 'General Methods'
    RunningComponent.prototype.runCasing = function () {
        this.message.next({
            key: 'c',
            sticky: true,
            severity: 'warn',
            summary: 'Please select the Date and Time of Runing',
            detail: "",
            clear: true,
            toastType: 'Finalize',
        });
    };
    RunningComponent.prototype.runClearAll = function () {
        this.message.next({
            key: 'c',
            sticky: true,
            severity: 'warn',
            summary: 'Clear All',
            detail: "This action will DELETE all the items in this casing, are you sure you want to continue?.",
            clear: true,
            toastType: 'ClearAll',
        });
    };
    RunningComponent.prototype.openCentralizedNotes = function (casingComponent, index) {
        this.centralizedNotes = casingComponent.CentralizedNotes;
        this.tmpSelectedCasingComponent = casingComponent;
        this.tmpSelectedIndex = index;
        this.disableCentralizedNotes = this.tmpSelectedCasingComponent.IsApproved ? true : false;
        this.displayCentralizedNotes = true;
        this.isEditNote = true;
    };
    RunningComponent.prototype.saveNotes = function () {
        if (this.isEditNote) {
            this.displayCentralizedNotes = false;
            if (this.centralizedNotes.trim() !== '' &&
                this.tmpSelectedCasingComponent.CentralizedNotes !== this.centralizedNotes.trim()) {
                this.tmpSelectedCasingComponent.CentralizedNotes = this.centralizedNotes.trim();
                this.updateCasingInspect(this.tmpSelectedCasingComponent, this.tmpSelectedIndex, 'casingSpacingUpdate');
            }
        }
    };
    RunningComponent.prototype.generateReport = function (OPGenerateReports) {
        var _this = this;
        if (this.selectedReportFormat.includes('PDF_AllCasings')) {
            this._sectionCasing.getCasingRunningTallyPDF_AllCasings().then(function () { }, function (error) {
                _this.showNotification(error);
            });
        }
        if (this.selectedReportFormat.includes('PDF_RunOnly')) {
            this._sectionCasing.getCasingRunningTallyPDF_RunOnly().then(function () { }, function (error) {
                _this.showNotification(error);
            });
        }
        if (this.selectedReportFormat.includes('Excel_AllCasings')) {
            this._sectionCasing.getCasingRunningTallyExcel_AllCasings().then(function () { }, function (error) {
                _this.showNotification(error);
            });
        }
        if (this.selectedReportFormat.includes('Excel_RunOnly')) {
            this._sectionCasing.getCasingRunningTallyExcel_RunOnly().then(function () { }, function (error) {
                _this.showNotification(error);
            });
        }
        this.selectedReportFormat = [];
        OPGenerateReports.hide();
    };
    RunningComponent.prototype.onClickBad = function (casingComponent) {
        var _this = this;
        casingComponent.IsCentralized = false;
        casingComponent.IsReferenceDepth = false;
        casingComponent.CentralizedNotes = '';
        if (casingComponent.IsBadJoint ||
            casingComponent.Description.Value === 'Drill Pipe' ||
            casingComponent.Description.Value === 'Heavyweight Drill Pipe') {
            casingComponent.IsExclude = true;
        }
        else {
            casingComponent.IsExclude = false;
        }
        this._sectionCasing.casingComponentUpdate(casingComponent, true).then(function () { }, function (error) {
            _this.showNotification(error);
        });
    };
    RunningComponent.prototype.updtCasingInspectDragDrop = function (ev) {
        return __awaiter(this, void 0, void 0, function () {
            var dropIndex, originalRC, arrData, diMinus, i, element, x, dragIndex;
            var _this = this;
            return __generator(this, function (_a) {
                dropIndex = ev.dropIndex;
                originalRC = this._sectionCasing.lstCasingComponents_RunChecked.slice();
                arrData = ev.indexes;
                diMinus = dropIndex > arrData[arrData.length - 1] ? true : false;
                for (i = arrData.length; i > 0; i--) {
                    element = arrData[i - 1];
                    x = originalRC[element];
                    dragIndex = this._sectionCasing.lstCasingComponents_RunChecked.indexOf(x);
                    if (dropIndex !== dragIndex) {
                        this._sectionCasing.lstCasingComponents_RunChecked = this._sectionCasing.array_move(this._sectionCasing.lstCasingComponents_RunChecked, dragIndex, dropIndex);
                    }
                    if (dropIndex > 0 && diMinus) {
                        dropIndex--;
                    }
                }
                this._sectionCasing.reOrderRunCasings();
                this._sectionCasing
                    .updateCasingComponentBulk(this._sectionCasing.lstCasingComponents_RunChecked, 'ToggleSequence', 1)
                    .then(function (done) {
                    _this.showNotification(done);
                }, function (error) {
                    _this.showNotification(error);
                });
                return [2 /*return*/];
            });
        });
    };
    RunningComponent.prototype.computeThreadOffValues = function (casingComponent, index, info) {
        this.threadOffValues.next({
            casingComponent: casingComponent,
            index: index,
            info: info,
            updateTopBot: true,
        });
    };
    RunningComponent.prototype.resetALLData = function () {
        this.casingComponentId = undefined;
        this.centralizedNotes = '';
        this.isEditNote = undefined;
        this.displayCentralize = undefined;
        this.disableCentralizedNotes = undefined;
        this.displayCentralizedNotes = undefined;
        this.displayMultipleRunning = undefined;
        this.dragIndex = undefined;
        this.dragEnterHover = undefined;
        this.OLD_threadLoss = undefined;
        this.centralizeData = [];
        // Toggle
        this.isToggleEnabled = false;
        this.isCutEnabled = false;
        this.showCut = false;
        this.showPaste = false;
        this.pasteIndex = undefined;
        this.pasteArray = [];
        this.toggleArrayCasingComponentSet = [];
        this.toggleMessages = '';
        // Report Format
        this.selectedReportFormat = [];
        // Centralize
        this.showCtlNumberExist = false;
        this.showCtlIncorrectNumber = false;
        // Multiple Running
        this.startSeq = 0;
        this.finalSeq = 0;
        this.runningType = 'Un-Run';
        this.showMultipleRunningError = false;
    };
    //#endregion 'General Methods'
    //#region 'Cut & Paste'
    RunningComponent.prototype.selectToggleIndex = function (casingComponent) {
        var exist = this.toggleArrayCasingComponentSet.findIndex(function (cc) { return cc === casingComponent; });
        if (exist === -1) {
            casingComponent.isReorder = true;
            this.toggleArrayCasingComponentSet.push(casingComponent);
        }
        else {
            casingComponent.isReorder = false;
            this.toggleArrayCasingComponentSet.splice(exist, 1);
        }
        if (this.toggleArrayCasingComponentSet.length > 0) {
            this.toggleMessages =
                this.toggleArrayCasingComponentSet.length +
                    (this.toggleArrayCasingComponentSet.length === 1 ? ' component' : ' components') +
                    ' selected for Cut.';
            this.isToggleEnabled = true;
            this.showCut = true;
            this.showPaste = false;
        }
        else {
            this.cancelCutPaste();
        }
    };
    RunningComponent.prototype.cutRunComponents = function () {
        var _this = this;
        this.toggleMessages =
            this.toggleArrayCasingComponentSet.length +
                (this.toggleArrayCasingComponentSet.length === 1 ? ' component' : ' components') +
                (this.toggleArrayCasingComponentSet.length === 1 ? ' has' : ' have') +
                ' been Cut from Casings Run. Please select a designated paste area to Paste.';
        this.pasteIndex = -2;
        this.isCutEnabled = true;
        this.showCut = false;
        this.showPaste = true;
        this.toggleArrayCasingComponentSet.sort(function (a, b) {
            return Number(a.SysSeq.Value) > Number(b.SysSeq.Value) ? 1 : Number(b.SysSeq.Value) > Number(a.SysSeq.Value) ? -1 : 0;
        });
        this.toggleArrayCasingComponentSet.forEach(function (element) {
            // Push into Paste Array
            _this.pasteArray.push(element);
            // And filter from Run Array i.e Cut Elements Out
            _this._sectionCasing.lstCasingComponents_RunChecked = _this._sectionCasing.lstCasingComponents_RunChecked.filter(function (obj) { return obj.CasingComponentId !== element.CasingComponentId; });
        });
        this.readyToPaste = true;
    };
    RunningComponent.prototype.setPasteIndex = function (index) {
        this.pasteIndex = index;
    };
    RunningComponent.prototype.pasteRunComponents = function () {
        var _this = this;
        var _a;
        (_a = this._sectionCasing.lstCasingComponents_RunChecked).splice.apply(_a, [this.pasteIndex + 1, 0].concat(this.pasteArray));
        this._sectionCasing.reOrderRunCasings();
        this._sectionCasing
            .updateCasingComponentBulk(this._sectionCasing.lstCasingComponents_RunChecked, 'ToggleSequence', 1)
            .then(function (done) {
            _this.showNotification(done);
        }, function (error) {
            _this.showNotification(error);
        });
        this.isCutEnabled = false;
        this.readyToPaste = false;
        this.isToggleEnabled = false;
        this.pasteIndex = -2;
        this.pasteArray = [];
        this.toggleArrayCasingComponentSet = [];
    };
    RunningComponent.prototype.cancelCutPaste = function () {
        // Restore the current cut
        if (this.isCutEnabled) {
            this._sectionCasing.lstCasingComponents_RunChecked = this._sectionCasing.lstCasingComponents_RunChecked.concat(this.toggleArrayCasingComponentSet);
        }
        this._sectionCasing.lstCasingComponents_RunChecked = this._sectionCasing.filterBySequence(this._sectionCasing.lstCasingComponents_RunChecked);
        this._sectionCasing.lstCasingComponents_RunChecked.map(function (cc) {
            cc.isReorder = false;
        });
        this.pasteArray = [];
        this.toggleArrayCasingComponentSet = [];
        this.showCut = false;
        this.showPaste = false;
        this.readyToPaste = false;
        this.isCutEnabled = false;
        this.isToggleEnabled = false;
    };
    RunningComponent.prototype.isSelectedOrNot = function (rowData) {
        var dto = this.toggleArrayCasingComponentSet.indexOf(rowData);
        if (dto === -1) {
            return true;
        }
        else {
            return false;
        }
    };
    //#endregion 'Cut & Paste'
    //#region 'Validations'
    RunningComponent.prototype.isCentralizeHtmlValid = function (idx) {
        var DTO = this._sectionCasing.lstCasingComponents_RunChecked[idx];
        if (DTO.IsApproved || DTO.EquipmentType.Value.toUpperCase() !== 'CASING' || DTO.IsBadJoint) {
            return true;
        }
        else {
            return false;
        }
    };
    //#endregion 'Validation'
    //#region 'Centralize'
    RunningComponent.prototype.centralizeCasings = function () {
        var _this = this;
        if (this.showCtlIncorrectNumber || this.showCtlNumberExist) {
            return;
        }
        this.hideCentralize();
        this.centralizeData.sort(function (a, b) {
            return Number(a.StartDepth) < Number(b.StartDepth) ? 1 : Number(b.StartDepth) < Number(a.StartDepth) ? -1 : 0;
        });
        this.centralizeData.map(function (ctlElement) {
            // Get all the joints in the range of start/bottom depth
            var RANGE = _this._sectionCasing.lstCasingComponents_RunChecked.filter(function (obj) {
                return (Number(obj.JointBottom) >= Number(ctlElement.StartDepth) ||
                    Number(obj.JointTop) >= Number(ctlElement.StartDepth)) &&
                    (Number(obj.JointBottom) <= Number(ctlElement.EndDepth) ||
                        Number(obj.JointTop) <= Number(ctlElement.EndDepth)) &&
                    obj.EquipmentType.Value.toUpperCase() === 'CASING' &&
                    !obj.IsBadJoint;
            });
            RANGE.sort(function (a, b) {
                return Number(a.CumLength.Value) < Number(b.CumLength.Value)
                    ? 1
                    : Number(b.CumLength.Value) < Number(a.CumLength.Value)
                        ? -1
                        : 0;
            });
            // Reset all the Joints we filtered
            RANGE.map(function (element) {
                element.CentralizedNotes = '';
                element.IsCentralized = false;
                return element;
            });
            var _loop_1 = function (idx) {
                var rngElement = RANGE[idx];
                var indexRun = _this._sectionCasing.lstCasingComponents_RunChecked.findIndex(function (dto) { return dto.SysSeq.Value === rngElement.SysSeq.Value; });
                _this._sectionCasing.lstCasingComponents_RunChecked[indexRun].IsCentralized = true;
                _this._sectionCasing.lstCasingComponents_RunChecked[indexRun].CentralizedNotes = ctlElement.CentralizerType;
                if (ctlElement.CentralizerPlacement === 'All Joint') {
                    idx++;
                }
                else if (ctlElement.CentralizerPlacement === 'Every Other Joint') {
                    idx = idx + 2;
                }
                else if (ctlElement.CentralizerPlacement === 'Every 3rd Joint') {
                    idx = idx + 3;
                }
                else if (ctlElement.CentralizerPlacement === 'Every 4th Joint') {
                    idx = idx + 4;
                }
                else if (ctlElement.CentralizerPlacement === 'No Centralizer') {
                    _this._sectionCasing.lstCasingComponents_RunChecked[indexRun].IsCentralized = false;
                    _this._sectionCasing.lstCasingComponents_RunChecked[indexRun].CentralizedNotes = '';
                    idx++;
                }
                out_idx_1 = idx;
            };
            var out_idx_1;
            for (var idx = 0; idx < RANGE.length;) {
                _loop_1(idx);
                idx = out_idx_1;
            }
        });
        this.executeCentralize();
    };
    RunningComponent.prototype.addNewCentrilize = function () {
        var DATA = new Centralizer();
        DATA.CasingStringId = this._sectionCasing.casingStringID;
        if (this.centralizeData.length > 0) {
            DATA.EndDepth = this.centralizeData[this.centralizeData.length - 1].StartDepth;
        }
        this.centralizeData.push(DATA);
    };
    RunningComponent.prototype.deleteCentrilize = function (index) {
        var _this = this;
        var ARR = [];
        ARR.push(this.centralizeData[index]);
        if (ARR[0].Id > 0) {
            this.SUBS$.add(this._sectionCasing.deleteCentralizeSetup(ARR).subscribe(function () {
                _this.removeCTL(index);
            }));
        }
        else {
            this.removeCTL(index);
        }
    };
    RunningComponent.prototype.removeCTL = function (index) {
        this.centralizeData.splice(index, 1);
    };
    RunningComponent.prototype.showCentralize = function () {
        var _this = this;
        this.centralizeData = [];
        this.SUBS$.add(this._centralize.getByCasingStringId(this._sectionCasing.casingStringID).subscribe(function (data) {
            if (data.length > 0) {
                _this.centralizeData = data;
            }
            else {
                _this.addNewCentrilize();
            }
            _this.displayCentralize = true;
        }));
    };
    RunningComponent.prototype.hideCentralize = function () {
        this.displayCentralize = false;
    };
    RunningComponent.prototype.validateNumber = function (ctl, position) {
        var _this = this;
        // Start
        this.showCtlNumberExist = false;
        this.showCtlIncorrectNumber = false;
        var DATA = this.centralizeData.filter(function (obj) { return obj !== ctl; });
        if (position === 1 && Number(ctl.EndDepth) > 0) {
            this.showCtlIncorrectNumber = Number(ctl.StartDepth) >= Number(ctl.EndDepth) ? true : false;
        }
        if (position === 2 && Number(ctl.StartDepth) > 0) {
            this.showCtlIncorrectNumber = Number(ctl.EndDepth) <= Number(ctl.StartDepth) ? true : false;
        }
        if (this.showCtlIncorrectNumber) {
            return;
        }
        if (DATA.length > 0) {
            if (position === 1) {
                var START_1 = Number(ctl.StartDepth);
                DATA.map(function (element) {
                    if (START_1 >= Number(element.StartDepth) && START_1 <= Number(element.EndDepth)) {
                        // Exists
                        _this.showCtlNumberExist = true;
                    }
                });
            }
            // Bottom
            if (position === 2) {
                var BOTTOM_1 = Number(ctl.EndDepth);
                DATA.map(function (element) {
                    if (BOTTOM_1 >= Number(element.StartDepth) && BOTTOM_1 <= Number(element.EndDepth)) {
                        // Exists
                        _this.showCtlNumberExist = true;
                    }
                });
            }
        }
    };
    RunningComponent.prototype.executeCentralize = function () {
        var _this = this;
        this._sectionCasing.calculateAmounts();
        var DELETE = this.centralizeData.filter(function (obj) { return obj.Id > 0; });
        if (DELETE.length > 0) {
            this.SUBS$.add(this._sectionCasing.deleteCentralizeSetup(DELETE).subscribe(function () {
                _this.centralizeData.map(function (element) {
                    element.Id = 0;
                    return element;
                });
                _this.centralizeCU();
            }));
        }
        else {
            this.centralizeCU();
        }
    };
    RunningComponent.prototype.centralizeCU = function () {
        var _this = this;
        this.SUBS$.add(this._sectionCasing.createCentralizeSetup(this.centralizeData).subscribe(function () {
            _this._sectionCasing
                .updateCasingComponentBulk(_this._sectionCasing.lstCasingComponents_RunChecked, 'Centralized', 1)
                .then(function () {
                _this.showNotification({
                    severity: 'success',
                    summary: 'Correct!',
                    detail: 'Casing running successfully centralized.',
                });
            }, function (error) { return _this.showNotification(error); });
        }));
    };
    //#endregion 'Centralize'
    //#region 'Multiple Running'
    RunningComponent.prototype.onClickRun = function (casingComponent, type) {
        if (type === 'RUN') {
            // Run
            var INDEX = this.casingsToRun.findIndex(function (dto) { return dto === casingComponent; });
            if (INDEX != -1) {
                this.casingsToRun.splice(INDEX, 1);
            }
            else {
                this.casingsToRun.push(casingComponent);
            }
        }
        else {
            // UnRun
            var INDEX = this.casingsToUnRun.findIndex(function (dto) { return dto === casingComponent; });
            if (INDEX != -1) {
                this.casingsToUnRun.splice(INDEX, 1);
            }
            else {
                this.casingsToUnRun.push(casingComponent);
            }
        }
        this.disableRunUnRun();
    };
    RunningComponent.prototype.applyRunUnRun = function () {
        var _this = this;
        var bulkCasings = [];
        this._sectionCasing.resetNotifications();
        if (this.casingsToRun && this.casingsToRun.length > 0) {
            this.casingsToRun.sort(function (a, b) {
                return Number(a.JointNumber) > Number(b.JointNumber) ? 1 : Number(b.JointNumber) > Number(a.JointNumber) ? -1 : 0;
            });
            this.casingsToRun.forEach(function (casing) {
                _this._sectionCasing.runCasing(casing, true, false);
            });
        }
        if (this.casingsToUnRun && this.casingsToUnRun.length > 0) {
            this.casingsToUnRun.forEach(function (casing) {
                casing.SysSeq.Value = '';
                casing.IsReferenceDepth = false;
                casing.IsCentralized = false;
                casing.CentralizedNotes = '';
                var INDEX = _this._sectionCasing.lstCasingComponents_RunChecked.findIndex(function (dto) { return dto === casing; });
                _this._sectionCasing.lstCasingComponents_RunChecked.splice(INDEX, 1);
            });
        }
        bulkCasings = JSON.parse(JSON.stringify(this._sectionCasing.reDoNumerationInArray(this._sectionCasing.lstCasingComponents_RunChecked, 1)));
        bulkCasings.push.apply(bulkCasings, this.casingsToUnRun);
        this._sectionCasing.updateCasingComponentBulk(bulkCasings, 'ToggleSequence', 1).then(function (done) {
            _this.casingsToRun = [];
            _this.casingsToUnRun = [];
            _this.disableRunUnRun();
            _this.showNotification(done);
        }, function (error) { return _this.showNotification(error); });
        this.hideMultipleRunUnRun();
    };
    RunningComponent.prototype.showMultipleRunUnRun = function () {
        this.displayMultipleRunning = true;
    };
    RunningComponent.prototype.hideMultipleRunUnRun = function () {
        this.displayMultipleRunning = false;
    };
    RunningComponent.prototype.showRunUnRunButton = function () {
        if (this._sectionCasing.lstCasingComponents_RunChecked.length > 0 ||
            this._sectionCasing.lstCasingComponents_Validated.length > 0) {
            return true;
        }
        else {
            return false;
        }
    };
    RunningComponent.prototype.disableRunUnRun = function () {
        if (this.casingsToRun.length > 0 || this.casingsToUnRun.length > 0) {
            this.disableRunUnRunButton = false;
        }
        else {
            this.disableRunUnRunButton = true;
        }
    };
    //#endregion 'Multiple Running'
    //#region 'CRUD'
    RunningComponent.prototype.updateCasingInspect = function (casingComponent, index, infoField) {
        this._sectionCasing.resetNotifications();
        this.updtCasingComponent.next({
            casingComponent: casingComponent,
            index: index,
            infoField: infoField,
        });
    };
    RunningComponent.prototype.updateExclude = function (casingComponent) {
        var _this = this;
        if (casingComponent) {
            this._sectionCasing.casingComponentUpdate(casingComponent).then(function () { }, function (error) {
                _this.showNotification(error);
            });
        }
    };
    RunningComponent.prototype.updateDepthRef = function (casingComponent) {
        var _this = this;
        if (!casingComponent) {
            return;
        }
        var currentRD = this._sectionCasing.lstCasingComponents_RunChecked.filter(function (obj) { return obj.IsReferenceDepth && obj.CasingComponentId !== casingComponent.CasingComponentId; });
        if (currentRD && currentRD.length > 0 && currentRD[0].IsReferenceDepth) {
            currentRD[0].IsReferenceDepth = false;
            var casings = [];
            casings.push(currentRD[0]);
            casings.push(casingComponent);
            this._sectionCasing.updateCasingComponentBulk(casings, 'LoadRun', 1).then(function (done) {
                _this.showNotification(done);
            }, function (error) {
                _this.showNotification(error);
            });
        }
        else {
            this._sectionCasing.casingComponentUpdate(casingComponent, true).then(function () { }, function (error) {
                _this.showNotification(error);
            });
        }
    };
    RunningComponent.prototype.updateCasingString = function (type, from) {
        var _this = this;
        this._sectionCasing.updateCasingString(type, from).then(function () { }, function (error) {
            _this.showNotification(error);
        });
    };
    RunningComponent.prototype.removeCasingInspect = function (validatedCasingComponent) {
        this.casingComponentId = validatedCasingComponent;
        this.message.next({
            key: 'c',
            sticky: true,
            severity: 'warn',
            summary: 'Delete Casing',
            detail: 'Are you sure you want to delete selected casing?',
            clear: true,
            toastType: 'DeleteRunning',
        });
    };
    RunningComponent.prototype.unvalidateCasing = function (dto, idx, cameFromRun) {
        this._sectionCasing.unvalidateFromRun = cameFromRun;
        this._sectionCasing.casingToUnvalidateIndex = idx;
        this._sectionCasing.casingToUnvalidate = dto;
        this.message.next({
            key: 'c',
            sticky: true,
            severity: 'warn',
            summary: 'Edit Casing',
            detail: 'Are you sure you want to Edit selected casing?',
            clear: true,
            toastType: 'Unvalidate',
        });
    };
    //#endregion 'CRUD'
    //#region 'Notification'
    RunningComponent.prototype.showNotification = function (msg) {
        this._message.add({
            key: msg && msg.key && msg.key.length > 0 ? msg.key : 'runningNotify',
            sticky: msg && msg.key && msg.key != 'runningNotify' ? true : false,
            closable: msg && msg.key && msg.key != 'runningNotify' ? true : false,
            severity: msg.severity,
            summary: msg.summary,
            detail: msg.detail,
            life: msg.life,
        });
    };
    return RunningComponent;
}());
export { RunningComponent };
