import { SectionDataService } from '#services-shared/index';
import { EventEmitter, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import * as XLSX from 'ts-xlsx';
var FileUploadComponent = /** @class */ (function () {
    //#endregion 'Variables'
    //#region 'Angular Life Cycle'
    function FileUploadComponent(_sectionData, _message) {
        this._sectionData = _sectionData;
        this._message = _message;
        this.importFileSuccess = new EventEmitter();
    }
    Object.defineProperty(FileUploadComponent.prototype, "setFileImportNotify", {
        //#region 'Input, Output, ViewChild'
        set: function (importStatus) {
            if (importStatus === true) {
                this.showNotification({
                    key: 'fileNotify',
                    severity: 'success',
                    summary: 'Correct!',
                    detail: this.uploadedFileName + " was Uploaded.",
                });
            }
            else if (importStatus === false) {
                this.showNotification({
                    key: 'fileError',
                    severity: 'warn',
                    summary: 'We got a problem!',
                    detail: this.uploadedFileName + " contains duplicate joint numbers. \n        Please remove duplicates identified below and try again.",
                    life: 6000,
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    FileUploadComponent.prototype.ngOnInit = function () { };
    //#endregion 'Angular Life Cycle'
    //#region 'General Methods'
    FileUploadComponent.prototype.uploadFile = function (file, type) {
        var _this = this;
        var dataAlreadyImported = this._sectionData.getImportFileData();
        if (dataAlreadyImported && dataAlreadyImported.length > 0) {
            // Clear Files UploadedList
            file.clear();
            this.showNotification({
                key: 'fileError',
                severity: 'warn',
                summary: 'We got a problem!',
                detail: 'Please Create/Delete Casings before uploading more files.',
                life: 6000,
            });
        }
        else {
            // Defaulting to only 1 File
            this.uploadedFile = file.files[0];
            this.uploadedFileName = this.uploadedFile.name;
            var splitArray = this.uploadedFileName.split('.');
            var fileExtension_1 = splitArray[splitArray.length - 1];
            var reader = new FileReader();
            reader.readAsArrayBuffer(this.uploadedFile);
            reader.onload = function (e) {
                var importedData;
                if (fileExtension_1 === 'xls') {
                    var data = '';
                    var bytes = new Uint8Array(e.target['result']);
                    for (var i = 0; i < bytes.byteLength; i++) {
                        data += String.fromCharCode(bytes[i]);
                    }
                    var workbook = XLSX.read(data, { type: 'binary' });
                    var saved_SheetName = workbook.SheetNames[0];
                    importedData = XLSX.utils.sheet_to_json(workbook.Sheets[saved_SheetName], { header: 1 });
                }
                else {
                    var data = e.target['result'];
                    data = new Uint8Array(data);
                    var workbook = XLSX.read(data, { type: 'array' });
                    var saved_SheetName = workbook.SheetNames[0];
                    importedData = XLSX.utils.sheet_to_json(workbook.Sheets[saved_SheetName], { header: 1 });
                }
                if (importedData.length <= 1) {
                    _this.showNotification({
                        key: 'fileError',
                        severity: 'warn',
                        summary: 'We got a problem!',
                        detail: _this.uploadedFileName + " does not contain any data. Please verify and try again.",
                        life: 6000,
                    });
                }
                else {
                    _this._sectionData.setImportFileData(importedData);
                    // Clear Files UploadedList
                    file.clear();
                    _this.importFileSuccess.emit({ ok: true, type: type });
                }
            };
        }
    };
    FileUploadComponent.prototype.showPipeTracer = function () {
        var URL = window.location.href;
        return URL.includes('localhost') || URL.includes('-dev.') || URL.includes('-qa.') || URL.includes('-test.')
            ? true
            : false;
    };
    //#endregion 'General Methods'
    //#region 'Notification'
    FileUploadComponent.prototype.showNotification = function (msg) {
        this._message.add({
            key: msg && msg.key && msg.key.length > 0 ? msg.key : 'fileNotify',
            sticky: msg && msg.key && msg.key != 'fileNotify' ? true : false,
            closable: msg && msg.key && msg.key != 'fileNotify' ? true : false,
            severity: msg.severity,
            summary: msg.summary,
            detail: msg.detail,
            life: msg.life,
        });
    };
    return FileUploadComponent;
}());
export { FileUploadComponent };
