import {
  DownloadedFiles,
  OfflineActivity,
  OfflineActivityAttachment,
  OfflineActivityDates,
  OfflineActivityTimelog,
  SharepointFiles,
} from '#models/index';
import { EmailNotification } from '#models/shared/email-notification';
import { AttachmentApiService } from '#services-api/index';
import { OfflineActivityApiService } from '#services-api/jobs/offline-activity-api.service';
import { OfflineActivityDatesApiService } from '#services-api/jobs/offline-activity-dates-api.service';
import { OfflineActivityTimelogApiService } from '#services-api/jobs/offline-activity-timelog-api.service';
import { LoadingIndicatorService } from '#services-shared/loading-indicator.service';
import { TooltipService } from '#services-shared/tooltip.service';
import { Injectable, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { Message } from 'primeng/api';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
import { DailyOperationService } from './daily-operation.service';

//? OA = Offline Activity
//? OAD = Offline Activity Dates
//? OAT = Offline Activity Timelog

@Injectable({
  providedIn: 'root',
})
export class OfflineActivityService implements OnDestroy {
  //#region 'Variables'
  public wellId: string;
  public jobID: string;
  public OADId: string;
  public TMLSysSeq = 0;

  //? Offline Activity
  public selectedOAId = 0;
  public OAList: OfflineActivity[] = [];

  //? Offline Activity Dates
  public OADTime = 0;
  public selectedOADId = 0;
  public showOADTimeError = false;
  public OADObject: OfflineActivityDates;
  public OADDeleteObject: OfflineActivityDates;

  public OADList: OfflineActivityDates[] = [];
  public OADDataDates: OfflineActivityDates[] = [];

  //? Offline Activity Timelog
  public selectedOATId = 0;
  public currentTimelogAvailable = 0;
  public TMLTime = 0;

  public OATObject: OfflineActivityTimelog;
  public OATDeleteObject: OfflineActivityTimelog;
  public OATList: OfflineActivityTimelog[] = [];

  //? Offline Activity Attachments
  public showViewFiles = false;
  public downloadedFiles: DownloadedFiles[] = [];
  public spFilesList: SharepointFiles[] = [];
  public OAAList: OfflineActivityAttachment[] = [];

  //? Notifications
  public notifyOA: Message[] = [];
  public notifyTMLOA: Message[] = [];

  //?Email Notification
  public emailData: EmailNotification;

  //? Subscriptions
  private SUBS$ = new Subscription();
  //#endregion 'Variables'

  //#region 'Angular Life Cycle'
  constructor(
    private _OA: OfflineActivityApiService,
    private _OAD: OfflineActivityDatesApiService,
    private _OAT: OfflineActivityTimelogApiService,
    public _AttachmentAPI: AttachmentApiService,
    private _loader: LoadingIndicatorService,
    public _sectionDailyOperation: DailyOperationService,
    public _tooltip: TooltipService
  ) {}

  ngOnDestroy(): void {
    this.SUBS$.unsubscribe();
  }
  //#endregion 'Angular Life Cycle'

  //? Offline Activity
  //#region 'Loads -- Offline Activity'
  public loadOA() {
    return new Promise((resolve, reject) => {
      try {
        this._loader.show();
        this.OAList = [];
        this.resetAll();

        const OA$ = this._OA
          .getByJobId(this.jobID)
          .finally(() => {
            this._loader.hide();
          })
          .subscribe((OA: OfflineActivity[]) => {
            if (OA && OA.length > 0) {
              OA.sort((a, b) =>
                a.CreationDate.Value > b.CreationDate.Value ? 1 : b.CreationDate.Value > a.CreationDate.Value ? -1 : 0
              );
              OA[0].selected = true;
              this.selectedOAId = 0;
              this.OAList = [...OA];
              resolve(true);
            }
          });
        this.SUBS$.add(OA$);
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  //#endregion 'Loads -- Offline Activity'

  //#region 'General Methods -- Offline Activity'
  public changeOASelection(idx: number) {
    for (let i = 0; i < this.OAList.length; i++) {
      if (idx !== i) this.OAList[i].selected = false;
      if (idx === i) this.OAList[i].selected = true;
    }
  }
  //#endregion 'General Methods -- Offline Activity'

  //#region 'Validations -- Offline Activity'
  public OAHaveData(): boolean {
    return this.OAList && this.OAList.length === 0 ? true : false;
  }
  //#endregion 'Validations -- Offline Activity'

  //#region 'CRUD -- Offline Activity'
  public createOA(dto: OfflineActivity) {
    return new Promise((resolve, reject) => {
      try {
        this._loader.show();
        this.SUBS$.add(
          this._OA
            .create(dto)
            .finally(() => {
              this._loader.hide();
            })
            .subscribe(
              () => {
                resolve({
                  key: 'OANotify',
                  severity: 'success',
                  summary: 'Correct!',
                  detail: 'Offline Activity Created Successfully',
                });
              },
              (e: any) => {
                console.error(e.message);
                reject({
                  key: 'OAError',
                  severity: 'error',
                  summary: 'We got a problem!',
                  detail: 'We had a problem while creating, please refresh the page and try again.',
                  life: 6000,
                });
              }
            )
        );
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public updateOA(dto: OfflineActivity) {
    return new Promise((resolve, reject) => {
      try {
        this._loader.show();
        this.SUBS$.add(
          this._OA
            .update(dto)
            .finally(() => {
              this._loader.hide();
            })
            .subscribe(
              () => {
                resolve({
                  key: 'OANotify',
                  severity: 'success',
                  summary: 'Correct!',
                  detail: 'Offline Activity Updated Successfully',
                });
              },
              (e: any) => {
                console.error(e.message);
                reject({
                  key: 'OAError',
                  severity: 'error',
                  summary: 'We got a problem!',
                  detail: 'We had a problem while updating, please refresh the page and try again.',
                  life: 6000,
                });
              }
            )
        );
      } catch (err) {
        reject(Error(err));
      }
    });
  }
  //#endregion 'CRUD -- Offline Activity'

  //? Offline Activity Dates
  //#region 'Loads -- Offline Activity Dates'
  public getOADByOAId() {
    return new Promise((resolve, reject) => {
      try {
        this._loader.show();
        this.changeOASelection(this.selectedOAId);
        this.resetAll();

        const OAD$ = this._OAD
          .getByActivityId(this.OAList[this.selectedOAId].IdRec.Value)
          .subscribe((OAD: OfflineActivityDates[]) => {
            if (OAD && OAD.length > 0) {
              OAD.sort((a, b) => {
                if (a.StartDate.Value === '' && b.StartDate.Value !== '') {
                  return 1;
                } else if (a.StartDate.Value !== '' && b.StartDate.Value === '') {
                  return -1;
                } else {
                  if (new Date(a.StartDate.Value) > new Date(b.StartDate.Value)) {
                    return -1;
                  } else if (new Date(a.StartDate.Value) < new Date(b.StartDate.Value)) {
                    return 1;
                  } else {
                    return 0;
                  }
                }
              });

              this.selectedOADId = 0;
              this.OADList = [...OAD];
              this._loader.hide();
              this.changeOADSelection();
              resolve(true);
            } else {
              this._loader.hide();
              resolve(true);
            }
          });

        this.SUBS$.add(OAD$);
      } catch (err) {
        reject(Error(err));
      }
    });
  }
  //#endregion 'Loads -- Offline Activity Dates'

  //#region 'General Methods -- Offline Activity Dates'
  public changeOADSelection() {
    return new Promise((resolve, reject) => {
      try {
        for (let i = 0; i < this.OADList.length; i++) {
          if (this.selectedOADId !== i) this.OADList[i].selected = false;
          if (this.selectedOADId === i) {
            this.setDataToValidateDates(true);
            this.OADList[i].selected = true;
            this.OADObject = this.OADList[i];
            this.OADId = this.OADList[i].IdRec.Value;
            const START = this.OADObject.StartDate.Value;
            const END = this.OADObject.EndDate.Value;
            this.calculateRigTime(START, END);
            this.getOATByOADId();
            this.getOAAByOADId();
            this.resetOAA();
            this.resetNotifications();
            this.initializeEmailObj();
            resolve(true);
          }
        }
      } catch (err) {
        reject(Error(err));
      }
    });
  }

  public setDataToValidateDates(splice: boolean) {
    this.OADDataDates = this._tooltip.deepClone(this.OADList);
    if (splice) {
      if (!this.isSelectedOADDatesAsToday()) {
        const tempIndex = this.OADDataDates.indexOf(this.OADDataDates[this.selectedOADId]);
        this.OADDataDates.splice(tempIndex, 1);
      }
    }
  }

  public checkDatesExist(_date: any, isStart: boolean) {
    const DATE = moment(_date);
    for (const element of this.OADDataDates) {
      const dateExist =
        moment(DATE).isBetween(element.StartDate.Value, element.EndDate.Value) ||
        moment(DATE).isSame(isStart ? element.StartDate.Value : element.EndDate.Value);
      if (dateExist && this.OADList.length > 1) {
        return dateExist;
      }
    }

    return false;
  }

  public calculateRigTime(start: any, end: any, self?: boolean) {
    if (self) {
      start = this.OADObject.StartDate.Value;
      end = this.OADObject.EndDate.Value;
    }

    this.OADTime = 0;
    this.showOADTimeError = false;
    this.OADTime = this.getOADTime(start, end);

    if (this.OADTime > 24) {
      this.showOADTimeError = true;
    }
  }

  public getOADTime(start: any, end: any): number {
    const DURATION = moment.duration(moment(end).diff(moment(start)));
    const HOURS = DURATION.asHours();
    return HOURS;
  }

  private resetOAD() {
    this.OADTime = 0;
    this.showOADTimeError = false;
    this.OADObject = undefined;
    this.OADDeleteObject = undefined;

    this.OADList = [];
    this.OADDataDates = [];
  }
  //#endregion 'General Methods -- Offline Activity Dates'

  //#region 'Validations -- Offline Activity Dates'
  public OADObjectExist(): boolean {
    return this.OADObject ? true : false;
  }

  public OADHaveData(): boolean {
    return this.OADList && this.OADList.length > 0 ? true : false;
  }

  private isSelectedOADDatesAsToday(): boolean {
    const TODAY_START = moment().format('MM/DD/YYYY 00:00');
    const OAD_START = moment(this.OADDataDates[this.selectedOADId].StartDate.Value).format('MM/DD/YYYY 00:00');

    return moment(TODAY_START).isSame(OAD_START) ? true : false;
  }
  //#endregion 'Validations -- Offline Activity Dates'

  //#region 'CRUD -- Offline Activity Dates'
  public createOAD(dto: OfflineActivityDates) {
    return new Promise(async (resolve, reject) => {
      this._loader.show();
      this.SUBS$.add(
        this._OAD
          .create(dto)
          .finally(() => {
            this._loader.hide();
            this.getOADByOAId();
          })
          .subscribe(
            () => {
              resolve({
                key: 'OANotify',
                severity: 'success',
                summary: 'Correct!',
                detail: 'Offline Activity Date Created Successfully',
              });
            },
            (e: any) => {
              console.error(e.message);
              reject({
                key: 'OAError',
                severity: 'error',
                summary: 'We got a problem!',
                detail: 'We had a problem while creating, please refresh the page and try again.',
                life: 6000,
              });
            }
          )
      );
    });
  }

  public updateOAD(dto: OfflineActivityDates) {
    return new Promise(async (resolve, reject) => {
      this._loader.show();
      this.SUBS$.add(
        this._OAD
          .update(dto)
          .finally(() => {
            this._loader.hide();
            this.getOADByOAId();
          })
          .subscribe(
            () => {
              resolve({
                key: 'OANotify',
                severity: 'success',
                summary: 'Correct!',
                detail: 'Offline Activity Date Updated Successfully',
              });
            },
            (e: any) => {
              console.error(e.message);
              reject({
                key: 'OAError',
                severity: 'error',
                summary: 'We got a problem!',
                detail: 'We had a problem while updating, please refresh the page and try again.',
                life: 6000,
              });
            }
          )
      );
    });
  }

  public deleteOAD() {
    return new Promise(async (resolve, reject) => {
      this._loader.show();
      this.SUBS$.add(
        this._OAD
          .delete(this.OADDeleteObject)
          .finally(() => {
            this._loader.hide();
            this.getOADByOAId();
          })
          .subscribe(
            () => {
              this.selectedOADId = 0;
              resolve({
                key: 'OANotify',
                severity: 'success',
                summary: 'Correct!',
                detail: 'Offline Activity Date Deleted Successfully',
              });
            },
            (e: any) => {
              console.error(e.message);
              reject({
                key: 'OAError',
                severity: 'error',
                summary: 'We got a problem!',
                detail: 'We had a problem while deleting, please refresh the page and try again.',
                life: 6000,
              });
            }
          )
      );
    });
  }
  //#endregion 'CRUD -- Offline Activity Dates'

  //? Offline Activity Timelog
  //#region 'Loads -- Offline Activity Timelog'
  public getOATByOADId() {
    return new Promise((resolve, reject) => {
      try {
        this._loader.show();
        this.resetOAT();
        const OAT$ = this._OAT
          .getByOfflineActivityDateId(this.OADList[this.selectedOADId].IdRec.Value)
          .pipe(
            map<OfflineActivityTimelog[], OfflineActivityTimelog[]>((dt) => {
              dt.map((tml) => {
                this.TMLTime = this.TMLTime + Number(tml.Duration.Value);
                tml.EndDepth.Value = tml.EndDepth.Value.replace(/,/g, '');
                tml.StartDepth.Value = tml.StartDepth.Value.replace(/,/g, '');
                return tml;
              });
              return dt;
            })
          )
          .subscribe((OAT: OfflineActivityTimelog[]) => {
            this.getAvailableTime(false);
            if (OAT && OAT.length > 0) {
              OAT.sort((a, b) =>
                Number(a.SysSeq.Value) > Number(b.SysSeq.Value)
                  ? 1
                  : Number(b.SysSeq.Value) > Number(a.SysSeq.Value)
                  ? -1
                  : 0
              );

              this.OATList = [...OAT];
              this._loader.hide();
              resolve(true);
            } else {
              this._loader.hide();
              resolve(true);
            }
          });

        this.SUBS$.add(OAT$);
      } catch (err) {
        this._loader.hide();
        reject(Error(err));
      }
    });
  }
  //#endregion 'Loads -- Offline Activity Timelog'

  //#region 'General Methods -- Offline Activity Timelog'
  public validateAvailableDuration(duration: number, isEdit: boolean): boolean {
    //? Validate if duration don't exceeds the time available
    this.getAvailableTime(isEdit);
    const AVAILABLE = this.currentTimelogAvailable - duration;
    if (isEdit) this.currentTimelogAvailable = AVAILABLE;
    if (AVAILABLE < 0) {
      return true;
    } else {
      return false;
    }
  }

  public getAvailableTime(isEdit: boolean) {
    this.currentTimelogAvailable = 0;
    if (isEdit) {
      this.currentTimelogAvailable = this.OADTime - (this.TMLTime - Number(this.OATObject.Duration.Value));
    } else {
      this.currentTimelogAvailable = this.OADTime - this.TMLTime;
    }
  }

  public resetOAT() {
    this.currentTimelogAvailable = 0;
    this.TMLTime = 0;

    this.OATObject = undefined;
    this.OATDeleteObject = undefined;
    this.OATList = [];
  }

  public getLastSequenceNumber(): string {
    const LENGHT = this.OATList && this.OATList.length === 0 ? 0 : this.OATList.length;
    if (LENGHT === 0) return '1';
    if (LENGHT > 0) return (Number(this.OATList[LENGHT - 1].SysSeq.Value) + 1).toString();
  }
  //#endregion 'General Methods -- Offline Activity Timelog'

  //#region 'Validations -- Offline Activity Timelog'
  public isDOPFull() {
    return new Promise(async (resolve, reject) => {
      if (this.TMLTime >= 24) {
        reject({
          key: 'OAError',
          severity: 'warn',
          summary: 'Timelog Full',
          detail: 'Timelog time is full now (24 hrs only), please verify.',
          life: 6000,
        });
      } else {
        resolve(false);
      }
    });
  }

  public getInactive(data: string | boolean) {
    if (typeof data === 'string') {
      if (data === '' || data === 'N') {
        return false;
      } else {
        return true;
      }
    }

    if (typeof data === 'boolean') {
      if (data) {
        return 'Y';
      } else {
        return 'N';
      }
    }

    return null;
  }

  public OATHaveData(): boolean {
    return this.OATList && this.OATList.length > 0 ? true : false;
  }
  //#endregion 'Validations -- Offline Activity Timelog'

  //#region 'Re-Order'
  public reOrderTMLRecord(old_index: number, new_index: number) {
    while (old_index < 0) {
      old_index += this.OATList.length;
    }
    while (new_index < 0) {
      new_index += this.OATList.length;
    }
    if (new_index >= this.OATList.length) {
      let k = new_index - this.OATList.length + 1;
      while (k--) {
        this.OATList.push(undefined);
      }
    }
    this.OATList.splice(new_index, 0, this.OATList.splice(old_index, 1)[0]);
  }
  //#endregion 'Re-Order'

  //#region 'CRUD -- Offline Activity Timelog'
  public createOAT(dto: OfflineActivityTimelog) {
    return new Promise(async (resolve, reject) => {
      this._loader.show();
      dto.SysSeq.Value = this.getLastSequenceNumber();
      this.SUBS$.add(
        this._OAT
          .create(dto)
          .finally(() => {
            this._loader.hide();
            this.getOATByOADId();
          })
          .subscribe(
            () => {
              resolve({
                key: 'TMLOANotify',
                severity: 'success',
                summary: 'Correct!',
                detail: 'Offline Activity Timelog Created Successfully',
              });
            },
            (e: any) => {
              console.error(e.message);
              reject({
                key: 'TMLOAError',
                severity: 'error',
                summary: 'We got a problem!',
                detail: 'We had a problem while creating, please refresh the page and try again.',
                life: 6000,
              });
            }
          )
      );
    });
  }

  public updateOAT(dto: OfflineActivityTimelog) {
    return new Promise(async (resolve, reject) => {
      this._loader.show();
      this.SUBS$.add(
        this._OAT
          .update(dto)
          .finally(() => {
            this._loader.hide();
            this.getOATByOADId();
          })
          .subscribe(
            () => {
              resolve({
                key: 'TMLOANotify',
                severity: 'success',
                summary: 'Correct!',
                detail: 'Offline Activity Timelog Updated Successfully',
              });
            },
            (e: any) => {
              console.error(e.message);
              reject({
                key: 'TMLOANotify',
                severity: 'error',
                summary: 'We got a problem!',
                detail: 'We had a problem while updating, please refresh the page and try again.',
                life: 6000,
              });
            }
          )
      );
    });
  }

  public updateOATBulk(dto: OfflineActivityTimelog[]) {
    return new Promise(async (resolve, reject) => {
      this._loader.show();
      this.SUBS$.add(
        this._OAT
          .updateBulk(dto)
          .finally(() => {
            this._loader.hide();
            this.getOATByOADId();
          })
          .subscribe(
            () => {
              resolve({
                key: 'TMLOANotify',
                severity: 'success',
                summary: 'Correct!',
                detail: 'Offline Activity Timelogs Updated Successfully',
              });
            },
            (e: any) => {
              console.error(e.message);
              reject({
                key: 'TMLOANotify',
                severity: 'error',
                summary: 'We got a problem!',
                detail: 'We had a problem while updating, please refresh the page and try again.',
                life: 6000,
              });
            }
          )
      );
    });
  }

  public deleteOAT() {
    return new Promise(async (resolve, reject) => {
      this._loader.show();
      this.SUBS$.add(
        this._OAT
          .delete(this.OATDeleteObject)
          .finally(() => {
            this._loader.hide();
            this.selectedOADId = 0;
            this.changeOADSelection();
          })
          .subscribe(
            () => {
              resolve({
                key: 'OANotify',
                severity: 'success',
                summary: 'Correct!',
                detail: 'Offline Activity Timelog Deleted Successfully',
              });
            },
            (e: any) => {
              console.error(e.message);
              reject({
                key: 'OAError',
                severity: 'error',
                summary: 'We got a problem!',
                detail: 'We had a problem while deleting, please refresh the page and try again.',
                life: 6000,
              });
            }
          )
      );
    });
  }
  //#endregion 'CRUD -- Offline Activity Timelog'

  //? Offline Activity Attachments
  //#region 'Loads -- Offline Activity Attachments'
  public getOAAByOADId() {
    return new Promise((resolve, reject) => {
      try {
        this._loader.show();
        this.spFilesList = [];
        const OAA$ = this._AttachmentAPI
          .getByOfflineActivityDateId(this.OADId)
          .subscribe((OAA: OfflineActivityAttachment[]) => {
            this.getAvailableTime(false);
            if (OAA && OAA.length > 0) {
              OAA.forEach((att) => {
                const COMMENT = att.Comment.Value.split('|');
                const SF: SharepointFiles = {
                  _url: COMMENT.length > 0 ? COMMENT[0] : '',
                  name: att.Description.Value,
                  nameToShow: att.Name.Value,
                  idRec: att.IdRec.Value,
                  imgType: att.EmailCAI.Value,
                  size: COMMENT.length > 0 ? COMMENT[1] : '',
                };
                this.spFilesList.push(SF);
              });
              this.OAAList = [...OAA];

              this.spFilesList.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
              this.OAAList.sort((a, b) => (a.Name.Value > b.Name.Value ? 1 : b.Name.Value > a.Name.Value ? -1 : 0));
              this._loader.hide();
              resolve(true);
            } else {
              this._loader.hide();
              resolve(true);
            }
          });

        this.SUBS$.add(OAA$);
      } catch (err) {
        this._loader.hide();
        reject(Error(err));
      }
    });
  }
  //#endregion 'Loads -- Offline Activity Attachments'

  //#region 'General Methods -- Offline Activity Attachments'
  private resetOAA() {
    this.OAAList = [];
    this.spFilesList = [];
  }
  //#endregion 'General Methods -- Offline Activity Attachments'

  //#region 'Validations -- Offline Activity Attachment'
  public OAAHaveData(): boolean {
    return this.OAAList && this.OAAList.length > 0 ? true : false;
  }

  public SPFHaveData(): boolean {
    return this.spFilesList && this.spFilesList.length > 0 ? true : false;
  }

  private isFileAlreadyDownloaded(name: string): boolean {
    const EXIST = this.downloadedFiles.filter((obj) => obj.fullName === name);
    return EXIST && EXIST.length > 0 ? true : false;
  }
  //#endregion 'Validations -- Offline Activity Attachment'

  //#region 'CRUD -- Offline Activity Attachment'
  public deleteOAA(index: number) {
    return new Promise(async (resolve, reject) => {
      this._loader.show();
      this.closeViewFiles();
      const DTO = this.OAAList[index];
      this.SUBS$.add(
        this._AttachmentAPI
          .delete(DTO)
          .finally(() => {
            this._loader.hide();
            this.selectedOADId = 0;
          })
          .subscribe(
            () => {
              resolve(DTO);
            },
            (e: any) => {
              console.error(e.message);
              reject({
                key: 'TMLOAError',
                severity: 'error',
                summary: 'We got a problem!',
                detail: 'We had a problem while deleting, please refresh the page and try again.',
                life: 6000,
              });
              this._loader.hide();
            }
          )
      );
    });
  }

  public uploadFilesToAzure(FDATA: FormData) {
    return new Promise(async (resolve, reject) => {
      this._loader.show();
      this.SUBS$.add(
        this._AttachmentAPI
          .UploadFiles(FDATA, this.OADId)
          .finally(() => {
            this._loader.hide();
          })
          .subscribe(
            (res: any[]) => {
              this.spFilesList.map((spf) => {
                const _URL = res.filter((obj: string) => obj.includes(spf.name));
                if (_URL && _URL.length > 0) {
                  spf._url = `${_URL[0]}|${spf.size}`;
                  return spf;
                }
              });
              this._loader.hide();
              resolve(true);
            },
            () => {
              this._loader.hide();
              reject({
                key: 'TMLOAError',
                severity: 'error',
                summary: 'We got a problem!',
                detail: 'We had a problem while uploading files, please contact you system admin.',
                life: 6000,
              });
            }
          )
      );
    });
  }

  public uploadUrlsToWellView() {
    return new Promise(async (resolve, reject) => {
      this._loader.show();
      this.SUBS$.add(
        this._AttachmentAPI.createBulk(this.initWellViewUrls()).subscribe(
          () => {
            this._loader.hide();
            this.getOAAByOADId();
            resolve({
              key: 'TMLOANotify',
              severity: 'success',
              summary: 'Correct!',
              detail: 'Attachments uploaded to WellView Successfully',
            });
          },
          () => {
            this._loader.hide();
            reject({
              key: 'TMLOAError',
              severity: 'error',
              summary: 'We got a problem!',
              detail: 'We had a problem while uploading to WellView, please contact you system admin.',
              life: 6000,
            });
          }
        )
      );
    });
  }

  private initWellViewUrls(): OfflineActivityAttachment[] {
    let OAA: OfflineActivityAttachment[] = [];

    this.spFilesList.forEach((spf) => {
      const DTO = new OfflineActivityAttachment();
      DTO.OADId.Value = this.OADId;
      DTO.WellId.Value = this.wellId;
      DTO.Description.Value = spf.name;
      DTO.Name.Value = spf.nameToShow;
      DTO.Comment.Value = spf._url;
      DTO.EmailCAI.Value = spf.imgType;
      DTO.ReferenceId.Value = this._sectionDailyOperation.wellName;
      OAA.push(DTO);
    });

    return OAA;
  }

  public deleteFromAzure(dto: OfflineActivityAttachment) {
    return new Promise(async (resolve, reject) => {
      this._loader.show();
      this.SUBS$.add(
        this._AttachmentAPI.DeleteAzureFile(this.getAZDTO(dto)).subscribe(
          () => {
            this.spFilesList.length === 0 ? this.closeViewFiles() : this.openViewFiles();
            this._loader.hide();
            resolve({
              key: 'TMLOANotify',
              severity: 'success',
              summary: 'Correct!',
              detail: 'Offline Activity Attachment Deleted Successfully',
            });
          },
          (e: any) => {
            this._loader.hide();
            console.error(e.message);
            reject({
              key: 'TMLOAError',
              severity: 'error',
              summary: 'We got a problem!',
              detail: 'We had a problem while deleting files, please refresh the page and try again.',
              life: 6000,
            });
          }
        )
      );
    });
  }

  private getAZDTO(dto: OfflineActivityAttachment) {
    return {
      FileName: dto.Description.Value,
      FileNameDisplay: dto.Name.Value,
    };
  }

  public downloadFile(index: number) {
    this._loader.show();
    const FILE = this.spFilesList[index];

    if (!this.isFileAlreadyDownloaded(FILE.name)) {
      this.SUBS$.add(
        this._AttachmentAPI.DownloadAttachment(FILE.name, FILE.imgType, FILE.size).subscribe((dto: any) => {
          const SIZE = this._tooltip.getFileSize(dto.body.size);
          const blobData = new Blob([dto.body], { type: FILE.imgType });

          if (SIZE > 2) {
            //? File is too big, we download it better
            this.addToDownloadedFiles(FILE, undefined, SIZE, dto.body.size, blobData);
            this._tooltip.downloadBlob(blobData, FILE.nameToShow);
            this._loader.hide();
          } else {
            const reader = new FileReader();
            reader.readAsArrayBuffer(blobData);
            reader.onload = (e: any) => {
              const arrayBuffer = e.target['result'];
              const IMG = this._tooltip.convertToBase64(arrayBuffer);
              this.addToDownloadedFiles(FILE, IMG, SIZE, dto.body.size, blobData);

              if (IMG) {
                this.showImage(FILE.imgType, IMG);
              } else {
                this._tooltip.downloadBlob(blobData, FILE.nameToShow);
              }
              this._loader.hide();
            };
          }
        })
      );
    } else {
      const IMG = this.downloadedFiles.filter((obj) => obj.fullName === FILE.name);

      if (!IMG[0].data) {
        this._tooltip.downloadBlob(IMG[0].blobData, IMG[0].name);
      } else {
        this.showImage(IMG[0].type, IMG[0].data);
      }
      this._loader.hide();
    }
  }

  private addToDownloadedFiles(File: SharepointFiles, img: any, mb: number, size: string, blobData: any) {
    this.downloadedFiles.push({
      fullName: File.name,
      name: File.nameToShow,
      type: File.imgType,
      data: img,
      blobData: img ? undefined : blobData,
      mb: mb,
      size: size,
    });
  }

  public getAllAttachments(OADId: string) {
    this._loader.show();
    return new Promise((resolve, reject) => {
      try {
        this.SUBS$.add(
          this._AttachmentAPI
            .DownloadAllAttachments(OADId)
            .finally(() => {
              this._loader.hide();
            })
            .subscribe((b64: OfflineActivityAttachment[]) => {
              resolve(b64);
            })
        );
      } catch (err) {
        reject(Error(err));
      }
    });
  }
  //#endregion 'CRUD -- Offline Activity Attachment'

  //#region 'View Attachments'
  public openViewFiles() {
    this.showViewFiles = true;
  }

  public closeViewFiles() {
    this.showViewFiles = false;
  }

  public showImage(type: string, b64: string) {
    let previewWindow = window.open();
    previewWindow.document.write(
      `<iframe width='100%' height='100%' src='data:${type};base64,${encodeURI(b64)}'></iframe>`
    );
  }
  //#endregion 'View Attachments'

  //? General Code
  //#region 'Email Notification'
  public generateEmailData(): EmailNotification {
    const _url = new URL(window.location.href);
    this.emailData.WELL = this._sectionDailyOperation.wellName;
    this.emailData.JOB = this._sectionDailyOperation.selectedJob.JobCategory.Value;
    this.emailData.OANAME = this.OAList[this.selectedOAId].Name.Value;
    this.emailData.DATESTART = this.OADList[this.selectedOADId].StartDate.Value;
    this.emailData.DATEEND = this.OADList[this.selectedOADId].EndDate.Value;
    this.emailData.ROAH = this.currentTimelogAvailable.toString();
    this.emailData.COAH = this.TMLTime.toString();
    this.emailData.URL = `${_url.origin}/view-attachment;OADId=${this.OADList[this.selectedOADId].IdRec.Value}`;

    this.emailData.TMLS = [];
    for (let i = 0; i < this.OATList.length; i++) {
      const tl = this.OATList[i];
      this.emailData.TMLS.push(`${(i + 1).toString()}|${tl.Duration.Value}|${tl.Comment.Value}`);
    }

    this.emailData.Subject = 'DSR Workspace - Offline Activity Notification';
    return this.emailData;
  }

  private initializeEmailObj() {
    this.emailData = {
      Emails: [],
      TMLS: [],
      TypeOfNotification: 0,
      Subject: '',
      WELL: '',
      JOB: '',
      OANAME: '',
      DATESTART: '',
      DATEEND: '',
      ROAH: '',
      COAH: '',
      URL: '',
    };

    const CURR_DESC_LS = this._tooltip.getItemLocalStorage('emails');
    if (CURR_DESC_LS && CURR_DESC_LS.length > 0) {
      const EMAILS = CURR_DESC_LS.split(',');
      EMAILS.forEach((em) => {
        this.pushEmail(em);
      });
    }
  }

  public addEmail(email: string) {
    if (email.includes(';')) {
      //? split emails in case needed
      const EMAILS = email.split(';');
      EMAILS.forEach((em) => {
        this.pushEmail(em);
      });
    } else {
      this.pushEmail(email);
    }
  }

  private pushEmail(email: string) {
    const EXIST = this.emailData.Emails.filter((obj) => obj === email);
    if (EXIST && EXIST.length === 0) {
      this.emailData.Emails.push(email);
      this._tooltip.addItemLocalStorage('emails', `${this.emailData.Emails}`);
    }
  }

  public deleteEmail(index: number) {
    this.emailData.Emails.splice(index, 1);
    this._tooltip.addItemLocalStorage('emails', `${this.emailData.Emails}`);
  }
  //#endregion 'Email Notification'

  //#region 'Notification'
  public showNotification(ntf: any, type: number) {
    this.resetNotifications();
    if (type === 1) {
      this.notifyOA = [];
      this.notifyOA.push({
        severity: ntf.severity,
        summary: ntf.summary,
        detail: ntf.detail,
      });
    } else if (type === 2) {
      this.notifyTMLOA = [];
      this.notifyTMLOA.push({
        severity: ntf.severity,
        summary: ntf.summary,
        detail: ntf.detail,
      });
    }

    setTimeout(() => {
      this.notifyOA = [];
      this.notifyTMLOA = [];
    }, 4000);
  }

  public resetNotifications() {
    this.notifyOA = [];
    this.notifyTMLOA = [];
  }
  //#endregion 'Notification'

  //#region 'General Methods'
  public resetAll() {
    this.resetOAD();
    this.resetOAT();
    this.resetOAA();
    this.resetNotifications();
  }
  //#endregion 'General Methods'
}
