import { ATRProcedureApiService } from '#services-api/index';
import { TooltipService } from '#services-shared/tooltip.service';
import { OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import * as i0 from "@angular/core";
import * as i1 from "../../api/jobs/atr-procedure-api.service";
import * as i2 from "../tooltip.service";
var SmartProceduresService = /** @class */ (function () {
    //#endregion 'Variables'
    //#region 'Angular Life Cycle'
    function SmartProceduresService(_ATRProcedure, _tooltip) {
        this._ATRProcedure = _ATRProcedure;
        this._tooltip = _tooltip;
        //#region 'Variables'
        this.stepsTotalDuration = 0;
        this.smartProcedureDATA = [];
        this.smartProcedureDATAOriginal = [];
        //? Subscriptions
        this.SUBS$ = new Subscription();
    }
    SmartProceduresService.prototype.ngOnDestroy = function () {
        this.SUBS$.unsubscribe();
    };
    //#endregion 'Angular Life Cycle'
    //#region 'Load'
    SmartProceduresService.prototype.loadATRData = function (startDate, endDate) {
        var _this = this;
        var ATR$ = this._ATRProcedure.getATRByStartEndDate(startDate, endDate).subscribe(function (data) {
            data = _this.setStatusNumber(data);
            data.map(function (el) {
                el.Sessions.map(function (session) {
                    session.Steps.sort(function (a, b) {
                        if (new Date(a.EventDate) > new Date(b.EventDate)) {
                            return -1;
                        }
                        else if (new Date(a.EventDate) < new Date(b.EventDate)) {
                            return 1;
                        }
                        else {
                            return 0;
                        }
                    });
                    return session;
                });
                return el;
            });
            _this.smartProcedureDATA = data;
            _this.smartProcedureDATAOriginal = _this._tooltip.deepClone(_this.smartProcedureDATA);
        });
        this.SUBS$.add(ATR$);
    };
    //#endregion 'Load'
    //#region 'Methods'
    SmartProceduresService.prototype.filterByName = function (filterText) {
        this.smartProcedureDATA = [];
        if (filterText && filterText.length > 0) {
            var DETAIL = this.smartProcedureDATAOriginal.filter(function (obj) {
                return obj.Name.toUpperCase().includes(filterText.toUpperCase());
            });
            this.smartProcedureDATA = this._tooltip.deepClone(DETAIL);
        }
        else {
            this.smartProcedureDATA = this._tooltip.deepClone(this.smartProcedureDATAOriginal);
        }
    };
    SmartProceduresService.prototype.openSession = function (procedureIndex, sessionIndex) {
        // First reset the data
        var current = this.smartProcedureDATA[procedureIndex].Sessions.findIndex(function (obj) { return obj.showDetail === true; });
        if (current !== -1 && current !== sessionIndex) {
            this.smartProcedureDATA[procedureIndex].Sessions[current].showDetail = false;
        }
        this.smartProcedureDATA[procedureIndex].Sessions[sessionIndex].showDetail = !this.smartProcedureDATA[procedureIndex]
            .Sessions[sessionIndex].showDetail;
    };
    SmartProceduresService.prototype.showSPDetail = function (index) {
        // First reset the data
        var current = this.smartProcedureDATA.findIndex(function (obj) { return obj.showDetail === true; });
        if (current !== -1 && current !== index) {
            this.smartProcedureDATA[current].showDetail = false;
        }
        // Set the new one
        this.smartProcedureDATA[index].showDetail = !this.smartProcedureDATA[index].showDetail;
    };
    SmartProceduresService.prototype.setStatusNumber = function (data) {
        data.map(function (proc) {
            proc.Sessions.map(function (element) {
                if (element.Status === 'Completed') {
                    element.StatusByNumber = 1;
                }
                else if (element.Status === 'Abandoned') {
                    element.StatusByNumber = 2;
                }
                else if (element.Status === 'Paused') {
                    element.StatusByNumber = 3;
                }
                else if (element.Status === 'In Progress') {
                    element.StatusByNumber = 4;
                }
                else {
                    element.StatusByNumber = 5;
                }
                return element;
            });
            return proc;
        });
        return data;
    };
    SmartProceduresService.ngInjectableDef = i0.defineInjectable({ factory: function SmartProceduresService_Factory() { return new SmartProceduresService(i0.inject(i1.ATRProcedureApiService), i0.inject(i2.TooltipService)); }, token: SmartProceduresService, providedIn: "root" });
    return SmartProceduresService;
}());
export { SmartProceduresService };
