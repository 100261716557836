import { environment } from '#environments/environment';
import { Adal6HTTPService } from 'adal-angular6';
import { Observable } from 'rxjs';
var ɵ0 = Adal6HTTPService.factory, ɵ1 = function (authSvc, adalSvc) {
    return function () {
        adalSvc.init(environment.adalConfig);
        adalSvc.handleWindowCallback();
        if (adalSvc.userInfo.authenticated) {
            return authSvc.loadUserInfo();
        }
        else {
            adalSvc.login(true);
            return Observable.of().toPromise();
        }
    };
};
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1 };
