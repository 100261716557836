/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./welcome.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./welcome.component";
import * as i4 from "../../services/shared/auth.service";
var styles_WelcomeComponent = [i0.styles];
var RenderType_WelcomeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WelcomeComponent, data: {} });
export { RenderType_WelcomeComponent as RenderType_WelcomeComponent };
function View_WelcomeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [["aria-hidden", "true"], ["class", "glyphicon glyphicon-user black btn btn-lg"]], null, null, null, null, null))], null, null); }
export function View_WelcomeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_WelcomeComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "boldTextFont"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userName; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.userName; _ck(_v, 3, 0, currVal_1); }); }
export function View_WelcomeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-welcome", [], null, null, null, View_WelcomeComponent_0, RenderType_WelcomeComponent)), i1.ɵdid(1, 114688, null, 0, i3.WelcomeComponent, [i4.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WelcomeComponentNgFactory = i1.ɵccf("app-welcome", i3.WelcomeComponent, View_WelcomeComponent_Host_0, {}, {}, []);
export { WelcomeComponentNgFactory as WelcomeComponentNgFactory };
