import { environment } from '#environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var TimelogApiService = /** @class */ (function () {
    //#region 'Angular Life Cycle'
    function TimelogApiService(http) {
        this.http = http;
    }
    //#endregion 'Angular Life Cycle'
    //#region 'General'
    TimelogApiService.prototype.getURL = function (method) {
        return environment.apiEndpoint + "/timeLog" + method;
    };
    //#endregion 'General'
    //#region 'Get'
    TimelogApiService.prototype.getActivityCode = function () {
        return this.http.get(this.getURL("/getActivityCode"));
    };
    TimelogApiService.prototype.getByDailyOperationId = function (dailyOperationId) {
        return this.http.get(this.getURL("/getByDailyOperationId"), {
            params: { dailyOperationId: dailyOperationId },
        });
    };
    //#endregion 'Get'
    //#region 'Post'
    TimelogApiService.prototype.create = function (timeLog) {
        return this.http.post(this.getURL("/create"), timeLog);
    };
    TimelogApiService.prototype.createBulk = function (timeLog) {
        return this.http.post(this.getURL("/createBulk"), timeLog);
    };
    //#endregion 'Post'
    //#region 'Put'
    TimelogApiService.prototype.update = function (timeLog) {
        return this.http.put(this.getURL("/update"), timeLog);
    };
    TimelogApiService.prototype.updateBulk = function (timeLog) {
        return this.http.put(this.getURL("/updateBulk"), timeLog);
    };
    //#endregion 'Put'
    //#region 'Delete'
    TimelogApiService.prototype.delete = function (timeLog) {
        return this.http.put(this.getURL("/delete"), timeLog);
    };
    TimelogApiService.ngInjectableDef = i0.defineInjectable({ factory: function TimelogApiService_Factory() { return new TimelogApiService(i0.inject(i1.HttpClient)); }, token: TimelogApiService, providedIn: "root" });
    return TimelogApiService;
}());
export { TimelogApiService };
