/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./not-authorized.component";
var styles_NotAuthorizedComponent = [];
var RenderType_NotAuthorizedComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NotAuthorizedComponent, data: {} });
export { RenderType_NotAuthorizedComponent as RenderType_NotAuthorizedComponent };
export function View_NotAuthorizedComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "box-shadow module"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Not authorized"])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["You don't have access to the system. Please contact help desk."]))], null, null); }
export function View_NotAuthorizedComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-not-authorized", [], null, null, null, View_NotAuthorizedComponent_0, RenderType_NotAuthorizedComponent)), i0.ɵdid(1, 114688, null, 0, i1.NotAuthorizedComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotAuthorizedComponentNgFactory = i0.ɵccf("app-not-authorized", i1.NotAuthorizedComponent, View_NotAuthorizedComponent_Host_0, {}, {}, []);
export { NotAuthorizedComponentNgFactory as NotAuthorizedComponentNgFactory };
