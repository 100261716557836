/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/forms";
import * as i3 from "./task-plan-create.component";
import * as i4 from "../../../services/api/shared/task-plan-api.service";
import * as i5 from "../../../services/shared/loading-indicator.service";
import * as i6 from "@angular/router";
var styles_TaskPlanCreateComponent = [];
var RenderType_TaskPlanCreateComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TaskPlanCreateComponent, data: {} });
export { RenderType_TaskPlanCreateComponent as RenderType_TaskPlanCreateComponent };
function View_TaskPlanCreateComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "label", [["style", "margin-left: 17%; margin-top: 1%; color: orangered; font: small-caption"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" * This Code has already been taken "]))], null, null); }
function View_TaskPlanCreateComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "text-danger field-validation-error"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["The Code field is required."]))], null, null); }
function View_TaskPlanCreateComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "text-danger field-validation-error"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["The Task Plan field is required."]))], null, null); }
export function View_TaskPlanCreateComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 40, "div", [["class", "box-shadow module"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Create Task Plan"])), (_l()(), i0.ɵeld(3, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 33, "div", [["class", "form-horizontal"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TaskPlanCreateComponent_1)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "label", [["style", "margin-left: 17%; color: dimgray; font: small-caption"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" [Note] Code name need to be unique, and it cannot be renamed after Task Plan is created. "])), (_l()(), i0.ɵeld(10, 0, null, null, 11, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "label", [["class", "control-label col-md-2"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Code"])), (_l()(), i0.ɵeld(13, 0, null, null, 8, "div", [["class", "col-md-10"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 5, "input", [["class", "form-control text-box single-line"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 15)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 15).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 15)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 15)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.taskPlan.Code = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i0.ɵdid(15, 16384, null, 0, i2.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i0.ɵdid(17, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i0.ɵdid(19, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TaskPlanCreateComponent_2)), i0.ɵdid(21, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(22, 0, null, null, 11, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(23, 0, null, null, 1, "label", [["class", "control-label col-md-2"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Task Plan"])), (_l()(), i0.ɵeld(25, 0, null, null, 8, "div", [["class", "col-md-10"]], null, null, null, null, null)), (_l()(), i0.ɵeld(26, 0, null, null, 5, "input", [["class", "form-control text-box single-line"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 27)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 27).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 27)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 27)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.taskPlan.Description = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i0.ɵdid(27, 16384, null, 0, i2.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i0.ɵdid(29, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i0.ɵdid(31, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TaskPlanCreateComponent_3)), i0.ɵdid(33, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(34, 0, null, null, 3, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(35, 0, null, null, 2, "div", [["class", "col-md-offset-2 col-md-10"]], null, null, null, null, null)), (_l()(), i0.ɵeld(36, 0, null, null, 1, "a", [["class", "btn btn-default"], ["style", "width: 100px"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createTaskPlan() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Create"])), (_l()(), i0.ɵeld(38, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(39, 0, null, null, 1, "a", [["style", "cursor: pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onRedirectButtonClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Back to Task Plan"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayWarningNote; _ck(_v, 6, 0, currVal_0); var currVal_8 = _co.taskPlan.Code; _ck(_v, 17, 0, currVal_8); var currVal_9 = _co.displayWarningCode; _ck(_v, 21, 0, currVal_9); var currVal_17 = _co.taskPlan.Description; _ck(_v, 29, 0, currVal_17); var currVal_18 = _co.displayWarningDesc; _ck(_v, 33, 0, currVal_18); }, function (_ck, _v) { var currVal_1 = i0.ɵnov(_v, 19).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 19).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 19).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 19).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 19).ngClassValid; var currVal_6 = i0.ɵnov(_v, 19).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 19).ngClassPending; _ck(_v, 14, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_10 = i0.ɵnov(_v, 31).ngClassUntouched; var currVal_11 = i0.ɵnov(_v, 31).ngClassTouched; var currVal_12 = i0.ɵnov(_v, 31).ngClassPristine; var currVal_13 = i0.ɵnov(_v, 31).ngClassDirty; var currVal_14 = i0.ɵnov(_v, 31).ngClassValid; var currVal_15 = i0.ɵnov(_v, 31).ngClassInvalid; var currVal_16 = i0.ɵnov(_v, 31).ngClassPending; _ck(_v, 26, 0, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16); }); }
export function View_TaskPlanCreateComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-task-plan-create", [], null, null, null, View_TaskPlanCreateComponent_0, RenderType_TaskPlanCreateComponent)), i0.ɵdid(1, 245760, null, 0, i3.TaskPlanCreateComponent, [i4.TaskPlanApiService, i5.LoadingIndicatorService, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TaskPlanCreateComponentNgFactory = i0.ɵccf("app-task-plan-create", i3.TaskPlanCreateComponent, View_TaskPlanCreateComponent_Host_0, {}, {}, []);
export { TaskPlanCreateComponentNgFactory as TaskPlanCreateComponentNgFactory };
