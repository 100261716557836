import { environment } from '#environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var DailyOperationApiService = /** @class */ (function () {
    //#region 'Angular Life Cycle'
    function DailyOperationApiService(http) {
        this.http = http;
    }
    //#endregion 'Angular Life Cycle'
    //#region 'General'
    DailyOperationApiService.prototype.getURL = function (method) {
        return environment.apiEndpoint + "/dailyOperation" + method;
    };
    //#endregion 'General'
    //#region 'Get'
    DailyOperationApiService.prototype.getByJobId = function (jobId) {
        return this.http.get(this.getURL("/getByJobId"), {
            params: { jobId: jobId },
        });
    };
    //#endregion 'Get'
    //#region 'Post'
    DailyOperationApiService.prototype.create = function (dailyOperation) {
        return this.http.post(this.getURL("/create"), dailyOperation);
    };
    //#endregion 'Post'
    //#region 'Put'
    DailyOperationApiService.prototype.update = function (dailyOperation) {
        return this.http.put(this.getURL("/update"), dailyOperation);
    };
    //#endregion 'Put'
    //#region 'Delete'
    DailyOperationApiService.prototype.delete = function (dailyOperation) {
        return this.http.put(this.getURL("/delete"), dailyOperation);
    };
    DailyOperationApiService.ngInjectableDef = i0.defineInjectable({ factory: function DailyOperationApiService_Factory() { return new DailyOperationApiService(i0.inject(i1.HttpClient)); }, token: DailyOperationApiService, providedIn: "root" });
    return DailyOperationApiService;
}());
export { DailyOperationApiService };
