import { environment } from '#environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ATRProcedureApiService = /** @class */ (function () {
    //#region 'Angular Life Cycle'
    function ATRProcedureApiService(http) {
        this.http = http;
    }
    //#endregion 'Angular Life Cycle'
    //#region 'Get'
    ATRProcedureApiService.prototype.getATRByStartEndDate = function (startDate, endDate) {
        return this.http.get(environment.apiEndpoint + "/atrProcedure/getByStartEndDate", {
            params: { startDate: startDate, endDate: endDate },
        });
    };
    ATRProcedureApiService.ngInjectableDef = i0.defineInjectable({ factory: function ATRProcedureApiService_Factory() { return new ATRProcedureApiService(i0.inject(i1.HttpClient)); }, token: ATRProcedureApiService, providedIn: "root" });
    return ATRProcedureApiService;
}());
export { ATRProcedureApiService };
