import { environment } from '#environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CasingStringApiService = /** @class */ (function () {
    //#region 'Angular Life Cycle'
    function CasingStringApiService(http) {
        this.http = http;
    }
    //#endregion 'Angular Life Cycle'
    //#region 'General'
    CasingStringApiService.prototype.getURL = function (method) {
        return environment.apiEndpoint + "/CasingString" + method;
    };
    //#endregion 'General'
    //#region 'Get'
    CasingStringApiService.prototype.getCasingDescription = function () {
        return this.http.get(this.getURL("/getCasingDescription"));
    };
    CasingStringApiService.prototype.getWellboreIdCasingDescription = function (wellboreId, casingDescription) {
        return this.http.get(this.getURL("/getByWellboreIdCasingDescription"), {
            params: { wellboreId: wellboreId, casingDescription: casingDescription },
        });
    };
    CasingStringApiService.prototype.getCasingStringApproved = function (casingStringId) {
        return this.http.get(this.getURL("/IsApproved"), {
            params: { casingStringId: casingStringId },
        });
    };
    //#endregion 'Get'
    //#region 'Create'
    CasingStringApiService.prototype.create = function (casingString) {
        return this.http.post(this.getURL("/create"), casingString);
    };
    //#endregion 'Create'
    //#region 'Update'
    CasingStringApiService.prototype.update = function (casingString) {
        return this.http.put(this.getURL("/update"), casingString);
    };
    CasingStringApiService.ngInjectableDef = i0.defineInjectable({ factory: function CasingStringApiService_Factory() { return new CasingStringApiService(i0.inject(i1.HttpClient)); }, token: CasingStringApiService, providedIn: "root" });
    return CasingStringApiService;
}());
export { CasingStringApiService };
