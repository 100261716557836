/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./view-attachments.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./view-attachments.component";
import * as i4 from "@angular/router";
import * as i5 from "../../../services/shared/jobs/offline-activity.service";
import * as i6 from "../../../services/shared/loading-indicator.service";
import * as i7 from "../../../services/api/jobs/attachment-api.service";
import * as i8 from "../../../services/shared/tooltip.service";
var styles_ViewAttachmentsComponent = [i0.styles];
var RenderType_ViewAttachmentsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ViewAttachmentsComponent, data: {} });
export { RenderType_ViewAttachmentsComponent as RenderType_ViewAttachmentsComponent };
function View_ViewAttachmentsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "col-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "img-div mt12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 9, "div", [["class", "mt12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "span", [["class", "wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "mt12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "button", [["class", "btn btn-primary bbottom centerContent fl"], ["id", "btn_download"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.downloadFile(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "i", [["class", "pi pi-cloud-download"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [["class", "ml6"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Download "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.icon; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.Name; _ck(_v, 7, 0, currVal_1); }); }
export function View_ViewAttachmentsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "panel"], ["style", "height: 100%"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Attachments"])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ViewAttachmentsComponent_1)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.files; _ck(_v, 7, 0, currVal_0); }, null); }
export function View_ViewAttachmentsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-view-attachments", [], null, null, null, View_ViewAttachmentsComponent_0, RenderType_ViewAttachmentsComponent)), i1.ɵdid(1, 245760, null, 0, i3.ViewAttachmentsComponent, [i4.ActivatedRoute, i5.OfflineActivityService, i6.LoadingIndicatorService, i7.AttachmentApiService, i8.TooltipService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ViewAttachmentsComponentNgFactory = i1.ɵccf("app-view-attachments", i3.ViewAttachmentsComponent, View_ViewAttachmentsComponent_Host_0, {}, {}, []);
export { ViewAttachmentsComponentNgFactory as ViewAttachmentsComponentNgFactory };
