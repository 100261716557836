import { environment } from '#environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var JobsApiService = /** @class */ (function () {
    //#region 'Angular Life Cycle'
    function JobsApiService(http) {
        this.http = http;
    }
    //#endregion 'Angular Life Cycle'
    //#region 'General'
    JobsApiService.prototype.getURL = function (method) {
        return environment.apiEndpoint + "/job" + method;
    };
    //#endregion 'General'
    //#region 'Get'
    JobsApiService.prototype.getByWellId = function (wellId) {
        return this.http.get(this.getURL("/getByWellId"), {
            params: { wellId: wellId },
        });
    };
    JobsApiService.prototype.getJobByID = function (jobID) {
        return this.http.get(this.getURL("/getByJobId"), {
            params: { jobId: jobID },
        });
    };
    JobsApiService.ngInjectableDef = i0.defineInjectable({ factory: function JobsApiService_Factory() { return new JobsApiService(i0.inject(i1.HttpClient)); }, token: JobsApiService, providedIn: "root" });
    return JobsApiService;
}());
export { JobsApiService };
