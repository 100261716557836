var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Organization, OrganizationUser } from '#models/index';
import { BusinessUnitsApiService, OrganizationApiService, UserApiService } from '#services-api/index';
import { AuthService, LoadingIndicatorService } from '#services-shared/index';
import { OnDestroy, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';
var UserComponent = /** @class */ (function () {
    //#endregion 'Variables'
    //#region 'Angular Life Cycle'
    function UserComponent(authService, userService, organizationSvc, businessUnitsSvc, confirmationService, loadingIndicatorSvc, _message) {
        this.authService = authService;
        this.userService = userService;
        this.organizationSvc = organizationSvc;
        this.businessUnitsSvc = businessUnitsSvc;
        this.confirmationService = confirmationService;
        this.loadingIndicatorSvc = loadingIndicatorSvc;
        this._message = _message;
        this.lstUsers = [];
        this.lstBusinessUnit = [];
        this.lstOrganization = [];
        //? Subscriptions
        this.SUBS$ = new Subscription();
    }
    UserComponent.prototype.ngOnInit = function () {
        this.isExternal = 0;
        this.selectedOrg = new Organization();
        this.loadBusinessUnits();
        this.loadOrganizations();
        this.loadAllUsers();
    };
    UserComponent.prototype.ngOnDestroy = function () {
        this.SUBS$.unsubscribe();
    };
    //#endregion 'Angular Life Cycle'
    //#region 'Load'
    UserComponent.prototype.loadBusinessUnits = function () {
        var _this = this;
        this.loadingIndicatorSvc.show();
        this.SUBS$.add(this.businessUnitsSvc
            .getUserAdminBUs()
            .finally(function () { return _this.loadingIndicatorSvc.hide(); })
            .subscribe(function (data) {
            _this.lstBusinessUnit = data;
            if (_this.lstBusinessUnit.length > 0) {
                _this.selectedBU = _this.lstBusinessUnit[0];
                _this.loadOrganizations();
            }
        }));
    };
    UserComponent.prototype.loadOrganizations = function () {
        var _this = this;
        this.loadingIndicatorSvc.show();
        if (this.selectedBU) {
            this.SUBS$.add(this.organizationSvc
                .getUserAdminOrgs(this.selectedBU.Id)
                .finally(function () { return _this.loadingIndicatorSvc.hide(); })
                .subscribe(function (data) {
                var usrs = data[0].OrganizationUsers.filter(function (usr) { return usr.CAI !== _this.authService.currentUser.CAI; });
                usrs.sort(function (a, b) { return (a.FirstName > b.FirstName ? 1 : b.FirstName > a.FirstName ? -1 : 0); });
                data[0].OrganizationUsers = usrs;
                _this.lstOrganization = data;
                if (_this.lstOrganization.length > 0) {
                    _this.selectedOrg = _this.lstOrganization[0];
                }
            }));
        }
        else {
            this.loadingIndicatorSvc.hide();
            this.lstOrganization = [];
        }
    };
    UserComponent.prototype.loadAllUsers = function () {
        var _this = this;
        this.loadingIndicatorSvc.show();
        this.SUBS$.add(this.userService
            .getAll()
            .finally(function () { return _this.loadingIndicatorSvc.hide(); })
            .subscribe(function (data) {
            _this.lstUsers = data;
        }));
    };
    //#endregion 'Load'
    //#region 'Validations'
    UserComponent.prototype.isInvalidString = function (value) {
        return value === undefined || value == null || value.length === 0;
    };
    //#endregion 'Validations'
    //#region 'General Methods'
    UserComponent.prototype.initializeOrganizationUser = function () {
        var orgUsr = new OrganizationUser();
        orgUsr.OrganizationId = this.selectedOrg.Id;
        orgUsr.FirstName = this.isExternal === 0 ? this.selectedUser.FirstName : this.firstName;
        orgUsr.LastName = this.isExternal === 0 ? this.selectedUser.LastName : this.lastName;
        orgUsr.Email = this.isExternal === 0 ? this.selectedUser.Email : this.email;
        orgUsr.CAI = this.isExternal === 0 ? this.selectedUser.CAI : '';
        orgUsr.IsAdmin = true;
        orgUsr.IsUser = true;
        orgUsr.IsExternal = this.isExternal > 0;
        return orgUsr;
    };
    //#endregion 'General Methods'
    //#region 'CRUD'
    UserComponent.prototype.getUsers = function (event) {
        var _this = this;
        var query = event.query;
        this.SUBS$.add(this.userService.getParticipants(query).subscribe(function (data) {
            _this.users = data;
            _this.users.forEach(function (obj, index) {
                this[index].FullName = this[index].FirstName + " " + this[index].LastName + " / " + this[index].CAI + " / " + this[index].Email;
            }, _this.users);
        }));
    };
    UserComponent.prototype.addUser = function () {
        return __awaiter(this, void 0, void 0, function () {
            var orgUsr, _a, _b;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.loadingIndicatorSvc.show();
                        orgUsr = this.initializeOrganizationUser();
                        _b = (_a = this.SUBS$).add;
                        return [4 /*yield*/, this.organizationSvc
                                .addOrganizationUser(orgUsr)
                                .finally(function () { return _this.loadingIndicatorSvc.hide(); })
                                .subscribe(function (data) {
                                _this.selectedOrg.OrganizationUsers.push(data);
                                _this.firstName = '';
                                _this.lastName = '';
                                _this.email = '';
                                _this.selectedUser = null;
                                _this.showNotification({
                                    key: 'UserNotify',
                                    severity: 'success',
                                    summary: 'Success',
                                    detail: 'User Successfully added',
                                    life: 3000,
                                });
                            }, function (error) {
                                _this.showNotification({
                                    key: 'UserError',
                                    severity: 'error',
                                    summary: 'Error',
                                    detail: "Something went wrong, please refresh the page.",
                                    life: 6000,
                                });
                            })];
                    case 1:
                        _b.apply(_a, [_c.sent()]);
                        return [2 /*return*/];
                }
            });
        });
    };
    UserComponent.prototype.updateOganizationUser = function (orgUsrId) {
        var _this = this;
        this.loadingIndicatorSvc.show();
        var currentUser = this.lstUsers.filter(function (obj) { return obj.CAI == orgUsrId.CAI; });
        var userOrg = this.selectedOrg.OrganizationUsers.find(function (x) { return x.Id === orgUsrId.Id; });
        if (currentUser && currentUser.length > 0) {
            userOrg.IsAdmin = !userOrg.IsAdmin;
            currentUser[0].IsSystemAdmin = userOrg.IsAdmin;
            this.SUBS$.add(this.organizationSvc.updateOrganizationUser(userOrg).subscribe(function () {
                _this.updateUser(currentUser[0]);
            }, function (error) {
                userOrg.IsAdmin = !userOrg.IsAdmin;
                _this.showNotification({
                    key: 'UserError',
                    severity: 'error',
                    summary: 'Error',
                    detail: "Something went wrong, please refresh the page.",
                    life: 6000,
                });
            }));
        }
        else {
            this.showNotification({
                key: 'UserError',
                severity: 'error',
                summary: 'Error',
                detail: "Something went wrong, please refresh the page.",
                life: 6000,
            });
        }
    };
    UserComponent.prototype.updateUser = function (user) {
        var _this = this;
        this.SUBS$.add(this.userService
            .update(user)
            .finally(function () { return _this.loadingIndicatorSvc.hide(); })
            .subscribe(function () { }));
    };
    UserComponent.prototype.removeUser = function (orgUsr) {
        var _this = this;
        var message = "Record will be permanently removed, continue?<br/>";
        this.confirmationService.confirm({
            message: message,
            header: 'Confirm delete',
            accept: function () {
                _this.deleteOrganization(orgUsr);
            },
            reject: function () { },
        });
    };
    UserComponent.prototype.deleteOrganization = function (orgUsr) {
        return __awaiter(this, void 0, void 0, function () {
            var USER, _a, _b;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.loadingIndicatorSvc.show();
                        USER = this.lstUsers.filter(function (obj) { return obj.CAI == orgUsr.CAI; });
                        if (!(USER && USER.length > 0)) return [3 /*break*/, 2];
                        _b = (_a = this.SUBS$).add;
                        return [4 /*yield*/, this.organizationSvc.removeOrganizationUser(orgUsr.Id).subscribe(function () {
                                var userOrgIndex = _this.selectedOrg.OrganizationUsers.findIndex(function (x) { return x.Id === orgUsr.Id; });
                                _this.selectedOrg.OrganizationUsers.splice(userOrgIndex, 1);
                                _this.deleteUser(USER[0]);
                            }, function (error) {
                                _this.showNotification({
                                    key: 'UserError',
                                    severity: 'error',
                                    summary: 'Error',
                                    detail: "Something went wrong, please refresh the page.",
                                    life: 6000,
                                });
                            })];
                    case 1:
                        _b.apply(_a, [_c.sent()]);
                        return [3 /*break*/, 3];
                    case 2:
                        this.showNotification({
                            key: 'UserError',
                            severity: 'error',
                            summary: 'Error',
                            detail: "Something went wrong, please refresh the page.",
                            life: 6000,
                        });
                        _c.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    UserComponent.prototype.deleteUser = function (userID) {
        var _this = this;
        this.SUBS$.add(this.userService
            .removeUser(userID.Id)
            .finally(function () { return _this.loadingIndicatorSvc.hide(); })
            .subscribe(function () { }, function (error) {
            _this.showNotification({
                key: 'UserError',
                severity: 'error',
                summary: 'Error',
                detail: "Something went wrong, please refresh the page.",
                life: 6000,
            });
        }));
    };
    //#endregion 'CRUD'
    //#region 'Notification'
    UserComponent.prototype.showNotification = function (msg) {
        this._message.add({
            key: msg.key && msg.key.length > 0 ? msg.key : 'UserNotify',
            sticky: false,
            closable: false,
            severity: msg.severity,
            summary: msg.summary,
            detail: msg.detail,
            life: msg.life,
        });
    };
    return UserComponent;
}());
export { UserComponent };
