import { environment } from '#environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AttachmentApiService = /** @class */ (function () {
    //#region 'Angular Life Cycle'
    function AttachmentApiService(http) {
        this.http = http;
    }
    //#endregion 'Angular Life Cycle'
    //#region 'General'
    AttachmentApiService.prototype.getURL = function (method) {
        return environment.apiEndpoint + "/offlineActivityAttachment" + method;
    };
    //#endregion 'General'
    //#region 'Get'
    AttachmentApiService.prototype.getByOfflineActivityDateId = function (OADId) {
        return this.http.get(this.getURL("/getByOfflineActivityDateId"), {
            params: { oaDateId: OADId },
        });
    };
    //#endregion 'Get'
    //#region 'Post'
    AttachmentApiService.prototype.create = function (offlineActivityTimelog) {
        return this.http.post(this.getURL("/create"), offlineActivityTimelog);
    };
    AttachmentApiService.prototype.createBulk = function (offlineActivityTimelog) {
        return this.http.post(this.getURL("/createBulk"), offlineActivityTimelog);
    };
    //#endregion 'Post'
    //#region 'Put'
    AttachmentApiService.prototype.update = function (offlineActivityTimelog) {
        return this.http.put(this.getURL("/update"), offlineActivityTimelog);
    };
    AttachmentApiService.prototype.updateBulk = function (offlineActivityTimelog) {
        return this.http.put(this.getURL("/updateBulk"), offlineActivityTimelog);
    };
    //#endregion 'Put'
    //#region 'Delete'
    AttachmentApiService.prototype.delete = function (offlineActivityTimelog) {
        return this.http.put(this.getURL("/delete"), offlineActivityTimelog);
    };
    //#endregion 'Delete'
    //#region 'SharePoint'
    AttachmentApiService.prototype.UploadFiles = function (files, OADId) {
        return this.http.post(this.getURL("/uploadFiles") + "?OADId=" + OADId, files);
    };
    AttachmentApiService.prototype.DeleteAzureFile = function (attach) {
        return this.http.put(this.getURL("/deleteAzureFile"), attach);
    };
    AttachmentApiService.prototype.DownloadAttachment = function (name, type, size) {
        return this.http.get(this.getURL("/downloadAzureAttachment"), {
            params: { FileName: name, FileType: type, Size: size },
            responseType: 'blob',
            observe: 'response',
        });
    };
    AttachmentApiService.prototype.DownloadAllAttachments = function (OADId) {
        return this.http.get(this.getURL("/downloadAllAttachment"), {
            params: { OADId: OADId },
        });
    };
    AttachmentApiService.ngInjectableDef = i0.defineInjectable({ factory: function AttachmentApiService_Factory() { return new AttachmentApiService(i0.inject(i1.HttpClient)); }, token: AttachmentApiService, providedIn: "root" });
    return AttachmentApiService;
}());
export { AttachmentApiService };
