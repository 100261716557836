import { environment } from '#environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var OfflineActivityDatesApiService = /** @class */ (function () {
    //#region 'Angular Life Cycle'
    function OfflineActivityDatesApiService(http) {
        this.http = http;
    }
    //#endregion 'Angular Life Cycle'
    //#region 'General'
    OfflineActivityDatesApiService.prototype.getURL = function (method) {
        return environment.apiEndpoint + "/offlineActivityDates" + method;
    };
    //#endregion 'General'
    //#region 'Get'
    OfflineActivityDatesApiService.prototype.getByActivityId = function (oaId) {
        return this.http.get(this.getURL("/getByActivityId"), {
            params: { oaId: oaId },
        });
    };
    //#endregion 'Get'
    //#region 'Post'
    OfflineActivityDatesApiService.prototype.create = function (offlineActivityDates) {
        return this.http.post(this.getURL("/create"), offlineActivityDates);
    };
    //#endregion 'Post'
    //#region 'Put'
    OfflineActivityDatesApiService.prototype.update = function (offlineActivityDates) {
        return this.http.put(this.getURL("/update"), offlineActivityDates);
    };
    //#endregion 'Put'
    //#region 'Delete'
    OfflineActivityDatesApiService.prototype.delete = function (offlineActivityDates) {
        return this.http.put(this.getURL("/delete"), offlineActivityDates);
    };
    OfflineActivityDatesApiService.ngInjectableDef = i0.defineInjectable({ factory: function OfflineActivityDatesApiService_Factory() { return new OfflineActivityDatesApiService(i0.inject(i1.HttpClient)); }, token: OfflineActivityDatesApiService, providedIn: "root" });
    return OfflineActivityDatesApiService;
}());
export { OfflineActivityDatesApiService };
