import { environment } from '#environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var BusinessUnitsApiService = /** @class */ (function () {
    //#region 'Angular Life Cycle'
    function BusinessUnitsApiService(http) {
        this.http = http;
    }
    //#endregion 'Angular Life Cycle'
    //#region 'General'
    BusinessUnitsApiService.prototype.getURL = function (method) {
        return environment.apiEndpoint + "/businessUnit" + method;
    };
    //#endregion 'General'
    //#region 'Get'
    BusinessUnitsApiService.prototype.getUserAdminBUs = function () {
        return this.http.get(this.getURL("/currentUserAdminBUs"));
    };
    BusinessUnitsApiService.prototype.getUserBUs = function () {
        return this.http.get(this.getURL("/currentUserBUs"));
    };
    //#endregion 'Get'
    //#region 'Create'
    BusinessUnitsApiService.prototype.addBusinessUnit = function (businessUnit) {
        return this.http.post(this.getURL(), businessUnit);
    };
    BusinessUnitsApiService.ngInjectableDef = i0.defineInjectable({ factory: function BusinessUnitsApiService_Factory() { return new BusinessUnitsApiService(i0.inject(i1.HttpClient)); }, token: BusinessUnitsApiService, providedIn: "root" });
    return BusinessUnitsApiService;
}());
export { BusinessUnitsApiService };
