/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../loading-indicator/loading-indicator.component.ngfactory";
import * as i3 from "../loading-indicator/loading-indicator.component";
import * as i4 from "../../services/shared/loading-indicator.service";
import * as i5 from "@angular/router";
import * as i6 from "../header/header.component.ngfactory";
import * as i7 from "../header/header.component";
import * as i8 from "../../services/shared/auth.service";
import * as i9 from "@angular/common";
import * as i10 from "../../services/shared/tooltip.service";
import * as i11 from "../footer/footer.component.ngfactory";
import * as i12 from "../footer/footer.component";
import * as i13 from "./app.component";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "module box-shadow text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [["style", "margin-bottom: 0px"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["NOTICE TO USERS:"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" This Chevron software product is for use by authorized users only. To the extent permitted by law, by using this software product you acknowledge and consent to the monitoring, access (including the decryption and inspection of selected encrypted internet traffic), use or disclosure by Chevron of any information generated, received or stored on this system/device and waive any right or expectation of privacy in connection with your use. Unauthorized and/or improper use of this software product in violation of Chevron corporate policies will be not tolerated and may result in disciplinary action, including termination. "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["class", "btn btn-light"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.iAccept() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["I Agree"]))], null, null); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["style", "min-height: 500px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-loading-indicator", [], null, null, null, i2.View_LoadingIndicatorComponent_0, i2.RenderType_LoadingIndicatorComponent)), i1.ɵdid(3, 114688, null, 0, i3.LoadingIndicatorComponent, [i4.LoadingIndicatorService], null, null), (_l()(), i1.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 3, 0); _ck(_v, 5, 0); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, i6.View_HeaderComponent_0, i6.RenderType_HeaderComponent)), i1.ɵdid(1, 8503296, null, 0, i7.HeaderComponent, [i8.AuthService, i5.Router, i9.Location, i10.TooltipService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["id", "body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "main", [["id", "main-content"], ["role", "main"], ["tabindex", "-1"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(5, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(7, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-footer", [], null, null, null, i11.View_FooterComponent_0, i11.RenderType_FooterComponent)), i1.ɵdid(9, 114688, null, 0, i12.FooterComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = _co.showDisclaimer; _ck(_v, 5, 0, currVal_0); var currVal_1 = !_co.showDisclaimer; _ck(_v, 7, 0, currVal_1); _ck(_v, 9, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i13.AppComponent, [i8.AuthService, i5.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i13.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
