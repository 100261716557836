var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OfflineActivityAttachment, } from '#models/index';
import { AttachmentApiService } from '#services-api/index';
import { OfflineActivityApiService } from '#services-api/jobs/offline-activity-api.service';
import { OfflineActivityDatesApiService } from '#services-api/jobs/offline-activity-dates-api.service';
import { OfflineActivityTimelogApiService } from '#services-api/jobs/offline-activity-timelog-api.service';
import { LoadingIndicatorService } from '#services-shared/loading-indicator.service';
import { TooltipService } from '#services-shared/tooltip.service';
import { OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
import { DailyOperationService } from './daily-operation.service';
import * as i0 from "@angular/core";
import * as i1 from "../../api/jobs/offline-activity-api.service";
import * as i2 from "../../api/jobs/offline-activity-dates-api.service";
import * as i3 from "../../api/jobs/offline-activity-timelog-api.service";
import * as i4 from "../../api/jobs/attachment-api.service";
import * as i5 from "../loading-indicator.service";
import * as i6 from "./daily-operation.service";
import * as i7 from "../tooltip.service";
//? OA = Offline Activity
//? OAD = Offline Activity Dates
//? OAT = Offline Activity Timelog
var OfflineActivityService = /** @class */ (function () {
    //#endregion 'Variables'
    //#region 'Angular Life Cycle'
    function OfflineActivityService(_OA, _OAD, _OAT, _AttachmentAPI, _loader, _sectionDailyOperation, _tooltip) {
        this._OA = _OA;
        this._OAD = _OAD;
        this._OAT = _OAT;
        this._AttachmentAPI = _AttachmentAPI;
        this._loader = _loader;
        this._sectionDailyOperation = _sectionDailyOperation;
        this._tooltip = _tooltip;
        this.TMLSysSeq = 0;
        //? Offline Activity
        this.selectedOAId = 0;
        this.OAList = [];
        //? Offline Activity Dates
        this.OADTime = 0;
        this.selectedOADId = 0;
        this.showOADTimeError = false;
        this.OADList = [];
        this.OADDataDates = [];
        //? Offline Activity Timelog
        this.selectedOATId = 0;
        this.currentTimelogAvailable = 0;
        this.TMLTime = 0;
        this.OATList = [];
        //? Offline Activity Attachments
        this.showViewFiles = false;
        this.downloadedFiles = [];
        this.spFilesList = [];
        this.OAAList = [];
        //? Notifications
        this.notifyOA = [];
        this.notifyTMLOA = [];
        //? Subscriptions
        this.SUBS$ = new Subscription();
    }
    OfflineActivityService.prototype.ngOnDestroy = function () {
        this.SUBS$.unsubscribe();
    };
    //#endregion 'Angular Life Cycle'
    //? Offline Activity
    //#region 'Loads -- Offline Activity'
    OfflineActivityService.prototype.loadOA = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            try {
                _this._loader.show();
                _this.OAList = [];
                _this.resetAll();
                var OA$ = _this._OA
                    .getByJobId(_this.jobID)
                    .finally(function () {
                    _this._loader.hide();
                })
                    .subscribe(function (OA) {
                    if (OA && OA.length > 0) {
                        OA.sort(function (a, b) {
                            return a.CreationDate.Value > b.CreationDate.Value ? 1 : b.CreationDate.Value > a.CreationDate.Value ? -1 : 0;
                        });
                        OA[0].selected = true;
                        _this.selectedOAId = 0;
                        _this.OAList = OA.slice();
                        resolve(true);
                    }
                });
                _this.SUBS$.add(OA$);
            }
            catch (err) {
                reject(Error(err));
            }
        });
    };
    //#endregion 'Loads -- Offline Activity'
    //#region 'General Methods -- Offline Activity'
    OfflineActivityService.prototype.changeOASelection = function (idx) {
        for (var i = 0; i < this.OAList.length; i++) {
            if (idx !== i)
                this.OAList[i].selected = false;
            if (idx === i)
                this.OAList[i].selected = true;
        }
    };
    //#endregion 'General Methods -- Offline Activity'
    //#region 'Validations -- Offline Activity'
    OfflineActivityService.prototype.OAHaveData = function () {
        return this.OAList && this.OAList.length === 0 ? true : false;
    };
    //#endregion 'Validations -- Offline Activity'
    //#region 'CRUD -- Offline Activity'
    OfflineActivityService.prototype.createOA = function (dto) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            try {
                _this._loader.show();
                _this.SUBS$.add(_this._OA
                    .create(dto)
                    .finally(function () {
                    _this._loader.hide();
                })
                    .subscribe(function () {
                    resolve({
                        key: 'OANotify',
                        severity: 'success',
                        summary: 'Correct!',
                        detail: 'Offline Activity Created Successfully',
                    });
                }, function (e) {
                    console.error(e.message);
                    reject({
                        key: 'OAError',
                        severity: 'error',
                        summary: 'We got a problem!',
                        detail: 'We had a problem while creating, please refresh the page and try again.',
                        life: 6000,
                    });
                }));
            }
            catch (err) {
                reject(Error(err));
            }
        });
    };
    OfflineActivityService.prototype.updateOA = function (dto) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            try {
                _this._loader.show();
                _this.SUBS$.add(_this._OA
                    .update(dto)
                    .finally(function () {
                    _this._loader.hide();
                })
                    .subscribe(function () {
                    resolve({
                        key: 'OANotify',
                        severity: 'success',
                        summary: 'Correct!',
                        detail: 'Offline Activity Updated Successfully',
                    });
                }, function (e) {
                    console.error(e.message);
                    reject({
                        key: 'OAError',
                        severity: 'error',
                        summary: 'We got a problem!',
                        detail: 'We had a problem while updating, please refresh the page and try again.',
                        life: 6000,
                    });
                }));
            }
            catch (err) {
                reject(Error(err));
            }
        });
    };
    //#endregion 'CRUD -- Offline Activity'
    //? Offline Activity Dates
    //#region 'Loads -- Offline Activity Dates'
    OfflineActivityService.prototype.getOADByOAId = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            try {
                _this._loader.show();
                _this.changeOASelection(_this.selectedOAId);
                _this.resetAll();
                var OAD$ = _this._OAD
                    .getByActivityId(_this.OAList[_this.selectedOAId].IdRec.Value)
                    .subscribe(function (OAD) {
                    if (OAD && OAD.length > 0) {
                        OAD.sort(function (a, b) {
                            if (a.StartDate.Value === '' && b.StartDate.Value !== '') {
                                return 1;
                            }
                            else if (a.StartDate.Value !== '' && b.StartDate.Value === '') {
                                return -1;
                            }
                            else {
                                if (new Date(a.StartDate.Value) > new Date(b.StartDate.Value)) {
                                    return -1;
                                }
                                else if (new Date(a.StartDate.Value) < new Date(b.StartDate.Value)) {
                                    return 1;
                                }
                                else {
                                    return 0;
                                }
                            }
                        });
                        _this.selectedOADId = 0;
                        _this.OADList = OAD.slice();
                        _this._loader.hide();
                        _this.changeOADSelection();
                        resolve(true);
                    }
                    else {
                        _this._loader.hide();
                        resolve(true);
                    }
                });
                _this.SUBS$.add(OAD$);
            }
            catch (err) {
                reject(Error(err));
            }
        });
    };
    //#endregion 'Loads -- Offline Activity Dates'
    //#region 'General Methods -- Offline Activity Dates'
    OfflineActivityService.prototype.changeOADSelection = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            try {
                for (var i = 0; i < _this.OADList.length; i++) {
                    if (_this.selectedOADId !== i)
                        _this.OADList[i].selected = false;
                    if (_this.selectedOADId === i) {
                        _this.setDataToValidateDates(true);
                        _this.OADList[i].selected = true;
                        _this.OADObject = _this.OADList[i];
                        _this.OADId = _this.OADList[i].IdRec.Value;
                        var START = _this.OADObject.StartDate.Value;
                        var END = _this.OADObject.EndDate.Value;
                        _this.calculateRigTime(START, END);
                        _this.getOATByOADId();
                        _this.getOAAByOADId();
                        _this.resetOAA();
                        _this.resetNotifications();
                        _this.initializeEmailObj();
                        resolve(true);
                    }
                }
            }
            catch (err) {
                reject(Error(err));
            }
        });
    };
    OfflineActivityService.prototype.setDataToValidateDates = function (splice) {
        this.OADDataDates = this._tooltip.deepClone(this.OADList);
        if (splice) {
            if (!this.isSelectedOADDatesAsToday()) {
                var tempIndex = this.OADDataDates.indexOf(this.OADDataDates[this.selectedOADId]);
                this.OADDataDates.splice(tempIndex, 1);
            }
        }
    };
    OfflineActivityService.prototype.checkDatesExist = function (_date, isStart) {
        var DATE = moment(_date);
        for (var _i = 0, _a = this.OADDataDates; _i < _a.length; _i++) {
            var element = _a[_i];
            var dateExist = moment(DATE).isBetween(element.StartDate.Value, element.EndDate.Value) ||
                moment(DATE).isSame(isStart ? element.StartDate.Value : element.EndDate.Value);
            if (dateExist && this.OADList.length > 1) {
                return dateExist;
            }
        }
        return false;
    };
    OfflineActivityService.prototype.calculateRigTime = function (start, end, self) {
        if (self) {
            start = this.OADObject.StartDate.Value;
            end = this.OADObject.EndDate.Value;
        }
        this.OADTime = 0;
        this.showOADTimeError = false;
        this.OADTime = this.getOADTime(start, end);
        if (this.OADTime > 24) {
            this.showOADTimeError = true;
        }
    };
    OfflineActivityService.prototype.getOADTime = function (start, end) {
        var DURATION = moment.duration(moment(end).diff(moment(start)));
        var HOURS = DURATION.asHours();
        return HOURS;
    };
    OfflineActivityService.prototype.resetOAD = function () {
        this.OADTime = 0;
        this.showOADTimeError = false;
        this.OADObject = undefined;
        this.OADDeleteObject = undefined;
        this.OADList = [];
        this.OADDataDates = [];
    };
    //#endregion 'General Methods -- Offline Activity Dates'
    //#region 'Validations -- Offline Activity Dates'
    OfflineActivityService.prototype.OADObjectExist = function () {
        return this.OADObject ? true : false;
    };
    OfflineActivityService.prototype.OADHaveData = function () {
        return this.OADList && this.OADList.length > 0 ? true : false;
    };
    OfflineActivityService.prototype.isSelectedOADDatesAsToday = function () {
        var TODAY_START = moment().format('MM/DD/YYYY 00:00');
        var OAD_START = moment(this.OADDataDates[this.selectedOADId].StartDate.Value).format('MM/DD/YYYY 00:00');
        return moment(TODAY_START).isSame(OAD_START) ? true : false;
    };
    //#endregion 'Validations -- Offline Activity Dates'
    //#region 'CRUD -- Offline Activity Dates'
    OfflineActivityService.prototype.createOAD = function (dto) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this._loader.show();
                this.SUBS$.add(this._OAD
                    .create(dto)
                    .finally(function () {
                    _this._loader.hide();
                    _this.getOADByOAId();
                })
                    .subscribe(function () {
                    resolve({
                        key: 'OANotify',
                        severity: 'success',
                        summary: 'Correct!',
                        detail: 'Offline Activity Date Created Successfully',
                    });
                }, function (e) {
                    console.error(e.message);
                    reject({
                        key: 'OAError',
                        severity: 'error',
                        summary: 'We got a problem!',
                        detail: 'We had a problem while creating, please refresh the page and try again.',
                        life: 6000,
                    });
                }));
                return [2 /*return*/];
            });
        }); });
    };
    OfflineActivityService.prototype.updateOAD = function (dto) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this._loader.show();
                this.SUBS$.add(this._OAD
                    .update(dto)
                    .finally(function () {
                    _this._loader.hide();
                    _this.getOADByOAId();
                })
                    .subscribe(function () {
                    resolve({
                        key: 'OANotify',
                        severity: 'success',
                        summary: 'Correct!',
                        detail: 'Offline Activity Date Updated Successfully',
                    });
                }, function (e) {
                    console.error(e.message);
                    reject({
                        key: 'OAError',
                        severity: 'error',
                        summary: 'We got a problem!',
                        detail: 'We had a problem while updating, please refresh the page and try again.',
                        life: 6000,
                    });
                }));
                return [2 /*return*/];
            });
        }); });
    };
    OfflineActivityService.prototype.deleteOAD = function () {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this._loader.show();
                this.SUBS$.add(this._OAD
                    .delete(this.OADDeleteObject)
                    .finally(function () {
                    _this._loader.hide();
                    _this.getOADByOAId();
                })
                    .subscribe(function () {
                    _this.selectedOADId = 0;
                    resolve({
                        key: 'OANotify',
                        severity: 'success',
                        summary: 'Correct!',
                        detail: 'Offline Activity Date Deleted Successfully',
                    });
                }, function (e) {
                    console.error(e.message);
                    reject({
                        key: 'OAError',
                        severity: 'error',
                        summary: 'We got a problem!',
                        detail: 'We had a problem while deleting, please refresh the page and try again.',
                        life: 6000,
                    });
                }));
                return [2 /*return*/];
            });
        }); });
    };
    //#endregion 'CRUD -- Offline Activity Dates'
    //? Offline Activity Timelog
    //#region 'Loads -- Offline Activity Timelog'
    OfflineActivityService.prototype.getOATByOADId = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            try {
                _this._loader.show();
                _this.resetOAT();
                var OAT$ = _this._OAT
                    .getByOfflineActivityDateId(_this.OADList[_this.selectedOADId].IdRec.Value)
                    .pipe(map(function (dt) {
                    dt.map(function (tml) {
                        _this.TMLTime = _this.TMLTime + Number(tml.Duration.Value);
                        tml.EndDepth.Value = tml.EndDepth.Value.replace(/,/g, '');
                        tml.StartDepth.Value = tml.StartDepth.Value.replace(/,/g, '');
                        return tml;
                    });
                    return dt;
                }))
                    .subscribe(function (OAT) {
                    _this.getAvailableTime(false);
                    if (OAT && OAT.length > 0) {
                        OAT.sort(function (a, b) {
                            return Number(a.SysSeq.Value) > Number(b.SysSeq.Value)
                                ? 1
                                : Number(b.SysSeq.Value) > Number(a.SysSeq.Value)
                                    ? -1
                                    : 0;
                        });
                        _this.OATList = OAT.slice();
                        _this._loader.hide();
                        resolve(true);
                    }
                    else {
                        _this._loader.hide();
                        resolve(true);
                    }
                });
                _this.SUBS$.add(OAT$);
            }
            catch (err) {
                _this._loader.hide();
                reject(Error(err));
            }
        });
    };
    //#endregion 'Loads -- Offline Activity Timelog'
    //#region 'General Methods -- Offline Activity Timelog'
    OfflineActivityService.prototype.validateAvailableDuration = function (duration, isEdit) {
        //? Validate if duration don't exceeds the time available
        this.getAvailableTime(isEdit);
        var AVAILABLE = this.currentTimelogAvailable - duration;
        if (isEdit)
            this.currentTimelogAvailable = AVAILABLE;
        if (AVAILABLE < 0) {
            return true;
        }
        else {
            return false;
        }
    };
    OfflineActivityService.prototype.getAvailableTime = function (isEdit) {
        this.currentTimelogAvailable = 0;
        if (isEdit) {
            this.currentTimelogAvailable = this.OADTime - (this.TMLTime - Number(this.OATObject.Duration.Value));
        }
        else {
            this.currentTimelogAvailable = this.OADTime - this.TMLTime;
        }
    };
    OfflineActivityService.prototype.resetOAT = function () {
        this.currentTimelogAvailable = 0;
        this.TMLTime = 0;
        this.OATObject = undefined;
        this.OATDeleteObject = undefined;
        this.OATList = [];
    };
    OfflineActivityService.prototype.getLastSequenceNumber = function () {
        var LENGHT = this.OATList && this.OATList.length === 0 ? 0 : this.OATList.length;
        if (LENGHT === 0)
            return '1';
        if (LENGHT > 0)
            return (Number(this.OATList[LENGHT - 1].SysSeq.Value) + 1).toString();
    };
    //#endregion 'General Methods -- Offline Activity Timelog'
    //#region 'Validations -- Offline Activity Timelog'
    OfflineActivityService.prototype.isDOPFull = function () {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (this.TMLTime >= 24) {
                    reject({
                        key: 'OAError',
                        severity: 'warn',
                        summary: 'Timelog Full',
                        detail: 'Timelog time is full now (24 hrs only), please verify.',
                        life: 6000,
                    });
                }
                else {
                    resolve(false);
                }
                return [2 /*return*/];
            });
        }); });
    };
    OfflineActivityService.prototype.getInactive = function (data) {
        if (typeof data === 'string') {
            if (data === '' || data === 'N') {
                return false;
            }
            else {
                return true;
            }
        }
        if (typeof data === 'boolean') {
            if (data) {
                return 'Y';
            }
            else {
                return 'N';
            }
        }
        return null;
    };
    OfflineActivityService.prototype.OATHaveData = function () {
        return this.OATList && this.OATList.length > 0 ? true : false;
    };
    //#endregion 'Validations -- Offline Activity Timelog'
    //#region 'Re-Order'
    OfflineActivityService.prototype.reOrderTMLRecord = function (old_index, new_index) {
        while (old_index < 0) {
            old_index += this.OATList.length;
        }
        while (new_index < 0) {
            new_index += this.OATList.length;
        }
        if (new_index >= this.OATList.length) {
            var k = new_index - this.OATList.length + 1;
            while (k--) {
                this.OATList.push(undefined);
            }
        }
        this.OATList.splice(new_index, 0, this.OATList.splice(old_index, 1)[0]);
    };
    //#endregion 'Re-Order'
    //#region 'CRUD -- Offline Activity Timelog'
    OfflineActivityService.prototype.createOAT = function (dto) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this._loader.show();
                dto.SysSeq.Value = this.getLastSequenceNumber();
                this.SUBS$.add(this._OAT
                    .create(dto)
                    .finally(function () {
                    _this._loader.hide();
                    _this.getOATByOADId();
                })
                    .subscribe(function () {
                    resolve({
                        key: 'TMLOANotify',
                        severity: 'success',
                        summary: 'Correct!',
                        detail: 'Offline Activity Timelog Created Successfully',
                    });
                }, function (e) {
                    console.error(e.message);
                    reject({
                        key: 'TMLOAError',
                        severity: 'error',
                        summary: 'We got a problem!',
                        detail: 'We had a problem while creating, please refresh the page and try again.',
                        life: 6000,
                    });
                }));
                return [2 /*return*/];
            });
        }); });
    };
    OfflineActivityService.prototype.updateOAT = function (dto) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this._loader.show();
                this.SUBS$.add(this._OAT
                    .update(dto)
                    .finally(function () {
                    _this._loader.hide();
                    _this.getOATByOADId();
                })
                    .subscribe(function () {
                    resolve({
                        key: 'TMLOANotify',
                        severity: 'success',
                        summary: 'Correct!',
                        detail: 'Offline Activity Timelog Updated Successfully',
                    });
                }, function (e) {
                    console.error(e.message);
                    reject({
                        key: 'TMLOANotify',
                        severity: 'error',
                        summary: 'We got a problem!',
                        detail: 'We had a problem while updating, please refresh the page and try again.',
                        life: 6000,
                    });
                }));
                return [2 /*return*/];
            });
        }); });
    };
    OfflineActivityService.prototype.updateOATBulk = function (dto) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this._loader.show();
                this.SUBS$.add(this._OAT
                    .updateBulk(dto)
                    .finally(function () {
                    _this._loader.hide();
                    _this.getOATByOADId();
                })
                    .subscribe(function () {
                    resolve({
                        key: 'TMLOANotify',
                        severity: 'success',
                        summary: 'Correct!',
                        detail: 'Offline Activity Timelogs Updated Successfully',
                    });
                }, function (e) {
                    console.error(e.message);
                    reject({
                        key: 'TMLOANotify',
                        severity: 'error',
                        summary: 'We got a problem!',
                        detail: 'We had a problem while updating, please refresh the page and try again.',
                        life: 6000,
                    });
                }));
                return [2 /*return*/];
            });
        }); });
    };
    OfflineActivityService.prototype.deleteOAT = function () {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this._loader.show();
                this.SUBS$.add(this._OAT
                    .delete(this.OATDeleteObject)
                    .finally(function () {
                    _this._loader.hide();
                    _this.selectedOADId = 0;
                    _this.changeOADSelection();
                })
                    .subscribe(function () {
                    resolve({
                        key: 'OANotify',
                        severity: 'success',
                        summary: 'Correct!',
                        detail: 'Offline Activity Timelog Deleted Successfully',
                    });
                }, function (e) {
                    console.error(e.message);
                    reject({
                        key: 'OAError',
                        severity: 'error',
                        summary: 'We got a problem!',
                        detail: 'We had a problem while deleting, please refresh the page and try again.',
                        life: 6000,
                    });
                }));
                return [2 /*return*/];
            });
        }); });
    };
    //#endregion 'CRUD -- Offline Activity Timelog'
    //? Offline Activity Attachments
    //#region 'Loads -- Offline Activity Attachments'
    OfflineActivityService.prototype.getOAAByOADId = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            try {
                _this._loader.show();
                _this.spFilesList = [];
                var OAA$ = _this._AttachmentAPI
                    .getByOfflineActivityDateId(_this.OADId)
                    .subscribe(function (OAA) {
                    _this.getAvailableTime(false);
                    if (OAA && OAA.length > 0) {
                        OAA.forEach(function (att) {
                            var COMMENT = att.Comment.Value.split('|');
                            var SF = {
                                _url: COMMENT.length > 0 ? COMMENT[0] : '',
                                name: att.Description.Value,
                                nameToShow: att.Name.Value,
                                idRec: att.IdRec.Value,
                                imgType: att.EmailCAI.Value,
                                size: COMMENT.length > 0 ? COMMENT[1] : '',
                            };
                            _this.spFilesList.push(SF);
                        });
                        _this.OAAList = OAA.slice();
                        _this.spFilesList.sort(function (a, b) { return (a.name > b.name ? 1 : b.name > a.name ? -1 : 0); });
                        _this.OAAList.sort(function (a, b) { return (a.Name.Value > b.Name.Value ? 1 : b.Name.Value > a.Name.Value ? -1 : 0); });
                        _this._loader.hide();
                        resolve(true);
                    }
                    else {
                        _this._loader.hide();
                        resolve(true);
                    }
                });
                _this.SUBS$.add(OAA$);
            }
            catch (err) {
                _this._loader.hide();
                reject(Error(err));
            }
        });
    };
    //#endregion 'Loads -- Offline Activity Attachments'
    //#region 'General Methods -- Offline Activity Attachments'
    OfflineActivityService.prototype.resetOAA = function () {
        this.OAAList = [];
        this.spFilesList = [];
    };
    //#endregion 'General Methods -- Offline Activity Attachments'
    //#region 'Validations -- Offline Activity Attachment'
    OfflineActivityService.prototype.OAAHaveData = function () {
        return this.OAAList && this.OAAList.length > 0 ? true : false;
    };
    OfflineActivityService.prototype.SPFHaveData = function () {
        return this.spFilesList && this.spFilesList.length > 0 ? true : false;
    };
    OfflineActivityService.prototype.isFileAlreadyDownloaded = function (name) {
        var EXIST = this.downloadedFiles.filter(function (obj) { return obj.fullName === name; });
        return EXIST && EXIST.length > 0 ? true : false;
    };
    //#endregion 'Validations -- Offline Activity Attachment'
    //#region 'CRUD -- Offline Activity Attachment'
    OfflineActivityService.prototype.deleteOAA = function (index) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var DTO;
            var _this = this;
            return __generator(this, function (_a) {
                this._loader.show();
                this.closeViewFiles();
                DTO = this.OAAList[index];
                this.SUBS$.add(this._AttachmentAPI
                    .delete(DTO)
                    .finally(function () {
                    _this._loader.hide();
                    _this.selectedOADId = 0;
                })
                    .subscribe(function () {
                    resolve(DTO);
                }, function (e) {
                    console.error(e.message);
                    reject({
                        key: 'TMLOAError',
                        severity: 'error',
                        summary: 'We got a problem!',
                        detail: 'We had a problem while deleting, please refresh the page and try again.',
                        life: 6000,
                    });
                    _this._loader.hide();
                }));
                return [2 /*return*/];
            });
        }); });
    };
    OfflineActivityService.prototype.uploadFilesToAzure = function (FDATA) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this._loader.show();
                this.SUBS$.add(this._AttachmentAPI
                    .UploadFiles(FDATA, this.OADId)
                    .finally(function () {
                    _this._loader.hide();
                })
                    .subscribe(function (res) {
                    _this.spFilesList.map(function (spf) {
                        var _URL = res.filter(function (obj) { return obj.includes(spf.name); });
                        if (_URL && _URL.length > 0) {
                            spf._url = _URL[0] + "|" + spf.size;
                            return spf;
                        }
                    });
                    _this._loader.hide();
                    resolve(true);
                }, function () {
                    _this._loader.hide();
                    reject({
                        key: 'TMLOAError',
                        severity: 'error',
                        summary: 'We got a problem!',
                        detail: 'We had a problem while uploading files, please contact you system admin.',
                        life: 6000,
                    });
                }));
                return [2 /*return*/];
            });
        }); });
    };
    OfflineActivityService.prototype.uploadUrlsToWellView = function () {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this._loader.show();
                this.SUBS$.add(this._AttachmentAPI.createBulk(this.initWellViewUrls()).subscribe(function () {
                    _this._loader.hide();
                    _this.getOAAByOADId();
                    resolve({
                        key: 'TMLOANotify',
                        severity: 'success',
                        summary: 'Correct!',
                        detail: 'Attachments uploaded to WellView Successfully',
                    });
                }, function () {
                    _this._loader.hide();
                    reject({
                        key: 'TMLOAError',
                        severity: 'error',
                        summary: 'We got a problem!',
                        detail: 'We had a problem while uploading to WellView, please contact you system admin.',
                        life: 6000,
                    });
                }));
                return [2 /*return*/];
            });
        }); });
    };
    OfflineActivityService.prototype.initWellViewUrls = function () {
        var _this = this;
        var OAA = [];
        this.spFilesList.forEach(function (spf) {
            var DTO = new OfflineActivityAttachment();
            DTO.OADId.Value = _this.OADId;
            DTO.WellId.Value = _this.wellId;
            DTO.Description.Value = spf.name;
            DTO.Name.Value = spf.nameToShow;
            DTO.Comment.Value = spf._url;
            DTO.EmailCAI.Value = spf.imgType;
            DTO.ReferenceId.Value = _this._sectionDailyOperation.wellName;
            OAA.push(DTO);
        });
        return OAA;
    };
    OfflineActivityService.prototype.deleteFromAzure = function (dto) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this._loader.show();
                this.SUBS$.add(this._AttachmentAPI.DeleteAzureFile(this.getAZDTO(dto)).subscribe(function () {
                    _this.spFilesList.length === 0 ? _this.closeViewFiles() : _this.openViewFiles();
                    _this._loader.hide();
                    resolve({
                        key: 'TMLOANotify',
                        severity: 'success',
                        summary: 'Correct!',
                        detail: 'Offline Activity Attachment Deleted Successfully',
                    });
                }, function (e) {
                    _this._loader.hide();
                    console.error(e.message);
                    reject({
                        key: 'TMLOAError',
                        severity: 'error',
                        summary: 'We got a problem!',
                        detail: 'We had a problem while deleting files, please refresh the page and try again.',
                        life: 6000,
                    });
                }));
                return [2 /*return*/];
            });
        }); });
    };
    OfflineActivityService.prototype.getAZDTO = function (dto) {
        return {
            FileName: dto.Description.Value,
            FileNameDisplay: dto.Name.Value,
        };
    };
    OfflineActivityService.prototype.downloadFile = function (index) {
        var _this = this;
        this._loader.show();
        var FILE = this.spFilesList[index];
        if (!this.isFileAlreadyDownloaded(FILE.name)) {
            this.SUBS$.add(this._AttachmentAPI.DownloadAttachment(FILE.name, FILE.imgType, FILE.size).subscribe(function (dto) {
                var SIZE = _this._tooltip.getFileSize(dto.body.size);
                var blobData = new Blob([dto.body], { type: FILE.imgType });
                if (SIZE > 2) {
                    //? File is too big, we download it better
                    _this.addToDownloadedFiles(FILE, undefined, SIZE, dto.body.size, blobData);
                    _this._tooltip.downloadBlob(blobData, FILE.nameToShow);
                    _this._loader.hide();
                }
                else {
                    var reader = new FileReader();
                    reader.readAsArrayBuffer(blobData);
                    reader.onload = function (e) {
                        var arrayBuffer = e.target['result'];
                        var IMG = _this._tooltip.convertToBase64(arrayBuffer);
                        _this.addToDownloadedFiles(FILE, IMG, SIZE, dto.body.size, blobData);
                        if (IMG) {
                            _this.showImage(FILE.imgType, IMG);
                        }
                        else {
                            _this._tooltip.downloadBlob(blobData, FILE.nameToShow);
                        }
                        _this._loader.hide();
                    };
                }
            }));
        }
        else {
            var IMG = this.downloadedFiles.filter(function (obj) { return obj.fullName === FILE.name; });
            if (!IMG[0].data) {
                this._tooltip.downloadBlob(IMG[0].blobData, IMG[0].name);
            }
            else {
                this.showImage(IMG[0].type, IMG[0].data);
            }
            this._loader.hide();
        }
    };
    OfflineActivityService.prototype.addToDownloadedFiles = function (File, img, mb, size, blobData) {
        this.downloadedFiles.push({
            fullName: File.name,
            name: File.nameToShow,
            type: File.imgType,
            data: img,
            blobData: img ? undefined : blobData,
            mb: mb,
            size: size,
        });
    };
    OfflineActivityService.prototype.getAllAttachments = function (OADId) {
        var _this = this;
        this._loader.show();
        return new Promise(function (resolve, reject) {
            try {
                _this.SUBS$.add(_this._AttachmentAPI
                    .DownloadAllAttachments(OADId)
                    .finally(function () {
                    _this._loader.hide();
                })
                    .subscribe(function (b64) {
                    resolve(b64);
                }));
            }
            catch (err) {
                reject(Error(err));
            }
        });
    };
    //#endregion 'CRUD -- Offline Activity Attachment'
    //#region 'View Attachments'
    OfflineActivityService.prototype.openViewFiles = function () {
        this.showViewFiles = true;
    };
    OfflineActivityService.prototype.closeViewFiles = function () {
        this.showViewFiles = false;
    };
    OfflineActivityService.prototype.showImage = function (type, b64) {
        var previewWindow = window.open();
        previewWindow.document.write("<iframe width='100%' height='100%' src='data:" + type + ";base64," + encodeURI(b64) + "'></iframe>");
    };
    //#endregion 'View Attachments'
    //? General Code
    //#region 'Email Notification'
    OfflineActivityService.prototype.generateEmailData = function () {
        var _url = new URL(window.location.href);
        this.emailData.WELL = this._sectionDailyOperation.wellName;
        this.emailData.JOB = this._sectionDailyOperation.selectedJob.JobCategory.Value;
        this.emailData.OANAME = this.OAList[this.selectedOAId].Name.Value;
        this.emailData.DATESTART = this.OADList[this.selectedOADId].StartDate.Value;
        this.emailData.DATEEND = this.OADList[this.selectedOADId].EndDate.Value;
        this.emailData.ROAH = this.currentTimelogAvailable.toString();
        this.emailData.COAH = this.TMLTime.toString();
        this.emailData.URL = _url.origin + "/view-attachment;OADId=" + this.OADList[this.selectedOADId].IdRec.Value;
        this.emailData.TMLS = [];
        for (var i = 0; i < this.OATList.length; i++) {
            var tl = this.OATList[i];
            this.emailData.TMLS.push((i + 1).toString() + "|" + tl.Duration.Value + "|" + tl.Comment.Value);
        }
        this.emailData.Subject = 'DSR Workspace - Offline Activity Notification';
        return this.emailData;
    };
    OfflineActivityService.prototype.initializeEmailObj = function () {
        var _this = this;
        this.emailData = {
            Emails: [],
            TMLS: [],
            TypeOfNotification: 0,
            Subject: '',
            WELL: '',
            JOB: '',
            OANAME: '',
            DATESTART: '',
            DATEEND: '',
            ROAH: '',
            COAH: '',
            URL: '',
        };
        var CURR_DESC_LS = this._tooltip.getItemLocalStorage('emails');
        if (CURR_DESC_LS && CURR_DESC_LS.length > 0) {
            var EMAILS = CURR_DESC_LS.split(',');
            EMAILS.forEach(function (em) {
                _this.pushEmail(em);
            });
        }
    };
    OfflineActivityService.prototype.addEmail = function (email) {
        var _this = this;
        if (email.includes(';')) {
            //? split emails in case needed
            var EMAILS = email.split(';');
            EMAILS.forEach(function (em) {
                _this.pushEmail(em);
            });
        }
        else {
            this.pushEmail(email);
        }
    };
    OfflineActivityService.prototype.pushEmail = function (email) {
        var EXIST = this.emailData.Emails.filter(function (obj) { return obj === email; });
        if (EXIST && EXIST.length === 0) {
            this.emailData.Emails.push(email);
            this._tooltip.addItemLocalStorage('emails', "" + this.emailData.Emails);
        }
    };
    OfflineActivityService.prototype.deleteEmail = function (index) {
        this.emailData.Emails.splice(index, 1);
        this._tooltip.addItemLocalStorage('emails', "" + this.emailData.Emails);
    };
    //#endregion 'Email Notification'
    //#region 'Notification'
    OfflineActivityService.prototype.showNotification = function (ntf, type) {
        var _this = this;
        this.resetNotifications();
        if (type === 1) {
            this.notifyOA = [];
            this.notifyOA.push({
                severity: ntf.severity,
                summary: ntf.summary,
                detail: ntf.detail,
            });
        }
        else if (type === 2) {
            this.notifyTMLOA = [];
            this.notifyTMLOA.push({
                severity: ntf.severity,
                summary: ntf.summary,
                detail: ntf.detail,
            });
        }
        setTimeout(function () {
            _this.notifyOA = [];
            _this.notifyTMLOA = [];
        }, 4000);
    };
    OfflineActivityService.prototype.resetNotifications = function () {
        this.notifyOA = [];
        this.notifyTMLOA = [];
    };
    //#endregion 'Notification'
    //#region 'General Methods'
    OfflineActivityService.prototype.resetAll = function () {
        this.resetOAD();
        this.resetOAT();
        this.resetOAA();
        this.resetNotifications();
    };
    OfflineActivityService.ngInjectableDef = i0.defineInjectable({ factory: function OfflineActivityService_Factory() { return new OfflineActivityService(i0.inject(i1.OfflineActivityApiService), i0.inject(i2.OfflineActivityDatesApiService), i0.inject(i3.OfflineActivityTimelogApiService), i0.inject(i4.AttachmentApiService), i0.inject(i5.LoadingIndicatorService), i0.inject(i6.DailyOperationService), i0.inject(i7.TooltipService)); }, token: OfflineActivityService, providedIn: "root" });
    return OfflineActivityService;
}());
export { OfflineActivityService };
