<!-- Casing Content -->
<!-- Toolbar Top -->
<div class="row">
  <div class="col-md-3">
    <!-- Add -->
    <div id="addCasingDiv" *ngIf="!_sectionCasing.casingStringIdApproved" class="pull-left">
      <button id="btn_add" type="button" class="btn btn-success" (click)="openMultipleEdit()">
        <span class="divCenter"><i class="pi pi-plus mr6"></i>Add</span>
      </button>
    </div>
    <!-- Add -->
    <!-- Edit -->
    <div id="multiEdit" *ngIf="showMultiEdit && !_sectionCasing.casingStringIdApproved" class="ml4 pull-left">
      <button id="btn_edit" type="button" class="btn btn-primary" (click)="showMultiEditCasings()">
        <span class="divCenter"><i class="pi pi-pencil mr6"></i>Edit</span>
      </button>
    </div>
    <!-- Edit -->
    <!-- Delete -->
    <div id="multiEdit" *ngIf="showMultiEdit && !_sectionCasing.casingStringIdApproved" class="ml4 pull-left">
      <button id="btn_delete" type="button" class="btn btn-danger" (click)="removeCasingInspect()">
        <span class="divCenter"><i class="pi pi-trash mr6"></i>Delete</span>
      </button>
    </div>
    <!-- Delete -->
    <!-- Validate All -->
    <div *ngIf="showMultiEdit && !_sectionCasing.casingStringIdApproved" class="ml4 pull-left">
      <button id="btn_validate" type="button" class="btn btn-primary" (click)="validateAllCasingInspect()">
        <span class="divCenter"><i class="pi pi-check mr6"></i>Validate & Run</span>
      </button>
    </div>
    <!-- Validate All -->
  </div>

  <div class="col-md-3"></div>

  <!-- Reports -->
  <div class="col-md-6">
    <div *ngIf="_sectionCasing.lstCasingComponent.length > 0" class="ml4 pull-right">
      <button
        id="btn_generate_report"
        type="button"
        class="btn btn btn-primary"
        (click)="OPGenerateReports.toggle($event)"
      >
        <span class="glyphicon glyphicon-download-alt" aria-hidden="true"></span>
        <span class="ml8">Generate Report</span>
      </button>
    </div>
    <div *ngIf="!_sectionCasing.casingStringIdApproved" class="ml4 pull-right">
      <button id="btn_import_excel" type="button" class="btn btn btn-primary" (click)="displayImportExcelDialog = true">
        <span class="divCenter"><i class="pi pi-arrow-circle-up mr6"></i>Import Excel</span>
      </button>
    </div>
    <div *ngIf="!_sectionCasing.casingStringIdApproved && _sectionCasing.getValidatedAmount() > 0" class="pull-right">
      <div class="fl">
        <p-multiSelect
          id="ddl_item"
          [options]="itemsToSelect"
          [(ngModel)]="selectedItemsDesc"
          [panelStyle]="{ minWidth: '16em' }"
          [defaultLabel]="'Select by Description'"
          [maxSelectedLabels]="1"
          (onChange)="selectItems()"
        ></p-multiSelect>
      </div>
    </div>
  </div>
  <!-- Reports -->
</div>
<!-- Toolbar Top -->

<!-- Notification Message -->
<!-- <div class="row">
  <p-messages id="msg_inventory" [(value)]="_sectionCasing.notificationsInventory" [closable]="false"></p-messages>
</div> -->
<!-- Notification Message -->

<!-- List -->
<div class="row">
  <div class="text-left table-responsive">
    <!-- Header Labels -->
    <table class="table table-striped noborder">
      <thead class="text-primary">
        <tr class="helpCursor">
          <th class="w2" *ngIf="_sectionCasing.getValidatedAmount() > 0">
            <div>
              <p-checkbox
                id="ccb_select_all"
                *ngIf="!_sectionCasing.casingStringIdApproved"
                name="multiEdit"
                (onChange)="selectAll()"
                binary="true"
                [(ngModel)]="editAll"
              >
              </p-checkbox>
            </div>
          </th>
          <th class="w4">Joint#</th>
          <!-- Item Description -->
          <th
            class="w12 pl0"
            data-toggle="tooltip"
            onmouseenter="$(this).tooltip('show')"
            data-container="body"
            data-placement="top"
            [title]="_sectionCasing.lstCasingComponentColumns.Description.Help"
          >
            <div>
              {{ _sectionCasing.lstCasingComponentColumns.Description.Label }}
              <span *ngIf="_sectionCasing.lstCasingComponentColumns.Description.UOM !== ''"
                >({{ _sectionCasing.lstCasingComponentColumns.Description.UOM }})</span
              >
            </div>
          </th>
          <!-- Item Description -->

          <!-- Equipment Type -->
          <th
            class="w10 pl0"
            data-toggle="tooltip"
            onmouseenter="$(this).tooltip('show')"
            data-container="body"
            data-placement="top"
            [title]="_sectionCasing.lstCasingComponentColumns.EquipmentType.Help"
          >
            <div>
              {{ _sectionCasing.lstCasingComponentColumns.EquipmentType.Label }}
              <span *ngIf="_sectionCasing.lstCasingComponentColumns.EquipmentType.UOM !== ''"
                >({{ _sectionCasing.lstCasingComponentColumns.EquipmentType.UOM }})</span
              >
            </div>
          </th>
          <!-- Equipment Type -->

          <!-- Len Thread On (ft) -->
          <th
            class="w6 pl0"
            data-toggle="tooltip"
            onmouseenter="$(this).tooltip('show')"
            data-container="body"
            data-placement="top"
            [title]="_sectionCasing.lstCasingComponentColumns.Length.Help"
          >
            <div>
              Len Thread On
              <span *ngIf="_sectionCasing.lstCasingComponentColumns.Length.UOM !== ''"
                >({{ _sectionCasing.lstCasingComponentColumns.Length.UOM }})</span
              >
            </div>
          </th>
          <!-- Len Thread On (ft) -->

          <th class="w6 pl0">
            <div>Len Thread Off</div>
          </th>
          <th class="w6 pl0">
            <div>Len Thread Loss (in)</div>
          </th>

          <!-- OD Nominal (in) -->
          <th
            class="w8 pl0"
            data-toggle="tooltip"
            onmouseenter="$(this).tooltip('show')"
            data-container="body"
            data-placement="top"
            [title]="_sectionCasing.lstCasingComponentColumns.OD.Help"
          >
            <div>
              {{ _sectionCasing.lstCasingComponentColumns.OD.Label }}
              <span *ngIf="_sectionCasing.lstCasingComponentColumns.OD.UOM !== ''"
                >({{ _sectionCasing.lstCasingComponentColumns.OD.UOM }})</span
              >
            </div>
          </th>
          <!-- OD Nominal (in) -->

          <!-- ID (in) -->
          <th
            class="w6 pl0"
            data-toggle="tooltip"
            onmouseenter="$(this).tooltip('show')"
            data-container="body"
            data-placement="top"
            [title]="_sectionCasing.lstCasingComponentColumns.ID.Help"
          >
            <div>
              {{ _sectionCasing.lstCasingComponentColumns.ID.Label }}
              <span *ngIf="_sectionCasing.lstCasingComponentColumns.ID.UOM !== ''"
                >({{ _sectionCasing.lstCasingComponentColumns.ID.UOM }})</span
              >
            </div>
          </th>
          <!-- ID (in) -->

          <!-- Weight/Length (lb/ft) -->
          <th
            class="w10 pl0"
            data-toggle="tooltip"
            onmouseenter="$(this).tooltip('show')"
            data-container="body"
            data-placement="top"
            [title]="_sectionCasing.lstCasingComponentColumns.Weight.Help"
          >
            <div>
              {{ _sectionCasing.lstCasingComponentColumns.Weight.Label }}
              <span *ngIf="_sectionCasing.lstCasingComponentColumns.Weight.UOM !== ''"
                >({{ _sectionCasing.lstCasingComponentColumns.Weight.UOM }})</span
              >
            </div>
          </th>
          <!-- Weight/Length (lb/ft) -->

          <!-- Grade -->
          <th
            class="w6 pl0"
            data-toggle="tooltip"
            onmouseenter="$(this).tooltip('show')"
            data-container="body"
            data-placement="top"
            [title]="_sectionCasing.lstCasingComponentColumns.Grade.Help"
          >
            <div>
              {{ _sectionCasing.lstCasingComponentColumns.Grade.Label }}
              <span *ngIf="_sectionCasing.lstCasingComponentColumns.Grade.UOM !== ''"
                >({{ _sectionCasing.lstCasingComponentColumns.Grade.UOM }})</span
              >
            </div>
          </th>
          <!-- Grade -->

          <!-- Top Connection Thread -->
          <th
            class="w12 pl0"
            data-toggle="tooltip"
            onmouseenter="$(this).tooltip('show')"
            data-container="body"
            data-placement="top"
            [title]="_sectionCasing.lstCasingComponentColumns.TopThread.Help"
          >
            <div>
              {{ _sectionCasing.lstCasingComponentColumns.TopThread.Label }}
              <span *ngIf="_sectionCasing.lstCasingComponentColumns.TopThread.UOM !== ''"
                >({{ _sectionCasing.lstCasingComponentColumns.TopThread.UOM }})</span
              >
            </div>
          </th>
          <!-- Top Connection Thread -->

          <!-- Bottom Connection Thread -->
          <th
            class="w12 pl0"
            data-toggle="tooltip"
            onmouseenter="$(this).tooltip('show')"
            data-container="body"
            data-placement="top"
            [title]="_sectionCasing.lstCasingComponentColumns.BottomThread.Help"
          >
            <div>
              {{ _sectionCasing.lstCasingComponentColumns.BottomThread.Label }}
              <span *ngIf="_sectionCasing.lstCasingComponentColumns.BottomThread.UOM !== ''"
                >({{ _sectionCasing.lstCasingComponentColumns.BottomThread.UOM }})</span
              >
            </div>
          </th>
          <!-- Bottom Connection Thread -->
        </tr>
      </thead>
    </table>
    <!-- Header Labels -->

    <!-- List Content -->
    <cdk-virtual-scroll-viewport
      itemSize="40"
      style="height: 570px; max-height: 570px"
      *ngIf="_sectionCasing.lstCasingComponent.length > 0"
    >
      <table class="table table-striped noborder">
        <tbody>
          <tr *cdkVirtualFor="let cc of _sectionCasing.lstCasingComponent; let rowIndex = index">
            <!-- Multiple Edit -->
            <td class="w2" *ngIf="_sectionCasing.getValidatedAmount() > 0">
              <div>
                <p-checkbox
                  id="ddl_select_0{{ rowIndex }}"
                  *ngIf="!_sectionCasing.casingStringIdApproved && !cc.IsValidated"
                  name="multiEdit"
                  (onChange)="allowMultiEdit(cc, rowIndex, true)"
                  [(ngModel)]="cc.isEdit"
                  binary="true"
                >
                </p-checkbox>
              </div>
            </td>
            <!-- Multiple Edit -->

            <!-- Joint # -->
            <td class="w4">
              <div *ngIf="cc.IsValidated || _sectionCasing.casingStringIdApproved; else elseJointNumberBlock">
                {{ cc.JointNumber }}
              </div>
              <ng-template #elseJointNumberBlock>
                <div>
                  <input
                    id="txt_joint_0{{ rowIndex }}"
                    OnlyLettersNumbers
                    #JointNumber
                    (blur)="updateCasingInspect(cc, rowIndex, 'updateJointNumber')"
                    size="3"
                    type="text"
                    class="form-control"
                    [(ngModel)]="cc.JointNumber"
                  />
                </div>
              </ng-template>
            </td>
            <!-- Joint # -->

            <!-- Item Description -->
            <td class="w12">
              <div
                *ngIf="
                  cc.IsValidated || _sectionCasing.casingStringIdApproved || isDrill(rowIndex);
                  else elseDescriptionBlock
                "
              >
                {{ cc.Description.Value }}
              </div>
              <ng-template #elseDescriptionBlock>
                <div>
                  <select
                    id="ddl_item_description"
                    class="form-control"
                    [(ngModel)]="cc.Description.Value"
                    (change)="updateDescription(cc, rowIndex)"
                  >
                    <option *ngFor="let desc of _sectionCasing.iconDescription" [ngValue]="desc">
                      {{ desc }}
                    </option>
                  </select>
                </div>
              </ng-template>
            </td>
            <!-- Item Description -->

            <!-- Equipment Type -->
            <td class="w10">
              <div
                *ngIf="
                  cc.IsValidated || _sectionCasing.casingStringIdApproved || isDrill(rowIndex);
                  else elseEquipmentBlock
                "
              >
                {{ cc.EquipmentType.Value }}
              </div>
              <ng-template #elseEquipmentBlock>
                <div>
                  <select
                    id="ddl_equipment_type"
                    class="form-control"
                    [(ngModel)]="cc.EquipmentType.Value"
                    (change)="updateCasingInspect(cc, rowIndex, '')"
                  >
                    <option *ngFor="let subType of _sectionCasing.lstCasingComponentSubType" [ngValue]="subType">
                      {{ subType }}
                    </option>
                  </select>
                </div>
              </ng-template>
            </td>
            <!-- Equipment Type -->

            <!-- Thread On (ft) -->
            <td class="w6">
              <div *ngIf="cc.IsValidated || _sectionCasing.casingStringIdApproved; else elseLengthBlock">
                {{ cc.Length.Value }}
              </div>
              <ng-template #elseLengthBlock>
                <div>
                  <input
                    #threadon
                    id="txt_thread_on_0{{ rowIndex }}"
                    (blur)="computeThreadOffValues(cc, rowIndex, 'threadOn', 1)"
                    onFocus="this.select();"
                    size="6"
                    type="number"
                    onkeyup="if(this.value < 0){this.value = this.value * -1}"
                    min="0"
                    class="form-control text-right"
                    [(ngModel)]="cc.Length.Value"
                  />
                </div>
              </ng-template>
            </td>
            <!-- Thread On (ft) -->

            <!-- Thread Off -->
            <td class="w6">
              <div *ngIf="isThreadOffValid(cc.ThreadOff)">
                <span class="divCenter">{{ cc.ThreadOff | number: '1.2' }}</span>
              </div>
            </td>
            <!-- Thread Off -->

            <!-- Thread Loss -->
            <td class="w6">
              <div
                *ngIf="cc.IsValidated || _sectionCasing.casingStringIdApproved; else elseThreadLossBlock"
                class="centerContent"
              >
                {{ cc.ThreadLoss }}
                <i [ngClass]="cc.IsThreadLoss ? 'pi pi-check text-primary ml6' : 'pi pi-ban text-warning ml6'"></i>
              </div>
              <ng-template #elseThreadLossBlock>
                <div>
                  <div class="col-sm-9 threadLoss">
                    <input
                      id="txt_thread_loss_0{{ rowIndex }}"
                      (blur)="computeThreadOffValues(cc, rowIndex, '', 2)"
                      onFocus="this.select();"
                      size="6"
                      type="number"
                      onkeyup="if(this.value < 0){this.value = this.value * -1}"
                      min="0"
                      class="form-control text-right"
                      [(ngModel)]="cc.ThreadLoss"
                    />
                  </div>
                  <div class="col-sm-2 threadLossCheck" title="Toggle Thread Loss">
                    <p-checkbox
                      id="cbx_is_thread_loss_0{{ rowIndex }}"
                      class="ml6"
                      name="chkIsThreadLoss"
                      (onChange)="computeThreadOffValues(cc, rowIndex, '', 3)"
                      [(ngModel)]="cc.IsThreadLoss"
                      binary="true"
                    >
                    </p-checkbox>
                  </div>
                </div>
              </ng-template>
            </td>
            <!-- Thread Loss -->

            <!-- OD Nominal (in) -->
            <td class="w8">
              <div *ngIf="cc.IsValidated || _sectionCasing.casingStringIdApproved; else elseODBlock">
                {{ cc.OD.Value }}
              </div>
              <ng-template #elseODBlock>
                <div>
                  <select
                    id="ddl_od_nominal"
                    class="form-control fixInput"
                    [(ngModel)]="cc.OD.Value"
                    (change)="selectDDLchangeHandler(cc, rowIndex)"
                    (focus)="getCurrentValues(cc, rowIndex, 1)"
                  >
                    <option *ngFor="let item of _sectionCasing.filteredOD" [ngValue]="item.OD">
                      {{ item.OD }}
                    </option>
                  </select>
                </div>
              </ng-template>
            </td>
            <!-- OD Nominal (in) -->

            <!-- ID (in) -->
            <td class="w6">
              <div>
                <span>
                  {{ cc.ID.Value }}
                </span>
              </div>
            </td>
            <!-- ID (in) -->

            <!-- Wieght/Length (lb/ft) -->
            <td class="w10">
              <div>
                <span>
                  {{ cc.Weight.Value }}
                </span>
              </div>
            </td>
            <!-- Wieght/Length (lb/ft) -->

            <!-- Grade -->
            <td class="w6">
              <div>
                <span>
                  {{ cc.Grade.Value }}
                </span>
              </div>
            </td>
            <!-- Grade -->

            <!-- Top Connection Thread -->
            <td class="w12">
              <div *ngIf="cc.IsValidated || _sectionCasing.casingStringIdApproved; else elseTopThreadBlock">
                {{ cc.TopThread.Value }}
              </div>
              <ng-template #elseTopThreadBlock>
                <div>
                  <select
                    id="ddl_conn_thread"
                    class="form-control"
                    [(ngModel)]="cc.TopThread.Value"
                    (change)="updateCasingInspect(cc, rowIndex, '')"
                  >
                    <option *ngFor="let item of _sectionCasing.filteredTopThread" [ngValue]="item.Name">
                      {{ item.Name }}
                    </option>
                  </select>
                </div>
              </ng-template>
            </td>
            <!-- Top Connection Thread -->

            <!-- Bottom Connection Thread -->
            <td class="w12">
              <div *ngIf="cc.IsValidated || _sectionCasing.casingStringIdApproved; else elseBottomThreadBlock">
                {{ cc.BottomThread.Value }}
              </div>
              <ng-template #elseBottomThreadBlock>
                <div>
                  <select
                    id="ddl_btm_conn_thread"
                    class="form-control"
                    [(ngModel)]="cc.BottomThread.Value"
                    (change)="updateCasingInspect(cc, rowIndex, '')"
                  >
                    <option *ngFor="let item of _sectionCasing.filteredBottomThread" [ngValue]="item.Name">
                      {{ item.Name }}
                    </option>
                  </select>
                </div>
              </ng-template>
            </td>
            <!-- Bottom Connection Thread -->
          </tr>
        </tbody>
      </table>
    </cdk-virtual-scroll-viewport>
    <!-- List Content -->
  </div>
  <div *ngIf="_sectionCasing.lstCasingComponent.length === 0" class="centerNoData">
    <span>Sorry, the selected casing has no data to display in Casing Inventory at this time.</span>
  </div>
</div>
<!-- List -->
<!-- Casing Content -->

<!-- Generate Reports Option -->
<p-overlayPanel #OPGenerateReports appendTo="body">
  <div class="tac">
    <label class="text-primary text-center boldTextFont">Select Casing Inventory Report</label>
  </div>
  <div>
    <p-checkbox
      id="ccb_pdf"
      class="boldTextFont"
      name="group1"
      value="PDF"
      label="PDF"
      [(ngModel)]="selectedReportFormat"
      inputId="pdf"
    >
    </p-checkbox>
    <p-checkbox
      id="ccb_excel"
      class="ml12 boldTextFont"
      name="group1"
      value="Excel"
      label="Excel"
      [(ngModel)]="selectedReportFormat"
      inputId="xcl"
    >
    </p-checkbox>
  </div>
  <div class="mt12">
    <button id="btn_download" type="button" class="btn btn-primary tac" (click)="generateReport(OPGenerateReports)">
      <span class="glyphicon glyphicon-download-alt" aria-hidden="true"></span>
      <span class="ml8">Download</span>
    </button>
  </div>
</p-overlayPanel>
<!-- Generate Reports Option -->

<!-- Import -->
<p-dialog
  header="Upload Casing File"
  [(visible)]="displayImportExcelDialog"
  width="1400"
  height="850"
  [baseZIndex]="5000"
  [modal]="true"
  [closable]="true"
  [responsive]="true"
  [style]="{ 'max-height': '850px', overflow: 'auto' }"
  (onHide)="clearImportFile()"
>
  <!-- Import File -->
  <app-file-upload [setFileImportNotify]="setFileImportNotify" (importFileSuccess)="importFileSuccess($event)">
  </app-file-upload>
  <!-- Import File -->
  <!-- Note -->
  <div class="row mt10" *ngIf="displayImportExcelDialogButtons">
    <span class="errorSpan"
      ><b
        >Note: JT Start and JT End are both required to make the configuration valid, if one configuration is missing
        one of them, the WHOLE configuration will be ignored, JT End should always be greater than JT End.</b
      ></span
    >
  </div>
  <br *ngIf="displayImportExcelDialogButtons" />
  <!-- Note -->
  <!-- Chevron Excel Casing Configuration -->
  <div class="row" *ngIf="displayImportExcelDialogButtons">
    <table class="table table-striped noborder">
      <thead>
        <tr>
          <td class="w6">JT Start</td>
          <td class="w6">JT End</td>
          <td class="w20">Item Description</td>
          <td class="w14">Item Type</td>
          <td class="w10">OD Nominal</td>
          <td class="w14">Top Thread</td>
          <td class="w14">Bottom Thread</td>
          <td class="w16"></td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let mid of multipleImportData; let idx = index">
          <td class="w6">
            <input
              id="txt_jts_mid_0{{ idx }}"
              size="5"
              type="number"
              onkeyup="if(this.value < 0){this.value = this.value * -1}"
              min="1"
              required
              class="form-control text-right"
              [(ngModel)]="mid.JointStart"
              onfocus="this.select();"
              (blur)="setValueJTEnd(idx)"
            />
          </td>
          <td class="w6">
            <input
              id="txt_jte_mid_0{{ idx }}"
              size="5"
              type="number"
              min="{{ mid.JointStart }}"
              onkeyup="if(this.value < 0){this.value = this.value * -1}"
              (blur)="validateImportJTEnd(idx)"
              required
              class="form-control text-right"
              [(ngModel)]="mid.JointEnd"
              onfocus="this.select();"
            />
          </td>
          <td class="w20">
            <select
              id="ddl_item_description_mid_0{{ idx }}"
              class="form-control"
              [(ngModel)]="mid.ItemDesc"
              (change)="isDrillingDescription(mid.ItemDesc, idx, 1)"
            >
              <option *ngFor="let desc of _sectionCasing.iconDescription" [ngValue]="desc">
                {{ desc }}
              </option>
            </select>
          </td>
          <td class="w14">
            <select
              id="ddl_equipment_type_mid_0{{ idx }}"
              class="form-control"
              [(ngModel)]="mid.ItemType"
              [disabled]="mid.IsDrillPipe"
            >
              <option *ngFor="let subType of _sectionCasing.lstCasingComponentSubType" [ngValue]="subType">
                {{ subType }}
              </option>
            </select>
          </td>
          <td class="w10">
            <select
              id="ddl_od_nominal_mid_0{{ idx }}"
              class="form-control fixInput"
              [(ngModel)]="mid.ODNominal"
              (change)="filterODNominalMultipleImport(mid, idx)"
              (focus)="getCurrentValues(mid, idx, 3)"
            >
              <option *ngFor="let item of _sectionCasing.filteredOD" [ngValue]="item.OD">
                {{ item.OD }}
              </option>
            </select>
          </td>
          <td class="w14">
            <select id="ddl_top_thread_mid_0{{ idx }}" class="form-control" [(ngModel)]="mid.TopThread">
              <option *ngFor="let item of _sectionCasing.filteredTopThread" [ngValue]="item.Name">
                {{ item.Name }}
              </option>
            </select>
          </td>
          <td class="w14">
            <select id="ddl_btm_thread_mid_0{{ idx }}" class="form-control" [(ngModel)]="mid.BottomThread">
              <option *ngFor="let item of _sectionCasing.filteredBottomThread" [ngValue]="item.Name">
                {{ item.Name }}
              </option>
            </select>
          </td>
          <td class="w16">
            <div class="fl">
              <button
                id="btn_add_mid_0{{ idx }}"
                type="button"
                class="btn btn-primary"
                (click)="addNewMultipleImportRecord()"
              >
                <span class="divCenter"><i class="pi pi-plus-circle mr6"></i>Add</span>
              </button>
            </div>
            <div *ngIf="multipleImportData.length > 1">
              <button
                id="btn_delete_mid_0{{ idx }}"
                type="button"
                class="btn btn-danger ml4"
                (click)="deleteMultipleImportRecord(idx)"
              >
                <span class="divCenter"><i class="pi pi-trash mr6"></i>Delete</span>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- Chevron Excel Casing Configuration -->
  <div class="row mt12" *ngIf="displayImportExcelDialogButtons">
    <!-- Toolbar -->
    <div class="col-md-9"></div>
    <div class="col-md-3">
      <div class="fr ml8">
        <button
          id="btn_create_casings_import"
          *ngIf="setFileImportNotify"
          type="button"
          class="btn btn-primary pull-right"
          (click)="createCasingsFromImport()"
        >
          <span class="divCenter"> <i class="pi pi-plus mr6"></i> Create Casings </span>
        </button>
      </div>
      <div class="fr">
        <button id="btn_delete_data" type="button" class="btn btn-danger ml8" (click)="clearImportFile()">
          <span class="divCenter"> <i class="pi pi-trash mr6"></i> Delete Data </span>
        </button>
      </div>
    </div>
    <!-- Toolbar -->
  </div>
  <!-- Imported Data -->
  <div class="row mt12" *ngIf="displayImportExcelDialogButtons">
    <p-table [columns]="tableColumns" [value]="tableRows">
      <ng-template pTemplate="caption"> Verify Imported File Data </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">
            {{ col.header }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td *ngFor="let col of columns">
            {{ rowData[col.field] }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <!-- Imported Data -->
</p-dialog>
<!-- Import -->

<!-- Edit -->
<p-dialog
  *ngIf="_sectionCasing.lstCasingComponent.length > 0"
  header="Edit Multiple Casings"
  [(visible)]="displayMultipleEdit"
  width="850"
  height="auto"
  [baseZIndex]="5000"
  [modal]="true"
  [closable]="true"
  [responsive]="true"
  [style]="{ overflow: 'auto' }"
>
  <div class="row">
    <div class="col-12 pl8">
      <span>Please select the values to update the casings.</span>
    </div>
  </div>

  <table class="table table-striped noborder noMarginBottom">
    <thead>
      <tr>
        <th>
          <span><b>Thread On</b></span>
        </th>
        <th>
          <span><b>Thread Loss</b></span>
        </th>
        <th>
          <span><b>Apply Thread Loss</b></span>
        </th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr class="transparentBG">
        <td>
          <input
            id="txt_edit_thread_on"
            type="number"
            onkeyup="if(this.value < 0){this.value = this.value * -1}"
            min="0"
            class="form-control"
            [(ngModel)]="ME_ThreadOn"
          />
        </td>
        <td>
          <input
            id="txt_edit_thread_loss"
            type="number"
            onkeyup="if(this.value < 0){this.value = this.value * -1}"
            min="0"
            class="form-control"
            [(ngModel)]="ME_ThreadLoss"
          />
        </td>
        <td>
          <p-checkbox id="ccb_edit_is_thread_loss" [(ngModel)]="ME_ChkThreadLoss" binary="true"></p-checkbox>
        </td>
        <td></td>
      </tr>
    </tbody>
  </table>

  <table class="table table-striped noborder noMarginBottom">
    <thead>
      <tr>
        <th>
          <span><b> OD Nominal (in)</b></span>
        </th>
        <th>
          <span><b>ID (in)</b></span>
        </th>
        <th>
          <span><b>Weight/Length (lb/ft)</b></span>
        </th>
        <th>
          <span><b>Grade</b></span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr class="transparentBG">
        <!-- OD Nominal (in) -->
        <td>
          <select
            id="ddl_edit_od_nominal"
            class="form-control fixInput"
            (change)="selectDDLchangeHandler(null, null)"
            (focus)="getCurrentValues(null, null, 2)"
            [(ngModel)]="ME_ODNominal"
          >
            <option *ngFor="let item of _sectionCasing.filteredOD" [ngValue]="item.OD">
              {{ item.OD }}
            </option>
          </select>
        </td>
        <!-- OD Nominal (in) -->

        <!-- ID (in) -->
        <td>
          <div>
            <span>
              {{ ME_ID }}
            </span>
          </div>
        </td>
        <!-- ID (in) -->

        <!-- Weight/Length (lb/ft) -->
        <td>
          <div>
            <span>
              {{ ME_WeighLength }}
            </span>
          </div>
        </td>
        <!-- Weight/Length (lb/ft) -->

        <!-- Grade -->
        <td>
          <div>
            <span>
              {{ ME_Grade }}
            </span>
          </div>
        </td>
        <!-- Grade -->
      </tr>
    </tbody>
  </table>

  <table class="table table-striped noborder">
    <thead>
      <tr>
        <th>
          <span><b>Top Connection Thread</b></span>
        </th>
        <th>
          <span><b>Bottom Connection Thread</b></span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr class="transparentBG">
        <td>
          <select id="ddl_edit_top_conn_thread" class="form-control" [(ngModel)]="ME_TopThread">
            <option *ngFor="let item of _sectionCasing.filteredTopThread" [ngValue]="item.Name">
              {{ item.Name }}
            </option>
          </select>
        </td>
        <td>
          <select id="ddl_edit_bot_conn_thread" class="form-control" [(ngModel)]="ME_BottomThread">
            <option *ngFor="let item of _sectionCasing.filteredBottomThread" [ngValue]="item.Name">
              {{ item.Name }}
            </option>
          </select>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="row">
    <div class="col-12 pl8">
      <button id="btn_edit_save" type="button" class="btn btn-success" (click)="updateMultipleValues()">
        Save Changes
      </button>
    </div>
  </div>
</p-dialog>
<!-- Edit -->

<!-- Multiple Add -->
<p-dialog
  header="Add Casing Tally Items"
  [(visible)]="showMultipleAdd"
  width="1200"
  height="auto"
  [baseZIndex]="5000"
  [modal]="true"
  [closable]="true"
  [responsive]="true"
  [style]="{ overflow: 'auto' }"
>
  <div class="row">
    <table class="table table-striped noborder">
      <thead>
        <tr>
          <td class="w8"><span class="mark-asterisk">*</span> Quantity</td>
          <td class="w23"><span class="mark-asterisk">*</span> Item Description</td>
          <td class="w15"><span class="mark-asterisk">*</span> Item Type</td>
          <td class="w10">OD Nominal</td>
          <td class="w14">Top Thread</td>
          <td class="w14">Bottom Thread</td>
          <td class="w16"></td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let mad of multipleAddData; let idx = index">
          <td class="w8">
            <input
              id="txt_qty_mad_0{{ idx }}"
              size="5"
              type="number"
              onkeyup="if(this.value < 0){this.value = this.value * -1}"
              min="0"
              required
              class="form-control text-right"
              [(ngModel)]="mad.Quantity"
              onfocus="this.select();"
            />
          </td>
          <td class="w23">
            <select
              id="ddl_item_description_mad_0{{ idx }}"
              class="form-control"
              [(ngModel)]="mad.ItemDesc"
              (change)="isDrillingDescription(mad.ItemDesc, idx, 2)"
            >
              <option *ngFor="let desc of _sectionCasing.iconDescription" [ngValue]="desc">
                {{ desc }}
              </option>
            </select>
          </td>
          <td class="w15">
            <select
              id="ddl_equipment_type_mad_0{{ idx }}"
              class="form-control"
              [(ngModel)]="mad.ItemType"
              [disabled]="mad.IsDrillPipe"
            >
              <option *ngFor="let subType of _sectionCasing.lstCasingComponentSubType" [ngValue]="subType">
                {{ subType }}
              </option>
            </select>
          </td>
          <td class="w10">
            <select
              id="ddl_od_nominal_mad_0{{ idx }}"
              class="form-control fixInput"
              [(ngModel)]="mad.ODNominal"
              (change)="filterODNominalMultipleAdd(mad, idx)"
              (focus)="getCurrentValues(mad, idx, 3)"
            >
              <option *ngFor="let item of _sectionCasing.filteredOD" [ngValue]="item.OD">
                {{ item.OD }}
              </option>
            </select>
          </td>
          <td class="w14">
            <select id="ddl_top_thread_mad_0{{ idx }}" class="form-control" [(ngModel)]="mad.TopThread">
              <option *ngFor="let item of _sectionCasing.filteredTopThread" [ngValue]="item.Name">
                {{ item.Name }}
              </option>
            </select>
          </td>
          <td class="w14">
            <select id="ddl_btm_thread_mad_0{{ idx }}" class="form-control" [(ngModel)]="mad.BottomThread">
              <option *ngFor="let item of _sectionCasing.filteredBottomThread" [ngValue]="item.Name">
                {{ item.Name }}
              </option>
            </select>
          </td>
          <td class="w16">
            <div class="fl">
              <button
                id="btn_add_mad_0{{ idx }}"
                type="button"
                class="btn btn-primary"
                (click)="addNewMultipleAddRecord()"
              >
                <span class="divCenter"><i class="pi pi-plus-circle mr6"></i>Add</span>
              </button>
            </div>
            <div *ngIf="multipleAddData.length > 1">
              <button
                id="btn_delete_mad_0{{ idx }}"
                type="button"
                class="btn btn-danger ml4"
                (click)="deleteMultipleAddRecord(idx)"
              >
                <span class="divCenter"><i class="pi pi-trash mr6"></i>Delete</span>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="row">
    <div class="col-md-12">
      <span class="errorSpan">NOTE: All the fields marked with (*) are required.</span>
    </div>
  </div>
  <div *ngIf="showMultipleAddError" class="row">
    <div class="col-md-12">
      <span class="errorSpan">Please verify that Quantity, Item Description and Item Type is selected.</span>
    </div>
  </div>

  <div class="row fr mt8">
    <div class="col-12 pl8">
      <button id="btn_edit_save" type="button" class="btn btn-success" (click)="addMultiple()">Save Changes</button>
      <button id="btn_edit_save" type="button" class="btn btn-danger ml8" (click)="hideMultipleAdd()">Cancel</button>
    </div>
  </div>
</p-dialog>
<!-- Multiple Add -->

<!-- SideBar -->
<p-sidebar
  [(visible)]="displayCasingComponentConfigurations"
  [showCloseIcon]="true"
  [dismissible]="true"
  styleClass="ui-sidebar-lg"
  position="right"
  [style]="{ overflow: 'auto' }"
  [baseZIndex]="10000"
  (onHide)="resetToOldValues()"
>
  <br />
  <p-table #dt [value]="sidebar_CasingODID" selectionMode="single" (onRowSelect)="casingComponentSelect($event)">
    <ng-template pTemplate="caption">
      <h3 class="boldTextFont">Select Casing Configuration</h3>
    </ng-template>
    <ng-template pTemplate="header">
      <tr class="boldTextFont">
        <th>OD Nominal (in)</th>
        <th>ID (in)</th>
        <th>Weight/Length (lb/ft)</th>
        <th>Grade</th>
      </tr>
      <tr>
        <th>
          <i class="pi pi-search"></i>
          <input
            id="txt_slide_od"
            size="10"
            class="w70 normalText ml12"
            pInputText
            type="text"
            placeholder="Filter"
            [(ngModel)]="F_ODNominal"
            (input)="dt.filter($event.target.value, 'OD', 'contains')"
          />
        </th>
        <th>
          <i class="pi pi-search"></i>
          <input
            id="txt_slide_id"
            size="10"
            class="w70 normalText ml12"
            pInputText
            type="text"
            placeholder="Filter"
            [(ngModel)]="F_ID"
            (input)="dt.filter($event.target.value, 'ID', 'contains')"
          />
        </th>
        <th>
          <i class="pi pi-search"></i>
          <input
            id="txt_slide_weight"
            size="10"
            class="w70 normalText ml12"
            pInputText
            type="text"
            placeholder="Filter"
            [(ngModel)]="F_WeightLength"
            (input)="dt.filter($event.target.value, 'Weight', 'contains')"
          />
        </th>
        <th>
          <i class="pi pi-search"></i>
          <input
            id="txt_slide_grade"
            size="10"
            class="w70 normalText ml12"
            pInputText
            type="text"
            placeholder="Filter"
            [(ngModel)]="F_Grade"
            (input)="dt.filter($event.target.value, 'Grade', 'contains')"
          />
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
      <tr [pSelectableRow]="item" class="normalText">
        <td>{{ item.OD }}</td>
        <td>{{ item.ID }}</td>
        <td>{{ item.Weight }}</td>
        <td>{{ item.Grade }}</td>
      </tr>
    </ng-template>
  </p-table>
</p-sidebar>
<!-- SideBar -->

<!-- Toast -->
<p-toast [style]="{ marginTop: '40px !important' }" key="inventoryNotify" [baseZIndex]="6000"></p-toast>
<p-toast
  [style]="{ marginTop: '40px !important' }"
  key="inventoryError"
  [baseZIndex]="6000"
  position="top-center"
></p-toast>
<!-- Toast -->
