import { environment } from '#environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CasingComponentApiService = /** @class */ (function () {
    //#region 'Angular Life Cycle'
    function CasingComponentApiService(http) {
        this.http = http;
    }
    //#endregion 'Angular Life Cycle'
    //#region 'General'
    CasingComponentApiService.prototype.getURL = function (method) {
        return environment.apiEndpoint + "/CasingComponent" + method;
    };
    CasingComponentApiService.prototype.getURLRep = function (method) {
        return environment.apiEndpoint + "/reports" + method;
    };
    //#endregion 'General'
    //#region 'Get'
    CasingComponentApiService.prototype.getCasingStringId = function (casingStringId) {
        return this.http.get(this.getURL("/getByCasingStringId"), {
            params: { casingStringId: casingStringId },
        });
    };
    CasingComponentApiService.prototype.getCasingComponentMetaData = function () {
        return this.http.get(this.getURL("/getMetaData"));
    };
    CasingComponentApiService.prototype.getRunningByCasingStringId = function (casingStringId) {
        return this.http.get(this.getURL("/GetRunningByCasingStringId"), {
            params: { casingStringId: casingStringId },
        });
    };
    CasingComponentApiService.prototype.getCasingItemSubType = function () {
        return this.http.get(this.getURL("/getSubType"));
    };
    CasingComponentApiService.prototype.getAllToolTips = function () {
        return this.http.get(this.getURL("/getToolTip"));
    };
    CasingComponentApiService.prototype.getCasingItemDescription = function () {
        return this.http.get(this.getURL("/getCasingItemDescription"));
    };
    CasingComponentApiService.prototype.getCasingItemConnectionThread = function () {
        return this.http.get(this.getURL("/getConnectionThread"));
    };
    CasingComponentApiService.prototype.getIconDescription = function () {
        return this.http.get(this.getURL("/getIconDescription"));
    };
    //#endregion 'Get'
    //#region 'Create'
    CasingComponentApiService.prototype.create = function (casingComponent) {
        return this.http.post(this.getURL("/create"), casingComponent);
    };
    CasingComponentApiService.prototype.createBulk = function (casingComponentBulk) {
        return this.http.post(this.getURL("/createBulk"), casingComponentBulk);
    };
    //#endregion 'Create'
    //#region 'Update'
    CasingComponentApiService.prototype.update = function (casingComponent) {
        return this.http.put(this.getURL("/update"), casingComponent);
    };
    CasingComponentApiService.prototype.updateBulk = function (casingComponentBulk) {
        return this.http.put(this.getURL("/updateBulk"), casingComponentBulk);
    };
    CasingComponentApiService.prototype.approveBulk = function (casingComponentBulk, runTime) {
        return this.http.put(this.getURL("/approveBulk"), {
            LstDto: casingComponentBulk,
            RunDate: runTime,
        });
    };
    CasingComponentApiService.prototype.validateCasing = function (casingComponent) {
        return this.http.put(this.getURL("/validate"), casingComponent);
    };
    CasingComponentApiService.prototype.unvalidateCasing = function (casingComponent) {
        return this.http.put(this.getURL("/unvalidate"), casingComponent);
    };
    CasingComponentApiService.prototype.validateCasingBulk = function (casingComponentBulk) {
        return this.http.put(this.getURL("/validateBulk"), casingComponentBulk);
    };
    //#endregion 'Update'
    //#region 'Delete'
    CasingComponentApiService.prototype.delete = function (casingComponentId) {
        return this.http.put(this.getURL("/delete"), casingComponentId);
    };
    CasingComponentApiService.prototype.deleteBulk = function (casingComponentBulk) {
        return this.http.put(this.getURL("/deleteBulk"), casingComponentBulk);
    };
    //#endregion 'Delete'
    //#region 'PDF & Excel'
    CasingComponentApiService.prototype.getCasingInventoryPDF = function (CasingStringId) {
        return this.http.get(this.getURLRep("/getCasingInventoryPDF"), {
            params: {
                CasingStringId: CasingStringId,
            },
            responseType: 'blob',
            observe: 'response',
        });
    };
    CasingComponentApiService.prototype.getCasingRunningTallyPDF_AllCasings = function (CasingStringId) {
        return this.http.get(this.getURLRep("/getCasingRunningTallyPDF/AllCasings"), {
            params: {
                CasingStringId: CasingStringId,
            },
            responseType: 'blob',
            observe: 'response',
        });
    };
    CasingComponentApiService.prototype.getCasingRunningTallyPDF_RunOnly = function (CasingStringId) {
        return this.http.get(this.getURLRep("/getCasingRunningTallyPDF/RunOnly"), {
            params: {
                CasingStringId: CasingStringId,
            },
            responseType: 'blob',
            observe: 'response',
        });
    };
    CasingComponentApiService.prototype.getCasingInventoryExcel = function (CasingStringId) {
        return this.http.get(this.getURLRep("/getCasingInventoryExcel"), {
            params: {
                CasingStringId: CasingStringId,
            },
            responseType: 'blob',
            observe: 'response',
        });
    };
    CasingComponentApiService.prototype.getCasingRunningTallyExcel_AllCasings = function (CasingStringId) {
        return this.http.get(this.getURLRep("/getCasingRunningTallyExcel/AllCasings"), {
            params: {
                CasingStringId: CasingStringId,
            },
            responseType: 'blob',
            observe: 'response',
        });
    };
    CasingComponentApiService.prototype.getCasingRunningTallyExcel_RunOnly = function (CasingStringId) {
        return this.http.get(this.getURLRep("/getCasingRunningTallyExcel/RunOnly"), {
            params: {
                CasingStringId: CasingStringId,
            },
            responseType: 'blob',
            observe: 'response',
        });
    };
    CasingComponentApiService.ngInjectableDef = i0.defineInjectable({ factory: function CasingComponentApiService_Factory() { return new CasingComponentApiService(i0.inject(i1.HttpClient)); }, token: CasingComponentApiService, providedIn: "root" });
    return CasingComponentApiService;
}());
export { CasingComponentApiService };
