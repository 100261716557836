var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OfflineActivity, OfflineActivityDates } from '#models/index';
import { OfflineActivityService } from '#services-shared/jobs/offline-activity.service';
import { OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs/Subscription';
//? OA = Offline Activity
//? OAD = Offline Activity Dates
//? OAT = Offline Activity Timelog
var OfflineActivityComponent = /** @class */ (function () {
    //#endregion 'Variables'
    //#region 'Angular Life Cycle'
    function OfflineActivityComponent(_message, _offlineActivity) {
        this._message = _message;
        this._offlineActivity = _offlineActivity;
        //#region 'Variables'
        this.setToastType = '';
        this.preventSelectOA = false;
        this.preventSelectOAD = false;
        //? Offline Activity
        this.isOAUpdate = false;
        this.showCreateNewOA = false;
        this.showErrorCreateNewOA = false;
        //? Offline Activity Dates
        this.isOADUpdate = false;
        this.areDatesInvalids = false;
        this.showCreateNewOAD = false;
        this.showEndDateErrorOAD = false;
        this.showStartDateErrorOAD = false;
        this.showErrorCreateNewOAD = false;
        this.validateDates = false;
        //? Subscriptions
        this.SUBS$ = new Subscription();
    }
    OfflineActivityComponent.prototype.ngOnInit = function () {
        this.loadForms();
        this.loadOA();
    };
    OfflineActivityComponent.prototype.ngOnDestroy = function () {
        this.SUBS$.unsubscribe();
    };
    //#endregion 'Angular Life Cycle'
    //#region 'Load'
    OfflineActivityComponent.prototype.loadForms = function () {
        this.formOA = new FormGroup({
            name: new FormControl('', Validators.required),
        });
        this.formOAD = new FormGroup({
            startDate: new FormControl('', Validators.required),
            endDate: new FormControl('', Validators.required),
            comments: new FormControl(''),
        });
        this._onStartDateFormChanges();
        this._onEndDateFormChanges();
    };
    //#endregion 'Load'
    //? Offline Activity
    //#region 'Load -- Offline Activity'
    OfflineActivityComponent.prototype.loadOA = function () {
        var _this = this;
        this._offlineActivity.loadOA().then(function (OADone) {
            if (OADone) {
                _this._offlineActivity.getOADByOAId();
            }
        });
    };
    //#endregion 'Load -- Offline Activity'
    //#region 'General Methods -- Offline Activity'
    OfflineActivityComponent.prototype.selectOA = function (index) {
        if (!this.preventSelectOA) {
            this._offlineActivity.selectedOAId = index;
            this._offlineActivity.getOADByOAId();
        }
    };
    //#endregion 'General Methods -- Offline Activity'
    //#region 'Modals -- Offline Activity'
    OfflineActivityComponent.prototype.openOA = function (isEdit, index) {
        this.isOAUpdate = isEdit;
        this.preventSelectOA =
            index !== null && index !== undefined && index >= 0 && index !== this._offlineActivity.selectedOAId
                ? false
                : true;
        if (isEdit && index !== null && index !== undefined && index >= 0) {
            this._offlineActivity.selectedOAId = index;
            this.formOA.controls['name'].setValue(this._offlineActivity.OAList[this._offlineActivity.selectedOAId].Name.Value);
        }
        this.showCreateNewOA = true;
    };
    OfflineActivityComponent.prototype.closeOA = function () {
        this.formOA.reset();
        this.preventSelectOA = false;
        this.showCreateNewOA = false;
        this.showErrorCreateNewOA = false;
    };
    //#endregion 'Modals -- Offline Activity'
    //#region 'Validations -- Offline Activity'
    OfflineActivityComponent.prototype.isOAValid = function () {
        if (!this.formOA.valid) {
            this.showErrorCreateNewOA = true;
            return false;
        }
        else {
            this.showErrorCreateNewOAD = false;
            return true;
        }
    };
    //#endregion 'Validations -- Offline Activity'
    //#region 'CRUD -- Offline Activity'
    OfflineActivityComponent.prototype.setOA = function (isEdit) {
        var dto = new OfflineActivity();
        if (!isEdit) {
            dto.WellId.Value = this._offlineActivity.wellId;
            dto.JobId.Value = this._offlineActivity.jobID;
        }
        else {
            dto = this._offlineActivity.OAList[this._offlineActivity.selectedOAId];
        }
        dto.Name.Value = this.formOA.controls['name'].value;
        return dto;
    };
    OfflineActivityComponent.prototype.createOA = function () {
        var _this = this;
        if (this.isOAValid()) {
            this._offlineActivity.createOA(this.setOA(false)).then(function (done) {
                _this.loadOA();
                _this.showNotification(done);
            }, function (error) {
                _this.showNotification(error);
            });
            this.closeOA();
        }
    };
    OfflineActivityComponent.prototype.updateOA = function () {
        var _this = this;
        if (this.isOAValid()) {
            this._offlineActivity.updateOA(this.setOA(true)).then(function (done) {
                _this.loadOA();
                _this.showNotification(done);
            }, function (error) {
                _this.showNotification(error);
            });
            this.closeOA();
        }
    };
    //#endregion 'CRUD -- Offline Activity'
    //? Offline Activity Dates
    //#region 'Load -- Offline Activity Dates'
    OfflineActivityComponent.prototype._onStartDateFormChanges = function () {
        var _this = this;
        this.SUBS$.add(this.formOAD.get('startDate').valueChanges.subscribe(function () {
            if (_this.validateDates) {
                _this.areDatesValid();
            }
        }));
    };
    OfflineActivityComponent.prototype._onEndDateFormChanges = function () {
        var _this = this;
        this.SUBS$.add(this.formOAD.get('endDate').valueChanges.subscribe(function () {
            if (_this.validateDates) {
                _this.areDatesValid();
            }
        }));
    };
    //#endregion 'Load -- Offline Activity Dates'
    //#region 'General Methods -- Offline Activity Dates'
    OfflineActivityComponent.prototype.editOADDate = function (index) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(index !== null && index !== undefined && index >= 0 && index !== this._offlineActivity.selectedOADId)) return [3 /*break*/, 2];
                        this.preventSelectOAD = false;
                        return [4 /*yield*/, this.selectOAD(index).then(function () {
                                _this.preventSelectOAD = true;
                            })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        this.preventSelectOAD = true;
                        _a.label = 3;
                    case 3:
                        this.openOAD(true);
                        return [2 /*return*/];
                }
            });
        });
    };
    OfflineActivityComponent.prototype.selectOAD = function (index) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            try {
                if (!_this.preventSelectOAD) {
                    _this._offlineActivity.selectedOADId = index;
                    _this._offlineActivity.changeOADSelection().then(function () {
                        resolve(true);
                    });
                }
                else {
                    resolve(true);
                }
            }
            catch (err) {
                reject(Error(err));
            }
        });
    };
    OfflineActivityComponent.prototype.loadOADToEdit = function () {
        this.validateDates = false;
        this.formOAD.controls['startDate'].setValue(this._offlineActivity.OADObject.StartDate.Value);
        this.formOAD.controls['endDate'].setValue(this._offlineActivity.OADObject.EndDate.Value);
        this.formOAD.controls['comments'].setValue(this._offlineActivity.OADObject.Comment.Value);
        this.validateDates = true;
    };
    OfflineActivityComponent.prototype.resetFormOAD = function () {
        this.formOAD.reset();
        this.formOAD.controls['startDate'].setValue(moment().format('MM/DD/YYYY 00:00'));
        this.formOAD.controls['endDate'].setValue(moment().add(1, 'days').format('MM/DD/YYYY 00:00'));
    };
    //#endregion 'General Methods -- Offline Activity Dates'
    //#region 'Modals -- Offline Activity Dates'
    OfflineActivityComponent.prototype.openOAD = function (isEdit) {
        this.validateDates = false;
        this.isOADUpdate = isEdit;
        if (isEdit) {
            this.loadOADToEdit();
        }
        else {
            this.resetFormOAD();
            this._offlineActivity.setDataToValidateDates(false);
            this.areDatesValid();
        }
        this.showCreateNewOAD = true;
        this.validateDates = true;
    };
    OfflineActivityComponent.prototype.closeOAD = function (calculate) {
        this.validateDates = false;
        this.areDatesInvalids = false;
        this.preventSelectOAD = false;
        this.showCreateNewOAD = false;
        this.showEndDateErrorOAD = false;
        this.showStartDateErrorOAD = false;
        this.showErrorCreateNewOAD = false;
        if (calculate)
            this._offlineActivity.calculateRigTime(null, null, true);
        this.resetFormOAD();
    };
    //#endregion 'Modals -- Offline Activity Dates'
    //#region 'Validations -- Offline Activity Dates'
    OfflineActivityComponent.prototype.isOADValid = function () {
        if (!this.formOAD.valid) {
            this.showErrorCreateNewOAD = true;
            return false;
        }
        if (this.areDatesInvalids) {
            return false;
        }
        if (this.showStartDateErrorOAD) {
            return false;
        }
        if (this.showEndDateErrorOAD) {
            return false;
        }
        if (this._offlineActivity.showOADTimeError) {
            return false;
        }
        this._offlineActivity.showOADTimeError = false;
        this.areDatesInvalids = false;
        this.showErrorCreateNewOAD = false;
        return true;
    };
    OfflineActivityComponent.prototype.areDatesValid = function () {
        var start = moment(this.formOAD.controls['startDate'].value).format('MM/DD/YYYY H:mm');
        var end = moment(this.formOAD.controls['endDate'].value).format('MM/DD/YYYY H:mm');
        if (start) {
            this.showStartDateErrorOAD = false;
            this.showStartDateErrorOAD = this._offlineActivity.checkDatesExist(start, true);
        }
        if (end) {
            this.showEndDateErrorOAD = false;
            this.showEndDateErrorOAD = this._offlineActivity.checkDatesExist(end, false);
        }
        if (start && end) {
            this.areDatesInvalids = moment(start).isAfter(end);
            this._offlineActivity.calculateRigTime(start, end, false);
        }
        else {
            this.areDatesInvalids = false;
        }
    };
    OfflineActivityComponent.prototype.isRigTimeOADValid = function () {
        var NEWRIGTIME = this._offlineActivity.OADTime;
        var AVAILABLETIME = this._offlineActivity.getOADTime(this.formOAD.controls['startDate'].value, this.formOAD.controls['endDate'].value);
        if (NEWRIGTIME > 24 || NEWRIGTIME > AVAILABLETIME || NEWRIGTIME < this._offlineActivity.TMLTime) {
            this._offlineActivity.showOADTimeError = true;
            return false;
        }
        else {
            this._offlineActivity.showOADTimeError = false;
            return true;
        }
    };
    //#endregion 'Validations -- Offline Activity Dates'
    //#region 'Toast'
    OfflineActivityComponent.prototype.showMessage = function (msg) {
        if (msg.clear) {
            this.hideToast();
        }
        if (msg.toastType) {
            this.setToastType = msg.toastType;
        }
        this._message.clear();
        this._message.add({
            key: msg.key,
            sticky: msg.sticky,
            severity: msg.severity,
            summary: msg.summary,
            detail: msg.detail,
        });
    };
    OfflineActivityComponent.prototype.onReject = function () {
        this.hideToast();
    };
    OfflineActivityComponent.prototype.hideToast = function () {
        this._message.clear('c');
        this.preventSelectOAD = false;
    };
    OfflineActivityComponent.prototype.onConfirm = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.hideToast();
                if (this.setToastType === 'DeleteOAD') {
                    this._offlineActivity.deleteOAD().then(function (done) {
                        _this.loadOA();
                        _this.showNotification(done);
                    }, function (error) {
                        _this.showNotification(error);
                    });
                }
                else if (this.setToastType === 'DeleteTimeLogOA') {
                    this._offlineActivity.deleteOAT().then(function (done) {
                        _this.showNotification(done);
                    }, function (error) {
                        _this.showNotification(error);
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    //#endregion 'Toast'
    //#region 'CRUD -- Offline Activity Dates'
    OfflineActivityComponent.prototype.setOAD = function (isEdit) {
        var dto = new OfflineActivityDates();
        if (!isEdit) {
            dto.WellId.Value = this._offlineActivity.wellId;
            dto.OAId.Value = this._offlineActivity.OAList[this._offlineActivity.selectedOAId].IdRec.Value;
        }
        else {
            dto = this._offlineActivity.OADList[this._offlineActivity.selectedOADId];
        }
        dto.StartDate.Value = moment(this.formOAD.controls['startDate'].value).format('MM/DD/YYYY H:mm');
        dto.EndDate.Value = moment(this.formOAD.controls['endDate'].value).format('MM/DD/YYYY H:mm');
        dto.Comment.Value = this.formOAD.controls['comments'].value;
        return dto;
    };
    OfflineActivityComponent.prototype.createOAD = function () {
        var _this = this;
        if (this.isOADValid()) {
            this._offlineActivity.createOAD(this.setOAD(false)).then(function (done) {
                _this.showNotification(done);
            }, function (error) {
                _this.showNotification(error);
            });
            this.closeOAD();
        }
    };
    OfflineActivityComponent.prototype.updateOAD = function () {
        var _this = this;
        if (this.isOADValid() && this.isRigTimeOADValid()) {
            this._offlineActivity.updateOAD(this.setOAD(true)).then(function (done) {
                _this.showNotification(done);
            }, function (error) {
                _this.showNotification(error);
            });
            this.closeOAD();
        }
    };
    OfflineActivityComponent.prototype.deleteOAD = function (oad) {
        this.preventSelectOAD = true;
        this._offlineActivity.OADDeleteObject = oad;
        this.showMessage({
            key: 'c',
            sticky: true,
            severity: 'warn',
            summary: 'Delete Offline Activity Desc',
            detail: 'Are you sure you want to delete the selected Offline Activity Desc?',
            clear: true,
            toastType: 'DeleteOAD',
        });
    };
    //#endregion 'CRUD -- Offline Activity Dates'
    //#region 'Notification'
    OfflineActivityComponent.prototype.showNotification = function (msg) {
        this._message.add({
            key: msg && msg.key && msg.key.length > 0 ? msg.key : 'OANotify',
            sticky: msg && msg.key && msg.key != 'OANotify' ? true : false,
            closable: msg && msg.key && msg.key != 'OANotify' ? true : false,
            severity: msg.severity,
            summary: msg.summary,
            detail: msg.detail,
            life: msg.life,
        });
    };
    return OfflineActivityComponent;
}());
export { OfflineActivityComponent };
