/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./task-plan-section-delete.component";
import * as i2 from "../../../services/api/shared/task-plan-api.service";
import * as i3 from "../../../services/shared/loading-indicator.service";
import * as i4 from "@angular/router";
var styles_TaskPlanSectionDeleteComponent = [".centerButtonContainer[_ngcontent-%COMP%] {\n    display: flex;\n    justify-content: center;\n  }"];
var RenderType_TaskPlanSectionDeleteComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_TaskPlanSectionDeleteComponent, data: {} });
export { RenderType_TaskPlanSectionDeleteComponent as RenderType_TaskPlanSectionDeleteComponent };
export function View_TaskPlanSectionDeleteComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 18, "div", [["class", "box-shadow module"], ["style", "width:80%; margin:auto; background-color: midnightblue; color: white"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Delete Task Plan Section"])), (_l()(), i0.ɵeld(3, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 5, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Are you sure you want to delete "])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [["style", "color:red"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), (_l()(), i0.ɵeld(8, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, [" from Task Plan ", " ? "])), (_l()(), i0.ɵeld(10, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["All the related subsection(s) | item(s) will be removed."])), (_l()(), i0.ɵeld(12, 0, null, null, 6, "div", [["class", "form-actions no-color"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 5, "div", [["class", "centerButtonContainer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 1, "a", [["class", "btn btn-default"], ["style", "width: 100px;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteTaskPlanSection() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Delete"])), (_l()(), i0.ɵted(-1, null, [" \u00A0 \u00A0 "])), (_l()(), i0.ɵeld(17, 0, null, null, 1, "a", [["class", "btn btn-default"], ["style", "width: 100px;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onRedirectButtonClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Back"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.taskPlanSectionDesc; _ck(_v, 7, 0, currVal_0); var currVal_1 = _co.taskPlanDesc; _ck(_v, 9, 0, currVal_1); }); }
export function View_TaskPlanSectionDeleteComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-task-plan-section-delete", [], null, null, null, View_TaskPlanSectionDeleteComponent_0, RenderType_TaskPlanSectionDeleteComponent)), i0.ɵdid(1, 245760, null, 0, i1.TaskPlanSectionDeleteComponent, [i2.TaskPlanApiService, i3.LoadingIndicatorService, i4.Router, i4.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TaskPlanSectionDeleteComponentNgFactory = i0.ɵccf("app-task-plan-section-delete", i1.TaskPlanSectionDeleteComponent, View_TaskPlanSectionDeleteComponent_Host_0, {}, {}, []);
export { TaskPlanSectionDeleteComponentNgFactory as TaskPlanSectionDeleteComponentNgFactory };
